/**
 * @Contact Accordion Functionality
 */

// on load we make sure all accordions, other than the first are hidden
.contact-accordion {
	> .row .module-free-text-header {
		margin-top: 3rem;
		margin-bottom: 2rem;
	}
	.accordion-wrap > .accordion-content{
		display: none;
		max-width: 100%;
	}
	.first > .accordion-content{
		display: block;
	}
}