@keyframes modalFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes modalFadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

body {
	position: relative;
	overflow-y: auto;

	&.modal-open {
		overflow: hidden;

		// scrollhack to avoid page jumping with modal. will work with new navigation. desktop only (breaks zoom in mobile)
		// overflow-y: scroll;
		// position: fixed;
	}
}

html.touchevents body.modal-open {
	position: fixed;
}




.modal {
	display: none;
	align-items: center;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 3000;

	padding: 20px;

	width: 100%;
	height: 100%;

	background-color: rgba( 0, 0, 0, 0.8 );
	opacity: 0;

	&.product-modal {
		// this should not affect other modals. refactor this later
		animation: modalFadeOut 0.3s forwards;
	}
	
	&.in {
		opacity: 1;
		animation: modalFadeIn 0.3s forwards;
	}
}

.modal-dialog {
	position: relative;
	margin: 0 auto;
	border-radius: $base-border-radius;
	width: 100%;
	max-width: 61.2rem;

	background-color: #fff;
}

.modal-content {

}

.modal-header {
	position: absolute;
	width: 100%;
	z-index: 5000;	

	@include media($large) {
		
		z-index: 300;
	}
	

	@include padding( 1rem 0 1.2rem );

	h1, h2, h3, h4, h5, h6 {
		font-size: rem( 18px );
		text-align: center;
		text-transform: uppercase;
		font-family: $brown-light;
	}
}

.modal-body {
	position: relative;
	z-index: 190;
	// @include padding( 1rem 0 1.2rem );
}

.modal-footer {
	@include padding( 1rem 0 1.2rem );
}

.modal-close {
	position: absolute;
	width: 60px;
	height: 60px;
	top: 0;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	padding: 15px;

	/*@include media($large) {

		padding: 15px;
	}*/

	background-color: transparent;
	color: #000;
	text-align: center;
	z-index: 5100;

	.layout-reversed & {
		right: auto;
		left: 0;
	}

	&:before {
		font-size: inherit;
		line-height: inherit;
		height: auto;
	}

	&:active,
	&:active:focus,
	&:focus {
		outline: none;
	}

	&:hover {
		background-color: transparent;
		color: #000;
		opacity: 0.7;
	}
}

#share-modal {

	.modal-dialog {
		@include padding(2rem null 2.6rem);
	}

	.modal-header {
		position: static;
	}

	.modal-body {
		min-height: 1rem;
		min-height: initial;
	}
}