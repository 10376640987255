.page-maintenance_cos {

	.main-header {
		.nav-container {
			position: relative;

			 .logo-container {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);

				.village-logo {
					height: 50px;
					margin-top: 2px;
				}
			}
		}
	}
}

.maintenance {
	.site-canvas {
		padding-top: 0;
	}
	.page-maintenance_cos {
		margin-top: 0;
	}
	.main-header {
		height: auto;
		border-bottom: 30px solid $teal;
		position: relative;
	}
	.header-logo {
		padding: 30px 0 0;
		text-align: center;
		max-width: 340px;
		margin: 0 auto;
		img {
			margin: 0 auto;
		}
	}
}
