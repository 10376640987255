
.full-video {
	position: relative;
	width: 100%;
	margin: 0 auto rem(40) auto;
	@include padding(rem(20) null 53.25%);

	// video widget
	&.module {

		margin-bottom: rem(60);

		.play-button {
			@extend %m-t-none;
		}
	}
}

.video-cover-overlay {
	width: 100%;
	height: 100%;
	@include radial-gradient(circle at 50% 50%, rgba(0,0,0,0.4) 10%, rgba(0,0,0,0) 70%);
	position: absolute;
	top: 0;
	left: 0;
}

.transparent-video-img {
	width: 100%;
	position: relative;
	z-index: -10;
}

.full-video .video-brightcove,
.full-video iframe,
.video-cover {
	@include position(absolute, 0 null null 0);
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	@include transform(translate3d(0,0,0));
}
.video-cover {
	z-index: 10;
	background-color: black;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;

	.no-touchevents .slider-sync-simple & {
		background: none !important;
	}
}

.video-cover-content {
	width: 100%;
	@include position(absolute, 50% null null);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	@extend %margin-center;
	@include padding(2rem 2.1rem 2rem);
	color: white;
	text-align: center;

	h2 {
		margin-bottom: rem(10);
		font-size: rem(24);
		@include base-font-family;
		text-transform: uppercase;
	}

	p {
		@include base-font-family;
		font-size: rem(12);
	}

	.play-button {
		width: rem(50);
		margin-top: rem(10);
	}

	// tablet small
	@include media($small) {
		h2 {
			margin-bottom: rem(20);
			font-size: rem(40);
		}
		p {
			font-size: rem(15);
		}
		.play-button {
			width: rem(79);
			margin-top: rem(20);
		}
	}

	// tablet large
	@include media($medium) {
		h2 {
			margin-bottom: rem(40);
		}
		.play-button {
			margin-top: rem(40);
		}
	}
}

.video-embed {
	z-index: 1;
}

.full-video .video-brightcove,
.full-video iframe {
	z-index: 5;
}

// video widget
.video-widget-title {
	@include position(absolute, null 0 0 0);
	@extend %m-b-none;
	@include padding(1.2rem 1rem 1rem);
	color: #fff;
	font-size: rem(18);
	@include base-font-family;
	text-transform: uppercase;
	text-align: center;
	background: rgba(0,138,157,0.8);
}
.vjs-dock-text {
	display: none;
}

// video full-width and vertical center
.vjs-tech {
 	height: auto !important;
 	position: relative !important;
  	top: 50% !important;
  	transform: translateY(-50%);
 }
