.slider-partners-wrapper {
	height: auto !important;

	.section-title-wrapper {
		padding-bottom: 0;
		border-bottom: 0;
		color: $teal;

		.section-title {
			font-family: $brown-regular;
			font-size: 18px;
		}
	}

	.slider-manual {

		&:before,
		&:after {
			content: '';
			@include position(absolute, 0 null);
			z-index: 20;
			display: block;
			width: rem(25);
			height: 100%;
			background-repeat: repeat-y;
		}

		&:before {
			left: 0;
			@include background-image(linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%));
		}
		&:after {
			right: 0;
			@include background-image(linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%));
		}
	}
}

.partner-image-wrapper {
	@include padding(null rem(20) rem(12));
}
.partner-brand {
	margin-bottom: 3rem;

	.slider-partners-wrapper & {
		margin-bottom: 0;
	}

	h5 {
		color: #000;
		font-size: 1.0833rem;
		font-family: $brown-regular;
		letter-spacing: 0.05rem;
		text-transform: uppercase;
	}
}
