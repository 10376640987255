@import 'global';
@media only screen and (max-width: $medium-max) {
	.no-touchevents {
		@import 'off-canvas';
	}
}
.touchevents {
	@import 'off-canvas';
}

@import 'nav';
@import 'language-village';
@import 'search';
@import 'social-links';
@import 'login';