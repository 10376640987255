// =============================================================================
//  TYPOGRAPHY
// =============================================================================

html {
	font-size: 75%;
}

body {
	@include font-feature-settings('kern', 'liga', 'pnum');
	-webkit-font-smoothing: antialiased;
	color: $base-font-color;
	@include base-font-family;

	@if $is-opd != true {
		font-size: $base-font-size;
		font-size: 12px;
	}
	line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include margin(0 0 $small-spacing);
	@include heading-light-font-family;
	font-size: $base-font-size;
	font-weight: initial;
	line-height: $heading-line-height;
	letter-spacing: 0.1rem;

	a {
		color: inherit;
	}

	.layout-reversed & {
		letter-spacing: 0;
	}
}

p {
	@include margin(0 0 $small-spacing);

	strong {
		@include base-bold-font-family;
	}
}

a {
	color: $action-color;
	text-decoration: none;
	@include transition(all 0.2s linear);

	&:active,
	&:focus,
	&:hover {
		opacity: 0.7;

	}

	&:active {
		outline: none;
	}

	.module-img-content & {
		opacity: 1 !important;
	}
}

hr {
	margin: $base-spacing 0;
	border-bottom: $base-border;
	border-left: none;
	border-right: none;
	border-top: none;
}

img,
picture {
	max-width: 100%;
	@extend %m-none;
}

.ellipsis {
	overflow: hidden;
}
