.partner-promotion-component-wrapper-new {

    &.module-hero-new {
        height: auto;
        background: none;


        .module-image {
            height: 21.6rem;

            .mobile-tab {
                margin-bottom: rem(40);
            }

            @include media($small) {
                height: 42rem;
            }
        }

        .module-content-wrapper {
            position: relative;
            top: auto;
            max-width: rem(1040);
            height: auto;
            margin-top: rem(-136px);
            margin-bottom: rem(40);
            box-shadow: none;
            transform: none;

            @include media($small-down) {
                margin-top: -9.3333rem;
                padding-top: 0px;
            }

            header {
                margin-bottom: 1.6rem;
            }
        }

        .module-content {
            position: static;
            max-width: none;
            transform: none;
            width: calc(100% - 0rem);

            @include media($small-down) {
                width: calc(100% - 0rem);
            }

            .module-title-deco {
                width: 50%;
                margin: 0 auto;
                font-family: 'Bodoni MT', brow-light, sans-serif;

                @include media($small-down) {
                    width: 100%;
                    margin-top: 12px !important;
                    font-size: 1.6rem;
                }
            }

            > ul,
            > ol {
                font-size: 1.3333rem;
                @include margin(2rem null 2.4rem);
            }

            .mobile-gap {
                margin-bottom: 4rem;
            }

            .promotion-width {
                padding-top: 3rem;
                max-width: 88rem;
                text-align: justify;
                text-align-last: center;
                margin-bottom: 2rem;

                > label {

                    > p {
                        max-width: 100%;
                        text-align: justify;

                        > span {
                            max-width: 100%;
                            text-align: justify;
                        }
                    }
                }

                > p {
                    max-width: 100%;
                    text-align: justify;

                    > span {
                        max-width: 100%;
                        text-align: justify;
                    }
                }

                > span {
                    max-width: 100%;
                    text-align: justify;
                }
            }

            .promotion-width-TermsConditions {
                padding-top: 2rem;
                max-width: 88rem;
                text-align: justify;
                margin-bottom: 2rem;
                display: none;

                > label {

                    > p {
                        max-width: 100%;
                        text-align: justify;

                        > span {
                            max-width: 100%;
                            text-align: justify;
                        }
                    }
                }

                > p {
                    max-width: 100%;
                    text-align: justify;

                    > span {
                        max-width: 100%;
                        text-align: justify;
                    }
                }

                > span {
                    max-width: 100%;
                    text-align: justify;
                }
            }

            .btn-termsAndConditions {
                margin: 0 auto;
                /*@include media($medium) {
                    display: none;
                }

                @include media($medium-large) {
                    display: none;
                }

                @include media($medium-small) {
                    display: none;
                }

                @include media($small-down) {
                    display: block;
                }*/
            }


            div {
                margin: 0 auto;

                &.wrapper-partnerimage-disposition-new {
                    border-bottom: 25px;
                    width: 100%;
                    display: table;

                    @include media($small-down) {
                        height: auto;
                        margin: 0 auto;
                        display: block;

                        > div {
                            display: none;
                        }
                    }

                    .partner-Image {
                        display: table-cell;
                        text-align: center;
                        vertical-align: middle;
                        height: 100px;

                        @include media($small-down) {
                            display: block;
                            width: 100%;
                            height: auto;
                        }
                    }

                    .wrapper-partnerimage-disposition-item-new {
                        vertical-align: middle;
                        float: left;

                        @include media($small-down) {
                            margin: 0 auto;
                            vertical-align: middle;
                            text-align: center;
                            display: block;
                            float: none;
                        }
                    }

                    .wrapper-partnerimage-disposition-item-2-new {
                        vertical-align: middle;
                        float: right;

                        @include media($small-down) {
                            margin: 0 auto;
                            vertical-align: middle;
                            text-align: center;
                            display: block;
                            float: none;
                        }
                    }
                }

                &.promotion-partnerimage-disposition-new {
                    height: 170px;
                    white-space: nowrap;

                    @include media($small-down) {
                        height: auto;
                        margin: 0 auto;
                    }

                    .promotion-partnerimage-only-item {
                        max-height: 170px;
                    }

                    .promotion-partnerimage-disposition-item-new {
                        max-height: 170px;
                        vertical-align: middle;
                        margin-right: 25px;

                        @include media($small-down) {
                            margin: 0 auto;
                            vertical-align: middle;
                            text-align: center;
                            display: block;
                        }
                    }

                    .promotion-partnerimage-disposition-middle {
                        height: 100%;
                        display: inline-block;
                        vertical-align: middle;
                        border-right: 1px solid #000;

                        @include media($small-down) {
                            left: 0;
                            right: 0;
                            width: 3.75rem;
                            margin-right: auto;
                            margin-left: auto;
                            content: '';
                            position: absolute;
                            display: block;
                            height: 1px;
                            background: #000;
                        }
                    }

                    .promotion-partnerimage-disposition-item-2-new {
                        max-height: 170px;
                        vertical-align: middle;
                        padding-left: 40px;

                        @include media($small-down) {
                            margin: 0 auto;
                            vertical-align: middle;
                            text-align: center;
                            display: block;
                            padding-left: 0px;
                        }
                    }
                }


                &.promotion-partnerimage-disposition {
                    display: none;
                    //padding: 0px 0px 0px 0px;
                    margin: 0 auto;

                    @include media($small-down) {
                        display: none;
                        padding: 0px 0px 0px 0px;

                        > div {
                            margin: 0 auto;
                        }
                    }

                    .promotion-partnerimage-disposition-item-2 {
                        margin-left: -90px;

                        @include media($small-down) {
                            margin: 0 auto;
                        }

                        > img {
                            max-height: 200px;
                            max-width: 170px;
                        }
                    }

                    .promotion-partnerimage-disposition-item {
                        vertical-align: middle;


                        @include media($medium) {
                            padding-top: 5rem;
                            border-right: 1px solid #999;
                        }

                        @include media($medium-large) {
                            padding-top: 5rem;
                            border-right: 1px solid #999;
                        }

                        @include media($medium-small) {
                            padding-top: 5rem;
                            border-right: 1px solid #999;
                        }
                        /*
                        @include media($small) {
                            border-right: 1px solid #999;
                        }*/
                        @include media($small-down) {
                            padding-top: 0rem;
                            max-width: 16rem;
                            position: relative;

                            &:after {
                                left: 0;
                                right: 0;
                                width: 3.75rem;
                                margin-right: auto;
                                margin-left: auto;
                                content: '';
                                position: absolute;
                                display: block;
                                height: 1px;
                                background: #000;
                            }
                        }
                    }
                }
            }
        }


        p {
            /*max-width: rem(640px);
            @include margin(null auto null);
            text-align: center;*/
            max-width: 44rem;
            @include margin(null auto null);
            text-align: left;

            @include media($small) {
                text-align: center;
            }


            &.tiny {
                margin-bottom: 0;
                font-size: rem(12);
                @include font-family-serif-italic;
            }

            .layout-reversed & {
                text-align: right;

                @include media($small) {
                    text-align: center;
                }

                &.searchDd-main {
                    text-align: right;
                }
            }
        }

        ul {
            text-align: left;

            @include media($small) {
                text-align: center;
            }
        }

        .brand-contact {
            max-width: 20rem;
            @include margin(2rem auto);
            @include padding(1rem null);
            text-align: center;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            text-align: inherit;

            li {
                margin-bottom: 0.2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                color: #000;
            }
        }

        .brand-logo {
            max-width: 16rem;
            @include margin(null auto);
        }

        .barcode {
            display: block;
            max-width: rem(168);
            @include margin(-0.7rem auto);
        }

        .button {

            .layout-reversed & {
                text-align: center;
            }
        }

        .terms-conditions-title {
            @include media($small-down) {
                margin-left: 17%;
            }

            margin-left: 36%;
            float: left;
            font-size: 15px;
            padding-left: 20px;
            text-transform: uppercase
        }
    }
}


.ellip {
    display: block;
    height: 100%;
}

.ellip-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
}

.ellip,
.ellip-line {
    position: relative;
    overflow: hidden;
}

.btn-print {
    @include media($small-down) {
        display: none;
    }
}
/*@media print {
    .noPrint {
        display: none;
    }

    .module-text-subheader {
        text-align: center !important;
    }

    .yesPrint {
        display: block !important;
    }

    .wrapper-partnerimage-disposition-item-2-new {
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        display: block !important;
        float: none !important;
    }

    .wrapper-partnerimage-disposition-new {
        height: auto !important;
    }

    .wrapper-partnerimage-disposition-item-new {
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        display: block !important;
        float: none !important;
    }
}*/
@media print {

    .noPrint {
        display: none;
    }

    .yesPrint {
        display: block !important;

        > p {
            margin: 0 auto;
            text-align: center;
        }
    }

    .promotion-width-TermsConditions {
        display: block;
    }

    .module-content-wrapper {
        width: 100%;

        > p {
            margin: 0 auto;
            text-align: center;
        }

        .module-text-subheader {
            margin: 0 auto;
            text-align: center;
        }
    }

    .wrapper-partnerimage-disposition-item-2-new {
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        display: block !important;
        float: none !important;
    }

    .wrapper-partnerimage-disposition-new {
        height: auto !important;
    }

    .wrapper-partnerimage-disposition-item-new {
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        display: block !important;
        float: none !important;
    }
}

.module-text-subheader {
    color: #333 !important;
    font-family: bonodiStd-roman,serif;
    letter-spacing: .1rem;
    text-transform: none;
    text-align: center;
    margin-bottom: 15px !important;
}
