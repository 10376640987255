// RADIO BUTTONS

[type="radio"] {
	// Remove default Radio Buttons
	position: absolute;
	left: -9999px;

	& + label {
		position: relative;
		padding-right: 10px;
		padding-left: 25px;
		cursor: pointer;
		display: inline-block;
		min-height: 25px;
		line-height: 15px;
		font-size: 1rem;
		@include transition(all .28s ease);
		padding-top: rem(5);

		-webkit-user-select: none; // webkit (safari, chrome) browsers
		-moz-user-select: none; // mozilla browsers
		-khtml-user-select: none; // webkit (konqueror) browsers
		-ms-user-select: none; // IE10+

		// Unchecked styles
		&:after,
		&:before {
			content: '';
			position: absolute;
			left: 0px;
			top: 0px;
			margin: 4px;
			width: 16px;
			height: 16px;
			z-index: 0;
			@include transition(.28s ease);
		}

		&:before {
			border-radius: 50%;
			border: 1px solid $radio-border;
		}

		&:after {
			border-radius: 50%;
			border: 1px solid $radio-border;
			z-index: -1;

			@include transform(scale(0));
		}
	}

	// Checked styles
	&:checked {

		& + label:before {
			border-radius: 50%;
			border: 1px solid transparent;
		}
		& + label:after {
			border-radius: 50%;
			border: 1px solid $radio-fill-color;
			background-color: $radio-fill-color;
			z-index: 0;
			@include transform(scale(1.02));
		}
	}

	&:focus + label,
	&:focus + input[type="hidden"] + label {
		outline-width: 2px;
		outline-style: solid;
		outline-color: Highlight;

		@media (-webkit-min-device-pixel-ratio:0) {
			outline: -webkit-focus-ring-color auto 5px;
		}
	}

	// Radio With gap
	&.with-gap {

		&:checked {

			& + label{

				&:before {
					border-radius: 50%;
					border: 1px solid $radio-fill-color;
				}

				&:after {
					border-radius: 50%;
					border: 1px solid $radio-fill-color;
					background-color: $radio-fill-color;
					z-index: 0;
					@include transform(scale(.5));
				}
			}
		}
	}

	// Disabled style
	[type="radio"]:disabled {

		& + label {
			color: $disabled-color;

			&:before {
				background-color: transparent;
				border-color: $disabled-color;

				&:hover {
					border-color: $disabled-color;
				}
			}
		}
	}
}

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: $disabled-color;
}
[type="radio"]:disabled + label {
  color: $disabled-color;
}
[type="radio"]:disabled:not(:checked) + label:hover:before {
  border-color: $disabled-color;
}


/***************
   Checkboxes
***************/
.checkbox-wrap {
  position: relative;
  line-height: 150%;
  margin: rem(20) 0 rem(10) rem(5);

  label {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    padding-left: rem(28);

    [type="radio"] + label {
    	position: relative;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: rem(22);
      height: rem(22);
      top: -0.25rem;
      left: -0.4rem;
    }

    &:before {
      @include transition(all .3s ease-in-out);
      z-index: 1;
      border: 1px solid $checkbox-border;
    }
    &:after {
      background: white;
    }
  }

  input[type="checkbox"]:checked ~ label:not(.box-check-trigger) {
    &:before {
     @include transform(rotate(-45deg));
      height: rem(10);
      width: rem(24);
      border-color: $checked-fill-color;
      border-top-style: none;
      border-right-style: none;
    }
  }
}


// Boxed radios
.box-check-wrapper {
	@include clearfix;

	.box-check {
		float: left;
		margin-top: 0.4rem;
		cursor: pointer;
		@include transition(opacity 0.2s ease);

		input[type="checkbox"] {
			display: block;
    		@include position(absolute, rem(9) null null rem(4));
    		opacity: 0.01;
		}

		input[type="checkbox"]:checked ~ .box-check-trigger {

			&:before {
				background-size: 100%;
			}
		}

		input[type="checkbox"]:focus ~ .box-check-trigger {
			outline-width: 2px;
			outline-style: solid;
			outline-color: Highlight;

			@media (-webkit-min-device-pixel-ratio:0) {
				outline: -webkit-focus-ring-color auto 5px;
			}
		}

		&.disabled {
			opacity: 0.6;

			&:hover * {
				cursor: default !important;
			}
		}
	}


	.box-check-trigger {
		position: relative;
		padding-left: rem(25);
		margin-right: rem(14);
		color: #969899;

		&:before {
			content: '';
			@include position(absolute, -1px null null 0);
			display: inline-block;
			width: rem(18);
			height: rem(18);
			background: url(../img/src/deco/checkbox-cross-grey18x18.png) center center no-repeat;
			background-size: 0;
			border: 1px solid #969899;
			@include transition(all 0.3s ease);
		}

		&:after {
			display: none;
		}
	}
}

#login form {
	.checkbox-wrap {
		margin-top: rem(12);
		margin-bottom: rem(12);
	}
}

#login form,
.signin-form-content {
	.checkbox-wrap {
		margin-left: 0;
		// overflow: auto;
		
		label {
			position: relative;
			float: left;
			margin-bottom: 0;
			line-height: rem(25);

			&:before {
				top: 0;
				left: 0;
			}
		}
		input[type=checkbox]{
			display: none;
		}

		.tooltip-wrapper {
			position: relative;
    		float: left;
    		margin-left: 10px;
		}
		.tooltip-trigger {
			width: 1.5rem;
    		height: 1.5rem;
    		&:before{
				font-size: 0.8rem;
    		}
		}
	}
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}