
// Visibility Classes
// based on grid-settings variables, create them on demand

.hidden-small-down {
	@include media($small-down) {
	  display: none !important;
	}
}

.hidden-medium-down {
	@include media($medium-down) {
	  display: none !important;
	}
}

.hidden-medium-up {
	@include media($medium) {
	  display: none !important;
	}
}

.hidden-large-up {
	@include media($large) {
	  display: none !important;
	}
}

// hidden on desktop regardless of screen size
.desktop {
	.hidden-desktop {
		display: none !important;
	}
}