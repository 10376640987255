input::-webkit-calendar-picker-indicator{
    display: none;
}
input[type="date"]::-webkit-input-placeholder{
    visibility: hidden !important;
}

// Layout helpers
.ui-helper-hidden {
    display: none;
}
.ui-helper-hidden-accessible {
    position: absolute;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    @extend %p-none;
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}
.ui-helper-reset {
    @extend %m-none;
    @extend %p-none;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}
.ui-helper-clearfix {

    &:before,
    &:after {
        content: '';
        display: table;
        border-collapse: collapse;
    }

    &:after {
        clear: both;
    }
}
.ui-helper-zfix {
    @include position(absolute, 0 null null 0);
    width: 100%;
    height: 100%;
    opacity: 0;
}

.ui-front {
    z-index: 100;
}


// Interaction Cues
.ui-state-disabled {
    cursor: default !important;
}


// ICONS

// states and images
.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}


// Misc visuals

// Overlays
.ui-widget-overlay {
    @include position(fixed, 0 null null 0);
    width: 100%;
    height: 100%;
}
.ui-datepicker {
    width: 17em;
    padding: .2em .2em 0;
    display: none;

    .ui-datepicker-header {
        position: relative;
        padding: .2em 0;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        @include position(absolute, rem(2) null null);
        width: 1.8em;
        height: 1.8em;

        span {
            @include position(absolute, 50% null null 50%);
            display: block;
            margin-left: -8px;
            margin-top: -8px;
        }
    }

    .ui-datepicker-prev-hover,
    .ui-datepicker-next-hover {
        top: rem(1);
    }

    .ui-datepicker-prev {
        left: rem(2);
    }

    .ui-datepicker-next {
        right: rem(2);
    }

    .ui-datepicker-prev-hover {
        left: rem(1);
    }

    .ui-datepicker-next-hover {
        right: rem(1);
    }

    .ui-datepicker-title {
        margin: 0 2.3em;
        line-height: 1.8em;
        text-align: center;

        select {
            font-size: 1em;
            @include margin(rem(1) 0);
        }
    }

    select.ui-datepicker-month,
    select.ui-datepicker-year {
        width: 45%;
    }

    table {
        width: 100%;
        @include margin(0 0 .4em);
        font-size: .9em;
        border-collapse: collapse;
    }

    th {
        @include padding(.7em .3em);
        text-align: center;
        font-weight: bold;
        border: 0;
    }

    td {
        @include padding(rem(1));
        border: 0;
    }

    td span,
    td a {
        display: block;
        @include padding(.2em);
        text-align: right;
        text-decoration: none;
    }

    .ui-datepicker-buttonpane {
        background-image: none;
        @include margin(.7em 0 0 0);
        @include padding(0 .2em);
        border-left: 0;
        border-right: 0;
        border-bottom: 0;

        button {
            float: right;
            width: auto;
            @include margin(.5em .2em .4em);
            @include padding(.2em .6em .3em .6em);
            cursor: pointer;
            overflow: visible;

            &.ui-datepicker-current {
                float: left;
            }
        }
    }
}

// RTL support
// Add if source language micro site needs it
// .ui-datepicker.ui-datepicker-multi {
//     width: auto;
// }
// .ui-datepicker-rtl {
//     direction: rtl;
// }
// .ui-datepicker-rtl .ui-datepicker-prev {
//     right: 2px;
//     left: auto;
// }
// .ui-datepicker-rtl .ui-datepicker-next {
//     left: 2px;
//     right: auto;
// }
// .ui-datepicker-rtl .ui-datepicker-prev:hover {
//     right: 1px;
//     left: auto;
// }
// .ui-datepicker-rtl .ui-datepicker-next:hover {
//     left: 1px;
//     right: auto;
// }
// .ui-datepicker-rtl .ui-datepicker-buttonpane {
//     clear: right;
// }
// .ui-datepicker-rtl .ui-datepicker-buttonpane button {
//     float: left;
// }
// .ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
// .ui-datepicker-rtl .ui-datepicker-group {
//     float: right;
// }
// .ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
// .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
//     border-right-width: 0;
//     border-left-width: 1px;
// }
