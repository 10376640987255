/* =============================================================================
	MENU TOGGLE SWITCH (hamburger/cross)
============================================================================= */

// vars

$button-width: 24px;
$button-width-active: 20px;
$button-width: 24px;
$button-height: 16px;
$bar-thickness: 2px;
$button-pad: 1.5px;
$button-trans-delay: 0.2s;


.hamburger {
	display: block;
	width: $button-width;
	height: $button-height;
	@extend %m-none;
	@extend %p-none;
	overflow: hidden;
	font-size: 0;
	text-indent: -9999px;
	@include appearance(none);
	box-shadow: none;
	border-radius: 0;
	border: none;
	cursor: pointer;
	@include transition(all 0.5s ease);

	&:focus {
		outline: none;
	}

	&,
	&:hover,
	&:focus {
		background-color: transparent;
	}

	span {
		@include position(absolute, null $button-pad);
		top: ($button-height / 2) - ($bar-thickness / 2);
		display: block;
		height: $bar-thickness;
		background: #777;
		transition: background 0s $button-trans-delay;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			display: block;
			width: 100%;
			height: $bar-thickness;
			background-color: #777;
			transition-duration: $button-trans-delay, $button-trans-delay;
			transition-delay: $button-trans-delay, 0s;
		}

		&:before {
			transform: translateY(-5px);
			transition-property: top, transform;
		}

		&:after {
			transform: translateY(5px);
			transition-property: bottom, transform;
		}
	}

	// Menu open
	@include media($medium-down) {
		.no-touchevents & {
			&.active {
				z-index: 200;
				width: $button-width-active;
				pointer-events: none;
				cursor: default;
				transform: translateX(-260px);

				&,
				&:hover,
				&:focus {
					background-color: transparent;
				}

				span {
					background: none;

					&:before,
					&:after {
						background-color: #fff;
						transition-delay: 0s, $button-trans-delay;
					}

					&:before {
						top: 0;
						transform: rotate(45deg);
					}

					&:after {
						bottom: 0;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	.touchevents & {
		&.active {
			z-index: 200;
			width: $button-width-active;
			pointer-events: none;
			cursor: default;
			transform: translateX(-260px);

			&,
			&:hover,
			&:focus {
				background-color: transparent;
			}

			span {
				background: none;

				&:before,
				&:after {
					background-color: #fff;
					transition-delay: 0s, $button-trans-delay;
				}

				&:before {
					top: 0;
					transform: rotate(45deg);
				}

				&:after {
					bottom: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}
}