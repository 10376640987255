// Edited from jquery-custom-content-scroller
.mCustomScrollbar {
	-ms-touch-action: none;
	touch-action: none;

	&.mCS_no_scrollbar {
		-ms-touch-action: auto;
		touch-action: auto;
	}
}

.mCustomScrollBox{
	position: relative;
	overflow: hidden;
	height: 100%;
	max-width: 100%;
	outline: none;
	direction: ltr;
}

.mCSB_container{
	overflow: hidden;
	width: auto;
	min-height: 100%;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
}

.mCSB_outside + .mCSB_scrollTools {
	right: rem(-26);
}

.mCS-dir-rtl {

	& > .mCSB_inside > .mCSB_scrollTools,
	& > .mCSB_outside + .mCSB_scrollTools {
		right: auto;
		left: 0;
	}

	& > .mCSB_inside > .mCSB_container {
		@include margin(null 0 null rem(30));

		&.mCS_no_scrollbar_y.mCS_y_hidden {
			margin-left: 0;
		}
	}

	& > .mCSB_outside + .mCSB_scrollTools {
		left: rem(-26);
	}
}

.mCSB_scrollTools {
	@include position(absolute, 0 0 0 auto);
	width: rem(16);
	height: auto;

	.mCSB_draggerContainer{
		@include position(absolute, 0 0 0 0);
		height: auto;
	}

	& + .mCSB_draggerContainer {
		@include margin(rem(20) 0);
	}

	.mCSB_draggerRail{
		width: rem(2);
		height: 100%;
		@include margin(0 auto);
		border-radius: 16px;
	}

	.mCSB_dragger{
		z-index: 1;
		width: 100%;
		height: rem(30);
		cursor: pointer;

		.mCSB_dragger_bar{
			position: relative;
			width: rem(4);
			height: 100%;
			@include margin(0 auto);
			border-radius: rem(16);
			text-align: center;
		}
	}
}

.mCSB_scrollTools_vertical {

	&.mCSB_scrollTools_onDrag_expand {

		.mCSB_dragger.mCSB_dragger_onDrag_expanded,
		.mCSB_draggerContainer:hover .mCSB_dragger {

			.mCSB_dragger_bar {
				width: rem(12);
			}
		}

		.mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
		.mCSB_draggerContainer:hover .mCSB_draggerRail {
			width: rem(8);
		}
	}
}

.mCSB_scrollTools {

	.mCSB_buttonUp,
	.mCSB_buttonDown {
		position: absolute;
		display: block;
		width: 100%;
		height: rem(20);
		@include margin(0 auto);
		overflow: hidden;
		cursor: pointer;
	}

	.mCSB_buttonDown {
		bottom: 0;
	}

	// TRANSITIONS
	&,
	.mCSB_dragger .mCSB_dragger_bar,
	.mCSB_buttonUp,
	.mCSB_buttonDown,
	.mCSB_buttonLeft,
	.mCSB_buttonRight {
		@include transition(opacity .2s ease-in-out, background-color .2s ease-in-out);
	}
}

// TRANSITIONS
// auto-expanded scrollbar
.mCSB_scrollTools_vertical,
.mCSB_scrollTools_horizontal {

	&.mCSB_scrollTools_onDrag_expand {

		.mCSB_draggerRail,
		.mCSB_dragger_bar {
			@include transition(
				width .2s ease-out .2s, height .2s ease-out .2s,
				margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,
				margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
				opacity .2s ease-in-out, background-color .2s ease-in-out
			);
		}
	}
}

.mCSB_outside + .mCSB_scrollTools_vertical{
	right: rem(-4);
	z-index: 300;
	@include margin(rem(12) 0);
}

.mCustomScrollBox {

	& + .mCSB_scrollTools {

		&.mCSB_scrollTools_horizontal,
		& + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
			bottom: 0;
			@include margin(0 rem(12));
		}
	}
}


// RTL direction/left-side scrollbar
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical{
	left: 0;
	right: auto;
}

/*
.mCSB_scrollTools_vertical,
.mCSB_scrollTools_horizontal {

	.mCSB_dragger {
		height: rem(50);
	}
}
*/

.mCSB_scrollTools {

	.mCSB_draggerRail,
	.mCSB_dragger .mCSB_dragger_bar {
		width: rem(3);
		border-radius: 0;
	}
}


// Theming...

// ...for positioning
.mCSB_outside + .mCSB_scrollTools_vertical {

	.select-wrapper & {
		right: rem(9);
	}
	.select-wrapper.in-form & {
		right: rem(6);
	}
	.twitter-module & {
		right: rem(24);
		@include margin(2.6rem 0 2.3rem);
	}
}

// ...for the dragger bar background colour
.mCSB_draggerRail {
	.mCS-minimal-dark & {
		background: $light-gray;
	}
	.mCS-light-2 & {
		background: $dark-gray;
	}
}
// ...for the dragger bar colour
.mCSB_dragger_bar {
	.mCS-minimal-dark & {
		background: $medium-gray;
	}
	.mCS-light-2 & {
		background: $medium-gray;
	}
	.twitter-module & {
		background: $twitter-color;
	}
}

// ...for the dragger bar colour when active
.mCSB_dragger {
	&:active,
	&.mCSB_dragger_onDrag {

		.mCSB_dragger_bar {
			.mCS-minimal-dark & {
				background-color: black;
			}
			.mCS-light-2 & {
				background-color: white;
			}
		}
	}
}