.page-products {

	.hero-search {
		max-width: 24rem;
		@extend %margin-center;

		@include media($medium) {
			max-width: 50rem;
		}

		@include media($large) {
			max-width: 61.2rem;
		}
	}
}