.rich-text {
	h2 {
		@include margin(2.2rem null 1.2rem);
		color: #000;
		@include base-font-family;
		font-size: 1.4rem;
		letter-spacing: normal;

		&:first-child {
			@extend %m-t-none;
		}
	}

	p {

		color: $gray-dark;

		a {
			color: #999;

			&:hover {
				opacity: 0.6;
			}
		}

		a[href^="mailto"] {
			max-width: 100%;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			vertical-align: bottom;
		}

		img {
			@extend %m-b-none;
		}

		strong {

			.layout-reversed & {
				color: #4b4b4b;
			}
		}
	}

	ul,
	ol {
		color: $gray-dark;
	}

	&.smaller {
		p,
		ul,
		ol,
		li,
		h2 {
			font-size: rem(12);
			line-height: 1.6!important;
		}
		p {
			margin-bottom: 1rem;
		}
		ul,
		ol {
			margin-left: 2rem;
		}
	}

	.external-info {

		&,
		a {
			@include base-bold-font-family;
			opacity: 1;
			color: #000;
		}

		a {

			&:hover {
				opacity: 0.6;
			}
		}
	}

	p,
	ol,
	ul {
		margin-bottom: 1.75rem;
	}

	ol,
	ul {
		font-size: 1.25rem;
		margin-left: 4rem;

		.layout-reversed & {
			margin-right: 4rem;
			margin-left: 0;
		}

		ol,
		ul {
			@include margin(null null 0 2rem);

			.layout-reversed & {
				@include margin(null 2rem 0 0);
			}
		}
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}

	img {
		width: 100% !important;
		height: auto !important;
		margin-bottom: 1.75rem;

		& + h2 {
			@extend %m-t-none;
		}
	}
}

.layout-reversed .rich-text h2 {
	color: #4b4b4b;
	font-size: rem(20);
}
