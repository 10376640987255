.component-wrapper-new {

    &.module-hero-new {
        height: auto;
        background: none;


        .module-image {
            height: 21.6rem;

            .mobile-tab {
                margin-bottom: rem(40);
            }

            @include media($small) {
                height: 42rem;
            }
        }

        .module-content-wrapper {
            position: relative;
            top: auto;
            max-width: rem(1040);
            height: auto;
            margin-top: rem(-136px);
            margin-bottom: rem(40);
            box-shadow: none;
            transform: none;

            header {
                margin-bottom: 1.6rem;
            }
        }

        .module-content {
            position: static;
            max-width: none;
            transform: none;

            > ul,
            > ol {
                font-size: 1.3333rem;
                @include margin(2rem null 2.4rem);
            }

            .mobile-gap {
                margin-bottom: 4rem;
            }
        }

        p {
            max-width: rem(640px);
            @include margin(null auto null);
            text-align: center;



            &.tiny {
                margin-bottom: 0;
                font-size: rem(12);
                @include font-family-serif-italic;
            }

            .layout-reversed & {
                text-align: right;

                @include media($small) {
                    text-align: center;
                }

                &.searchDd-main {
                    text-align: right;
                }
            }
        }

        ul {
            text-align: left;

            @include media($small) {
                text-align: center;
            }
        }

        .brand-contact {
            max-width: 20rem;
            @include margin(2rem auto);
            @include padding(1rem null);
            text-align: center;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            text-align: inherit;

            li {
                margin-bottom: 0.2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                color: #000;
            }
        }

        .brand-logo {
            max-width: 16rem;
            @include margin(null auto);
        }

        .barcode {
            display: block;
            max-width: rem(168);
            @include margin(-0.7rem auto);
        }

        .button {

            .layout-reversed & {
                text-align: center;
            }
        }
    }
}
