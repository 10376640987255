// =============================================================================
//	SEARCH
// =============================================================================

$search-form-width: rem(260);
$search-form-height: rem(31);

.search, .bag {
  position: relative;
  height: rem(50);
  background: #f3f3f3;

  @include media($large) {
    .no-touchevents & {
      @include position(absolute, -0.2rem 0.7rem null null);
      display: inline-block;
      background: none;
    }
  }
}

.search-form {
  position: relative;
  width: 100%;
  height: 4rem;
  @extend %margin-center;
  padding-right: 1.4rem;
  z-index: 999999;

  @include media($medium-down) {
    .no-touchevents & {
      label {
        color: $action-color;
        font-size: rem(14);
        text-transform: uppercase;
        line-height: 3rem;
      }
    }
  }

  .touchevents & {
    label {
      color: $action-color;
      font-size: rem(14);
      text-transform: uppercase;
      line-height: 3rem;
    }
  }

  @include media($large) {
    .no-touchevents & {
      transform: translateX(21.6667rem);

      &.active {
        z-index: 50;
        transform: translateX(0);
      }

      @include position(absolute, null rem(4) null null);
      width: $search-form-width;
      height: $search-form-height;
      @include margin(null 0);
      opacity: 0;
      @include transition(all 0.5s ease 0.3s);
      background: white;
      top: 10px;

      input,
      label {
        pointer-events: none;
        cursor: default;
      }

      &.active {
        opacity: 1;
        @include transition(all 0.3s ease);

        input,
        label {
          pointer-events: initial;
        }

        input[type="text"],
        label {
          cursor: text;
        }

        input[type="submit"] {
          cursor: pointer;
        }
      }
    }
  }
}

#search,
.search-button,
.bag-button,
#searchSubmit {
  background-color: #f3f3f3;

  @include media($large) {
    .no-touchevents & {
      background-color: transparent;
    }
  }
}

.search-button,
.bag-button,
#searchSubmit {
  border: 0px solid transparent;
  vertical-align: bottom;

  &,
  &:hover,
  &:focus {
    background-color: transparent;
  }

  &:hover {
    opacity: 0.6;
  }
}

#search,
#searchSubmit {
  display: inline-block;
  vertical-align: middle;
}

.search-button,
.bag-button {
  @extend %p-none;
  position: absolute;
  z-index: 100;
  display: block;


  @include media($medium-down) {
    .no-touchevents & {
      @include position(absolute, 0 1.2rem null null);
    }
  }

  .touchevents & {
    @include position(absolute, 0 1.2rem null null);
  }

  &:before {
    font-size: 1.7rem;
    line-height: $main-navbar-height;
    color: $icon-color;
  }
}

.bag-button {
  > span {
    display: none;
  }

  > label {
    position: absolute;
    color: red;
    margin-top: -4px;
    top: 50%;
    // width: 100% !important;
    text-align: center;
    cursor: pointer !important;
    width: 24px;
  }

  > label:lang(ru) {
    margin-top: -5px;
  }

  > label:lang(zh-cn) {
    margin-top: -6px;
  }
  
  > label:lang(ar) {
    margin-top: -5px;
    left: 0px;
    right: 1.4rem;
  }

  > label:lang(ko) {
    margin-top: -5px;
  }
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    > label:lang(ru) {
      margin-top: -7px;
    }

    > label:lang(ar) {
      margin-top: -4px;
    }

    > label:lang(ko) {
      margin-top: -6px;
    }
  }

  @supports (-ms-ime-align:auto) {
    > label:lang(zh-cn) {
      margin-top: -5px;
    }

    > label:lang(ru) {
      margin-top: -6px;
    }

    > label:lang(ar) {
      margin-top: -5px;
      left: 0px;
    }
  }

  @include media($medium-down) {
    .no-touchevents &, .touchevents & {
      @include position(absolute, 0 null null 1.2rem);
      padding: 0 !important;

      > span {
        display: inline;
        font-size: 1.1rem;
        color: #7b7b7b;
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
        top: -5px;
        padding-left: 5px;
      }
    }
  }

  @include media($large) {
    .touchevents & {
      @include position(absolute, 0 null null 1.2rem);
      padding: 0 !important;

      > span {
        display: inline;
        font-size: 1.1rem;
        color: #7b7b7b;
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
        top: -5px;
        padding-left: 5px;
      }
    }
  }

  @include media($small-down) {
    // width: 24px !important
  }

  .touchevents & {
    @include position(absolute, 0 0 null null);
  }

  .layout-reversed & {
    @include media($medium-down) {
      .no-touchevents & > span, .touchevents & > span {
        padding-right: 5px;
      }
    }
  
    @include media($large) {
      .touchevents & > span {
        padding-right: 5px;
      }
    }
  }
}

#search {
  @extend %m-b-none;
  padding-left: 0;

  @include media($medium-down) {
    .no-touchevents & {
      border-bottom-width: 0;
      font-size: rem(14);
    }
  }

  .touchevents & {
    border-bottom-width: 0;
    font-size: rem(14);
  }

  @include media($large) {
    .no-touchevents & {
      margin-left: 0;
      width: $search-form-width - $search-form-height;
      box-shadow: -16px 0 10px #fff;
    }
  }
}

#searchSubmit {
  @include position(absolute, 1.15rem null null -0.4rem);
  text-indent: -9999px;
  @include transition(opacity 0.3s ease);

  @include media($medium-down) {
    .no-touchevents & {
      width: 2rem;
    }
  }

  .touchevents & {
    width: 2rem;
  }

  @include media($large) {
    .no-touchevents & {
      top: rem(3);
      right: rem(-4);
      left: auto;
    }
  }
}
