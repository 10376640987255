
.editorial-detail-list{
	.module-content-center {
		text-align: center;

		@include media($small) {
			text-align: left;
		}
	}
}

.content-list-2-col {
	text-align: center;

	.col {

		@include media($small) {
			@include clearfix;
			@include span-columns(6);
		}
	}

	@include media($small) {

		&.content-left {

			.col-content {
				float: right;
				text-align: right;
			}

			.col-image-wrapper {
				left: 50%;
				height: rem(500);
				overflow-y: hidden;
				overflow-x: visible;
			}
			.col-image {
				left: 0;
				max-height: rem(500);
				margin-left: 4%;
			}
		}

		&.content-right {

			.col-content-wrapper {
				float: right;
			}
			.col-content {
				float: left;
				text-align: left;
			}

			.col-image-wrapper {
				right: 50%;
				height: rem(500);
				overflow-y: hidden;
				overflow-x: visible;			}
			.col-image {
				right: 0;
				margin-right: 4%;
				max-height: rem(500);
			}
		}

		.col-image-wrapper,
		.col-content {
			display: inline-block;
			min-height: rem(540);

		}

		.col-image-wrapper {
			@include position(absolute, 0 null);


		}

		.col-image {
			@include position(absolute, 0 null);
			height: auto;
			width: auto;
			max-width: none;
		}
	}

	.col-content {
		width: 100%;
		max-width: rem(340);
		@include margin(3rem auto null);
		@include padding(null rem(20));

		@include media($small) {
			margin: 0;
		}
	}
}

.content-list-col-wrapper {
	position: relative;

	@include media($small) {
		@include clearfix;
	}

	.col-image-wrapper {
		overflow: hidden;
		max-height: rem(540);
		//max-width: rem(370);

	}



}

.content-list-number {
	margin-bottom: 0;
	color: #9f9f9f !important;
	font-size: rem(92) !important;
	@include font-family-serif-italic;
	line-height: 1 !important;
}

.content-list-title {
	margin-bottom: 1.2rem;
	font-size: rem(22);
	text-transform: uppercase;
}

.content-list-copy {

	p {

	}
}

.content-list-item {
	@include padding(2rem null);.module-content-center {
	text-align: center;

	@include media($small) {
		text-align: left;
	}
}

.content-list-2-col {
	text-align: center;

	.col {

		@include media($small) {
			@include clearfix;
			@include span-columns(6);
		}
	}

	@include media($small) {

		&.content-left {

			.col-content {
				float: right;
				text-align: right;
			}

			.col-image-wrapper {
				left: 50%;
				overflow: hidden;
			}
			.col-image {
				left: 0;
				margin-left: 4%;
			}
		}

		&.content-right {

			.col-content-wrapper {
				float: right;
			}
			.col-content {
				float: left;
				text-align: left;
			}

			.col-image-wrapper {
				overflow: hidden;
				right: 50%;
			}
			.col-image {
				right: 0;
				margin-right: 4%;
			}
		}

		.col-image-wrapper,
		.col-content {
			display: inline-block;
			overflow: hidden;
			min-height: rem(540);

		}

		.col-image-wrapper {
			@include position(absolute, 0 null);
			overflow: hidden;
			max-width: rem(370);

		}

		.col-image {
			@include position(absolute, 0 null);
			height: auto;
			width: auto;
			max-width: none;
		}
	}

	.col-content {
		width: 100%;
		max-width: rem(340);
		@include margin(3rem auto null);
		@include padding(null rem(20));

		@include media($small) {
			margin: 0;
		}
	}
}

.content-list-col-wrapper {
	position: relative;

	@include media($small) {
		@include clearfix;
	}

	.col-image-wrapper {
		overflow: hidden;
		max-height: rem(540);

	}



}

.content-list-number {
	margin-bottom: 0;
	color: #9f9f9f !important;
	font-size: rem(92) !important;
	@include font-family-serif-italic;
	line-height: 1 !important;
}

.content-list-title {
	margin-bottom: 1.2rem;
	font-size: rem(22);
	text-transform: uppercase;
}

.content-list-copy {

	p {

	}
}

.content-list-item {
	@include padding(2rem null);

	&.component-img-content {

		.module-content-wrapper {
			@include padding(null rem(20));
		}

		.module-content {
			max-width: none;

			@include media($small) {
				height: rem(422);
				max-width: rem(422);
			}

			> * {
				max-width: rem(270);
				@include margin(null auto);
			}
		}
	}
}

	&.component-img-content {

		.module-content-wrapper {
			@include padding(null rem(20));
		}

		.module-content {
			max-width: none;

			@include media($small) {
				height: rem(422);
				max-width: rem(422);
			}

			> * {
				max-width: rem(270);
				@include margin(null auto);
			}
		}
	}
}