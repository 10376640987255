// =============================================================================
//	WIDGET GRID
// =============================================================================

.grid-wrap,
.widget-slot {
	@include clearfix;
	margin-left: -1.7799%;
	margin-right: -1.7799%;
}

.standard-grid-wrap {
	@include clearfix;
}

.grid-item,
.product-page .product,
.grid-item-2col,
.alert-bar-wrapper {
	padding-left: 1.7799%;
	padding-right: 1.7799%;
}

.grid-wrap,
.standard-grid-wrap {

	&.side-widget-layout {
		margin-top: 1.8rem;

		@include media($small) {
			margin-top: 1.6rem;
		}

		@include media($medium) {
			margin-top: 2.8rem;
		}

		@include media($medium) {
			margin-top: 3.6rem;
		}

		> .grid-item {
			@extend %col-12-small-only;
		}

		.module-free-text-content {
			@extend %m-b-lg;
		}

		.grid-col {

			.grid-item {
				width: 100% !important;
			}
		}

		> .grid-2 {

			.grid-wrap,
			.standard-grid-wrap {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	> .row {
		display: block;
	}

	.side-widget-layout & {
		width: 100%;
	}
}

.widget:not(.grid-item) {
	width: 100%;
}

.grid-item {
	@extend %col-12;
	@extend %col-6-small-only;
	@extend %col-4-medium;

	.layout-reversed & {
		float: right!important;
	}

	.grid-full-tablet & {
		@extend %col-12-small-only;
	}

	&.grid-odd-last {
		@extend %col-12-small-only;
	}

	&.grid-2 {
		@extend %col-8-medium;
	}

	&.grid-3 {
		@extend %col-12-medium;
	}

	&.grid-full {
		width: 100%;
	}

	&.grid-half {
		@extend %col-6-medium;
	}
}

@for $i from 1 to 13 {
	.grid-col-#{$i} {
		@extend %col-#{$i}-medium;
		@extend %col-12-small;

		padding-left: 1.7799%;
		padding-right: 1.7799%;
	}

	.standard-grid-wrap {
		.row {
			.grid-col-#{$i} {
				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				@include media($small-down) {
					padding-left: 0;
					padding-right: 0;
				}
			}

			&.gutter {
				> .grid-col-#{$i} {
					padding-left: 1.7799%;
					padding-right: 1.7799%;
				}
			}
		}
	}
}



@for $i from 1 to 13 {
	.grid-col-md-#{$i} {
		@extend %col-#{$i}-small;
		padding-left: 1.7799%;
		padding-right: 1.7799%;
	}

	.grid-col-sm-#{$i} {
		@extend %col-#{$i}-small-down;
		padding-left: 1.7799%;
		padding-right: 1.7799%;
	}
}

.grid-item-2col {
	@include span-columns(6)
}

@include media($small-only) {

	.grid-hide {
		display: none;
	}
}

@include media($medium-small) {
	.widget-slot:not(.component-editorial-new) {
		display: flex;
    	align-items: flex-start;
    	flex-wrap: wrap;
    	height: 100%;
    	width: 100%;
	}
	.module-widget-carousel-inspiration .widget-slot {
		display: block;

	}
}


/**/
@include media($small) {
	.widget-slot:not(.component-editorial-new) {
		display: flex;
    	align-items: flex-start;
    	flex-wrap: wrap;
    	height: 100%;
    	width: 100%;
	}
	.module-widget-carousel-inspiration .widget-slot {
		display: block;

	}
}

// =============================================================================
//	2 COL LAYOUT PAGE
// =============================================================================
.widget-col,
.grid-col {

	.grid-item {

		&,
		&.grid-2,
		&.grid-3 {
			@extend %col-12-medium;
		}
	}
}