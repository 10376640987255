.table-scroll{
	width: 100%;
	overflow: auto;
}
.content-table{
	width: 100%;
	min-width: 900px;
	display: table;
	table-layout: fixed;
	border-spacing: 0;
    margin-bottom: rem(20);
    color: #777;
    font-size: 1.25rem;
	
	th, td {
	    padding: rem(8);
	    line-height: rem(24);
	    text-align: left;
	    vertical-align: top;
	    border-collapse: collapse;
	    
	    &:first-child {
    		width: rem(100);
	    }
	    &:last-child{
	    	width: 55%;
	    }
    }

	td{
    	border-top: 1px solid #dddddd;
	}

	tr{
	   	border-bottom: none;

	    &:nth-child(odd) td {
		        background-color: #f9f9f9;
		}
	}

	ul{
	    	list-style-type: disc;
	    	margin: 0 0 rem(10) rem(25);
	}
}