// =============================================================================
//	LAYOUT
// =============================================================================

@import
	'grid-helpers',
	'global',
	'modules',
	'visibility';


body[dir="rtl"] {
	.search-results .search-results-pagination li {
		float: right;
		&.arrow {
			transform: rotate(180deg);
			top: -2px;
			position: relative;
		}
	}
	.in-form .dk-select-options {
		margin-right: 0;
	}
	span.error {
		text-align: right;
	}
	.promo-editorial.component-wrapper-new p {
		text-align: right!important;
	}
	.light .dk-select {
		text-align: right;
	}
	.in-form .dk-selected, .in-form .searchDd-main {
		padding-left: rem(30);
	}
	.in-form .dk-selected:before, .in-form .searchDd-main:before {
		left: 1.6em !important;
		right: auto !important;
		width: 10px;
	}
	.f-site-map-col, .f-site-map, .footer-legal {
		@media only screen and (min-width: 32.5em) {
			float: right;
		}
	}
	.slide-content-header h2 {
		font-weight: normal;
	}
	// .search-form {
	// 	.no-touchevents & {
	// 		transform: translateX(-21.6667rem);
	// 		right: auto;
	// 		left: -0.3333rem;
	// 		z-index: 999999;
	// 	}
	// }
}
