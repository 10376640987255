.lang-vill {

	@include media($large) {
		.no-touchevents & {
			@include clearfix;
			@include position(absolute, 2rem null null rem(62));
			z-index: 600;
			width: auto;
			margin-left: rem(-6);
			@include transition(opacity 0.2s ease, top 0.1s linear 0.2s);

			.dropdown {
				@include padding(null rem(12));
			}

			.layout-reversed & {
				@include position(absolute, 2rem rem(62) null auto);
			}
		}
	}

	.dropdown-container {
		position: relative;
	}

	.dropdown-menu {
		margin-bottom: 1rem;
		text-transform: uppercase;
		line-height: 0;

		@include media($large) {
			.no-touchevents & {
				@include position(absolute, 30px null null rem(-5));
				margin-bottom: 0;
				@include padding(null rem(6));
				background-color: #fff;

				.layout-reversed & {
					@include position(absolute, 30px rem(-5) null auto);
				}
			}
		}

		li {
			margin-bottom: 0.5rem;

			&.active {

				a {
					border-bottom-color: $action-color;
				}
			}
		}

		a {
			display: block;
			width: 100%;
  			line-height: 2.2;
			border-bottom: 1px solid transparent;
			@include transition(border-bottom-color 0.2s linear);

			&:hover {
				opacity: 1;
				border-bottom-color: $action-color;
			}
		}

		@include media($medium-down) {
			.no-touchevents & {
				a {
					line-height: 1;
					padding: 10px 0;
				}
			}
		}
		.touchevents & {
			a {
				line-height: 1;
				padding: 10px 0;
			}
		}
	}
}

@include media($large) {
	.no-touchevents {
		#language,
		#village {
			float: left;
			min-width: 4rem;

			.layout-reversed & {
				float: right;
			}
		}
	}
}

#language,
#village {

	.dropdown-button {
		text-transform: uppercase;
	}

	.desktop {

		@include media($medium-down) {
			.no-touchevents & {
				display: none !important;
			}
		}
		.touchevents & {
			display: none !important;
		}
	}

	@include media($large) {
		.no-touchevents & {
			.offcanvas {
				display: none !important;
			}

			a {
				display: inline;
			}
		}
	}

	a {
		height: auto;
	}
}

#village {

	.dropdown-menu {

		@include media($large) {
			.no-touchevents & {
				width: 16rem;
			}
		}

		a {
			white-space: nowrap;
		}
	}
}

.dd-location {
	display: block;
	text-transform: none;
	@include font-family-serif-italic;
	line-height: 1;

	.layout-reversed & {
		font-family: $bodoni-roman;
		font-style: normal !important;
	}
}

.nav-village-link {
    text-transform: uppercase;
    color: #999 !important;
    line-height: 2rem;
}


.hide-mobile {
    @include media($large-down) {
        display: none;
    }
}