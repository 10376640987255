@charset "UTF-8";
@import url(//fonts.googleapis.com/css?family=Noto+Sans);
@import url(//fonts.googleapis.com/css?family=Playfair+Display);
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(//fonts.googleapis.com/earlyaccess/kopubbatang.css);
@import url(//fonts.googleapis.com/earlyaccess/notosanssc.css);
@import url(//fonts.googleapis.com/earlyaccess/notokufiarabic.css);
@import url(//fonts.googleapis.com/earlyaccess/notonaskharabic.css);
html,
body {
  width: 100%; }
  .layout-reversed * html, .layout-reversed *
  body {
    letter-spacing: 0 !important; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article,
aside,
footer,
header,
main,
nav,
section {
  display: block; }

video {
  display: inline-block;
  vertical-align: baseline; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

h1 {
  margin: 0.67rem 0;
  font-size: 2rem; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

hr {
  box-sizing: content-box;
  height: 0; }

button,
input,
select,
textarea {
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -ms-appearance: textfield;
  -o-appearance: textfield;
  appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

textarea {
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

@font-face {
  font-family: "brown-light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/BrownStd-Light.woff") format("woff"), url("../fonts/BrownStd-Light.ttf") format("truetype"), url("../fonts/BrownStd-Light.svg#brown-light") format("svg"); }

@font-face {
  font-family: "brown-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/BrownStd-Regular.woff") format("woff"), url("../fonts/BrownStd-Regular.ttf") format("truetype"), url("../fonts/BrownStd-Regular.svg#brown-regular") format("svg"); }

@font-face {
  font-family: "brown-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/BrownStd-Bold.woff") format("woff"), url("../fonts/BrownStd-Bold.ttf") format("truetype"), url("../fonts/BrownStd-Bold.svg#brown-bold") format("svg"); }

@font-face {
  font-family: "bonodiBe-medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/BodoniBE-Medium.woff") format("woff"), url("../fonts/BodoniBE-Medium.ttf") format("truetype"), url("../fonts/BodoniBE-Medium.svg#bonodiBe-medium") format("svg"); }

@font-face {
  font-family: "bonodiBe-italic";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/BodoniBE-Italic.woff") format("woff"), url("../fonts/BodoniBE-Italic.ttf") format("truetype"), url("../fonts/BodoniBE-Italic.svg#bonodiBe-italic") format("svg"); }

@font-face {
  font-family: "bonodiStd-roman";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/BodoniStd-Roman.woff") format("woff"), url("../fonts/BodoniStd-Roman.ttf") format("truetype"), url("../fonts/BodoniStd-Roman.svg#bonodiStd-roman") format("svg"); }

/**/
html[lang="ar"] .product-price .was, html[lang="ar"] .product-price .now,
html[lang="ko"] .product-price .was,
html[lang="ko"] .product-price .now,
html[lang="zh-cn"] .product-price .was,
html[lang="zh-cn"] .product-price .now {
  font-family: "bonodiBe-medium", serif !important; }

ul.dropdown-menu li a[href*='/ar/'] {
  font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif !important; }

fieldset {
  margin: 0 0 0.75rem;
  padding: 1.5rem;
  background-color: #f7f7f7;
  border: 1px solid #ddd; }

input,
label,
select {
  display: block;
  font-family: "brown-regular", sans-serif;
  font-size: 1rem;
  color: #444; }
  html[lang="ru"] input, html[lang="ru"]
  label, html[lang="ru"]
  select {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] input, html[lang="ko"]
  label, html[lang="ko"]
  select {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] input, html[lang="zh-cn"]
  label, html[lang="zh-cn"]
  select {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] input, html[lang="ar"]
  label, html[lang="ar"]
  select {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; }

@keyframes onAutoFillStart {
  from {
    /**/ }
  to {
    /**/ } }

@keyframes onAutoFillCancel {
  from {
    /**/ }
  to {
    /**/ } }

select:-webkit-autofill {
  animation-name: onAutoFillStart;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  transition: background-color 1s ease-in-out 0s; }

select:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel; }

::-webkit-input-placeholder {
  color: #979797; }

:-moz-placeholder {
  color: #979797; }

::-moz-placeholder {
  color: #979797; }

:-ms-input-placeholder {
  color: #979797; }

label {
  margin-bottom: 0.375rem;
  color: #000; }
  label.required::after {
    content: '*'; }
  label abbr {
    display: none; }
  label:hover {
    cursor: text; }
  label a {
    color: #c2092f; }

input[type="date"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], select[multiple="multiple"], textarea {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  font-family: "brown-regular", sans-serif;
  font-size: 1rem;
  transition: border-color;
  background-color: #fff;
  border: 0 solid transparent;
  border-bottom: 1px solid #b7b7b7;
  border-radius: 0; }
  html[lang="ru"] input[type="date"], html[lang="ru"] input[type="email"], html[lang="ru"] input[type="number"], html[lang="ru"] input[type="password"], html[lang="ru"] input[type="search"], html[lang="ru"] input[type="tel"], html[lang="ru"] input[type="text"], html[lang="ru"] input[type="url"], html[lang="ru"] select[multiple="multiple"], html[lang="ru"] textarea {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] input[type="date"], html[lang="ko"] input[type="email"], html[lang="ko"] input[type="number"], html[lang="ko"] input[type="password"], html[lang="ko"] input[type="search"], html[lang="ko"] input[type="tel"], html[lang="ko"] input[type="text"], html[lang="ko"] input[type="url"], html[lang="ko"] select[multiple="multiple"], html[lang="ko"] textarea {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] input[type="date"], html[lang="zh-cn"] input[type="email"], html[lang="zh-cn"] input[type="number"], html[lang="zh-cn"] input[type="password"], html[lang="zh-cn"] input[type="search"], html[lang="zh-cn"] input[type="tel"], html[lang="zh-cn"] input[type="text"], html[lang="zh-cn"] input[type="url"], html[lang="zh-cn"] select[multiple="multiple"], html[lang="zh-cn"] textarea {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] input[type="date"], html[lang="ar"] input[type="email"], html[lang="ar"] input[type="number"], html[lang="ar"] input[type="password"], html[lang="ar"] input[type="search"], html[lang="ar"] input[type="tel"], html[lang="ar"] input[type="text"], html[lang="ar"] input[type="url"], html[lang="ar"] select[multiple="multiple"], html[lang="ar"] textarea {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  input[type="date"]:hover, input[type="email"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="url"]:hover, select[multiple="multiple"]:hover, textarea:hover {
    border-color: #b7b7b7; }
  input[type="date"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, select[multiple="multiple"]:focus, textarea:focus {
    border-color: #b7b7b7;
    outline: none; }

.inpage-form input[type="date"], .inpage-form input[type="email"], .inpage-form input[type="number"], .inpage-form input[type="password"], .inpage-form input[type="search"], .inpage-form input[type="tel"], .inpage-form input[type="text"], .inpage-form input[type="url"], .inpage-form select[multiple="multiple"], .inpage-form textarea {
  border: 1px solid #b7b7b7; }
  .inpage-form input[type="date"]:hover, .inpage-form input[type="email"]:hover, .inpage-form input[type="number"]:hover, .inpage-form input[type="password"]:hover, .inpage-form input[type="search"]:hover, .inpage-form input[type="tel"]:hover, .inpage-form input[type="text"]:hover, .inpage-form input[type="url"]:hover, .inpage-form select[multiple="multiple"]:hover, .inpage-form textarea:hover {
    border-color: #b7b7b7; }
  .inpage-form input[type="date"]:focus, .inpage-form input[type="email"]:focus, .inpage-form input[type="number"]:focus, .inpage-form input[type="password"]:focus, .inpage-form input[type="search"]:focus, .inpage-form input[type="tel"]:focus, .inpage-form input[type="text"]:focus, .inpage-form input[type="url"]:focus, .inpage-form select[multiple="multiple"]:focus, .inpage-form textarea:focus {
    border-color: #b7b7b7;
    outline: none; }

.inpage-form p a, .inpage-form label a {
  color: #c2092f; }

.inpage-form .button-radio-wrapper .button {
  margin-top: 0; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="email"],
input[type="password"] {
  height: 2.9167rem;
  -webkit-appearance: none;
  color: black; }

input[type="text"].smart-search {
  padding-left: 0.9rem;
  border: none;
  border-radius: 3px; }

textarea {
  resize: vertical;
  color: black; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input[type="radio"] {
  display: inline;
  margin-right: 0.375rem; }

input[type="checkbox"] {
  display: inline;
  margin-right: 1.25rem; }

input[type="file"] {
  width: 100%;
  padding-bottom: 0.75rem; }

select {
  max-width: 100%;
  width: auto;
  margin-bottom: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

.input-wrapper {
  position: relative; }

.checkbox-wrap {
  margin: 1.6667rem 0 1rem 0; }

span.error {
  margin: -0.4167rem 0 1rem;
  color: red;
  display: block !important;
  clear: both;
  text-align: left; }

span.valid {
  display: none !important; }

#nlePrivateSale-error {
  position: absolute;
  bottom: -1.6667rem;
  left: 0; }

.dk-selected.select-error,
.dk-select-multi .select-error,
select.error,
textarea.error,
input.error {
  border: red solid 2px !important; }

.dk-selected.select-error {
  margin-bottom: 0.5rem; }

.select-hidden {
  width: 1px;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  opacity: 0; }
  .select-hidden.error {
    border: none !important; }

.dob-hidden {
  width: 1px;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  opacity: 0; }
  .dob-hidden.error {
    border: none !important; }

input[type="checkbox"].error + label:before {
  border: solid 2px red; }

form div.checkbox-error {
  position: relative;
  margin-top: -0.8333rem;
  margin-bottom: 2rem; }

.in-form-text {
  margin-top: 1.5rem; }
  .in-form-text a {
    color: #c2092f; }

.update-form .button {
  width: auto !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  text-transform: none !important;
  letter-spacing: normal !important; }

.update-form .preferences {
  margin-top: 2.5rem;
  width: 100%;
  float: left; }

.with-hidden-form {
  position: relative; }

.hidden-form-close {
  position: absolute;
  top: 95px;
  right: 15px;
  display: none;
  color: #767676;
  padding-right: 28px; }
  .hidden-form-close .close-icon {
    position: absolute;
    right: 0;
    top: -2px;
    width: 20px;
    height: 20px;
    background: #767676;
    border-radius: 50%; }
    .hidden-form-close .close-icon:before, .hidden-form-close .close-icon:after {
      content: ' ';
      position: absolute;
      top: 4px;
      left: 9px;
      height: 12px;
      width: 2px;
      background-color: #fff; }
    .hidden-form-close .close-icon:before {
      transform: rotate(45deg); }
    .hidden-form-close .close-icon:after {
      transform: rotate(-45deg); }

.hidden-form-wrapper {
  margin-top: 1.5rem; }

.hidden-form {
  display: none;
  padding: 25px 15px 15px;
  border: solid 1px #e5e5e5; }
  .hidden-form .button {
    margin-top: 15px !important;
    margin-bottom: 0 !important; }

.email-form .button.hidden-form-open {
  margin-top: 0 !important; }

.password-form .hidden-form-close {
  top: 15px; }

.password-form .hidden-form-wrapper {
  margin-top: 0; }

.button-radio .button {
  color: #2e2e2e;
  border-color: #c2c2c2; }
  .button-radio .button.inverted {
    color: #fff; }
    .button-radio .button.inverted:hover {
      color: #fff;
      background: #000;
      cursor: default; }

.button-radio-wrapper .button-radio {
  display: inline-block;
  margin-right: 0.6667rem; }
  .button-radio-wrapper .button-radio:last-child {
    margin-right: 0; }
  .button-radio-wrapper .button-radio .button {
    margin-top: 0 !important; }

.mandatory-field {
  float: right;
  font-size: 1rem;
  color: #9C9C9C; }

.birthday-selects {
  margin-bottom: 0.25rem; }

.tooltip-wrapper {
  position: relative;
  float: left;
  width: 2.1667rem;
  margin-left: 2rem; }

.tooltip-trigger {
  position: relative;
  width: 2.1667rem;
  height: 2.1667rem;
  border-radius: 50%;
  background: #000;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .tooltip-trigger:hover {
    opacity: 0.6; }
  .tooltip-trigger.webfont-tooltip:before {
    position: absolute;
    top: 0.4rem;
    left: 0.2rem;
    color: #fff;
    font-size: 1.3rem; }

.tooltip-content {
  z-index: 16;
  display: none;
  width: 16.6667rem;
  padding: 0.6rem 0.8rem;
  line-height: 1.2;
  color: black;
  background: #fff;
  border: 2px solid #b0a680;
  border-radius: 6px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  min-height: 36px; }
  .tooltip-content p {
    margin-bottom: 0; }
  .tooltip-content.active {
    display: block; }
  .tooltip-content:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -1.1667rem;
    display: block;
    position: absolute;
    width: 1.1667rem;
    height: 2.25rem;
    background: url(../img/src/deco/tooltip-arrow.png) center center no-repeat; }
  .tooltip-content, .tooltip-content.tooltip-left {
    position: absolute;
    top: 50%;
    right: 167%;
    bottom: auto;
    left: auto;
    transform: translateY(-55%); }
    .tooltip-content:after, .tooltip-content.tooltip-left:after {
      position: absolute;
      top: 50%;
      right: -1.1667rem;
      bottom: auto;
      left: auto;
      transform: translateY(-50%) rotate(0); }
  .tooltip-content.tooltip-top {
    position: absolute;
    top: auto;
    right: auto;
    bottom: 175%;
    left: auto;
    transform: translateX(-50%); }
    .tooltip-content.tooltip-top:after {
      position: absolute;
      top: auto;
      right: auto;
      bottom: -1.6667rem;
      left: auto;
      transform: translateX(90px) rotate(90deg); }
  .tooltip-content.tooltip-right {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 167%;
    transform: translateY(-55%); }
    .tooltip-content.tooltip-right:after {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: -1.1667rem;
      transform: translateY(-50%) rotate(180deg); }
  .tooltip-content.tooltip-bottom {
    position: absolute;
    top: 150%;
    right: auto;
    bottom: auto;
    left: auto;
    transform: translateX(-50%); }
    .tooltip-content.tooltip-bottom:after {
      position: absolute;
      top: -1.6667rem;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(90px) rotate(270deg); }
  @media only screen and (max-width: 29.99em) {
    .tooltip-content.tooltip-left-xsm {
      position: absolute;
      top: 50%;
      right: 167%;
      bottom: auto;
      left: auto;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-left-xsm:after {
        position: absolute;
        top: 50%;
        right: -1.1667rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%) rotate(0); }
    .tooltip-content.tooltip-top-xsm {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 175%;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-top-xsm:after {
        position: absolute;
        top: auto;
        right: auto;
        bottom: -1.6667rem;
        left: auto;
        transform: translateX(90px) rotate(90deg); }
    .tooltip-content.tooltip-right-xsm {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 167%;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-right-xsm:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: -1.1667rem;
        transform: translateY(-50%) rotate(180deg); }
    .tooltip-content.tooltip-bottom-xsm {
      position: absolute;
      top: 150%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-bottom-xsm:after {
        position: absolute;
        top: -1.6667rem;
        right: auto;
        bottom: auto;
        left: auto;
        transform: translateX(90px) rotate(270deg); } }
  @media only screen and (min-width: 30em) {
    .tooltip-content.tooltip-left-sm {
      position: absolute;
      top: 50%;
      right: 167%;
      bottom: auto;
      left: auto;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-left-sm:after {
        position: absolute;
        top: 50%;
        right: -1.1667rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%) rotate(0); }
    .tooltip-content.tooltip-top-sm {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 175%;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-top-sm:after {
        position: absolute;
        top: auto;
        right: auto;
        bottom: -1.6667rem;
        left: auto;
        transform: translateX(90px) rotate(90deg); }
    .tooltip-content.tooltip-right-sm {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 167%;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-right-sm:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: -1.1667rem;
        transform: translateY(-50%) rotate(180deg); }
    .tooltip-content.tooltip-bottom-sm {
      position: absolute;
      top: 150%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-bottom-sm:after {
        position: absolute;
        top: -1.6667rem;
        right: auto;
        bottom: auto;
        left: auto;
        transform: translateX(90px) rotate(270deg); } }
  @media only screen and (min-width: 40em) {
    .tooltip-content.tooltip-left-md {
      position: absolute;
      top: 50%;
      right: 167%;
      bottom: auto;
      left: auto;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-left-md:after {
        position: absolute;
        top: 50%;
        right: -1.1667rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%) rotate(0); }
    .tooltip-content.tooltip-top-md {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 175%;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-top-md:after {
        position: absolute;
        top: auto;
        right: auto;
        bottom: -1.6667rem;
        left: auto;
        transform: translateX(90px) rotate(90deg); }
    .tooltip-content.tooltip-right-md {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 167%;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-right-md:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: -1.1667rem;
        transform: translateY(-50%) rotate(180deg); }
    .tooltip-content.tooltip-bottom-md {
      position: absolute;
      top: 150%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-bottom-md:after {
        position: absolute;
        top: -1.6667rem;
        right: auto;
        bottom: auto;
        left: auto;
        transform: translateX(90px) rotate(270deg); } }
  @media only screen and (min-width: 60em) {
    .tooltip-content.tooltip-left-ml {
      position: absolute;
      top: 50%;
      right: 167%;
      bottom: auto;
      left: auto;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-left-ml:after {
        position: absolute;
        top: 50%;
        right: -1.1667rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%) rotate(0); }
    .tooltip-content.tooltip-top-ml {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 175%;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-top-ml:after {
        position: absolute;
        top: auto;
        right: auto;
        bottom: -1.6667rem;
        left: auto;
        transform: translateX(90px) rotate(90deg); }
    .tooltip-content.tooltip-right-ml {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 167%;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-right-ml:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: -1.1667rem;
        transform: translateY(-50%) rotate(180deg); }
    .tooltip-content.tooltip-bottom-ml {
      position: absolute;
      top: 150%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-bottom-ml:after {
        position: absolute;
        top: -1.6667rem;
        right: auto;
        bottom: auto;
        left: auto;
        transform: translateX(90px) rotate(270deg); } }
  @media only screen and (min-width: 80em) {
    .tooltip-content.tooltip-left-lg {
      position: absolute;
      top: 50%;
      right: 167%;
      bottom: auto;
      left: auto;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-left-lg:after {
        position: absolute;
        top: 50%;
        right: -1.1667rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%) rotate(0); }
    .tooltip-content.tooltip-top-lg {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 175%;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-top-lg:after {
        position: absolute;
        top: auto;
        right: auto;
        bottom: -1.6667rem;
        left: auto;
        transform: translateX(90px) rotate(90deg); }
    .tooltip-content.tooltip-right-lg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 167%;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-right-lg:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: -1.1667rem;
        transform: translateY(-50%) rotate(180deg); }
    .tooltip-content.tooltip-bottom-lg {
      position: absolute;
      top: 150%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-bottom-lg:after {
        position: absolute;
        top: -1.6667rem;
        right: auto;
        bottom: auto;
        left: auto;
        transform: translateX(90px) rotate(270deg); } }
  @media only screen and (min-width: 87.5em) {
    .tooltip-content.tooltip-left-llg {
      position: absolute;
      top: 50%;
      right: 167%;
      bottom: auto;
      left: auto;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-left-llg:after {
        position: absolute;
        top: 50%;
        right: -1.1667rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%) rotate(0); }
    .tooltip-content.tooltip-top-llg {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 175%;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-top-llg:after {
        position: absolute;
        top: auto;
        right: auto;
        bottom: -1.6667rem;
        left: auto;
        transform: translateX(90px) rotate(90deg); }
    .tooltip-content.tooltip-right-llg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 167%;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-right-llg:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: -1.1667rem;
        transform: translateY(-50%) rotate(180deg); }
    .tooltip-content.tooltip-bottom-llg {
      position: absolute;
      top: 150%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-bottom-llg:after {
        position: absolute;
        top: -1.6667rem;
        right: auto;
        bottom: auto;
        left: auto;
        transform: translateX(90px) rotate(270deg); } }
  @media only screen and (min-width: 105em) {
    .tooltip-content.tooltip-left-xlg {
      position: absolute;
      top: 50%;
      right: 167%;
      bottom: auto;
      left: auto;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-left-xlg:after {
        position: absolute;
        top: 50%;
        right: -1.1667rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%) rotate(0); }
    .tooltip-content.tooltip-top-xlg {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 175%;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-top-xlg:after {
        position: absolute;
        top: auto;
        right: auto;
        bottom: -1.6667rem;
        left: auto;
        transform: translateX(90px) rotate(90deg); }
    .tooltip-content.tooltip-right-xlg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 167%;
      transform: translateY(-55%); }
      .tooltip-content.tooltip-right-xlg:after {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: -1.1667rem;
        transform: translateY(-50%) rotate(180deg); }
    .tooltip-content.tooltip-bottom-xlg {
      position: absolute;
      top: 150%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateX(-50%); }
      .tooltip-content.tooltip-bottom-xlg:after {
        position: absolute;
        top: -1.6667rem;
        right: auto;
        bottom: auto;
        left: auto;
        transform: translateX(90px) rotate(270deg); } }

.input-and-tooltip::after {
  clear: both;
  content: "";
  display: table; }

.input-and-tooltip input,
.input-and-tooltip .select-wrapper {
  width: 86%;
  display: inline-block;
  float: left; }

.input-and-tooltip .tooltip-wrapper {
  float: right;
  margin-top: 0.3rem;
  margin-left: 0; }
  @media only screen and (min-width: 40em) {
    .input-and-tooltip .tooltip-wrapper {
      margin-left: 2.5rem; } }

.hidden-dependant {
  display: none; }

.accordion-form.accordion-wrap.accordion-simple .accordion-trigger {
  cursor: default; }

.accordion-form .select-wrapper.in-form {
  margin-bottom: 0.1rem; }

.accordion-form .accordion-content-2col-col .input-and-tooltip .tooltip-wrapper {
  margin-left: 0 !important; }

.inpage-form .accordion-form .button {
  margin-top: 2rem; }

.accordion-form .dk-select,
.accordion-form .dk-select-multi {
  z-index: initial; }

#MakeABooking .form-row::after {
  clear: both;
  content: "";
  display: table; }

#MakeABooking .control-group {
  width: 48%; }
  #MakeABooking .control-group:first-child {
    float: left; }
  #MakeABooking .control-group:last-child {
    float: right; }
  @media only screen and (min-width: 34em) and (max-width: 40em) {
    #MakeABooking .control-group {
      float: none !important;
      width: 100%; } }

#MakeABooking .checkbox-wrap {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem; }
  #MakeABooking .checkbox-wrap input {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
  #MakeABooking .checkbox-wrap label {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto; }
    #MakeABooking .checkbox-wrap label a {
      font-size: 1rem;
      display: inline;
      color: #000;
      cursor: pointer; }

.g-recaptcha {
  margin-top: 1rem; }

.grecaptcha-badge {
  z-index: 2; }

@media screen and (min-width: 40em) {
  .password-form {
    margin-top: 26px; }
    .password-form .button.hidden-form-open {
      margin-top: 0 !important; } }

.layout-reversed .dk-selected:before,
.layout-reversed .searchDd-main:before,
.layout-reversed .webfont:before,
.layout-reversed .dk-selected:before,
.layout-reversed
.searchDd-main:before,
.layout-reversed .searchDd-category span:before, .searchDd-category
.layout-reversed span:before,
.layout-reversed .link-out b:before, .link-out
.layout-reversed b:before,
.layout-reversed [class*="webfont-"]:before,
.layout-reversed [class^="webfont-"]:before {
  margin-right: 1.5rem; }

ul,
ol {
  list-style-type: none; }

dl {
  margin-bottom: 0.75rem; }
  dl dt {
    font-family: "brown-bold", sans-serif;
    font-family: "brown-regular", sans-serif;
    margin-top: 0.75rem; }
    html[lang="ru"] dl dt {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] dl dt {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] dl dt {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] dl dt {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
    dl dt html[lang="ru"],
    html[lang="ko"] dl dt,
    html[lang="zh-cn"] dl dt,
    html[lang="ar"] dl dt {
      font-weight: 700; }

table,
.table {
  table-layout: fixed;
  width: 100%;
  margin: 0.75rem 0;
  -webkit-font-feature-settings: "kern", "liga", "tnum";
  -moz-font-feature-settings: "kern", "liga", "tnum";
  -ms-font-feature-settings: "kern", "liga", "tnum";
  font-feature-settings: "kern", "liga", "tnum";
  border-collapse: collapse; }

th,
.th {
  padding: 0.75rem 0;
  font-weight: 600;
  text-align: left; }

td,
.td {
  padding: 0.75rem 0; }

.td {
  display: inline-block; }

tr,
td,
th,
.tr,
.td,
.th {
  vertical-align: middle; }

tr,
.tr {
  border-bottom: 1px solid #ddd; }

html {
  font-size: 75%; }

body {
  -webkit-font-feature-settings: "kern", "liga", "pnum";
  -moz-font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: "kern", "liga", "pnum";
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  color: #000;
  font-family: "brown-regular", sans-serif;
  font-size: 1rem;
  font-size: 12px;
  line-height: 1.5; }
  html[lang="ru"] body {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] body {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] body {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] body {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.75rem;
  font-family: "brown-light", sans-serif;
  font-size: 1rem;
  font-weight: initial;
  line-height: 1.2;
  letter-spacing: 0.1rem; }
  html[lang="ru"] h1, html[lang="ru"]
  h2, html[lang="ru"]
  h3, html[lang="ru"]
  h4, html[lang="ru"]
  h5, html[lang="ru"]
  h6 {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] h1, html[lang="ko"]
  h2, html[lang="ko"]
  h3, html[lang="ko"]
  h4, html[lang="ko"]
  h5, html[lang="ko"]
  h6 {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] h1, html[lang="zh-cn"]
  h2, html[lang="zh-cn"]
  h3, html[lang="zh-cn"]
  h4, html[lang="zh-cn"]
  h5, html[lang="zh-cn"]
  h6 {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] h1, html[lang="ar"]
  h2, html[lang="ar"]
  h3, html[lang="ar"]
  h4, html[lang="ar"]
  h5, html[lang="ar"]
  h6 {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit; }
  .layout-reversed h1, .layout-reversed
  h2, .layout-reversed
  h3, .layout-reversed
  h4, .layout-reversed
  h5, .layout-reversed
  h6 {
    letter-spacing: 0; }

p {
  margin: 0 0 0.75rem; }
  p strong {
    font-family: "brown-bold", sans-serif;
    font-family: "brown-regular", sans-serif; }
    html[lang="ru"] p strong {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] p strong {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] p strong {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] p strong {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
    p strong html[lang="ru"],
    html[lang="ko"] p strong,
    html[lang="zh-cn"] p strong,
    html[lang="ar"] p strong {
      font-weight: 700; }

a {
  color: #7b7b7b;
  text-decoration: none;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  a:active, a:focus, a:hover {
    opacity: 0.7; }
  a:active {
    outline: none; }
  .module-img-content a {
    opacity: 1 !important; }

hr {
  margin: 1.5rem 0;
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
  border-top: none; }

img,
picture {
  max-width: 100%; }

.ellipsis {
  overflow: hidden; }

.module-img-content .module-image,
.component-img-content .module-image, .module-background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

[id^="anchor-"] {
  display: block;
  visibility: hidden; }
  @media screen and (min-width: 64em) {
    .no-touchevents [id^="anchor-"] {
      height: 80px;
      margin-top: -80px; } }
  @media screen and (max-width: 63.99em) {
    .no-touchevents [id^="anchor-"] {
      height: 55px;
      margin-top: -55px; } }
  .touchevents [id^="anchor-"] {
    height: 55px;
    margin-top: -55px; }

.hide, .slide-village-title {
  display: none !important; }

.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots button, .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next,
.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev, .back-to-top {
  overflow: hidden;
  text-indent: -9999rem; }

.tel-link:hover {
  opacity: 1;
  cursor: default; }

.full-img {
  width: 100%; }

.component,
.row, .accordion-content-2col-row, .slider-manual-title, .video-cover-content, .page-products .hero-search, .search-form {
  margin: 0 auto; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

body, button,
input,
select,
textarea, #nlePrivateSale-error, ul,
ol, dl dd, img,
picture, .slick-list, .hamburger, .accordion-wrap.accordion-simple, .ui-timepicker-wrapper, .ui-timepicker-list, .ui-timepicker-list li, .ui-helper-reset, #village-google-map, .nav-subnav-trigger.webfont-cheveron:before, .nav-subnav-trigger.dk-selected:before,
.nav-subnav-trigger.searchDd-main:before, .searchDd-category span.nav-subnav-trigger:before,
.m-none {
  margin: 0rem; }

input[type="text"].smart-search, .rich-text h2:first-child, .rich-text img + h2, .accordion-wrap.accordion-simple .accordion-content, .full-video.module .play-button,
.m-t-none {
  margin-top: 0rem; }


.m-r-none {
  margin-right: 0rem; }

.dropdown-button, select, .rich-text p img, .accordion-trigger-text, .accordion-wrap.accordion-simple .accordion-content, .video-widget-title, #search, .f-sm-sitemap-title,
.m-b-none {
  margin-bottom: 0rem; }


.m-l-none {
  margin-left: 0rem; }

input[type="checkbox"],
input[type="radio"], td,
th, ul,
ol, .slick-list, .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots button, .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next,
.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev, .hamburger, .accordion-wrap, .accordion-wrap.accordion-simple, .ui-timepicker-list, .ui-helper-hidden-accessible, .ui-helper-reset, #village-google-map, .search-button,
.bag-button,
.p-none {
  padding: 0rem; }

.page-wrapper,
.page-wrapper-wide,
.page-wrapper-narrow, .hero + .page-wrapper,
.module + .page-wrapper,
.module-new + .page-wrapper,
.module-editorial + .page-wrapper,
.module-offer + .page-wrapper,
.p-t-none {
  padding-top: 0rem; }


.p-r-none {
  padding-right: 0rem; }


.p-b-none {
  padding-bottom: 0rem; }


.p-l-none {
  padding-left: 0rem; }


.m-xxs {
  margin: 0.4167rem; }


.m-t-xxs {
  margin-top: 0.4167rem; }


.m-r-xxs {
  margin-right: 0.4167rem; }


.m-b-xxs {
  margin-bottom: 0.4167rem; }


.m-l-xxs {
  margin-left: 0.4167rem; }


.p-xxs {
  padding: 0.4167rem; }


.p-t-xxs {
  padding-top: 0.4167rem; }


.p-r-xxs {
  padding-right: 0.4167rem; }


.p-b-xxs {
  padding-bottom: 0.4167rem; }


.p-l-xxs {
  padding-left: 0.4167rem; }


.m-xs {
  margin: 0.8333rem; }


.m-t-xs {
  margin-top: 0.8333rem; }


.m-r-xs {
  margin-right: 0.8333rem; }


.m-b-xs {
  margin-bottom: 0.8333rem; }


.m-l-xs {
  margin-left: 0.8333rem; }


.p-xs {
  padding: 0.8333rem; }


.p-t-xs {
  padding-top: 0.8333rem; }


.p-r-xs {
  padding-right: 0.8333rem; }


.p-b-xs {
  padding-bottom: 0.8333rem; }


.p-l-xs {
  padding-left: 0.8333rem; }


.m-sm {
  margin: 1.25rem; }


.m-t-sm {
  margin-top: 1.25rem; }


.m-r-sm {
  margin-right: 1.25rem; }


.m-b-sm {
  margin-bottom: 1.25rem; }


.m-l-sm {
  margin-left: 1.25rem; }


.p-sm {
  padding: 1.25rem; }


.p-t-sm {
  padding-top: 1.25rem; }


.p-r-sm {
  padding-right: 1.25rem; }


.p-b-sm {
  padding-bottom: 1.25rem; }


.p-l-sm {
  padding-left: 1.25rem; }


.m-md {
  margin: 1.6667rem; }


.m-t-md {
  margin-top: 1.6667rem; }


.m-r-md {
  margin-right: 1.6667rem; }


.m-b-md {
  margin-bottom: 1.6667rem; }


.m-l-md {
  margin-left: 1.6667rem; }


.p-md {
  padding: 1.6667rem; }


.p-t-md {
  padding-top: 1.6667rem; }


.p-r-md {
  padding-right: 1.6667rem; }

.accordion-tabs .accordion-tab-content .accordion-content,
.p-b-md {
  padding-bottom: 1.6667rem; }


.p-l-md {
  padding-left: 1.6667rem; }


.m-lg {
  margin: 2.5rem; }

#train .module-free-text-copy,
.m-t-lg {
  margin-top: 2.5rem; }


.m-r-lg {
  margin-right: 2.5rem; }

.update-form form, .grid-wrap.side-widget-layout .module-free-text-content,
.standard-grid-wrap.side-widget-layout .module-free-text-content, .grid-item .slider:not(.slider-hero-extended):not(.slider-hero-content) .slider-manual-wrapper, .accordion-wrap, .alert-bar-single,
.m-b-lg {
  margin-bottom: 2.5rem; }


.m-l-lg {
  margin-left: 2.5rem; }


.p-lg {
  padding: 2.5rem; }


.p-t-lg {
  padding-top: 2.5rem; }


.p-r-lg {
  padding-right: 2.5rem; }


.p-b-lg {
  padding-bottom: 2.5rem; }


.p-l-lg {
  padding-left: 2.5rem; }


.m-xl {
  margin: 5rem; }


.m-t-xl {
  margin-top: 5rem; }


.m-r-xl {
  margin-right: 5rem; }

.hero-home-extended, .module-new,
.component-wrapper-new,
.content-list-2-col,
.slider.partners,
.module-image,
.m-b-xl {
  margin-bottom: 5rem; }


.m-l-xl {
  margin-left: 5rem; }


.p-xl {
  padding: 5rem; }


.p-t-xl {
  padding-top: 5rem; }


.p-r-xl {
  padding-right: 5rem; }


.p-b-xl {
  padding-bottom: 5rem; }


.p-l-xl {
  padding-left: 5rem; }

button, button:disabled,
.button,
.button:disabled {
  display: inline-block;
  padding: 1.2rem 1.2rem 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  color: #000;
  font-family: "brown-regular", sans-serif;
  font-size: 1.17rem;
  font-weight: normal;
  line-height: 1.2;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #000;
  cursor: pointer;
  user-select: none;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }
  html[lang="ru"] button, html[lang="ru"] button:disabled, html[lang="ru"]
  .button, html[lang="ru"]
  .button:disabled {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] button, html[lang="ko"] button:disabled, html[lang="ko"]
  .button, html[lang="ko"]
  .button:disabled {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] button, html[lang="zh-cn"] button:disabled, html[lang="zh-cn"]
  .button, html[lang="zh-cn"]
  .button:disabled {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] button, html[lang="ar"] button:disabled, html[lang="ar"]
  .button, html[lang="ar"]
  .button:disabled {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }

.layout-reversed button, .layout-reversed
.button {
  letter-spacing: 0; }

button:hover, button:focus, button:active,
.button:hover,
.button:focus,
.button:active {
  color: #fff;
  background-color: #008a9d;
  border-color: #008a9d;
  opacity: 1; }

button:disabled, button:disabled:hover, button:disabled:focus, button:disabled:active,
.button:disabled,
.button:disabled:hover,
.button:disabled:focus,
.button:disabled:active {
  cursor: not-allowed;
  opacity: 0.5;
  outline: none;
  color: #000;
  background-color: #fff;
  border-color: #fff; }

button.inverted,
.button.inverted {
  border-color: #008a9d;
  background-color: #008a9d;
  color: #fff; }
  button.inverted:hover, button.inverted:focus, button.inverted:active,
  .button.inverted:hover,
  .button.inverted:focus,
  .button.inverted:active {
    color: #000;
    background-color: transparent; }
  .button-radio-wrapper button.inverted:hover, .button-radio-wrapper button.inverted:focus, .button-radio-wrapper button.inverted:active, .button-radio-wrapper
  .button.inverted:hover, .button-radio-wrapper
  .button.inverted:focus, .button-radio-wrapper
  .button.inverted:active {
    color: #fff;
    background-color: #008a9d; }
  button.inverted:disabled, button.inverted:disabled:hover, button.inverted:disabled:focus, button.inverted:disabled:active,
  .button.inverted:disabled,
  .button.inverted:disabled:hover,
  .button.inverted:disabled:focus,
  .button.inverted:disabled:active {
    color: #fff;
    background-color: #008a9d;
    border-color: #008a9d; }
  button.inverted:hover,
  .button.inverted:hover {
    border-color: #000; }

button.extra,
.button.extra {
  position: relative;
  border-color: #008a9d;
  background-color: #008a9d;
  color: white;
  text-decoration: none;
  padding: 1.3rem 6rem 1.1rem 1.3rem; }
  button.extra:before,
  .button.extra:before {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    bottom: auto;
    left: auto;
    margin-top: -0.5rem;
    font-size: 1.1667rem;
    transform: rotate(90deg); }

button.margin-bottom,
.button.margin-bottom {
  margin-bottom: 0.8333rem; }

button.min-width,
.button.min-width {
  display: block; }
  @media only screen and (min-width: 40em) {
    button.min-width,
    .button.min-width {
      min-width: 20.8333rem; } }

button.webfont-folder,
.button.webfont-folder {
  font-size: 1.4167rem; }
  button.webfont-folder:before,
  .button.webfont-folder:before {
    top: 28%;
    font-size: 2.5rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }

.layout-reversed .button.extra {
  padding: 1.2rem 1.3rem 1.2rem 6rem; }
  .layout-reversed .button.extra:before {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0.5rem; }

.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  .input-group .form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0; }
  .input-group .input-group-button {
    display: table-cell;
    border-collapse: separate;
    width: 1%;
    vertical-align: middle; }

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("eot"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg"); }

[class^="webfont-"],
[class*=" webfont-"],
.webfont,
.dk-selected,
.searchDd-main,
.searchDd-category span,
.link-out b {
  display: inline-block; }
  [class^="webfont-"]:before,
  [class*=" webfont-"]:before,
  .webfont:before,
  .dk-selected:before,
  .searchDd-main:before,
  .searchDd-category span:before,
  .link-out b:before {
    display: inline-block;
    height: inherit;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    font-size: 1rem;
    font-family: "icons";
    font-variant: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    text-align: center;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.webfont-arrow-link-out:before, .link-out b:before {
  content: ""; }

.webfont-arrow-long:before {
  content: ""; }

.webfont-arrow-longer:before {
  content: ""; }

.webfont-arrow-short:before {
  content: ""; }

.webfont-bag-0:before {
  content: ""; }

.webfont-bag-1:before {
  content: ""; }

.webfont-bag-2:before {
  content: ""; }

.webfont-bag-3:before {
  content: ""; }

.webfont-bag-4:before {
  content: ""; }

.webfont-bag-5:before {
  content: ""; }

.webfont-cheveron-thin:before {
  content: ""; }

.webfont-cheveron:before, .dk-selected:before,
.searchDd-main:before, .searchDd-category span:before {
  content: ""; }

.webfont-close:before {
  content: ""; }

.webfont-map-pin:before {
  content: ""; }

.webfont-minus:before {
  content: ""; }

.webfont-pinch:before {
  content: ""; }

.webfont-plus:before {
  content: ""; }

.webfont-search:before {
  content: ""; }

.webfont-share:before {
  content: ""; }

.webfont-tooltip:before {
  content: ""; }

.webfont-user:before {
  content: ""; }

.webfont-facebook-square:before {
  content: ""; }

.webfont-facebook:before {
  content: ""; }

.webfont-foursquare:before {
  content: ""; }

.webfont-google:before {
  content: ""; }

.webfont-instagram:before {
  content: ""; }

.webfont-messenger:before {
  content: ""; }

.webfont-pinterest:before {
  content: ""; }

.webfont-tripadvisor:before {
  content: ""; }

.webfont-twitter:before {
  content: ""; }

.webfont-wechat:before {
  content: ""; }

.webfont-weibo:before {
  content: ""; }

.webfont-whatsapp:before {
  content: ""; }

.webfont-youtube:before {
  content: ""; }

.grid-item, .page-title-full .module-free-text-header {
  float: left;
  display: block;
  margin-right: 0%;
  width: 100%; }
  .grid-item:last-child, .page-title-full .module-free-text-header:last-child {
    margin-right: 0; }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-12 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    .grid-col-sm-12:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) and (max-width: 49.99em) {
  .grid-wrap.side-widget-layout > .grid-item,
  .standard-grid-wrap.side-widget-layout > .grid-item, .grid-full-tablet .grid-item, .grid-item.grid-odd-last {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    .grid-wrap.side-widget-layout > .grid-item:last-child,
    .standard-grid-wrap.side-widget-layout > .grid-item:last-child, .grid-full-tablet .grid-item:last-child, .grid-item.grid-odd-last:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-1, .grid-col-2, .grid-col-3, .grid-col-4, .grid-col-5, .grid-col-6, .grid-col-7, .grid-col-8, .grid-col-9, .grid-col-10, .grid-col-11, .grid-col-12, .grid-col-md-12, .f-site-map-col.last {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    .grid-col-1:last-child, .grid-col-2:last-child, .grid-col-3:last-child, .grid-col-4:last-child, .grid-col-5:last-child, .grid-col-6:last-child, .grid-col-7:last-child, .grid-col-8:last-child, .grid-col-9:last-child, .grid-col-10:last-child, .grid-col-11:last-child, .grid-col-12:last-child, .grid-col-md-12:last-child, .f-site-map-col.last:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-item.grid-3, .grid-col-12, .widget-col .grid-item, .widget-col .grid-item.grid-2, .widget-col .grid-item.grid-3,
  .grid-col .grid-item,
  .grid-col .grid-item.grid-2,
  .grid-col .grid-item.grid-3, .f-site-map {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    .grid-item.grid-3:last-child, .grid-col-12:last-child, .widget-col .grid-item:last-child,
    .grid-col .grid-item:last-child, .f-site-map:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-11 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 91.6667%; }
    .grid-col-sm-11:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-11 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 91.6667%; }
    .grid-col-md-11:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-11 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 91.6667%; }
    .grid-col-11:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-10 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 83.3333%; }
    .grid-col-sm-10:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-10 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 83.3333%; }
    .grid-col-md-10:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-10 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 83.3333%; }
    .grid-col-10:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-9 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    .grid-col-sm-9:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-9 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    .grid-col-md-9:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-9 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    .grid-col-9:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-8 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.6667%; }
    .grid-col-sm-8:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) and (max-width: 49.99em) {
  .footer-content .opening-hours ul {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.6667%; }
    .footer-content .opening-hours ul:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-8, .f-site-map-col.last .f-site-map-group.last {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.6667%; }
    .grid-col-md-8:last-child, .f-site-map-col.last .f-site-map-group.last:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-item.grid-2, .grid-col-8 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.6667%; }
    .grid-item.grid-2:last-child, .grid-col-8:last-child {
      margin-right: 0; } }

@media screen and (min-width: 64em) {
  .f-site-map {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.6667%; }
    .f-site-map:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-7 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 58.3333%; }
    .grid-col-sm-7:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-7 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 58.3333%; }
    .grid-col-md-7:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-7 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 58.3333%; }
    .grid-col-7:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-6 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    .grid-col-sm-6:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) and (max-width: 49.99em) {
  .grid-item {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    .grid-item:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-6, .opening-hours .day,
  .opening-hours .hours, .f-sm-register-form p,
  .f-sm-register-form .form-single-item {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    .grid-col-md-6:last-child, .opening-hours .day:last-child,
    .opening-hours .hours:last-child, .f-sm-register-form p:last-child,
    .f-sm-register-form .form-single-item:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-item.grid-half, .grid-col-6, .slide-content-col {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    .grid-item.grid-half:last-child, .grid-col-6:last-child, .slide-content-col:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-5 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 41.6667%; }
    .grid-col-sm-5:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-5 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 41.6667%; }
    .grid-col-md-5:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-5 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 41.6667%; }
    .grid-col-5:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-4 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.3333%; }
    .grid-col-sm-4:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-4, .f-site-map-col, .f-site-map-col.last .f-site-map-group, .f-site-map-col.last {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.3333%; }
    .grid-col-md-4:last-child, .f-site-map-col:last-child, .f-site-map-col.last .f-site-map-group:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-item, .grid-col-4 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.3333%; }
    .grid-item:last-child, .grid-col-4:last-child {
      margin-right: 0; } }

@media screen and (min-width: 64em) {
  .footer-content .opening-hours {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.3333%; }
    .footer-content .opening-hours:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-3 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    .grid-col-sm-3:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-3 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    .grid-col-md-3:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-3 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    .grid-col-3:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-2 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.6667%; }
    .grid-col-sm-2:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-2 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.6667%; }
    .grid-col-md-2:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-2 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.6667%; }
    .grid-col-2:last-child {
      margin-right: 0; } }

@media screen and (max-width: 49.99em) {
  .grid-col-sm-1 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 8.3333%; }
    .grid-col-sm-1:last-child {
      margin-right: 0; } }

@media screen and (min-width: 40em) {
  .grid-col-md-1 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 8.3333%; }
    .grid-col-md-1:last-child {
      margin-right: 0; } }

@media screen and (min-width: 50em) {
  .grid-col-1 {
    float: left;
    display: block;
    margin-right: 0%;
    width: 8.3333%; }
    .grid-col-1:last-child {
      margin-right: 0; } }

.site-canvas {
  padding-top: 50px; }
  @media screen and (min-width: 64em) {
    .no-touchevents .site-canvas {
      padding-top: 80px; } }
  .site-canvas.webview {
    padding: 0; }

.component-wrapper,
.page-wrapper,
.page-wrapper-wide,
.page-wrapper-narrow,
.hero-wrapper.module {
  padding: 1rem 1.0833rem 1.5rem; }
  @media screen and (min-width: 40em) {
    .component-wrapper,
    .page-wrapper,
    .page-wrapper-wide,
    .page-wrapper-narrow,
    .hero-wrapper.module {
      padding: 1rem 1.6667rem 1.5rem; } }
  @media screen and (min-width: 50em) {
    .component-wrapper,
    .page-wrapper,
    .page-wrapper-wide,
    .page-wrapper-narrow,
    .hero-wrapper.module {
      padding-top: 2.5rem; } }
  @media screen and (min-width: 64em) {
    .component-wrapper,
    .page-wrapper,
    .page-wrapper-wide,
    .page-wrapper-narrow,
    .hero-wrapper.module {
      padding-right: 3.3333rem;
      padding-left: 3.3333rem; } }

.page-wrapper-wide,
.page-wrapper-narrow,
.crosslinks-wrapper {
  padding-bottom: 0; }
  .page-wrapper-wide.grey-wrapper,
  .page-wrapper-narrow.grey-wrapper,
  .crosslinks-wrapper.grey-wrapper {
    background-color: #f0f0f0;
    padding-top: 4.6rem !important; }
    .page-wrapper-wide.grey-wrapper.grey-wrapper-last,
    .page-wrapper-narrow.grey-wrapper.grey-wrapper-last,
    .crosslinks-wrapper.grey-wrapper.grey-wrapper-last {
      margin-bottom: 0; }

.page-wrapper-wide,
.page-wrapper-narrow + .page-wrapper-wide,
.page-wrapper-narrow,
.component-wrapper-new + .page-wrapper-wide,
.page-wrapper-narrow {
  padding-top: 0; }
  .page-wrapper-wide.grey-wrapper,
  .page-wrapper-narrow + .page-wrapper-wide.grey-wrapper,
  .page-wrapper-narrow.grey-wrapper,
  .component-wrapper-new + .page-wrapper-wide.grey-wrapper,
  .page-wrapper-narrow.grey-wrapper {
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding-top: 2.2rem;
    padding-bottom: 1rem; }

.page-wrapper-wide:not(.grey-wrapper) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.component-wrapper-new.grey-wrapper {
  background-color: #f0f0f0;
  padding-top: 4.6rem !important; }
  .component-wrapper-new.grey-wrapper.grey-wrapper-last {
    margin-bottom: 0; }

.hero .component-wrapper-new {
  margin-bottom: 3rem; }

.component::after,
.row::after,
.accordion-content-2col-row::after {
  clear: both;
  content: "";
  display: table; }

.component-wrapper {
  width: 100%;
  padding-top: 2.5rem !important; }

.component {
  position: relative; }
  .main-footer .component,
  .slider-manual-full .component {
    height: auto; }

.page-wrapper-narrow .row, .page-wrapper-narrow .accordion-content-2col-row {
  max-width: 80rem; }

.hero,
.module,
.module-new,
.module-editorial,
.module-offer {
  display: block; }

.row, .accordion-content-2col-row {
  max-width: 86.6667rem; }

.component-narrow {
  max-width: 77.5rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.83rem;
  padding-left: 1.83rem; }

.main-footer a {
  color: #8a8a8a; }
  .main-footer a:hover {
    color: #8a8a8a; }

.skip {
  height: 1px;
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: -10px; }
  .skip:focus {
    opacity: 1;
    height: auto;
    top: 0;
    z-index: 2000;
    background-color: white;
    color: #000;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    padding: 1.5rem 0.8rem; }

@media print {
  .main-footer {
    display: none; } }

.grid-wrap,
.widget-slot {
  margin-left: -1.7799%;
  margin-right: -1.7799%; }
  .grid-wrap::after,
  .widget-slot::after {
    clear: both;
    content: "";
    display: table; }

.standard-grid-wrap::after {
  clear: both;
  content: "";
  display: table; }

.grid-item,
.product-page .product,
.grid-item-2col,
.alert-bar-wrapper {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-wrap.side-widget-layout,
.standard-grid-wrap.side-widget-layout {
  margin-top: 1.8rem; }
  @media screen and (min-width: 40em) {
    .grid-wrap.side-widget-layout,
    .standard-grid-wrap.side-widget-layout {
      margin-top: 1.6rem; } }
  @media screen and (min-width: 50em) {
    .grid-wrap.side-widget-layout,
    .standard-grid-wrap.side-widget-layout {
      margin-top: 2.8rem; } }
  @media screen and (min-width: 50em) {
    .grid-wrap.side-widget-layout,
    .standard-grid-wrap.side-widget-layout {
      margin-top: 3.6rem; } }
  .grid-wrap.side-widget-layout .grid-col .grid-item,
  .standard-grid-wrap.side-widget-layout .grid-col .grid-item {
    width: 100% !important; }
  .grid-wrap.side-widget-layout > .grid-2 .grid-wrap,
  .grid-wrap.side-widget-layout > .grid-2 .standard-grid-wrap,
  .standard-grid-wrap.side-widget-layout > .grid-2 .grid-wrap,
  .standard-grid-wrap.side-widget-layout > .grid-2 .standard-grid-wrap {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }

.grid-wrap > .row, .grid-wrap > .accordion-content-2col-row,
.standard-grid-wrap > .row,
.standard-grid-wrap > .accordion-content-2col-row {
  display: block; }

.side-widget-layout .grid-wrap, .side-widget-layout
.standard-grid-wrap {
  width: 100%; }

.widget:not(.grid-item) {
  width: 100%; }

.layout-reversed .grid-item {
  float: right !important; }

.grid-item.grid-full {
  width: 100%; }

.grid-col-1 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-1:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-1:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-1:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-1:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-1, .standard-grid-wrap .accordion-content-2col-row .grid-col-1 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-1, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-1 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-2 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-2:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-2:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-2:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-2:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-2, .standard-grid-wrap .accordion-content-2col-row .grid-col-2 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-2, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-2 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-3 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-3:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-3:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-3:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-3:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-3, .standard-grid-wrap .accordion-content-2col-row .grid-col-3 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-3, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-3 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-4 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-4:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-4:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-4:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-4:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-4, .standard-grid-wrap .accordion-content-2col-row .grid-col-4 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-4, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-4 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-5 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-5:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-5:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-5:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-5:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-5, .standard-grid-wrap .accordion-content-2col-row .grid-col-5 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-5, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-5 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-6 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-6:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-6:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-6:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-6:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-6, .standard-grid-wrap .accordion-content-2col-row .grid-col-6 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-6, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-6 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-7 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-7:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-7:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-7:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-7:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-7, .standard-grid-wrap .accordion-content-2col-row .grid-col-7 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-7, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-7 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-8 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-8:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-8:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-8:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-8:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-8, .standard-grid-wrap .accordion-content-2col-row .grid-col-8 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-8, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-8 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-9 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-9:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-9:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-9:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-9:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-9, .standard-grid-wrap .accordion-content-2col-row .grid-col-9 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-9, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-9 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-10 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-10:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-10:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-10:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-10:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-10, .standard-grid-wrap .accordion-content-2col-row .grid-col-10 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-10, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-10 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-11 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-11:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-11:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-11:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-11:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-11, .standard-grid-wrap .accordion-content-2col-row .grid-col-11 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-11, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-11 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-12 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.standard-grid-wrap .row .grid-col-12:first-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-12:first-child {
  padding-left: 0; }

.standard-grid-wrap .row .grid-col-12:last-child, .standard-grid-wrap .accordion-content-2col-row .grid-col-12:last-child {
  padding-right: 0; }

@media screen and (max-width: 49.99em) {
  .standard-grid-wrap .row .grid-col-12, .standard-grid-wrap .accordion-content-2col-row .grid-col-12 {
    padding-left: 0;
    padding-right: 0; } }

.standard-grid-wrap .row.gutter > .grid-col-12, .standard-grid-wrap .gutter.accordion-content-2col-row > .grid-col-12 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-1 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-1 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-2 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-2 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-3 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-3 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-4 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-4 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-5 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-5 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-6 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-6 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-7 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-7 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-8 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-8 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-9 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-9 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-10 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-10 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-11 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-11 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-md-12 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-col-sm-12 {
  padding-left: 1.7799%;
  padding-right: 1.7799%; }

.grid-item-2col {
  float: left;
  display: block;
  margin-right: 0%;
  width: 50%; }
  .grid-item-2col:last-child {
    margin-right: 0; }

@media screen and (min-width: 40em) and (max-width: 49.99em) {
  .grid-hide {
    display: none; } }

@media screen and (min-width: 50em) {
  .widget-slot:not(.component-editorial-new) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
    width: 100%; }
  .module-widget-carousel-inspiration .widget-slot {
    display: block; } }

/**/
@media screen and (min-width: 40em) {
  .widget-slot:not(.component-editorial-new) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
    width: 100%; }
  .module-widget-carousel-inspiration .widget-slot {
    display: block; } }

@media screen and (max-width: 49.99em) {
  .hidden-small-down {
    display: none !important; } }

@media screen and (max-width: 63.99em) {
  .hidden-medium-down {
    display: none !important; } }

@media screen and (min-width: 50em) {
  .hidden-medium-up {
    display: none !important; } }

@media screen and (min-width: 64em) {
  .hidden-large-up {
    display: none !important; } }

.desktop .hidden-desktop {
  display: none !important; }

body[dir="rtl"] .search-results .search-results-pagination li {
  float: right; }
  body[dir="rtl"] .search-results .search-results-pagination li.arrow {
    transform: rotate(180deg);
    top: -2px;
    position: relative; }

body[dir="rtl"] .in-form .dk-select-options {
  margin-right: 0; }

body[dir="rtl"] span.error {
  text-align: right; }

body[dir="rtl"] .promo-editorial.component-wrapper-new p {
  text-align: right !important; }

body[dir="rtl"] .light .dk-select {
  text-align: right; }

body[dir="rtl"] .in-form .dk-selected, body[dir="rtl"] .in-form .searchDd-main {
  padding-left: 2.5rem; }

body[dir="rtl"] .in-form .dk-selected:before, body[dir="rtl"] .in-form .searchDd-main:before {
  left: 1.6em !important;
  right: auto !important;
  width: 10px; }

@media only screen and (min-width: 32.5em) {
  body[dir="rtl"] .f-site-map-col, body[dir="rtl"] .f-site-map, body[dir="rtl"] .footer-legal {
    float: right; } }

body[dir="rtl"] .slide-content-header h2 {
  font-weight: normal; }

@keyframes modalFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modalFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

body {
  position: relative;
  overflow-y: auto; }
  body.modal-open {
    overflow: hidden; }

html.touchevents body.modal-open {
  position: fixed; }

.modal {
  display: none;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0; }
  .modal.product-modal {
    animation: modalFadeOut 0.3s forwards; }
  .modal.in {
    opacity: 1;
    animation: modalFadeIn 0.3s forwards; }

.modal-dialog {
  position: relative;
  margin: 0 auto;
  border-radius: 3px;
  width: 100%;
  max-width: 61.2rem;
  background-color: #fff; }

.modal-header {
  position: absolute;
  width: 100%;
  z-index: 5000;
  padding: 1rem 0 1.2rem; }
  @media screen and (min-width: 64em) {
    .modal-header {
      z-index: 300; } }
  .modal-header h1, .modal-header h2, .modal-header h3, .modal-header h4, .modal-header h5, .modal-header h6 {
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    font-family: "brown-light", sans-serif; }

.modal-body {
  position: relative;
  z-index: 190; }

.modal-footer {
  padding: 1rem 0 1.2rem; }

.modal-close {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 15px;
  /*@include media($large) {

		padding: 15px;
	}*/
  background-color: transparent;
  color: #000;
  text-align: center;
  z-index: 5100; }
  .layout-reversed .modal-close {
    right: auto;
    left: 0; }
  .modal-close:before {
    font-size: inherit;
    line-height: inherit;
    height: auto; }
  .modal-close:active, .modal-close:active:focus, .modal-close:focus {
    outline: none; }
  .modal-close:hover {
    background-color: transparent;
    color: #000;
    opacity: 0.7; }

#share-modal .modal-dialog {
  padding-top: 2rem;
  padding-bottom: 2.6rem; }

#share-modal .modal-header {
  position: static; }

#share-modal .modal-body {
  min-height: 1rem;
  min-height: initial; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: '';
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  display: none;
  height: 100%;
  min-height: 65px; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: inline-block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots {
  position: absolute;
  right: 0;
  bottom: 1rem;
  left: 0;
  text-align: center; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots li {
    display: inline-block;
    padding-right: 0.4167rem;
    padding-left: 0.4167rem; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots .slick-active button {
    opacity: 1; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots button {
    width: 2.0833rem;
    height: 0.25rem;
    background: #fff;
    border: none;
    border-radius: 0;
    opacity: 0.4;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease; }
    .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots button:focus {
      outline: none; }
    .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-dots button:hover {
      opacity: 1; }

.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next,
.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev {
  z-index: 21;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  margin-top: -39px;
  border: none; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next:before,
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev:before {
    content: ""; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next:focus, .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next:hover,
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev:focus,
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev:hover {
    background-color: transparent; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next:focus,
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev:focus {
    outline: none; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next:hover:before,
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev:hover:before {
    opacity: 0.7; }
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next:before,
  .slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev:before {
    display: inline-block;
    height: inherit;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    font-size: 1rem;
    font-family: "icons";
    font-variant: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    text-align: center;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    text-align: left;
    text-indent: 0;
    color: #b1b1b1;
    font-size: 3.8333rem;
    line-height: 1.2; }

.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-prev {
  left: 0;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg); }

.slider:not(.slider-hero-extended):not(.slider-hero-content) .slick-next {
  right: 0; }

.grid-item .slider:not(.slider-hero-extended):not(.slider-hero-content) .slider-manual-wrapper {
  height: 30.8333rem; }

.slider-manual {
  position: relative;
  margin: 2.6em auto 0;
  padding-right: 2.2rem;
  padding-left: 2.2rem; }
  .slider-manual .slick-slide {
    text-align: center; }
    .slider-manual .slick-slide > a {
      display: block; }

.slider-manual-full {
  margin-bottom: 0.8333rem; }

.slider-manual-title {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px; }
  .layout-reversed .slider-manual-title {
    padding-left: 20px;
    padding-right: 0; }
  @media screen and (min-width: 50em) {
    .slider-manual-title {
      text-align: left; } }
  .main-footer .slider-manual-title {
    padding-right: 0; }

.slider {
  overflow: hidden;
  white-space: nowrap; }
  .slider::after {
    clear: both;
    content: "";
    display: table; }
  .slider > .slide-cta,
  .slider > .product,
  .slider > .slide-village {
    display: inline-block; }

.slick-list {
  white-space: normal; }
  .slick-list > .slide-cta,
  .slick-list > .product,
  .slick-list > .slide-village {
    display: block; }

.slider-widget-header {
  position: relative;
  margin-right: 90px; }

.slider-link-full {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  list-style: 0.2rem;
  color: #999; }
  .layout-reversed .slider-link-full {
    position: absolute;
    top: 0;
    right: auto;
    left: 0; }
  .slider-link-full .text {
    display: none;
    font-size: 1.0833rem; }
  .slider-link-full .icon {
    display: block;
    font-size: 2rem;
    line-height: 0.9; }

@media screen and (min-width: 40em) {
  .grid-item .slider-link-full .text {
    display: none; }
  .grid-item .slider-link-full .icon {
    display: block; } }

@media screen and (min-width: 40em) and (max-width: 49.99em) {
  .grid-item.grid-odd-last .slider-link-full .text {
    display: block; }
  .grid-item.grid-odd-last .slider-link-full .icon {
    display: none; } }

@media screen and (min-width: 40em) and (max-width: 49.99em) {
  .grid-item.grid-3 .slider-link-full .text {
    display: none; }
  .grid-item.grid-3 .slider-link-full .icon {
    display: block; } }

@media screen and (min-width: 50em) {
  .grid-item.grid-3 .slider-link-full .text {
    display: block; }
  .grid-item.grid-3 .slider-link-full .icon {
    display: none; } }

@media screen and (min-width: 40em) {
  .grid-item.grid-3.grid-odd-last .slider-link-full .text {
    display: block; }
  .grid-item.grid-3.grid-odd-last .slider-link-full .icon {
    display: none; } }

.hero-home-extended {
  margin-top: -10px; }
  .hero-home-extended .slider-sync-complex {
    overflow: visible; }
    .hero-home-extended .slider-sync-complex:after {
      display: none; }
    .hero-home-extended .slider-sync-complex .slick-list,
    .hero-home-extended .slider-sync-complex .slick-track {
      overflow: hidden; }

.slider-hero-extended {
  height: calc(100vh - 40px); }
  @media screen and (min-width: 64em) {
    .no-touchevents .slider-hero-extended {
      height: calc(100vh - 70px); } }
  .slider-hero-extended .slider,
  .slider-hero-extended .slick-list,
  .slider-hero-extended .slick-track {
    height: 100%; }
  .slider-hero-extended article {
    height: 100%;
    -webkit-transition: height 0.5s ease;
    -moz-transition: height 0.5s ease;
    transition: height 0.5s ease; }
  .slider-hero-extended .full-video {
    padding: 0;
    height: 100%; }
  .slider-hero-extended .video-cover-content {
    top: 42%; }
    .slider-hero-extended .video-cover-content .play-button {
      margin: 0; }

video {
  z-index: 11; }

.vjs-control-bar {
  z-index: 12; }

.slider-sync-simple .slick-slide > a {
  display: block;
  width: 100%;
  height: 100%; }

.slider-sync-simple .vjs-controls-enabled .vjs-control-bar {
  display: none !important; }

.slider-hero-content {
  width: 96%;
  max-width: 86.6667rem;
  margin-top: -13.2rem;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 1.7rem;
  background: #fff;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity 0.5s ease, margin 0.3s ease;
  -moz-transition: opacity 0.5s ease, margin 0.3s ease;
  transition: opacity 0.5s ease, margin 0.3s ease; }
  @media screen and (min-width: 50em) {
    .slider-hero-content {
      margin-top: -11.2rem;
      padding-bottom: 2.1rem; } }
  @media (max-width: 50em) and (orientation: landscape) {
    .slider-hero-content {
      margin-top: -8.6rem; } }
  .slider-hero-content.hero-home-loaded {
    opacity: 1; }
  .slider-hero-content.move-down {
    margin-top: 0; }
  .slider-hero-content *:focus, .slider-hero-content *:active {
    outline: none; }
  body.layout-reversed .slider-hero-content .slick-arrow {
    position: absolute;
    display: block !important;
    width: 40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-backface-visibility: hidden; }
    body.layout-reversed .slider-hero-content .slick-arrow:before, body.layout-reversed .slider-hero-content .slick-arrow:after {
      content: '';
      display: block;
      position: absolute;
      border-color: #000;
      border-style: solid;
      -webkit-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -webkit-backface-visibility: hidden;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    body.layout-reversed .slider-hero-content .slick-arrow:before {
      width: 1rem;
      height: 1rem; }
    body.layout-reversed .slider-hero-content .slick-arrow:after {
      top: 33px;
      width: 0;
      border-width: 0.2rem 0 0; }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-arrow:not(.slick-disabled):hover {
      opacity: 1; }
      .no-touchevents body.layout-reversed .slider-hero-content .slick-arrow:not(.slick-disabled):hover:after {
        width: 1.8rem; }
    body.layout-reversed .slider-hero-content .slick-arrow.slick-disabled {
      opacity: 0.5; }
      body.layout-reversed .slider-hero-content .slick-arrow.slick-disabled:hover {
        opacity: 0.5; }
  body.layout-reversed .slider-hero-content .slick-next {
    z-index: 3; }
    body.layout-reversed .slider-hero-content .slick-next:before {
      border-width: 2px 0 0 2px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-next:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-next:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0); }
  body.layout-reversed .slider-hero-content .tabletReset {
    background: red; }
  body.layout-reversed .slider-hero-content .slick-prev {
    z-index: 4; }
    body.layout-reversed .slider-hero-content .slick-prev:before {
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-prev:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-prev:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) translate3d(0, 0, 0) translateZ(0); }
  body:not(.layout-reversed) .slider-hero-content .slick-arrow {
    position: absolute;
    display: block !important;
    width: 40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-backface-visibility: hidden; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow:before, body:not(.layout-reversed) .slider-hero-content .slick-arrow:after {
      content: '';
      display: block;
      position: absolute;
      border-color: #000;
      border-style: solid;
      -webkit-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -webkit-backface-visibility: hidden;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow:before {
      width: 1rem;
      height: 1rem; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow:after {
      top: 33px;
      width: 0;
      border-width: 0.2rem 0 0; }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-arrow:not(.slick-disabled):hover {
      opacity: 1; }
      .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-arrow:not(.slick-disabled):hover:after {
        width: 1.8rem; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow.slick-disabled {
      opacity: 0.5; }
      body:not(.layout-reversed) .slider-hero-content .slick-arrow.slick-disabled:hover {
        opacity: 0.5; }
  body:not(.layout-reversed) .slider-hero-content .slick-prev {
    z-index: 3; }
    body:not(.layout-reversed) .slider-hero-content .slick-prev:before {
      border-width: 2px 0 0 2px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-prev:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-prev:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0); }
  body:not(.layout-reversed) .slider-hero-content .tabletReset {
    background: red; }
  body:not(.layout-reversed) .slider-hero-content .slick-next {
    z-index: 4; }
    body:not(.layout-reversed) .slider-hero-content .slick-next:before {
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-next:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-next:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) translate3d(0, 0, 0) translateZ(0); }
  .slider-hero-content .slick-arrow {
    height: 68px;
    background: #fff; }
    .slider-hero-content .slick-arrow:before {
      top: 28px; }
    .slider-hero-content .slick-arrow:after {
      top: 33px; }
  body.layout-reversed .slider-hero-content .slick-arrow {
    position: absolute;
    display: block !important;
    width: 40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-backface-visibility: hidden; }
    body.layout-reversed .slider-hero-content .slick-arrow:before, body.layout-reversed .slider-hero-content .slick-arrow:after {
      content: '';
      display: block;
      position: absolute;
      border-color: #000;
      border-style: solid;
      -webkit-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -webkit-backface-visibility: hidden;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    body.layout-reversed .slider-hero-content .slick-arrow:before {
      width: 1rem;
      height: 1rem; }
    body.layout-reversed .slider-hero-content .slick-arrow:after {
      top: 33px;
      width: 0;
      border-width: 0.2rem 0 0; }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-arrow:not(.slick-disabled):hover {
      opacity: 1; }
      .no-touchevents body.layout-reversed .slider-hero-content .slick-arrow:not(.slick-disabled):hover:after {
        width: 1.8rem; }
    body.layout-reversed .slider-hero-content .slick-arrow.slick-disabled {
      opacity: 0.5; }
      body.layout-reversed .slider-hero-content .slick-arrow.slick-disabled:hover {
        opacity: 0.5; }
  body.layout-reversed .slider-hero-content .slick-next {
    z-index: 3; }
    body.layout-reversed .slider-hero-content .slick-next:before {
      border-width: 2px 0 0 2px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-next:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-next:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0); }
  body.layout-reversed .slider-hero-content .tabletReset {
    background: red; }
  body.layout-reversed .slider-hero-content .slick-prev {
    z-index: 4; }
    body.layout-reversed .slider-hero-content .slick-prev:before {
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-prev:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body.layout-reversed .slider-hero-content .slick-prev:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) translate3d(0, 0, 0) translateZ(0); }
  body.layout-reversed .slider-hero-content .slick-next {
    top: 0;
    left: 5px; }
    @media screen and (min-width: 50em) {
      body.layout-reversed .slider-hero-content .slick-next {
        top: 68px; } }
    @media screen and (min-width: 40em) {
      body.layout-reversed .slider-hero-content .slick-next {
        left: 0; } }
    body.layout-reversed .slider-hero-content .slick-next:before, body.layout-reversed .slider-hero-content .slick-next:after {
      left: 1.4rem; }
  body.layout-reversed .slider-hero-content .slick-prev {
    top: 0;
    right: 5px; }
    @media screen and (min-width: 50em) {
      body.layout-reversed .slider-hero-content .slick-prev {
        right: auto;
        left: 0;
        border-bottom: 1px solid #000; } }
    body.layout-reversed .slider-hero-content .slick-prev:before, body.layout-reversed .slider-hero-content .slick-prev:after {
      right: 1.4rem; }
  body:not(.layout-reversed) .slider-hero-content .slick-arrow {
    position: absolute;
    display: block !important;
    width: 40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-backface-visibility: hidden; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow:before, body:not(.layout-reversed) .slider-hero-content .slick-arrow:after {
      content: '';
      display: block;
      position: absolute;
      border-color: #000;
      border-style: solid;
      -webkit-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
      -webkit-backface-visibility: hidden;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow:before {
      width: 1rem;
      height: 1rem; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow:after {
      top: 33px;
      width: 0;
      border-width: 0.2rem 0 0; }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-arrow:not(.slick-disabled):hover {
      opacity: 1; }
      .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-arrow:not(.slick-disabled):hover:after {
        width: 1.8rem; }
    body:not(.layout-reversed) .slider-hero-content .slick-arrow.slick-disabled {
      opacity: 0.5; }
      body:not(.layout-reversed) .slider-hero-content .slick-arrow.slick-disabled:hover {
        opacity: 0.5; }
  body:not(.layout-reversed) .slider-hero-content .slick-prev {
    z-index: 3; }
    body:not(.layout-reversed) .slider-hero-content .slick-prev:before {
      border-width: 2px 0 0 2px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-prev:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-prev:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0); }
  body:not(.layout-reversed) .slider-hero-content .tabletReset {
    background: red; }
  body:not(.layout-reversed) .slider-hero-content .slick-next {
    z-index: 4; }
    body:not(.layout-reversed) .slider-hero-content .slick-next:before {
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-next:not(.slick-disabled):hover:before {
      -webkit-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents body:not(.layout-reversed) .slider-hero-content .slick-next:not(.slick-disabled):hover:after {
      -webkit-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) translate3d(0, 0, 0) translateZ(0); }
  body:not(.layout-reversed) .slider-hero-content .slick-prev {
    top: 0;
    left: 5px; }
    @media screen and (min-width: 50em) {
      body:not(.layout-reversed) .slider-hero-content .slick-prev {
        top: 68px; } }
    @media screen and (min-width: 40em) {
      body:not(.layout-reversed) .slider-hero-content .slick-prev {
        left: 0; } }
    body:not(.layout-reversed) .slider-hero-content .slick-prev:before, body:not(.layout-reversed) .slider-hero-content .slick-prev:after {
      left: 1.4rem; }
  body:not(.layout-reversed) .slider-hero-content .slick-next {
    top: 0;
    right: 5px; }
    @media screen and (min-width: 50em) {
      body:not(.layout-reversed) .slider-hero-content .slick-next {
        right: auto;
        left: 0;
        border-bottom: 1px solid #000; } }
    body:not(.layout-reversed) .slider-hero-content .slick-next:before, body:not(.layout-reversed) .slider-hero-content .slick-next:after {
      right: 1.4rem; }
  .slider-hero-content .slick-dots {
    position: absolute;
    top: 1.8rem;
    right: 0;
    left: 0;
    z-index: 2;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 10px 10px #fff; }
    @media screen and (min-width: 50em) {
      .slider-hero-content .slick-dots {
        top: 7.6rem;
        right: 3rem;
        left: auto; } }

@-webkit-keyframes moreBellow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0); }
  50% {
    opacity: 1;
    -webkit-transform: translateY(0.8rem); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0.8rem); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(0.8rem); } }

@-moz-keyframes moreBellow {
  0% {
    opacity: 0;
    -moz-transform: translateY(0); }
  50% {
    opacity: 1;
    -moz-transform: translateY(0.8rem); }
  80% {
    opacity: 1;
    -moz-transform: translateY(0.8rem); }
  100% {
    opacity: 0;
    -moz-transform: translateY(0.8rem); } }

@keyframes moreBellow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  50% {
    opacity: 1;
    -webkit-transform: translateY(0.8rem);
    -moz-transform: translateY(0.8rem);
    -ms-transform: translateY(0.8rem);
    -o-transform: translateY(0.8rem);
    transform: translateY(0.8rem); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0.8rem);
    -moz-transform: translateY(0.8rem);
    -ms-transform: translateY(0.8rem);
    -o-transform: translateY(0.8rem);
    transform: translateY(0.8rem); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(0.8rem);
    -moz-transform: translateY(0.8rem);
    -ms-transform: translateY(0.8rem);
    -o-transform: translateY(0.8rem);
    transform: translateY(0.8rem); } }

.slide-content-header {
  position: relative;
  padding-top: 3.8rem;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
  text-align: center; }
  .slide-content-header:before {
    content: ""; }
  @media screen and (min-width: 50em) {
    .slide-content-header {
      padding-right: 4.4rem;
      padding-left: 4.4rem; } }
  .slide-content-header:before {
    display: inline-block;
    height: inherit;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    font-size: 1rem;
    font-family: "icons";
    font-variant: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    text-align: center;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 0;
    bottom: -1.4rem;
    left: 0;
    margin: 0 auto;
    font-size: 0.6rem;
    opacity: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation: moreBellow 2.5s ease infinite;
    -moz-animation: moreBellow 2.5s ease infinite;
    animation: moreBellow 2.5s ease infinite;
    color: #008a9d; }
  .slide-content-header.hide-arrow:before {
    display: none; }
  @media screen and (min-width: 50em) {
    .slide-content-header {
      padding-top: 0; } }
  .slide-content-header h2 {
    position: relative;
    padding-top: 1.6rem;
    text-transform: uppercase;
    font-size: 2.2rem;
    color: #008a9d;
    font-weight: bold; }
    @media screen and (min-width: 50em) {
      .slide-content-header h2 {
        font-size: 2.6rem; } }
    .slide-content-header h2:after {
      content: '';
      display: block;
      width: 4rem;
      height: 0.0833rem;
      margin: 0.8rem auto;
      background: #000; }
  .slide-content-header p {
    color: black;
    font-family: "bonodiStd-roman", serif;
    letter-spacing: 0.1rem;
    font-size: 16px; }
    html[lang="ru"] .slide-content-header p {
      font-family: "Playfair Display", serif; }
    html[lang="ko"] .slide-content-header p {
      font-family: "KoPub Batang", serif; }
    html[lang="zh-cn"] .slide-content-header p {
      font-family: "adobe-fangsong-std", serif; }
    html[lang="ar"] .slide-content-header p {
      font-family: "Noto Naskh Arabic", serif; }
    .layout-reversed .slide-content-header p {
      letter-spacing: 0; }
    .slide-content-header p span {
      position: relative;
      display: inline-block;
      margin-left: 0.4rem;
      padding-left: 0.8rem;
      text-transform: none; }
      .slide-content-header p span:before {
        content: '';
        position: absolute;
        top: 0.5rem;
        left: 0;
        height: 1rem;
        border-right: 1px solid #a3a3a3; }

.slide-content-row {
  padding: 2.8rem 2.2rem 2.2rem;
  transition: all 0.3s ease; }
  .slide-content-row::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 50em) {
    .slide-content-row {
      padding: 6rem 4rem 2rem; } }
  .hide-arrow + .slide-content-row {
    padding-top: 0.6rem; }
    @media screen and (min-width: 50em) {
      .hide-arrow + .slide-content-row {
        padding-top: 4.2rem; } }

.slide-content-full {
  max-width: 60rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center; }

@media screen and (min-width: 50em) {
  .slide-content-col {
    padding-right: 3rem;
    padding-left: 3rem; } }

.slide-content-left p {
  font-size: 1.2rem; }

@media screen and (min-width: 50em) {
  .slide-content-left .slide-content-links {
    text-align: left; } }

.slide-content-right {
  padding-top: 3rem; }
  @media screen and (min-width: 50em) {
    .slide-content-right {
      padding-top: 0; } }
  .slide-content-right .video-cover-content .play-button {
    margin-top: 0; }
  .slide-content-right .full-video {
    margin-bottom: 0; }
  .slide-content-right > img {
    width: 100%; }

.slide-content-links {
  padding-top: 1.6rem;
  text-align: center; }

.small-product-carousel {
  padding-bottom: 5.4rem; }
  @media screen and (min-width: 40em) {
    .small-product-carousel {
      padding-bottom: 0; }
      .small-product-carousel .product::after {
        clear: both;
        content: "";
        display: table; }
      .small-product-carousel .product-image-wrapper {
        float: left;
        width: 56%; }
      .small-product-carousel .product-image {
        display: block; }
      .small-product-carousel .product-image-wrapper {
        height: auto; }
        .small-product-carousel .product-image-wrapper img {
          position: static; }
      .small-product-carousel .small-product-carousel-copy {
        float: right;
        width: 40%;
        margin-top: 6.6%;
        text-align: left; }
      .small-product-carousel .product-price .was,
      .small-product-carousel .product-price .now,
      .small-product-carousel .product-price .sale {
        padding-left: 0;
        max-width: 100px; }
      .small-product-carousel .small-product-carousel {
        height: 14.3333rem; } }
  .small-product-carousel .product-brand {
    text-transform: uppercase; }
  .small-product-carousel .product-overlay-open,
  .small-product-carousel .product-overlay-close {
    top: 0.1667rem;
    right: 0.0833rem; }
  .small-product-carousel .product-overlay-open {
    line-height: 0.6; }
  .small-product-carousel .product-overlay {
    top: 0.1667rem;
    right: 0.0833rem; }
    .small-product-carousel .product-overlay.active {
      bottom: 0;
      right: 0.0833rem;
      width: 99%;
      height: 99%; }
      @media screen and (min-width: 50em) {
        .small-product-carousel .product-overlay.active {
          width: 98%;
          height: 98%; } }

.carousel-fancy-pagination .slick-dots,
.carousel-fancy-pagination .flickity-page-dots {
  display: inline-block;
  margin: 0 auto; }
  .carousel-fancy-pagination .slick-dots li,
  .carousel-fancy-pagination .slick-dots .dot,
  .carousel-fancy-pagination .flickity-page-dots li,
  .carousel-fancy-pagination .flickity-page-dots .dot {
    display: inline-block;
    padding-right: 0.1667rem;
    padding-left: 0.1667rem; }
  .carousel-fancy-pagination .slick-dots button,
  .carousel-fancy-pagination .slick-dots .dot,
  .carousel-fancy-pagination .flickity-page-dots button,
  .carousel-fancy-pagination .flickity-page-dots .dot {
    display: inline-block;
    border: none;
    color: #999;
    padding: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    font-size: 1rem; }
    @media screen and (min-width: 64em) {
      .carousel-fancy-pagination .slick-dots button,
      .carousel-fancy-pagination .slick-dots .dot,
      .carousel-fancy-pagination .flickity-page-dots button,
      .carousel-fancy-pagination .flickity-page-dots .dot {
        -webkit-transition-duration: 0.6s;
        -moz-transition-duration: 0.6s;
        transition-duration: 0.6s; } }
    .carousel-fancy-pagination .slick-dots button:hover, .carousel-fancy-pagination .slick-dots button:focus, .carousel-fancy-pagination .slick-dots button:active,
    .carousel-fancy-pagination .slick-dots .dot:hover,
    .carousel-fancy-pagination .slick-dots .dot:focus,
    .carousel-fancy-pagination .slick-dots .dot:active,
    .carousel-fancy-pagination .flickity-page-dots button:hover,
    .carousel-fancy-pagination .flickity-page-dots button:focus,
    .carousel-fancy-pagination .flickity-page-dots button:active,
    .carousel-fancy-pagination .flickity-page-dots .dot:hover,
    .carousel-fancy-pagination .flickity-page-dots .dot:focus,
    .carousel-fancy-pagination .flickity-page-dots .dot:active {
      background: transparent;
      outline: none; }
    .carousel-fancy-pagination .slick-dots button:after,
    .carousel-fancy-pagination .slick-dots .dot:after,
    .carousel-fancy-pagination .flickity-page-dots button:after,
    .carousel-fancy-pagination .flickity-page-dots .dot:after {
      content: '';
      display: inline-block;
      width: 0;
      vertical-align: middle;
      border-top: 1px solid #999;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      @media screen and (min-width: 64em) {
        .carousel-fancy-pagination .slick-dots button:after,
        .carousel-fancy-pagination .slick-dots .dot:after,
        .carousel-fancy-pagination .flickity-page-dots button:after,
        .carousel-fancy-pagination .flickity-page-dots .dot:after {
          -webkit-transition-duration: 0.6s;
          -moz-transition-duration: 0.6s;
          transition-duration: 0.6s; } }
  .carousel-fancy-pagination .slick-dots .slick-active button,
  .carousel-fancy-pagination .slick-dots .dot.is-selected,
  .carousel-fancy-pagination .flickity-page-dots .slick-active button,
  .carousel-fancy-pagination .flickity-page-dots .dot.is-selected {
    color: #008a9d; }
    .carousel-fancy-pagination .slick-dots .slick-active button:after,
    .carousel-fancy-pagination .slick-dots .dot.is-selected:after,
    .carousel-fancy-pagination .flickity-page-dots .slick-active button:after,
    .carousel-fancy-pagination .flickity-page-dots .dot.is-selected:after {
      width: 6rem;
      margin: 0 0.5rem 0 0.4rem;
      border-top-color: #000; }

.carousel-fancy-pagination .flickity-page-dots {
  position: static;
  margin-top: 1.6rem; }
  .carousel-fancy-pagination .flickity-page-dots .dot {
    width: auto;
    height: auto;
    margin-right: 0.3333rem;
    margin-left: 0.3333rem;
    background: none;
    opacity: 1; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(1):before {
      content: '1'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(2):before {
      content: '2'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(3):before {
      content: '3'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(4):before {
      content: '4'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(5):before {
      content: '5'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(6):before {
      content: '6'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(7):before {
      content: '7'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(8):before {
      content: '8'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(9):before {
      content: '9'; }
    .carousel-fancy-pagination .flickity-page-dots .dot:nth-child(10):before {
      content: '10'; }

/* =============================================================================
	MENU TOGGLE SWITCH (hamburger/cross)
============================================================================= */
.hamburger {
  display: block;
  width: 24px;
  height: 16px;
  overflow: hidden;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  .hamburger:focus {
    outline: none; }
  .hamburger, .hamburger:hover, .hamburger:focus {
    background-color: transparent; }
  .hamburger span {
    position: absolute;
    right: 1.5px;
    left: 1.5px;
    top: 7px;
    display: block;
    height: 2px;
    background: #777;
    transition: background 0s 0.2s; }
    .hamburger span:before, .hamburger span:after {
      content: '';
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: #777;
      transition-duration: 0.2s, 0.2s;
      transition-delay: 0.2s, 0s; }
    .hamburger span:before {
      transform: translateY(-5px);
      transition-property: top, transform; }
    .hamburger span:after {
      transform: translateY(5px);
      transition-property: bottom, transform; }
  @media screen and (max-width: 63.99em) {
    .no-touchevents .hamburger.active {
      z-index: 200;
      width: 20px;
      pointer-events: none;
      cursor: default;
      transform: translateX(-260px); }
      .no-touchevents .hamburger.active, .no-touchevents .hamburger.active:hover, .no-touchevents .hamburger.active:focus {
        background-color: transparent; }
      .no-touchevents .hamburger.active span {
        background: none; }
        .no-touchevents .hamburger.active span:before, .no-touchevents .hamburger.active span:after {
          background-color: #fff;
          transition-delay: 0s, 0.2s; }
        .no-touchevents .hamburger.active span:before {
          top: 0;
          transform: rotate(45deg); }
        .no-touchevents .hamburger.active span:after {
          bottom: 0;
          transform: rotate(-45deg); } }
  .touchevents .hamburger.active {
    z-index: 200;
    width: 20px;
    pointer-events: none;
    cursor: default;
    transform: translateX(-260px); }
    .touchevents .hamburger.active, .touchevents .hamburger.active:hover, .touchevents .hamburger.active:focus {
      background-color: transparent; }
    .touchevents .hamburger.active span {
      background: none; }
      .touchevents .hamburger.active span:before, .touchevents .hamburger.active span:after {
        background-color: #fff;
        transition-delay: 0s, 0.2s; }
      .touchevents .hamburger.active span:before {
        top: 0;
        transform: rotate(45deg); }
      .touchevents .hamburger.active span:after {
        bottom: 0;
        transform: rotate(-45deg); }

.dropdown-button {
  cursor: pointer;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  color: #7b7b7b;
  font-size: 1.0833rem;
  text-transform: uppercase; }
  @media screen and (min-width: 50em) {
    .dropdown-button {
      text-transform: none;
      font-size: 1.0833rem; } }
  .dropdown-button:active, .dropdown-button:focus, .dropdown-button:hover {
    opacity: 0.7; }
  .dropdown-button:active {
    outline: none; }
  .dropdown-button.webfont-cheveron, .dropdown-button.dk-selected,
  .dropdown-button.searchDd-main, .searchDd-category span.dropdown-button {
    display: block; }
    @media screen and (min-width: 64em) {
      .dropdown-button.webfont-cheveron, .dropdown-button.dk-selected,
      .dropdown-button.searchDd-main, .searchDd-category span.dropdown-button {
        padding-right: 1.8333rem; } }
    .dropdown-button.webfont-cheveron:before, .dropdown-button.dk-selected:before,
    .dropdown-button.searchDd-main:before, .searchDd-category span.dropdown-button:before {
      display: inline-block;
      margin-left: 0.5833rem;
      font-size: 0.6rem;
      color: #7b7b7b;
      -webkit-transition: -webkit-transform 0.2s linear;
      -moz-transition: -moz-transform 0.2s linear;
      transition: transform 0.2s linear;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: center 2px;
      -moz-transform-origin: center 2px;
      -ms-transform-origin: center 2px;
      -o-transform-origin: center 2px;
      transform-origin: center 2px;
      position: absolute;
      top: 1.5833rem;
      right: -0.1667rem;
      bottom: auto;
      left: auto; }
      @media screen and (min-width: 64em) {
        .dropdown-button.webfont-cheveron:before, .dropdown-button.dk-selected:before,
        .dropdown-button.searchDd-main:before, .searchDd-category span.dropdown-button:before {
          top: 1rem; } }
    .dropdown-button.webfont-cheveron.active:before, .dropdown-button.active.dk-selected:before,
    .dropdown-button.active.searchDd-main:before, .searchDd-category span.dropdown-button.active:before {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg); }
    @media screen and (min-width: 64em) {
      .layout-reversed .dropdown-button.webfont-cheveron, .layout-reversed .dropdown-button.dk-selected, .layout-reversed
      .dropdown-button.searchDd-main, .layout-reversed .searchDd-category span.dropdown-button, .searchDd-category .layout-reversed span.dropdown-button {
        padding-right: 0;
        padding-left: 1.8333rem; } }
    .layout-reversed .dropdown-button.webfont-cheveron:before, .layout-reversed .dropdown-button.dk-selected:before, .layout-reversed
    .dropdown-button.searchDd-main:before, .layout-reversed .searchDd-category span.dropdown-button:before, .searchDd-category .layout-reversed span.dropdown-button:before {
      right: auto;
      left: -0.8333rem;
      margin-right: 0.5833rem;
      margin-left: 0; }
      @media screen and (min-width: 64em) {
        .layout-reversed .dropdown-button.webfont-cheveron:before, .layout-reversed .dropdown-button.dk-selected:before, .layout-reversed
        .dropdown-button.searchDd-main:before, .layout-reversed .searchDd-category span.dropdown-button:before, .searchDd-category .layout-reversed span.dropdown-button:before {
          top: 1rem;
          left: 0; } }
    .layout-reversed .dropdown-button.webfont-cheveron.active:before, .layout-reversed .dropdown-button.active.dk-selected:before, .layout-reversed
    .dropdown-button.active.searchDd-main:before, .layout-reversed .searchDd-category span.dropdown-button.active:before, .searchDd-category .layout-reversed span.dropdown-button.active:before {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); }

.dropdown-menu {
  display: none; }

.select-wrapper {
  position: relative;
  min-width: 15.6667rem;
  text-align: left; }
  .select-wrapper.disabled select {
    opacity: 0.35; }
  .select-wrapper.disabled:after {
    opacity: 0.35; }
  html.touch .select-wrapper select {
    padding-right: 2.5rem; }
  html.touch .select-wrapper:after {
    content: ""; }
  html.touch .select-wrapper:after {
    position: absolute;
    top: 1.4rem;
    right: 0.8rem;
    z-index: 10;
    display: block;
    display: inline-block;
    height: inherit;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    font-size: 1rem;
    font-family: "icons";
    font-variant: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    text-align: center;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.6667rem; }
  html.touch .layout-reversed .select-wrapper select {
    padding-right: 0.5rem;
    padding-left: 2.5rem; }
  html.touch .layout-reversed .select-wrapper:after {
    left: 0.8rem;
    right: auto; }
  .select-wrapper.light {
    color: #777; }
    html.touch .select-wrapper.light:after {
      color: #000; }
    .select-wrapper.light select {
      color: black; }

.dk-select,
.dk-select-multi {
  position: relative;
  z-index: 15;
  width: 100%; }
  .dk-select.input-block-level,
  .dk-select-multi.input-block-level {
    z-index: 1; }
    .dk-select.input-block-level.dk-select-open-down,
    .dk-select-multi.input-block-level.dk-select-open-down {
      z-index: 1111; }
    .dk-select.input-block-level.dk-select-open-up,
    .dk-select-multi.input-block-level.dk-select-open-up {
      z-index: 1111; }
  .dk-select li,
  .dk-select-multi li {
    margin-bottom: 0 !important; }

.light .dk-select .dk-selected, .light
select {
  text-transform: none; }

.dk-select-options,
.searchDd-list {
  display: none; }

.dk-select-multi .dk-select-options {
  position: static;
  display: block;
  height: auto;
  background: #fff !important;
  color: #000 !important; }

.in-form .dk-select-multi .dk-select-options {
  max-height: 8.5rem;
  margin-bottom: 0.75rem !important;
  border-top-style: solid;
  margin-left: 0; }

.dk-selected,
select,
.searchDd-main {
  box-sizing: border-box;
  margin-bottom: 0.3rem;
  padding: 0.5rem;
  width: 100%;
  height: 3.3rem;
  padding: 0.6rem 2.5rem 0.6rem 0.9rem;
  font-size: 1rem;
  background: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: border-color;
  background-color: #fff;
  border: 0 solid transparent;
  border-bottom: 1px solid #b7b7b7;
  border-radius: 0; }
  .dk-selected:hover,
  select:hover,
  .searchDd-main:hover {
    border-color: #b7b7b7; }
  .dk-selected:focus,
  select:focus,
  .searchDd-main:focus {
    border-color: #b7b7b7;
    outline: none; }
  .layout-reversed .dk-selected, .layout-reversed
  select, .layout-reversed
  .searchDd-main {
    text-align: right;
    padding: 0.6rem 0.9rem 0.6rem 2.5rem; }
  .in-form .dk-selected, .in-form
  select, .in-form
  .searchDd-main {
    padding: 0.5rem;
    height: 2.9167rem;
    font-size: 1rem;
    line-height: 1.8; }
  .dk-selected:focus,
  select:focus,
  .searchDd-main:focus {
    outline: none; }
  .light .dk-selected, .light
  select, .light
  .searchDd-main {
    color: #000;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #b7b7b7;
    transition: all 0.3s ease; }
    .light .dk-selected:before, .light
    select:before, .light
    .searchDd-main:before {
      color: #000; }
    .light .open.dk-selected, .light
    select.open, .light
    .open.searchDd-main {
      color: #fff;
      background: #000; }
      .light .open.dk-selected:before, .light
      select.open:before, .light
      .open.searchDd-main:before {
        color: #000; }

select {
  position: relative;
  z-index: 5;
  color: #fff;
  border: none;
  border-radius: 0;
  text-align: center; }

.dk-selected,
.searchDd-main {
  cursor: pointer; }
  .dk-selected:before,
  .searchDd-main:before {
    position: absolute;
    top: 1.5rem;
    right: 0.8333rem;
    bottom: auto;
    left: auto;
    height: 0.8333rem;
    font-size: 0.6rem;
    -webkit-transition: -webkit-transform 0.2s linear;
    -moz-transition: -moz-transform 0.2s linear;
    transition: transform 0.2s linear; }
    .layout-reversed .dk-selected:before, .layout-reversed
    .searchDd-main:before {
      position: absolute;
      top: 1.2rem;
      right: auto;
      bottom: auto;
      left: 0.8333rem; }
    .in-form .dk-selected:before, .in-form
    .searchDd-main:before {
      top: 1.2rem;
      right: 0.6rem; }
  .light .dk-selected, .light
  .searchDd-main {
    color: #000;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #b7b7b7;
    transition: all 0.3s ease;
    text-transform: uppercase; }
    .light .dk-selected:before, .light
    .searchDd-main:before {
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      transition: all 0.2s linear; }
    .light .dk-selected:hover, .light
    .searchDd-main:hover {
      color: #000;
      background-color: #fff; }
      .light .dk-selected:hover:before, .light
      .searchDd-main:hover:before {
        color: #000; }

.dk-select-open-down .dk-selected:before {
  transform: rotate(-180deg) translateY(3px); }

.dk-select-options,
.searchDd-list {
  position: absolute;
  top: 3.3rem;
  right: 0;
  left: 0;
  z-index: 500;
  max-height: 25rem;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
  overflow-y: auto;
  font-size: 1.2rem;
  background: #fff;
  border-top: 1px dashed #fff; }
  .light .dk-select-options, .light
  .searchDd-list {
    margin-top: -1px;
    color: #000;
    background: #fff;
    border: 1px solid #b7b7b7;
    border-top: 1px dashed #b7b7b7; }
  .in-form .dk-select-options, .in-form
  .searchDd-list {
    font-size: 1rem;
    max-height: 24.3333rem; }

.dk-select-open-down .dk-select-options,
.dk-select-open-up .dk-select-options {
  display: block; }
  .in-form .dk-select-open-down .dk-select-options, .in-form
  .dk-select-open-up .dk-select-options {
    top: 2.75rem; }

.light .dk-select-open-down .dk-selected, .light
.dk-select-open-up .dk-selected {
  color: black;
  background-color: white; }
  .light .dk-select-open-down .dk-selected:before, .light
  .dk-select-open-up .dk-selected:before {
    color: #000; }

.dk-option {
  padding: 0.3rem 0.9rem;
  cursor: pointer;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  transition: background 0.2s ease; }
  .layout-reversed .dk-option {
    text-align: right; }
  .dk-option.dk-option-selected, .dk-option:hover {
    background: #777; }
  .light .dk-option.dk-option-selected, .light .dk-option:hover {
    background: #b7b7b7;
    color: white; }

.hero-search-item .dk-option:first-child {
  display: none; }

#searchDd {
  position: relative; }

.searchDd-main {
  margin-bottom: 0;
  cursor: pointer; }
  .searchDd-main:before {
    -webkit-transform-origin: center 0.25rem;
    -moz-transform-origin: center 0.25rem;
    -ms-transform-origin: center 0.25rem;
    -o-transform-origin: center 0.25rem;
    transform-origin: center 0.25rem; }
  .searchDd-main.open:before {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg); }

.searchDd-list {
  overflow: visible;
  max-height: initial;
  z-index: 480; }
  .searchDd-list.open {
    display: block; }

.searchDd-category {
  position: relative;
  padding: 0.4rem 1rem;
  cursor: pointer; }
  @media screen and (min-width: 50em) {
    .searchDd-category:hover > .searchDd-sublist {
      display: block; } }
  .searchDd-category span {
    position: relative;
    display: block;
    width: 100%; }
    .searchDd-category span:before {
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translateY(-50%) rotate(-90deg) translate3d(0, 0, 0);
      -moz-transform: translateY(-50%) rotate(-90deg) translate3d(0, 0, 0);
      -ms-transform: translateY(-50%) rotate(-90deg) translate3d(0, 0, 0);
      -o-transform: translateY(-50%) rotate(-90deg) translate3d(0, 0, 0);
      transform: translateY(-50%) rotate(-90deg) translate3d(0, 0, 0);
      -webkit-transition: -webkit-transform 0.2s ease;
      -moz-transition: -moz-transform 0.2s ease;
      transition: transform 0.2s ease;
      font-size: 0.5rem; }
  .searchDd-category.searchDd-clear span:before {
    display: none; }
  .searchDd-category.open span:before {
    -webkit-transform: translateY(-50%) rotate(0) translate3d(0, 0, 0);
    -moz-transform: translateY(-50%) rotate(0) translate3d(0, 0, 0);
    -ms-transform: translateY(-50%) rotate(0) translate3d(0, 0, 0);
    -o-transform: translateY(-50%) rotate(0) translate3d(0, 0, 0);
    transform: translateY(-50%) rotate(0) translate3d(0, 0, 0); }

.searchDd-sublist {
  display: none;
  width: 100%;
  max-height: 18.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
  overflow-y: auto;
  background: #000; }
  @media screen and (min-width: 50em) {
    .searchDd-sublist {
      position: absolute;
      top: 0;
      left: 99%;
      max-height: 25rem; } }
  .searchDd-sublist li {
    padding: 0.2rem 1rem;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease; }
    .searchDd-sublist li:hover {
      opacity: 0.7; }

.section-title {
  font-size: 1.33rem;
  font-family: "brown-light", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2rem; }
  html[lang="ru"] .section-title {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .section-title {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .section-title {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .section-title {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .layout-reversed .section-title {
    letter-spacing: 0; }

.section-title-wrapper {
  display: table;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 2.16rem;
  border-bottom: 1px solid #b2b2b2; }
  .section-title-wrapper .section-title {
    display: inline-block;
    margin-bottom: 0;
    font-size: 1.75rem;
    vertical-align: text-bottom; }
    .section-title-wrapper .section-title.centre {
      display: block;
      text-align: center; }
      @media screen and (min-width: 50em) and (max-width: 63.99em) {
        .section-title-wrapper .section-title.centre {
          text-align: left; }
          .layout-reversed .section-title-wrapper .section-title.centre {
            text-align: right; } }

.mCustomScrollbar {
  -ms-touch-action: none;
  touch-action: none; }
  .mCustomScrollbar.mCS_no_scrollbar {
    -ms-touch-action: auto;
    touch-action: auto; }

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  overflow: hidden;
  width: auto;
  min-height: 100%; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -2.1667rem; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 2.5rem; }
  .mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -2.1667rem; }

.mCSB_scrollTools {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 1.3333rem;
  height: auto; }
  .mCSB_scrollTools .mCSB_draggerContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto; }
  .mCSB_scrollTools + .mCSB_draggerContainer {
    margin: 1.6667rem 0; }
  .mCSB_scrollTools .mCSB_draggerRail {
    width: 0.1667rem;
    height: 100%;
    margin: 0 auto;
    border-radius: 16px; }
  .mCSB_scrollTools .mCSB_dragger {
    z-index: 1;
    width: 100%;
    height: 2.5rem;
    cursor: pointer; }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      position: relative;
      width: 0.3333rem;
      height: 100%;
      margin: 0 auto;
      border-radius: 1.3333rem;
      text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 1rem; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 0.6667rem; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  position: absolute;
  display: block;
  width: 100%;
  height: 1.6667rem;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out; }

.mCSB_outside + .mCSB_scrollTools_vertical {
  right: -0.3333rem;
  z-index: 300;
  margin: 1rem 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 1rem; }

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

/*
.mCSB_scrollTools_vertical,
.mCSB_scrollTools_horizontal {

	.mCSB_dragger {
		height: rem(50);
	}
}
*/
.mCSB_scrollTools .mCSB_draggerRail,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 0.25rem;
  border-radius: 0; }

.select-wrapper .mCSB_outside + .mCSB_scrollTools_vertical {
  right: 0.75rem; }

.select-wrapper.in-form .mCSB_outside + .mCSB_scrollTools_vertical {
  right: 0.5rem; }

.twitter-module .mCSB_outside + .mCSB_scrollTools_vertical {
  right: 2rem;
  margin: 2.6rem 0 2.3rem; }

.mCS-minimal-dark .mCSB_draggerRail {
  background: #ddd; }

.mCS-light-2 .mCSB_draggerRail {
  background: #333; }

.mCS-minimal-dark .mCSB_dragger_bar {
  background: #999; }

.mCS-light-2 .mCSB_dragger_bar {
  background: #999; }

.twitter-module .mCSB_dragger_bar {
  background: #55acee; }

.mCS-minimal-dark .mCSB_dragger:active .mCSB_dragger_bar, .mCS-minimal-dark .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: black; }

.mCS-light-2 .mCSB_dragger:active .mCSB_dragger_bar, .mCS-light-2 .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: white; }

.rich-text h2 {
  margin-top: 2.2rem;
  margin-bottom: 1.2rem;
  color: #000;
  font-family: "brown-regular", sans-serif;
  font-size: 1.4rem;
  letter-spacing: normal; }
  html[lang="ru"] .rich-text h2 {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .rich-text h2 {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .rich-text h2 {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .rich-text h2 {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }

.rich-text p {
  color: #333; }
  .rich-text p a {
    color: #999; }
    .rich-text p a:hover {
      opacity: 0.6; }
  .rich-text p a[href^="mailto"] {
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom; }
  .layout-reversed .rich-text p strong {
    color: #4b4b4b; }

.rich-text ul,
.rich-text ol {
  color: #333; }

.rich-text.smaller p,
.rich-text.smaller ul,
.rich-text.smaller ol,
.rich-text.smaller li,
.rich-text.smaller h2 {
  font-size: 1rem;
  line-height: 1.6 !important; }

.rich-text.smaller p {
  margin-bottom: 1rem; }

.rich-text.smaller ul,
.rich-text.smaller ol {
  margin-left: 2rem; }

.rich-text .external-info,
.rich-text .external-info a {
  font-family: "brown-bold", sans-serif;
  font-family: "brown-regular", sans-serif;
  opacity: 1;
  color: #000; }
  html[lang="ru"] .rich-text .external-info, html[lang="ru"]
  .rich-text .external-info a {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .rich-text .external-info, html[lang="ko"]
  .rich-text .external-info a {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .rich-text .external-info, html[lang="zh-cn"]
  .rich-text .external-info a {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .rich-text .external-info, html[lang="ar"]
  .rich-text .external-info a {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .rich-text .external-info html[lang="ru"],
  html[lang="ko"] .rich-text .external-info,
  html[lang="zh-cn"] .rich-text .external-info,
  html[lang="ar"] .rich-text .external-info,
  .rich-text .external-info a html[lang="ru"],
  html[lang="ko"]
  .rich-text .external-info a,
  html[lang="zh-cn"]
  .rich-text .external-info a,
  html[lang="ar"]
  .rich-text .external-info a {
    font-weight: 700; }

.rich-text .external-info a:hover {
  opacity: 0.6; }

.rich-text p,
.rich-text ol,
.rich-text ul {
  margin-bottom: 1.75rem; }

.rich-text ol,
.rich-text ul {
  font-size: 1.25rem;
  margin-left: 4rem; }
  .layout-reversed .rich-text ol, .layout-reversed
  .rich-text ul {
    margin-right: 4rem;
    margin-left: 0; }
  .rich-text ol ol,
  .rich-text ol ul,
  .rich-text ul ol,
  .rich-text ul ul {
    margin-bottom: 0;
    margin-left: 2rem; }
    .layout-reversed .rich-text ol ol, .layout-reversed
    .rich-text ol ul, .layout-reversed
    .rich-text ul ol, .layout-reversed
    .rich-text ul ul {
      margin-right: 2rem;
      margin-bottom: 0;
      margin-left: 0; }

.rich-text ul {
  list-style: disc; }

.rich-text ol {
  list-style: decimal; }

.rich-text img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 1.75rem; }

.layout-reversed .rich-text h2 {
  color: #4b4b4b;
  font-size: 1.6667rem; }

.back-to-top-wrap {
  text-align: center;
  margin: 3rem 0; }

.back-to-top {
  position: relative;
  width: 4.8333rem;
  height: 4.8333rem;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  border: solid #333 0.0833rem; }
  .back-to-top:before {
    position: absolute;
    top: 0;
    right: -0.3333rem;
    bottom: auto;
    left: auto;
    color: #333;
    font-size: 2rem;
    text-align: center;
    text-indent: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .back-to-top:active {
    background: #333; }
    .back-to-top:active, .back-to-top:active:before {
      color: white; }

.accordion-wrap .accordion-trigger {
  position: relative;
  cursor: pointer;
  height: auto;
  padding: 1rem; }
  @media screen and (min-width: 50em) {
    .accordion-wrap .accordion-trigger {
      padding: 0;
      cursor: auto; } }
  .accordion-wrap .accordion-trigger:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
    @media screen and (min-width: 50em) {
      .accordion-wrap .accordion-trigger:before {
        content: none; } }

.accordion-wrap .view-more {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -1.6667rem;
  font-size: 2.1667rem; }
  .accordion-wrap .view-more:after {
    content: '+';
    display: block;
    width: 100%;
    text-align: center; }
  .accordion-wrap .view-more.active:after {
    content: '–';
    margin-top: -0.1rem; }
  @media screen and (min-width: 50em) {
    .accordion-wrap .view-more:after {
      display: none; } }
  .layout-reversed .accordion-wrap .view-more {
    right: auto;
    left: 1rem; }

@media screen and (min-width: 50em) {
  .accordion-wrap.accordion-on-mobile {
    min-height: 30.8333rem;
    margin-bottom: 2.5rem;
    padding: 2rem; } }

.accordion-trigger-text {
  display: inline-block;
  width: 90%;
  font-size: 1.6667rem;
  vertical-align: middle; }
  @media screen and (min-width: 50em) {
    .accordion-trigger-text {
      height: auto;
      padding-right: 0;
      font-size: 2.1667rem;
      vertical-align: top; } }
  .accordion-trigger-text:after {
    display: none; }
    @media screen and (min-width: 50em) {
      .accordion-trigger-text:after {
        display: block; } }

.accordion-content {
  display: none;
  padding: 0 1rem 1rem; }
  @media screen and (min-width: 50em) {
    .accordion-content {
      display: block;
      margin-top: 0;
      padding: 0; }
      .accordion-on-mobile .accordion-content {
        display: block !important; } }
  .accordion-content p, .accordion-content li {
    font-size: 1.1667rem; }
  .accordion-content li {
    margin-bottom: 0.8rem; }

@media screen and (min-width: 50em) {
  .accordion-wrap.accordion-simple {
    min-height: 1rem; } }

.accordion-wrap.accordion-simple.full .accordion-content {
  max-width: 100%; }

.accordion-wrap.accordion-simple:first-child {
  display: block; }

.accordion-wrap.accordion-simple .accordion-trigger-text,
.accordion-wrap.accordion-simple .accordion-content {
  color: #fff; }

.accordion-wrap.accordion-simple .accordion-trigger-text {
  padding-right: 3.1rem;
  text-transform: uppercase; }
  @media screen and (min-width: 50em) {
    .accordion-wrap.accordion-simple .accordion-trigger-text {
      vertical-align: middle;
      font-size: 1.6667rem; } }
  .layout-reversed .accordion-wrap.accordion-simple .accordion-trigger-text {
    padding-right: 0;
    padding-left: 3.1rem; }

.accordion-wrap.accordion-simple .accordion-content {
  display: none;
  max-width: 66.6667rem;
  padding-left: 1.2rem; }
  .layout-reversed .accordion-wrap.accordion-simple .accordion-content {
    padding-left: 0;
    padding-right: 1.2rem; }

.accordion-wrap.accordion-simple .accordion-trigger {
  height: auto;
  margin-bottom: 1.25rem;
  background: #008a9d;
  cursor: pointer; }
  @media screen and (min-width: 50em) {
    .accordion-wrap.accordion-simple .accordion-trigger {
      padding: 1rem; } }

.accordion-wrap.accordion-simple .view-more {
  display: block;
  margin-top: -1.6rem;
  color: #fff; }
  .accordion-wrap.accordion-simple .view-more:after {
    content: '+';
    display: block;
    width: 100%;
    text-align: center; }
  .accordion-wrap.accordion-simple .view-more.active:after {
    content: '–';
    margin-top: -0.1rem; }

.open-wen-filled .accordion-content {
  display: none; }

.open-wen-filled .accordion-trigger {
  pointer-events: none; }

.accordion-content-2col-row {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 34em) {
    .accordion-content-2col-row.bigsmall .accordion-content-2col-col:first-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 58.3333%; }
      .accordion-content-2col-row.bigsmall .accordion-content-2col-col:first-child:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 40em) {
    .accordion-content-2col-row.bigsmall .accordion-content-2col-col:first-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 66.6667%; }
      .accordion-content-2col-row.bigsmall .accordion-content-2col-col:first-child:last-child {
        margin-right: 0; } }
  @media only screen and (min-width: 34em) {
    .accordion-content-2col-row.bigsmall .accordion-content-2col-col:last-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 41.6667%; }
      .accordion-content-2col-row.bigsmall .accordion-content-2col-col:last-child:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 40em) {
    .accordion-content-2col-row.bigsmall .accordion-content-2col-col:last-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 33.3333%; }
      .accordion-content-2col-row.bigsmall .accordion-content-2col-col:last-child:last-child {
        margin-right: 0; } }
  @media only screen and (min-width: 34em) {
    .accordion-content-2col-row.smallbig .accordion-content-2col-col:first-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 41.6667%; }
      .accordion-content-2col-row.smallbig .accordion-content-2col-col:first-child:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 40em) {
    .accordion-content-2col-row.smallbig .accordion-content-2col-col:first-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 33.3333%; }
      .accordion-content-2col-row.smallbig .accordion-content-2col-col:first-child:last-child {
        margin-right: 0; } }
  @media only screen and (min-width: 34em) {
    .accordion-content-2col-row.smallbig .accordion-content-2col-col:last-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 58.3333%; }
      .accordion-content-2col-row.smallbig .accordion-content-2col-col:last-child:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 40em) {
    .accordion-content-2col-row.smallbig .accordion-content-2col-col:last-child {
      float: left;
      display: block;
      margin-right: 0%;
      width: 66.6667%; }
      .accordion-content-2col-row.smallbig .accordion-content-2col-col:last-child:last-child {
        margin-right: 0; } }
  @media only screen and (min-width: 34em) {
    .accordion-content-2col-row.half .accordion-content-2col-col:first-child {
      margin-right: 8%; } }
  @media only screen and (min-width: 34em) {
    .accordion-content-2col-row.half .accordion-content-2col-col {
      float: left;
      display: block;
      width: 46%;
      padding-right: 0; } }
  @media only screen and (min-width: 34em) {
    .layout-reversed .accordion-content-2col-row.half .accordion-content-2col-col:first-child {
      margin-right: 0;
      margin-left: 8%; } }
  @media only screen and (min-width: 34em) {
    .layout-reversed .accordion-content-2col-row.half .accordion-content-2col-col {
      float: right;
      display: block;
      width: 46%;
      padding-left: 0; } }
  .accordion-content-2col-row.submit-right::after {
    clear: both;
    content: "";
    display: table; }
  .accordion-content-2col-row.submit-right .button {
    float: right; }
    .layout-reversed .accordion-content-2col-row.submit-right .button {
      float: left; }
  .accordion-content-2col-row .button[type="submit"] {
    min-width: 16rem; }
  .accordion-content-2col-row .captcha-container {
    margin: 33px 0; }

.accordion-content-2col-col:first-child {
  margin-bottom: 0.6rem; }
  @media only screen and (min-width: 34em) {
    .accordion-content-2col-col:first-child {
      margin-bottom: 0;
      padding-right: 1.2rem; } }

.accordion-content-2col-col p {
  margin-bottom: 0; }
  .accordion-content-2col-col p.lead {
    font-family: "brown-bold", sans-serif; }

.accordion-content-2col-col a {
  display: block;
  font-size: 1.25rem; }
  .accordion-content-2col-col a.tel {
    color: #000; }

.accordion-content-2col-col textarea {
  min-height: 8.5rem; }

@media only screen and (min-width: 34em) {
  .layout-reversed .accordion-content-2col-col {
    float: right !important; }
    .layout-reversed .accordion-content-2col-col:first-child {
      padding-right: 0;
      padding-left: 1.2rem; } }

.accordion-form.first .accordion-content {
  display: block; }

@media screen and (min-width: 50em) {
  .accordion-form .accordion-content {
    padding-right: 0.9rem; } }

.accordion-form.shown .accordion-content, .accordion-form.always-visible .accordion-content {
  display: block !important; }

.full-video {
  position: relative;
  width: 100%;
  margin: 0 auto 3.3333rem auto;
  padding-top: 1.6667rem;
  padding-bottom: 53.25%; }
  .full-video.module {
    margin-bottom: 5rem; }

.video-cover-overlay {
  width: 100%;
  height: 100%;
  background-image: -webkit-radial-gradient(50% 50%, circle, rgba(0, 0, 0, 0.4) 10%, transparent 70%);
  background-image: radial-gradient(  circle at 50% 50%, rgba(0, 0, 0, 0.4) 10%, transparent 70%);
  position: absolute;
  top: 0;
  left: 0; }

.transparent-video-img {
  width: 100%;
  position: relative;
  z-index: -10; }

.full-video .video-brightcove,
.full-video iframe,
.video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.video-cover {
  z-index: 10;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer; }
  .no-touchevents .slider-sync-simple .video-cover {
    background: none !important; }

.video-cover-content {
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 2rem 2.1rem;
  color: white;
  text-align: center; }
  .video-cover-content h2 {
    margin-bottom: 0.8333rem;
    font-size: 2rem;
    font-family: "brown-regular", sans-serif;
    text-transform: uppercase; }
    html[lang="ru"] .video-cover-content h2 {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] .video-cover-content h2 {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] .video-cover-content h2 {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] .video-cover-content h2 {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .video-cover-content p {
    font-family: "brown-regular", sans-serif;
    font-size: 1rem; }
    html[lang="ru"] .video-cover-content p {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] .video-cover-content p {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] .video-cover-content p {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] .video-cover-content p {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .video-cover-content .play-button {
    width: 4.1667rem;
    margin-top: 0.8333rem; }
  @media screen and (min-width: 40em) {
    .video-cover-content h2 {
      margin-bottom: 1.6667rem;
      font-size: 3.3333rem; }
    .video-cover-content p {
      font-size: 1.25rem; }
    .video-cover-content .play-button {
      width: 6.5833rem;
      margin-top: 1.6667rem; } }
  @media screen and (min-width: 50em) {
    .video-cover-content h2 {
      margin-bottom: 3.3333rem; }
    .video-cover-content .play-button {
      margin-top: 3.3333rem; } }

.video-embed {
  z-index: 1; }

.full-video .video-brightcove,
.full-video iframe {
  z-index: 5; }

.video-widget-title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.2rem 1rem 1rem;
  color: #fff;
  font-size: 1.5rem;
  font-family: "brown-regular", sans-serif;
  text-transform: uppercase;
  text-align: center;
  background: rgba(0, 138, 157, 0.8); }
  html[lang="ru"] .video-widget-title {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .video-widget-title {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .video-widget-title {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .video-widget-title {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }

.vjs-dock-text {
  display: none; }

.vjs-tech {
  height: auto !important;
  position: relative !important;
  top: 50% !important;
  transform: translateY(-50%); }

.accordion-tabs {
  position: relative;
  height: auto;
  margin-bottom: 5rem; }
  .accordion-tabs .accordion-tab-trigger,
  .accordion-tabs .tab-trigger {
    height: 5.2rem;
    padding: 1.25rem;
    background-color: #fff;
    cursor: pointer; }
    .accordion-tabs .accordion-tab-trigger.active,
    .accordion-tabs .tab-trigger.active {
      background-color: #008a9d;
      border-color: #008a9d;
      pointer-events: none; }
      .accordion-tabs .accordion-tab-trigger.active h2,
      .accordion-tabs .tab-trigger.active h2 {
        color: #fff; }
        .accordion-tabs .accordion-tab-trigger.active h2:after,
        .accordion-tabs .tab-trigger.active h2:after {
          content: '–'; }
          @media screen and (min-width: 50em) {
            .accordion-tabs .accordion-tab-trigger.active h2:after,
            .accordion-tabs .tab-trigger.active h2:after {
              content: ''; } }
    .accordion-tabs .accordion-tab-trigger:focus,
    .accordion-tabs .tab-trigger:focus {
      opacity: 1; }
    .accordion-tabs .accordion-tab-trigger h2,
    .accordion-tabs .tab-trigger h2 {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: rgba(0, 0, 0, 0.6);
      font-size: 1.3333rem;
      font-family: "brown-regular", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.05rem; }
      html[lang="ru"] .accordion-tabs .accordion-tab-trigger h2, html[lang="ru"]
      .accordion-tabs .tab-trigger h2 {
        font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ko"] .accordion-tabs .accordion-tab-trigger h2, html[lang="ko"]
      .accordion-tabs .tab-trigger h2 {
        font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="zh-cn"] .accordion-tabs .accordion-tab-trigger h2, html[lang="zh-cn"]
      .accordion-tabs .tab-trigger h2 {
        font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ar"] .accordion-tabs .accordion-tab-trigger h2, html[lang="ar"]
      .accordion-tabs .tab-trigger h2 {
        font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
      .accordion-tabs .accordion-tab-trigger h2:after,
      .accordion-tabs .tab-trigger h2:after {
        content: '+';
        position: absolute;
        top: -0.6667rem;
        right: 0;
        font-size: 2.1667rem; }
        .active .accordion-tabs .accordion-tab-trigger h2:after, .active
        .accordion-tabs .tab-trigger h2:after {
          content: '-'; }
      @media screen and (min-width: 50em) {
        .accordion-tabs .accordion-tab-trigger h2,
        .accordion-tabs .tab-trigger h2 {
          text-align: center; }
          .accordion-tabs .accordion-tab-trigger h2:after,
          .accordion-tabs .tab-trigger h2:after {
            content: ''; } }
  .accordion-tabs .accordion-tab-content .accordion-trigger {
    margin-bottom: 0.8333rem;
    padding-top: 1.3333rem;
    padding-bottom: 1.3333rem; }
  .accordion-tabs .accordion-tab-content .accordion-trigger-text {
    font-size: 1.1667rem;
    font-family: "brown-regular", sans-serif; }
    html[lang="ru"] .accordion-tabs .accordion-tab-content .accordion-trigger-text {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] .accordion-tabs .accordion-tab-content .accordion-trigger-text {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] .accordion-tabs .accordion-tab-content .accordion-trigger-text {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] .accordion-tabs .accordion-tab-content .accordion-trigger-text {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .accordion-tabs .accordion-tab-content .accordion-content {
    padding-bottom: 1.5rem; }
  .accordion-tabs .accordion-tab-content .module-free-text-header {
    margin-bottom: 1rem;
    font-family: "brown-regular", sans-serif;
    letter-spacing: 0.05rem; }
    html[lang="ru"] .accordion-tabs .accordion-tab-content .module-free-text-header {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] .accordion-tabs .accordion-tab-content .module-free-text-header {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] .accordion-tabs .accordion-tab-content .module-free-text-header {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] .accordion-tabs .accordion-tab-content .module-free-text-header {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .accordion-tabs .accordion-tab-content p + .module-free-text-header {
    margin-top: 2rem; }

.tab-triggers {
  display: none;
  height: 5.2rem; }
  @media screen and (min-width: 50em) {
    .tab-triggers {
      display: block; } }

.tab-trigger {
  float: left;
  width: 25%;
  height: 5.2rem;
  padding: 1.25rem;
  border-style: solid;
  border-color: #ccc transparent transparent #ccc;
  border-width: 1px;
  cursor: pointer; }
  .tab-trigger:last-child {
    border-color: #ccc #ccc transparent #ccc; }
  .layout-reversed .tab-trigger {
    float: right;
    left: auto;
    right: 0;
    border-color: #ccc #ccc transparent transparent; }
    .layout-reversed .tab-trigger:last-child {
      border-color: #ccc transparent #ccc #ccc; }

.accordion-tab-content {
  width: 100%;
  height: auto;
  font-family: "brown-regular", sans-serif; }
  html[lang="ru"] .accordion-tab-content {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .accordion-tab-content {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .accordion-tab-content {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .accordion-tab-content {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .accordion-tab-content > div {
    width: 100%;
    height: 100%;
    padding: 0.8333rem;
    border: 0.0833rem solid #000;
    border-bottom: none;
    background-color: white; }
    @media screen and (min-width: 50em) {
      .accordion-tab-content > div {
        border: 0.0833rem solid #000; } }
    @media screen and (min-width: 40em) {
      .accordion-tab-content > div {
        padding: 2.0833rem; } }
  .accordion-tab-wrap:last-child .accordion-tab-content > div {
    border-bottom: 0.0833rem solid #000; }
  .accordion-tab-content .module-free-text-header {
    margin-bottom: 1.6667rem;
    font-size: 1.3333rem !important;
    text-transform: uppercase; }
    .accordion-tab-content .module-free-text-header sup {
      font-size: 0.7em; }
    .accordion-tab-content .module-free-text-header::after {
      display: none; }
    @media screen and (min-width: 50em) {
      .accordion-tab-content .module-free-text-header .module-free-text-header {
        font-size: 1.6667rem; } }
  .accordion-tab-content .overlay-getting-here {
    width: 100%;
    text-align: center;
    margin-bottom: 1.6667rem; }
    .accordion-tab-content .overlay-getting-here .button {
      display: inline-block; }
  .accordion-tab-content[data-tab="shopping-express"] .overlay-getting-here {
    margin-top: 3rem; }

.accordion-tab-trigger {
  border-style: solid;
  border-color: #000;
  border-width: 1px 1px 0;
  cursor: pointer; }
  .accordion-tab-wrap:last-child .accordion-tab-trigger {
    border-width: 1px; }
  @media screen and (min-width: 50em) {
    .accordion-tab-trigger {
      display: none; } }

a.accordion-trigger {
  color: #000;
  opacity: 1;
  display: block;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
  a.accordion-trigger:hover {
    opacity: 1; }

[type="radio"] {
  position: absolute;
  left: -9999px; }
  [type="radio"] + label {
    position: relative;
    padding-right: 10px;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    min-height: 25px;
    line-height: 15px;
    font-size: 1rem;
    -webkit-transition: all 0.28s ease;
    -moz-transition: all 0.28s ease;
    transition: all 0.28s ease;
    padding-top: 0.4167rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none; }
    [type="radio"] + label:after, [type="radio"] + label:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      margin: 4px;
      width: 16px;
      height: 16px;
      z-index: 0;
      -webkit-transition: 0.28s ease;
      -moz-transition: 0.28s ease;
      transition: 0.28s ease; }
    [type="radio"] + label:before {
      border-radius: 50%;
      border: 1px solid #b7b7b7; }
    [type="radio"] + label:after {
      border-radius: 50%;
      border: 1px solid #b7b7b7;
      z-index: -1;
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0); }
  [type="radio"]:checked + label:before {
    border-radius: 50%;
    border: 1px solid transparent; }
  [type="radio"]:checked + label:after {
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
    z-index: 0;
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02); }
  [type="radio"]:focus + label,
  [type="radio"]:focus + input[type="hidden"] + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight; }
    @media (-webkit-min-device-pixel-ratio: 0) {
      [type="radio"]:focus + label,
      [type="radio"]:focus + input[type="hidden"] + label {
        outline: -webkit-focus-ring-color auto 5px; } }
  [type="radio"].with-gap:checked + label:before {
    border-radius: 50%;
    border: 1px solid black; }
  [type="radio"].with-gap:checked + label:after {
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
    z-index: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5); }
  [type="radio"] [type="radio"]:disabled + label {
    color: gray; }
    [type="radio"] [type="radio"]:disabled + label:before {
      background-color: transparent;
      border-color: gray; }
      [type="radio"] [type="radio"]:disabled + label:before:hover {
        border-color: gray; }

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: gray; }

[type="radio"]:disabled + label {
  color: gray; }

[type="radio"]:disabled:not(:checked) + label:hover:before {
  border-color: gray; }

/***************
   Checkboxes
***************/
.checkbox-wrap {
  position: relative;
  line-height: 150%;
  margin: 1.6667rem 0 0.8333rem 0.4167rem; }
  .checkbox-wrap label {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    padding-left: 2.3333rem; }
    .checkbox-wrap label [type="radio"] + label {
      position: relative; }
    .checkbox-wrap label:before, .checkbox-wrap label:after {
      content: "";
      position: absolute;
      width: 1.8333rem;
      height: 1.8333rem;
      top: -0.25rem;
      left: -0.4rem; }
    .checkbox-wrap label:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      border: 1px solid #b7b7b7; }
    .checkbox-wrap label:after {
      background: white; }
  .checkbox-wrap input[type="checkbox"]:checked ~ label:not(.box-check-trigger):before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    height: 0.8333rem;
    width: 2rem;
    border-color: black;
    border-top-style: none;
    border-right-style: none; }

.box-check-wrapper::after {
  clear: both;
  content: "";
  display: table; }

.box-check-wrapper .box-check {
  float: left;
  margin-top: 0.4rem;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; }
  .box-check-wrapper .box-check input[type="checkbox"] {
    display: block;
    position: absolute;
    top: 0.75rem;
    left: 0.3333rem;
    opacity: 0.01; }
  .box-check-wrapper .box-check input[type="checkbox"]:checked ~ .box-check-trigger:before {
    background-size: 100%; }
  .box-check-wrapper .box-check input[type="checkbox"]:focus ~ .box-check-trigger {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight; }
    @media (-webkit-min-device-pixel-ratio: 0) {
      .box-check-wrapper .box-check input[type="checkbox"]:focus ~ .box-check-trigger {
        outline: -webkit-focus-ring-color auto 5px; } }
  .box-check-wrapper .box-check.disabled {
    opacity: 0.6; }
    .box-check-wrapper .box-check.disabled:hover * {
      cursor: default !important; }

.box-check-wrapper .box-check-trigger {
  position: relative;
  padding-left: 2.0833rem;
  margin-right: 1.1667rem;
  color: #969899; }
  .box-check-wrapper .box-check-trigger:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url(../img/src/deco/checkbox-cross-grey18x18.png) center center no-repeat;
    background-size: 0;
    border: 1px solid #969899;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .box-check-wrapper .box-check-trigger:after {
    display: none; }

#login form .checkbox-wrap {
  margin-top: 1rem;
  margin-bottom: 1rem; }

#login form .checkbox-wrap,
.signin-form-content .checkbox-wrap {
  margin-left: 0; }
  #login form .checkbox-wrap label,
  .signin-form-content .checkbox-wrap label {
    position: relative;
    float: left;
    margin-bottom: 0;
    line-height: 2.0833rem; }
    #login form .checkbox-wrap label:before,
    .signin-form-content .checkbox-wrap label:before {
      top: 0;
      left: 0; }
  #login form .checkbox-wrap input[type=checkbox],
  .signin-form-content .checkbox-wrap input[type=checkbox] {
    display: none; }
  #login form .checkbox-wrap .tooltip-wrapper,
  .signin-form-content .checkbox-wrap .tooltip-wrapper {
    position: relative;
    float: left;
    margin-left: 10px; }
  #login form .checkbox-wrap .tooltip-trigger,
  .signin-form-content .checkbox-wrap .tooltip-trigger {
    width: 1.5rem;
    height: 1.5rem; }
    #login form .checkbox-wrap .tooltip-trigger:before,
    .signin-form-content .checkbox-wrap .tooltip-trigger:before {
      font-size: 0.8rem; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.page-products .hero-search {
  max-width: 24rem; }
  @media screen and (min-width: 50em) {
    .page-products .hero-search {
      max-width: 50rem; } }
  @media screen and (min-width: 64em) {
    .page-products .hero-search {
      max-width: 61.2rem; } }

.cookies-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  display: none;
  width: 100%;
  padding: 0 0.7rem 0.4rem;
  background-color: #b0d4e2;
  border-top: 2px solid #000;
  opacity: 0.95;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .cookies-container .page-wrapper-wide {
    padding-top: 2.0833rem !important;
    padding-bottom: 1.25rem !important; }
    .cookies-container .page-wrapper-wide p {
      margin-bottom: 0; }
  .cookies-container .row, .cookies-container .accordion-content-2col-row {
    position: relative;
    padding-right: 4.1667rem; }

.cookies-close {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  color: #000; }
  .cookies-close:hover, .cookies-close:visited, .cookies-close:focus {
    color: #000;
    opacity: 1; }

.table-scroll {
  width: 100%;
  overflow: auto; }

.content-table {
  width: 100%;
  min-width: 900px;
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  margin-bottom: 1.6667rem;
  color: #777;
  font-size: 1.25rem; }
  .content-table th, .content-table td {
    padding: 0.6667rem;
    line-height: 2rem;
    text-align: left;
    vertical-align: top;
    border-collapse: collapse; }
    .content-table th:first-child, .content-table td:first-child {
      width: 8.3333rem; }
    .content-table th:last-child, .content-table td:last-child {
      width: 55%; }
  .content-table td {
    border-top: 1px solid #dddddd; }
  .content-table tr {
    border-bottom: none; }
    .content-table tr:nth-child(odd) td {
      background-color: #f9f9f9; }
  .content-table ul {
    list-style-type: disc;
    margin: 0 0 0.8333rem 2.0833rem; }

.ui-timepicker-wrapper {
  z-index: 10001;
  height: 12.5rem;
  width: calc(100% - 104px);
  overflow-y: auto;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  outline: none; }
  @media screen and (min-width: 40em) {
    .ui-timepicker-wrapper {
      max-width: 20.4167rem; } }
  .ui-timepicker-wrapper.ui-timepicker-with-duration {
    width: 13em; }
    .ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30, .ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
      width: 11em; }

.ui-timepicker-list {
  list-style: none; }

.ui-timepicker-duration {
  margin-left: 5px;
  color: #888; }

.ui-timepicker-list li {
  padding: 0.25rem 0 0.25rem 0.4167rem;
  cursor: pointer;
  white-space: nowrap;
  color: #000;
  list-style: none; }

.ui-timepicker-list:hover .ui-timepicker-selected {
  background: #fff;
  color: #000; }

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
  background: #b7b7b7;
  color: #fff; }

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
  color: #ccc; }

.ui-timepicker-list:hover .ui-timepicker-duration,
.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  color: #888;
  cursor: default; }

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  background: #f2f2f2; }

input::-webkit-calendar-picker-indicator {
  display: none; }

input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important; }

.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  position: absolute;
  width: 0.0833rem;
  height: 0.0833rem;
  margin: -0.0833rem;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.ui-helper-reset {
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: '';
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-zfix {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.ui-front {
  z-index: 100; }

.ui-state-disabled {
  cursor: default !important; }

.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none; }
  .ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0; }
  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 0.1667rem;
    width: 1.8em;
    height: 1.8em; }
    .ui-datepicker .ui-datepicker-prev span,
    .ui-datepicker .ui-datepicker-next span {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin-left: -8px;
      margin-top: -8px; }
  .ui-datepicker .ui-datepicker-prev-hover,
  .ui-datepicker .ui-datepicker-next-hover {
    top: 0.0833rem; }
  .ui-datepicker .ui-datepicker-prev {
    left: 0.1667rem; }
  .ui-datepicker .ui-datepicker-next {
    right: 0.1667rem; }
  .ui-datepicker .ui-datepicker-prev-hover {
    left: 0.0833rem; }
  .ui-datepicker .ui-datepicker-next-hover {
    right: 0.0833rem; }
  .ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center; }
    .ui-datepicker .ui-datepicker-title select {
      font-size: 1em;
      margin: 0.0833rem 0; }
  .ui-datepicker select.ui-datepicker-month,
  .ui-datepicker select.ui-datepicker-year {
    width: 45%; }
  .ui-datepicker table {
    width: 100%;
    margin: 0 0 0.4em;
    font-size: .9em;
    border-collapse: collapse; }
  .ui-datepicker th {
    padding: 0.7em 0.3em;
    text-align: center;
    font-weight: bold;
    border: 0; }
  .ui-datepicker td {
    padding: 0.0833rem;
    border: 0; }
  .ui-datepicker td span,
  .ui-datepicker td a {
    display: block;
    padding: 0.2em;
    text-align: right;
    text-decoration: none; }
  .ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: 0.7em 0 0;
    padding: 0 0.2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0; }
    .ui-datepicker .ui-datepicker-buttonpane button {
      float: right;
      width: auto;
      margin: 0.5em 0.2em 0.4em;
      padding: 0.2em 0.6em 0.3em;
      cursor: pointer;
      overflow: visible; }
      .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
        float: left; }

#ui-datepicker-div {
  z-index: 5 !important; }

.ui-widget {
  font-size: 1.1em; }
  .ui-widget .ui-widget {
    font-size: 1em; }
  .ui-widget input,
  .ui-widget select,
  .ui-widget textarea,
  .ui-widget button {
    font-size: 1em; }

.ui-widget-content {
  border: 1px solid #ddd;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #222; }
  .ui-widget-content a {
    color: #222; }

.ui-widget-header {
  border: 1px solid #aaa;
  background: #ccc;
  color: #222;
  font-weight: bold; }
  .ui-widget-header a {
    color: #222; }

.ui-datepicker-year,
.ui-datepicker-month {
  color: white; }

.ui-datepicker {
  z-index: 99999 !important; }

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #d3d3d3;
  background: #f5f5f5;
  font-weight: normal;
  color: #555; }

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555;
  text-decoration: none; }

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content
.ui-state-focus,
.ui-widget-header
.ui-state-focus {
  border: 1px solid black;
  background: black;
  font-weight: normal;
  color: white;
  opacity: 1; }

.ui-datepicker-next,
.ui-datepicker-prev {
  cursor: pointer;
  background: none !important;
  border: none !important; }
  .ui-datepicker-next span,
  .ui-datepicker-prev span {
    background: none !important;
    width: 0;
    height: 0; }

.ui-datepicker-next span {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid white; }

.ui-datepicker-prev span {
  border-top: 7px solid transparent;
  border-right: 10px solid white;
  border-bottom: 7px solid transparent; }

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-active a,
.ui-state-active a:hover,
.ui-state-active a:link {
  color: #212121;
  text-decoration: none; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #aaa;
  background: black;
  font-weight: normal;
  color: white; }

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #b7b7b7;
  background: #b7b7b7;
  color: white; }
  .ui-state-highlight a,
  .ui-widget-content .ui-state-highlight a,
  .ui-widget-header .ui-state-highlight a {
    color: #363636; }

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #cd0a0a;
  background: #fef1ec;
  color: #cd0a0a; }
  .ui-state-error a,
  .ui-widget-content .ui-state-error a,
  .ui-widget-header .ui-state-error a {
    color: #cd0a0a; }

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #cd0a0a; }

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: .7;
  font-weight: normal; }

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  background-image: none; }

.ui-icon {
  width: 1.3333rem;
  height: 1.3333rem; }
  .ui-icon,
  .ui-widget-content .ui-icon,
  .ui-widget-header .ui-icon {
    background-image: url("../img/src/datepicker-icons/ui-icons_222222_256x240.png"); }
  .ui-state-default .ui-icon {
    background-image: url("../img/src/datepicker-icons/ui-icons_888888_256x240.png"); }
  .ui-state-hover .ui-icon,
  .ui-state-focus .ui-icon,
  .ui-state-active .ui-icon {
    background-image: url("../img/src/datepicker-icons/ui-icons_454545_256x240.png"); }
  .ui-state-highlight .ui-icon {
    background-image: url("../img/src/datepicker-icons/ui-icons_2e83ff_256x240.png"); }
  .ui-state-error .ui-icon,
  .ui-state-error-text .ui-icon {
    background-image: url("../img/src/datepicker-icons/ui-icons_cd0a0a_256x240.png"); }

.ui-icon-circle-triangle-e {
  background-position: -48px -192px; }

.ui-icon-circle-triangle-w {
  background-position: -80px -192px; }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-radius: 0 !important; }

.ui-widget-overlay,
.ui-widget-shadow {
  background: #aaa url("../img/src/datepicker-icons/ui-bg_flat_0_aaaaaa_40x100.png") 50% 50% repeat-x;
  opacity: .3; }

.ui-widget-shadow {
  margin: -0.6667rem 0 0 -0.6667rem;
  padding: 0.6667rem;
  border-radius: 8px; }

.small-info {
  font-size: 10px; }
  .page-products .small-info,
  .page-products-new .small-info {
    text-align: center;
    margin-bottom: 5rem; }

.search-results-form {
  margin-bottom: 5rem; }

.search-results {
  margin-top: 1.6667rem; }
  .search-results .search-item {
    margin-bottom: 5rem; }
    .search-results .search-item .search-item-title {
      font-size: 1.4167rem;
      color: #333;
      margin-bottom: 1.1667rem; }
    .search-results .search-item .search-item-link {
      margin-top: -0.8333rem; }
  .search-results .search-results-pagination {
    height: 2.0833rem;
    margin: 5rem 0 0 -0.5833rem;
    padding: 0; }
    @media screen and (min-width: 50em) {
      .search-results .search-results-pagination {
        margin-top: 6.6667rem; } }
    .search-results .search-results-pagination li {
      width: 2.0833rem;
      height: 100%;
      text-align: center;
      font-family: "brown-regular", sans-serif;
      list-style: none;
      float: left; }
      html[lang="ru"] .search-results .search-results-pagination li {
        font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ko"] .search-results .search-results-pagination li {
        font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="zh-cn"] .search-results .search-results-pagination li {
        font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ar"] .search-results .search-results-pagination li {
        font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
      .search-results .search-results-pagination li a {
        color: #ccc;
        width: 100%;
        height: 100%; }
      .search-results .search-results-pagination li .active, .search-results .search-results-pagination li :hover {
        color: #333; }
      .search-results .search-results-pagination li .webfont-cheveron, .search-results .search-results-pagination li .dk-selected, .search-results .search-results-pagination li
      .searchDd-main, .search-results .search-results-pagination li .searchDd-category span, .searchDd-category .search-results .search-results-pagination li span {
        display: block;
        position: relative; }
        .search-results .search-results-pagination li .webfont-cheveron:hover, .search-results .search-results-pagination li .dk-selected:hover, .search-results .search-results-pagination li
        .searchDd-main:hover, .search-results .search-results-pagination li .searchDd-category span:hover, .searchDd-category .search-results .search-results-pagination li span:hover {
          opacity: 1; }
        .search-results .search-results-pagination li .webfont-cheveron.next, .search-results .search-results-pagination li .next.dk-selected, .search-results .search-results-pagination li
        .next.searchDd-main, .search-results .search-results-pagination li .searchDd-category span.next, .searchDd-category .search-results .search-results-pagination li span.next {
          margin-top: 0.1667rem;
          transform: rotate(-90deg); }
        .search-results .search-results-pagination li .webfont-cheveron:before, .search-results .search-results-pagination li .dk-selected:before, .search-results .search-results-pagination li
        .searchDd-main:before, .search-results .search-results-pagination li .searchDd-category span:before, .searchDd-category .search-results .search-results-pagination li span:before {
          position: absolute;
          width: auto;
          height: auto;
          top: 50%;
          left: 50%;
          margin-top: -0.5833rem;
          margin-left: -0.5833rem; }
        .search-results .search-results-pagination li .webfont-cheveron.prev::before, .search-results .search-results-pagination li .prev.dk-selected::before, .search-results .search-results-pagination li
        .prev.searchDd-main::before, .search-results .search-results-pagination li .searchDd-category span.prev::before, .searchDd-category .search-results .search-results-pagination li span.prev::before {
          transform: rotate(90deg); }

.hero-nav-wrapper + p {
  margin-top: 3.6rem; }

p + .hero-nav-wrapper {
  margin-top: 2.4rem; }

.hero-nav-list {
  display: none;
  text-align: center;
  margin-bottom: -1.8rem; }
  @media screen and (min-width: 55em) {
    .hero-nav-list {
      display: block; } }

.hero-nav-list-item {
  display: inline-block;
  margin-right: 2%;
  margin-bottom: 1.8rem; }
  .hero-nav-list-item:last-child {
    margin-right: 0; }
  .hero-nav-list-item a {
    font-family: "brown-regular", sans-serif;
    text-transform: uppercase;
    color: #000;
    font-size: 1.1667rem; }
    html[lang="ru"] .hero-nav-list-item a {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] .hero-nav-list-item a {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] .hero-nav-list-item a {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] .hero-nav-list-item a {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .layout-reversed .hero-nav-list-item {
    margin-right: 0;
    margin-left: 2%; }
    .layout-reversed .hero-nav-list-item:last-child {
      margin-left: 0; }

.hero-nav-dropdown {
  max-width: 26rem;
  width: 84%;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
  text-align: center; }
  @media screen and (min-width: 55em) {
    .hero-nav-dropdown {
      display: none;
      margin-bottom: 0; } }
  .hero-nav-dropdown select {
    text-align: center;
    text-align-last: center; }
  .hero-nav-dropdown option {
    text-align: center; }

/*! Flickity v2.0.5
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus,
.flickity-viewport:focus,
.flickity-slider:focus,
.flickity-enabled:active,
.flickity-viewport:active,
.flickity-slider:active {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.credits {
  display: inline-block;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-style: solid;
  border-color: #b2b2b2;
  border-width: 1px 0; }
  .credits p {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 0.9167rem !important; }
    .credits p a {
      color: #000;
      font-family: "bonodiBe-medium", serif; }
      html[lang="ru"] .credits p a {
        font-family: "Playfair Display", serif; }
      html[lang="ko"] .credits p a {
        font-family: "KoPub Batang", serif; }
      html[lang="zh-cn"] .credits p a {
        font-family: "adobe-fangsong-std", serif; }
      html[lang="ar"] .credits p a {
        font-family: "Noto Naskh Arabic", serif; }
  .credits.centre {
    width: 100%;
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 5%;
    padding-left: 5%;
    text-align: center; }
  .module-hero-new .credits {
    margin-top: 3rem; }

.full-bleed-img-carousel-wrapper {
  position: relative; }

.full-bleed-img-carousel .carousel-cell {
  width: 100%; }

.full-bleed-img-carousel img {
  display: block;
  width: 100%;
  height: auto; }

.full-bleed-img-carousel-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.full-bleed-img-carousel-by {
  margin-top: 1.6rem;
  margin-bottom: 0;
  text-align: center;
  font-size: 0.9167rem; }
  @media screen and (min-width: 40em) {
    .full-bleed-img-carousel-by {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 1.6rem; }
      .layout-reversed .full-bleed-img-carousel-by {
        right: auto;
        left: 0;
        margin-right: 0;
        margin-left: 1.6rem; } }
  .full-bleed-img-carousel-by a {
    color: #000;
    font-family: "bonodiBe-medium", serif; }
    html[lang="ru"] .full-bleed-img-carousel-by a {
      font-family: "Playfair Display", serif; }
    html[lang="ko"] .full-bleed-img-carousel-by a {
      font-family: "KoPub Batang", serif; }
    html[lang="zh-cn"] .full-bleed-img-carousel-by a {
      font-family: "adobe-fangsong-std", serif; }
    html[lang="ar"] .full-bleed-img-carousel-by a {
      font-family: "Noto Naskh Arabic", serif; }

.editorial-detail-list .module-content-center {
  text-align: center; }
  @media screen and (min-width: 40em) {
    .editorial-detail-list .module-content-center {
      text-align: left; } }

.content-list-2-col {
  text-align: center; }
  @media screen and (min-width: 40em) {
    .content-list-2-col .col {
      float: left;
      display: block;
      margin-right: 0%;
      width: 50%; }
      .content-list-2-col .col::after {
        clear: both;
        content: "";
        display: table; }
      .content-list-2-col .col:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 40em) {
    .content-list-2-col.content-left .col-content {
      float: right;
      text-align: right; }
    .content-list-2-col.content-left .col-image-wrapper {
      left: 50%;
      height: 41.6667rem;
      overflow-y: hidden;
      overflow-x: visible; }
    .content-list-2-col.content-left .col-image {
      left: 0;
      max-height: 41.6667rem;
      margin-left: 4%; }
    .content-list-2-col.content-right .col-content-wrapper {
      float: right; }
    .content-list-2-col.content-right .col-content {
      float: left;
      text-align: left; }
    .content-list-2-col.content-right .col-image-wrapper {
      right: 50%;
      height: 41.6667rem;
      overflow-y: hidden;
      overflow-x: visible; }
    .content-list-2-col.content-right .col-image {
      right: 0;
      margin-right: 4%;
      max-height: 41.6667rem; }
    .content-list-2-col .col-image-wrapper,
    .content-list-2-col .col-content {
      display: inline-block;
      min-height: 45rem; }
    .content-list-2-col .col-image-wrapper {
      position: absolute;
      top: 0;
      bottom: 0; }
    .content-list-2-col .col-image {
      position: absolute;
      top: 0;
      bottom: 0;
      height: auto;
      width: auto;
      max-width: none; } }
  .content-list-2-col .col-content {
    width: 100%;
    max-width: 28.3333rem;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.6667rem;
    padding-left: 1.6667rem; }
    @media screen and (min-width: 40em) {
      .content-list-2-col .col-content {
        margin: 0; } }

.content-list-col-wrapper {
  position: relative; }
  @media screen and (min-width: 40em) {
    .content-list-col-wrapper::after {
      clear: both;
      content: "";
      display: table; } }
  .content-list-col-wrapper .col-image-wrapper {
    overflow: hidden;
    max-height: 45rem; }

.content-list-number {
  margin-bottom: 0;
  color: #9f9f9f !important;
  font-size: 7.6667rem !important;
  font-family: "bonodiBe-italic", serif;
  line-height: 1 !important; }
  html[lang="ru"] .content-list-number {
    font-family: "Playfair Display", serif; }
  html[lang="ko"] .content-list-number {
    font-family: "KoPub Batang", serif; }
  html[lang="zh-cn"] .content-list-number {
    font-family: "adobe-fangsong-std", serif; }
  html[lang="ar"] .content-list-number {
    font-family: "Noto Naskh Arabic", serif; }
  .content-list-number html[lang="ru"],
  html[lang="ko"] .content-list-number,
  html[lang="zh-cn"] .content-list-number,
  html[lang="ar"] .content-list-number {
    font-style: italic; }

.content-list-title {
  margin-bottom: 1.2rem;
  font-size: 1.8333rem;
  text-transform: uppercase; }

.content-list-item {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .content-list-item .module-content-center {
    text-align: center; }
    @media screen and (min-width: 40em) {
      .content-list-item .module-content-center {
        text-align: left; } }
  .content-list-item .content-list-2-col {
    text-align: center; }
    @media screen and (min-width: 40em) {
      .content-list-item .content-list-2-col .col {
        float: left;
        display: block;
        margin-right: 0%;
        width: 50%; }
        .content-list-item .content-list-2-col .col::after {
          clear: both;
          content: "";
          display: table; }
        .content-list-item .content-list-2-col .col:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 40em) {
      .content-list-item .content-list-2-col.content-left .col-content {
        float: right;
        text-align: right; }
      .content-list-item .content-list-2-col.content-left .col-image-wrapper {
        left: 50%;
        overflow: hidden; }
      .content-list-item .content-list-2-col.content-left .col-image {
        left: 0;
        margin-left: 4%; }
      .content-list-item .content-list-2-col.content-right .col-content-wrapper {
        float: right; }
      .content-list-item .content-list-2-col.content-right .col-content {
        float: left;
        text-align: left; }
      .content-list-item .content-list-2-col.content-right .col-image-wrapper {
        overflow: hidden;
        right: 50%; }
      .content-list-item .content-list-2-col.content-right .col-image {
        right: 0;
        margin-right: 4%; }
      .content-list-item .content-list-2-col .col-image-wrapper,
      .content-list-item .content-list-2-col .col-content {
        display: inline-block;
        overflow: hidden;
        min-height: 45rem; }
      .content-list-item .content-list-2-col .col-image-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        overflow: hidden;
        max-width: 30.8333rem; }
      .content-list-item .content-list-2-col .col-image {
        position: absolute;
        top: 0;
        bottom: 0;
        height: auto;
        width: auto;
        max-width: none; } }
    .content-list-item .content-list-2-col .col-content {
      width: 100%;
      max-width: 28.3333rem;
      margin-top: 3rem;
      margin-right: auto;
      margin-left: auto;
      padding-right: 1.6667rem;
      padding-left: 1.6667rem; }
      @media screen and (min-width: 40em) {
        .content-list-item .content-list-2-col .col-content {
          margin: 0; } }
  .content-list-item .content-list-col-wrapper {
    position: relative; }
    @media screen and (min-width: 40em) {
      .content-list-item .content-list-col-wrapper::after {
        clear: both;
        content: "";
        display: table; } }
    .content-list-item .content-list-col-wrapper .col-image-wrapper {
      overflow: hidden;
      max-height: 45rem; }
  .content-list-item .content-list-number {
    margin-bottom: 0;
    color: #9f9f9f !important;
    font-size: 7.6667rem !important;
    font-family: "bonodiBe-italic", serif;
    line-height: 1 !important; }
    html[lang="ru"] .content-list-item .content-list-number {
      font-family: "Playfair Display", serif; }
    html[lang="ko"] .content-list-item .content-list-number {
      font-family: "KoPub Batang", serif; }
    html[lang="zh-cn"] .content-list-item .content-list-number {
      font-family: "adobe-fangsong-std", serif; }
    html[lang="ar"] .content-list-item .content-list-number {
      font-family: "Noto Naskh Arabic", serif; }
    .content-list-item .content-list-number html[lang="ru"],
    html[lang="ko"] .content-list-item .content-list-number,
    html[lang="zh-cn"] .content-list-item .content-list-number,
    html[lang="ar"] .content-list-item .content-list-number {
      font-style: italic; }
  .content-list-item .content-list-title {
    margin-bottom: 1.2rem;
    font-size: 1.8333rem;
    text-transform: uppercase; }
  .content-list-item .content-list-item {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .content-list-item .content-list-item.component-img-content .module-content-wrapper {
      padding-right: 1.6667rem;
      padding-left: 1.6667rem; }
    .content-list-item .content-list-item.component-img-content .module-content {
      max-width: none; }
      @media screen and (min-width: 40em) {
        .content-list-item .content-list-item.component-img-content .module-content {
          height: 35.1667rem;
          max-width: 35.1667rem; } }
      .content-list-item .content-list-item.component-img-content .module-content > * {
        max-width: 22.5rem;
        margin-right: auto;
        margin-left: auto; }
  .content-list-item.component-img-content .module-content-wrapper {
    padding-right: 1.6667rem;
    padding-left: 1.6667rem; }
  .content-list-item.component-img-content .module-content {
    max-width: none; }
    @media screen and (min-width: 40em) {
      .content-list-item.component-img-content .module-content {
        height: 35.1667rem;
        max-width: 35.1667rem; } }
    .content-list-item.component-img-content .module-content > * {
      max-width: 22.5rem;
      margin-right: auto;
      margin-left: auto; }

.arrow-anim-wrapper {
  position: relative; }

.arrow-anim {
  font-size: 1.25rem;
  width: 3.3333rem;
  float: left;
  overflow: hidden;
  position: absolute;
  margin-left: 0.4167rem;
  line-height: 1.5rem;
  height: 1.5rem; }
  .arrow-anim .long-arrow {
    position: relative;
    display: block;
    left: -1.6667rem;
    margin-top: -0.2rem;
    line-height: 1.5rem;
    height: 1.5rem;
    transform: translateX(0);
    transition: transform 0.3s ease; }
    .arrow-anim .long-arrow svg {
      transform: translateZ(0);
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      transform: scale(0.5); }
    a:hover .arrow-anim .long-arrow {
      transform: translateX(12px); }
  .layout-reversed .arrow-anim {
    transform: rotate(180deg);
    left: -2rem; }

.lt-ie12 .arrow-anim .long-arrow svg {
  -webkit-transform: scale(0.58);
  -moz-transform: scale(0.58);
  -ms-transform: scale(0.58);
  -o-transform: scale(0.58);
  transform: scale(0.58); }

.search-results-form {
  position: relative;
  margin-top: 3.3333rem; }
  .search-results-form::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 50em) {
    .search-results-form {
      margin-top: 3.3333rem; } }
  .search-results-form label {
    top: 13px !important;
    font-size: 1.2rem;
    animation: fadeIn 0.3s ease 0.3s; }
    .layout-reversed .search-results-form label {
      left: auto !important;
      right: 4.8333rem; }
  .search-results-form input[type="text"].smart-search {
    float: left;
    width: calc(100% - 49px);
    height: 3.75rem;
    margin-bottom: 0;
    border-width: 1px 0 1px 1px;
    border-color: black;
    border-style: solid;
    border-radius: 0;
    transition: all 0.3s ease;
    font-size: 14px; }
  .search-results-form.collapsed label {
    display: none; }
  .search-results-form.collapsed input[type="text"].smart-search {
    width: 0;
    padding: 0;
    border: none; }
  .search-results-form .smart-search-submit {
    float: left;
    height: 3.75rem; }

.module-new,
.component-wrapper-new,
.content-list-2-col,
.slider.partners,
.module-image {
  position: relative;
  font-family: "brown-regular", sans-serif; }
  html[lang="ru"] .module-new, html[lang="ru"]
  .component-wrapper-new, html[lang="ru"]
  .content-list-2-col, html[lang="ru"]
  .slider.partners, html[lang="ru"]
  .module-image {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .module-new, html[lang="ko"]
  .component-wrapper-new, html[lang="ko"]
  .content-list-2-col, html[lang="ko"]
  .slider.partners, html[lang="ko"]
  .module-image {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .module-new, html[lang="zh-cn"]
  .component-wrapper-new, html[lang="zh-cn"]
  .content-list-2-col, html[lang="zh-cn"]
  .slider.partners, html[lang="zh-cn"]
  .module-image {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .module-new, html[lang="ar"]
  .component-wrapper-new, html[lang="ar"]
  .content-list-2-col, html[lang="ar"]
  .slider.partners, html[lang="ar"]
  .module-image {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  .accordion-tab-content .module-new, .accordion-tab-content
  .component-wrapper-new, .accordion-tab-content
  .content-list-2-col, .accordion-tab-content
  .slider.partners, .accordion-tab-content
  .module-image {
    margin-bottom: 1.6667rem; }
  .module-new.variable-height,
  .component-wrapper-new.variable-height,
  .content-list-2-col.variable-height,
  .slider.partners.variable-height,
  .module-image.variable-height {
    height: auto; }
  .module-new:not(.hero-products) p,
  .component-wrapper-new:not(.hero-products) p,
  .content-list-2-col:not(.hero-products) p,
  .slider.partners:not(.hero-products) p,
  .module-image:not(.hero-products) p {
    color: #000;
    font-size: 1.17rem;
    line-height: 1.4; }
  .module-new.module-may-like-new,
  .component-wrapper-new.module-may-like-new,
  .content-list-2-col.module-may-like-new,
  .slider.partners.module-may-like-new,
  .module-image.module-may-like-new {
    margin-bottom: 0; }

.grey-wrapper-title {
  position: relative;
  font-family: "brown-regular", sans-serif;
  text-transform: uppercase;
  font-size: 1.33rem;
  letter-spacing: 0.05rem;
  text-align: center; }
  html[lang="ru"] .grey-wrapper-title {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .grey-wrapper-title {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .grey-wrapper-title {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .grey-wrapper-title {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }

.module-title,
.module-text-header {
  color: #008a9d;
  font-family: "brown-regular", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis; }

.module-title-deco {
  position: relative; }
  .module-title-deco:before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background: #000; }

.p-title-border-short {
  text-align: center; }
  .p-title-border-short p {
    position: relative;
    display: inline-block;
    margin-bottom: 1.8rem;
    padding-bottom: 1.4rem;
    color: #000;
    font-size: 1.75rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem; }
    .layout-reversed .p-title-border-short p {
      letter-spacing: 0; }
    .p-title-border-short p:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 16.3333rem;
      height: 0.0833rem;
      margin-left: -8.1667rem;
      background: #b2b2b2; }

.module-hero-new,
.module-offer-new {
  text-align: center; }

.module-hero-new .module-content-wrapper,
.module-promo-new .module-content-wrapper {
  max-width: 88rem; }

.crosslinks-wrapper.module-widget-carousel-crosslinks .flickity-prev-next-button {
  display: none !important; }

@media screen and (min-width: 40em) and (max-width: 49.99em) {
  .crosslinks-wrapper.module-widget-carousel-crosslinks .module-content-wrapper {
    height: 19.6rem; } }

.module-img-content,
.component-img-content {
  position: relative; }
  .module-img-content .module-image,
  .component-img-content .module-image {
    position: relative;
    z-index: 1;
    background-color: #008a9d; }
    .logos-wrapper .module-img-content .module-image, .logos-wrapper
    .component-img-content .module-image {
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      border: 1px solid #b7b7b7; }

.module-text-header {
  font-size: 2rem; }
  @media screen and (min-width: 50em) {
    .module-text-header {
      font-size: 2.83rem; } }

.module-text-subheader {
  margin-bottom: 1rem;
  text-transform: none;
  color: #333 !important;
  font-family: "bonodiStd-roman", serif;
  font-size: 14 rem !important; }
  html[lang="ru"] .module-text-subheader {
    font-family: "Playfair Display", serif; }
  html[lang="ko"] .module-text-subheader {
    font-family: "KoPub Batang", serif; }
  html[lang="zh-cn"] .module-text-subheader {
    font-family: "adobe-fangsong-std", serif; }
  html[lang="ar"] .module-text-subheader {
    font-family: "Noto Naskh Arabic", serif; }
  .module-text-subheader span {
    text-transform: none;
    color: #000;
    font-family: "bonodiStd-roman", serif;
    letter-spacing: 0; }
    html[lang="ru"] .module-text-subheader span {
      font-family: "Playfair Display", serif; }
    html[lang="ko"] .module-text-subheader span {
      font-family: "KoPub Batang", serif; }
    html[lang="zh-cn"] .module-text-subheader span {
      font-family: "adobe-fangsong-std", serif; }
    html[lang="ar"] .module-text-subheader span {
      font-family: "Noto Naskh Arabic", serif; }
    .module-text-subheader span.module-text-subheader-days-right {
      float: right; }
      .layout-reversed .module-text-subheader span.module-text-subheader-days-right {
        float: left; }

.module-text-subheader,
.module-text-subheader-light {
  color: #333 !important;
  font-family: "bonodiStd-roman", serif;
  letter-spacing: 0.1rem;
  font-size: 14 rem !important; }
  html[lang="ru"] .module-text-subheader, html[lang="ru"]
  .module-text-subheader-light {
    font-family: "Playfair Display", serif; }
  html[lang="ko"] .module-text-subheader, html[lang="ko"]
  .module-text-subheader-light {
    font-family: "KoPub Batang", serif; }
  html[lang="zh-cn"] .module-text-subheader, html[lang="zh-cn"]
  .module-text-subheader-light {
    font-family: "adobe-fangsong-std", serif; }
  html[lang="ar"] .module-text-subheader, html[lang="ar"]
  .module-text-subheader-light {
    font-family: "Noto Naskh Arabic", serif; }

.module-find-out-more {
  position: relative;
  padding-right: 2.4rem;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.2rem;
  font-size: 1.2rem;
  height: 1.4rem; }
  .module-find-out-more a.span {
    bottom: -3.9167rem; }
  .module-find-out-more:before {
    display: inline-block;
    height: inherit;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    font-size: 1rem;
    font-family: "icons";
    font-variant: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    text-align: center;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 1.4rem;
    font-size: 1.4rem;
    height: 1.4rem;
    padding-top: 0; }
  .layout-reversed .module-find-out-more {
    padding-right: 0;
    padding-left: 2.4rem; }
    .iOS .layout-reversed .module-find-out-more {
      padding-left: 0.8rem; }
    .layout-reversed .module-find-out-more:before {
      right: auto;
      left: 0;
      transform: rotate(180deg);
      line-height: 1.6rem; }

.widget-carousel-new {
  margin-bottom: -1.6rem; }
  .widget-carousel-new:after {
    content: 'flickity';
    display: none; }
  .widget-carousel-new.flickity-enabled .carousel-cell {
    padding-bottom: 1.6rem; }
  @media screen and (max-width: 63.99em) {
    .touch .widget-carousel-new .grid-item {
      width: 42%; } }
  @media screen and (max-width: 39.99em) {
    .touch .widget-carousel-new .grid-item {
      width: 80%; } }
  @media screen and (min-width: 50em) {
    .touch .widget-carousel-new .grid-item {
      width: 33%; } }
  @media screen and (max-width: 49.99em) {
    .no-touchevents .crosslinks-wrapper .widget-carousel-new .grid-item {
      width: 42%; } }
  @media screen and (max-width: 39.99em) {
    .no-touchevents .crosslinks-wrapper .widget-carousel-new .grid-item {
      width: 80%; } }
  @media screen and (min-width: 50em) {
    .widget-carousel-new.carousel-disable-large::after {
      clear: both;
      content: "";
      display: table; } }
  @media screen and (min-width: 64em) {
    .widget-carousel-new.carousel-disable-xlarge::after {
      clear: both;
      content: "";
      display: table; } }

@media screen and (min-width: 50em) {
  .component-latest-carousel.component-latest.carousel-set.arrows-hidden-large .component-latest-footer .section-title-viewall {
    right: 0; }
    .layout-reversed .component-latest-carousel.component-latest.carousel-set.arrows-hidden-large .component-latest-footer .section-title-viewall {
      right: auto;
      left: 0; } }

@media screen and (min-width: 64em) {
  .component-latest-carousel.component-latest.carousel-set.arrows-hidden-xlarge .component-latest-footer .section-title-viewall {
    right: 0; }
    .layout-reversed .component-latest-carousel.component-latest.carousel-set.arrows-hidden-xlarge .component-latest-footer .section-title-viewall {
      right: auto;
      left: 0; } }

.module-widget-carousel-wrapper .flickity-prev-next-button {
  position: absolute;
  display: block !important;
  width: 40px;
  padding: 1rem 1.6rem 0.85rem;
  border-radius: 0;
  background-color: transparent;
  -webkit-transform: translate3d(0, 0, 0) translateZ(0);
  -moz-transform: translate3d(0, 0, 0) translateZ(0);
  -ms-transform: translate3d(0, 0, 0) translateZ(0);
  -o-transform: translate3d(0, 0, 0) translateZ(0);
  transform: translate3d(0, 0, 0) translateZ(0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden; }
  .module-widget-carousel-wrapper .flickity-prev-next-button:before, .module-widget-carousel-wrapper .flickity-prev-next-button:after {
    content: '';
    display: block;
    position: absolute;
    border-color: #000;
    border-style: solid;
    -webkit-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
    -moz-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
    -ms-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
    -o-transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
    transform: translateX(0) translate3d(0, 0, 0) translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .module-widget-carousel-wrapper .flickity-prev-next-button:before {
    width: 1rem;
    height: 1rem; }
  .module-widget-carousel-wrapper .flickity-prev-next-button:after {
    top: 33px;
    width: 0;
    border-width: 0.2rem 0 0; }
  .no-touchevents .module-widget-carousel-wrapper .flickity-prev-next-button:hover {
    opacity: 1; }
    .no-touchevents .module-widget-carousel-wrapper .flickity-prev-next-button:hover:after {
      width: 1.8rem; }
  .module-widget-carousel-wrapper .flickity-prev-next-button svg {
    display: none; }
  .module-widget-carousel-wrapper .flickity-prev-next-button.slick-disabled {
    opacity: 0.5; }
    .module-widget-carousel-wrapper .flickity-prev-next-button.slick-disabled:hover {
      opacity: 0.5; }
  .module-widget-carousel-wrapper .flickity-prev-next-button.previous {
    z-index: 3; }
    .module-widget-carousel-wrapper .flickity-prev-next-button.previous:before {
      border-width: 2px 0 0 2px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .no-touchevents .module-widget-carousel-wrapper .flickity-prev-next-button.previous:hover:before {
      -webkit-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents .module-widget-carousel-wrapper .flickity-prev-next-button.previous:hover:after {
      -webkit-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0); }
    .touchevents .module-widget-carousel-wrapper .flickity-prev-next-button.previous:active:before {
      -webkit-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) rotate(-45deg) translate3d(0, 0, 0) translateZ(0); }
    .touchevents .module-widget-carousel-wrapper .flickity-prev-next-button.previous:active:after {
      -webkit-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(-6px) translate3d(0, 0, 0) translateZ(0); }
  .module-widget-carousel-wrapper .flickity-prev-next-button.next {
    z-index: 4; }
    .module-widget-carousel-wrapper .flickity-prev-next-button.next:before {
      border-width: 2px 2px 0 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    .no-touchevents .module-widget-carousel-wrapper .flickity-prev-next-button.next:hover:before {
      -webkit-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) rotate(45deg) translate3d(0, 0, 0) translateZ(0); }
    .no-touchevents .module-widget-carousel-wrapper .flickity-prev-next-button.next:hover:after {
      -webkit-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -moz-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -ms-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      -o-transform: translateX(6px) translate3d(0, 0, 0) translateZ(0);
      transform: translateX(6px) translate3d(0, 0, 0) translateZ(0); }

.module-widget-carousel-wrapper .flickity-prev-next-button {
  top: -4.6rem;
  height: 40px; }
  @media screen and (max-width: 39.99em) {
    .module-widget-carousel-wrapper .flickity-prev-next-button {
      display: none !important; } }
  .module-widget-carousel-wrapper .flickity-prev-next-button:before {
    top: 16px; }
  .module-widget-carousel-wrapper .flickity-prev-next-button:after {
    top: 21px; }
  .module-widget-carousel-wrapper .flickity-prev-next-button.previous {
    right: 4rem;
    left: auto; }
    .module-widget-carousel-wrapper .flickity-prev-next-button.previous:before, .module-widget-carousel-wrapper .flickity-prev-next-button.previous:after {
      left: 0.6rem; }
    .layout-reversed .module-widget-carousel-wrapper .flickity-prev-next-button.previous {
      right: auto;
      left: 0.6rem; }
  .module-widget-carousel-wrapper .flickity-prev-next-button.next {
    right: 0.6rem;
    border-left: 1px solid #000; }
    .module-widget-carousel-wrapper .flickity-prev-next-button.next:before, .module-widget-carousel-wrapper .flickity-prev-next-button.next:after {
      right: 0.6rem; }
    .layout-reversed .module-widget-carousel-wrapper .flickity-prev-next-button.next {
      right: auto;
      left: 4rem; }

.module-widget-carousel-wrapper.module-widget-carousel-news .flickity-prev-next-button, .module-widget-carousel-wrapper.module-inspiration-new .flickity-prev-next-button, .module-widget-carousel-wrapper.module-widget-carousel-may-like .flickity-prev-next-button {
  top: -6.5rem; }

.widget-carousel-news-feed .flickity-prev-next-button {
  display: none !important; }

.ellipsis-new {
  overflow: hidden; }

.section-title-big-wrapper {
  margin-bottom: 3rem; }
  @media screen and (min-width: 50em) {
    .section-title-big-wrapper {
      margin-bottom: 5.2rem; } }

.section-title-big,
.section-title-big-wrapper .section-subtitle-big {
  margin-bottom: 0;
  font-size: 3rem;
  font-family: "brown-light", sans-serif;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.4rem; }
  html[lang="ru"] .section-title-big, html[lang="ru"]
  .section-title-big-wrapper .section-subtitle-big {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .section-title-big, html[lang="ko"]
  .section-title-big-wrapper .section-subtitle-big {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .section-title-big, html[lang="zh-cn"]
  .section-title-big-wrapper .section-subtitle-big {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .section-title-big, html[lang="ar"]
  .section-title-big-wrapper .section-subtitle-big {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  @media screen and (min-width: 40em) {
    .section-title-big,
    .section-title-big-wrapper .section-subtitle-big {
      font-size: 3.6667rem; } }

.component-latest {
  position: relative; }
  .component-latest .section-title-viewall {
    margin-right: auto;
    margin-left: auto; }

.component-latest-footer {
  margin-bottom: 2.5rem;
  text-align: center;
  margin-bottom: 2.5rem; }
  @media screen and (min-width: 50em) {
    .component-latest-footer {
      text-align: right;
      margin: 0; }
      .component-latest-footer .section-title-viewall {
        padding: 0;
        border: none;
        color: #535353;
        font-size: 1rem;
        font-family: "brown-regular", sans-serif;
        text-align: right;
        text-transform: uppercase; }
        html[lang="ru"] .component-latest-footer .section-title-viewall {
          font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
        html[lang="ko"] .component-latest-footer .section-title-viewall {
          font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
        html[lang="zh-cn"] .component-latest-footer .section-title-viewall {
          font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
        html[lang="ar"] .component-latest-footer .section-title-viewall {
          font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; } }
    @media screen and (min-width: 50em) and (min-width: 50em) {
      .component-latest-footer .section-title-viewall {
        position: absolute;
        top: 0.8rem;
        right: 0; }
        .layout-reversed .component-latest-footer .section-title-viewall {
          right: auto;
          left: 0; } }
  @media screen and (min-width: 50em) {
        .component-latest-footer .section-title-viewall:hover, .component-latest-footer .section-title-viewall:focus {
          background: none;
          opacity: 0.6; } }

div.widget.grid-item:not(.grid-2):not(.grid-3) .component-latest-footer {
  margin-bottom: 2.5rem;
  text-align: center;
  margin-right: auto;
  margin-left: auto; }
  div.widget.grid-item:not(.grid-2):not(.grid-3) .component-latest-footer .section-title-viewall {
    right: 0;
    display: inline-block;
    padding: 1.2rem 2.6rem 1rem;
    top: 40px;
    margin-top: 0;
    -webkit-appearance: none;
    color: #000;
    font-family: "brown-regular", sans-serif;
    font-size: 1.17rem;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #000;
    cursor: pointer;
    position: relative; }
    html[lang="ru"] div.widget.grid-item:not(.grid-2):not(.grid-3) .component-latest-footer .section-title-viewall {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] div.widget.grid-item:not(.grid-2):not(.grid-3) .component-latest-footer .section-title-viewall {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] div.widget.grid-item:not(.grid-2):not(.grid-3) .component-latest-footer .section-title-viewall {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] div.widget.grid-item:not(.grid-2):not(.grid-3) .component-latest-footer .section-title-viewall {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
    .layout-reversed div.widget.grid-item:not(.grid-2):not(.grid-3) .component-latest-footer .section-title-viewall {
      right: auto;
      left: 0; }

@media screen and (max-width: 39.99em) {
  .component-latest .section-title-wrapper .section-title {
    display: block;
    text-align: center; }
  .image-widget {
    margin-bottom: 2rem; } }

@media screen and (min-width: 50em) {
  .component-latest-carousel.carousel-set .component-latest-footer .section-title-viewall {
    right: 9rem; }
    .layout-reversed .component-latest-carousel.carousel-set .component-latest-footer .section-title-viewall {
      right: auto;
      left: 9rem; }
    .component-editorial-new .component-latest-carousel.carousel-set .component-latest-footer .section-title-viewall {
      right: 0; }
      .layout-reversed .component-editorial-new .component-latest-carousel.carousel-set .component-latest-footer .section-title-viewall {
        right: auto;
        left: 0; } }

.module-img-content .module-image {
  height: 80%; }

.module-img-content .module-content-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: calc(100% - 4rem);
  padding: 2rem 1.2rem;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12); }

.module-img-content .module-title {
  font-size: 2rem; }

.module-img-content h1.module-title {
  font-size: 2rem; }
  @media screen and (min-width: 40em) {
    .module-img-content h1.module-title {
      font-size: 2.8333rem;
      padding-bottom: 1.4rem;
      padding-top: 1.5rem; } }

.module-img-content .module-content {
  max-width: 20rem;
  margin-right: auto;
  margin-left: auto; }

.module-img-content .module-title-deco {
  margin-bottom: 1.4rem;
  padding-bottom: 1.8rem; }
  @media screen and (min-width: 64em) {
    .module-img-content .module-title-deco {
      padding-bottom: 1rem; } }
  .module-img-content .module-title-deco:before {
    bottom: 0;
    left: 0;
    right: 0;
    width: 3.75rem;
    margin-right: auto;
    margin-left: auto; }

.module-img-content.hero-basic-content .module-title-deco {
  margin-bottom: 2.6rem;
  padding-bottom: 3.2rem; }

.module-img-content.module-text-new {
  padding: 0;
  background-color: transparent; }
  .module-img-content.module-text-new .module-text-header {
    font-size: 2rem; }
  .module-img-content.module-text-new .module-title-deco:before {
    right: auto;
    margin: 0; }
  .layout-reversed .module-img-content.module-text-new .module-title-deco:before {
    left: auto;
    right: 0; }

.module-img-content .module-text-copy p {
  margin-bottom: 0; }

.page-campaign .module-img-content .module-img {
  background-size: contain; }

.module-hero-new {
  height: 35.83rem; }
  @media only screen and (min-width: 800px) and (max-width: 930px) {
    .module-hero-new {
      height: 43rem; } }
  @media only screen and (min-width: 931px) and (max-width: 1094px) {
    .module-hero-new {
      height: 38rem; } }
  .module-hero-new .module-text-subheader {
    margin-bottom: 0; }
  .module-hero-new .module-content-wrapper {
    height: 15.17rem; }
    @media only screen and (min-width: 800px) and (max-width: 930px) {
      .module-hero-new .module-content-wrapper {
        height: 22rem; } }
    @media only screen and (min-width: 931px) and (max-width: 1094px) {
      .module-hero-new .module-content-wrapper {
        height: 18rem; } }
  .module-hero-new .module-content {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    width: calc(100% - 2rem);
    margin-right: auto;
    margin-left: auto; }
    .module-hero-new .module-content > p {
      font-size: 1.3333rem; }
    @media screen and (min-width: 40em) {
      .module-hero-new .module-content {
        max-width: calc(100% - 4rem); } }
  .module-hero-new .hero-nav-share {
    display: inline-block;
    margin-top: 1rem;
    color: #000;
    text-transform: uppercase; }
    @media screen and (min-width: 50em) {
      .module-hero-new .hero-nav-share {
        position: absolute;
        top: 3.6rem;
        right: 2.8rem;
        width: auto; }
        .layout-reversed .module-hero-new .hero-nav-share {
          right: auto;
          left: 2.8rem; } }
  .module-hero-new .hero-new-links {
    display: inline-block; }
    .module-hero-new .hero-new-links a.button {
      display: block;
      margin: 1rem; }
    @media screen and (min-width: 40em) {
      .module-hero-new .hero-new-links {
        display: flex;
        justify-content: center;
        align-items: baseline; } }

.module-hero-new-no-spacing {
  margin-bottom: 0; }
  .module-hero-new-no-spacing.component-wrapper-new .module-content-wrapper .hero-content header {
    margin-bottom: 0; }

.component-title-intro .section-title {
  margin-bottom: 2rem; }

.component-title-intro .module-img-content {
  height: 36.3rem;
  margin-bottom: 0; }

.component-title-intro .module-image {
  height: 60%; }

.component-title-intro .module-text-subheader {
  margin-bottom: 1.2rem;
  padding-left: 0.2rem; }

.component-title-intro:not(.module-events-list) .module-title-deco {
  margin-bottom: 0.6rem;
  padding-bottom: 0;
  padding-top: 1.2rem; }
  .component-title-intro:not(.module-events-list) .module-title-deco:before {
    background: auto;
    top: 0; }

.component-title-intro .module-text-subheader-light {
  color: #9d9d9d; }

.component-title-intro .module-text-copy p {
  line-height: 1.3 !important; }

.component-title-intro .widget-carousel-new {
  margin-right: -1rem;
  margin-left: -1rem; }

.component-title-intro .slick-track {
  padding-bottom: 2rem; }

.component-title-intro .slick-slide {
  padding-right: 1rem;
  padding-left: 1rem; }

.component-title-intro.module-events-list .module-new {
  height: 32.5rem;
  padding: 2rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  background: #fff; }

.component-title-intro.module-events-list .module-text-subheader {
  margin-bottom: 0.6rem; }

.component-title-intro.module-events-list .module-text-content .module-text-header {
  margin-bottom: 1.2rem;
  padding-bottom: 1.6rem;
  font-size: 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.component-title-intro.module-events-list .module-text-content:last-child .module-title-deco:before {
  display: none; }

.partner-promotion-component-wrapper-new.module-hero-new {
  height: auto;
  background: none; }
  .partner-promotion-component-wrapper-new.module-hero-new .module-image {
    height: 21.6rem; }
    .partner-promotion-component-wrapper-new.module-hero-new .module-image .mobile-tab {
      margin-bottom: 3.3333rem; }
    @media screen and (min-width: 40em) {
      .partner-promotion-component-wrapper-new.module-hero-new .module-image {
        height: 42rem; } }
  .partner-promotion-component-wrapper-new.module-hero-new .module-content-wrapper {
    position: relative;
    top: auto;
    max-width: 86.6667rem;
    height: auto;
    margin-top: -11.3333rem;
    margin-bottom: 3.3333rem;
    box-shadow: none;
    transform: none; }
    @media screen and (max-width: 49.99em) {
      .partner-promotion-component-wrapper-new.module-hero-new .module-content-wrapper {
        margin-top: -9.3333rem;
        padding-top: 0px; } }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content-wrapper header {
      margin-bottom: 1.6rem; }
  .partner-promotion-component-wrapper-new.module-hero-new .module-content {
    position: static;
    max-width: none;
    transform: none;
    width: calc(100% - 0rem); }
    @media screen and (max-width: 49.99em) {
      .partner-promotion-component-wrapper-new.module-hero-new .module-content {
        width: calc(100% - 0rem); } }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content .module-title-deco {
      width: 50%;
      margin: 0 auto;
      font-family: 'Bodoni MT', brow-light, sans-serif; }
      @media screen and (max-width: 49.99em) {
        .partner-promotion-component-wrapper-new.module-hero-new .module-content .module-title-deco {
          width: 100%;
          margin-top: 12px !important;
          font-size: 1.6rem; } }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content > ul,
    .partner-promotion-component-wrapper-new.module-hero-new .module-content > ol {
      font-size: 1.3333rem;
      margin-top: 2rem;
      margin-bottom: 2.4rem; }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content .mobile-gap {
      margin-bottom: 4rem; }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width {
      padding-top: 3rem;
      max-width: 88rem;
      text-align: justify;
      text-align-last: center;
      margin-bottom: 2rem; }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width > label > p {
        max-width: 100%;
        text-align: justify; }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width > label > p > span {
          max-width: 100%;
          text-align: justify; }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width > p {
        max-width: 100%;
        text-align: justify; }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width > p > span {
          max-width: 100%;
          text-align: justify; }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width > span {
        max-width: 100%;
        text-align: justify; }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width-TermsConditions {
      padding-top: 2rem;
      max-width: 88rem;
      text-align: justify;
      margin-bottom: 2rem;
      display: none; }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width-TermsConditions > label > p {
        max-width: 100%;
        text-align: justify; }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width-TermsConditions > label > p > span {
          max-width: 100%;
          text-align: justify; }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width-TermsConditions > p {
        max-width: 100%;
        text-align: justify; }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width-TermsConditions > p > span {
          max-width: 100%;
          text-align: justify; }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content .promotion-width-TermsConditions > span {
        max-width: 100%;
        text-align: justify; }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content .btn-termsAndConditions {
      margin: 0 auto;
      /*@include media($medium) {
                    display: none;
                }

                @include media($medium-large) {
                    display: none;
                }

                @include media($medium-small) {
                    display: none;
                }

                @include media($small-down) {
                    display: block;
                }*/ }
    .partner-promotion-component-wrapper-new.module-hero-new .module-content div {
      margin: 0 auto; }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new {
        border-bottom: 25px;
        width: 100%;
        display: table; }
        @media screen and (max-width: 49.99em) {
          .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new {
            height: auto;
            margin: 0 auto;
            display: block; }
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new > div {
              display: none; } }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new .partner-Image {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          height: 100px; }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new .partner-Image {
              display: block;
              width: 100%;
              height: auto; } }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new .wrapper-partnerimage-disposition-item-new {
          vertical-align: middle;
          float: left; }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new .wrapper-partnerimage-disposition-item-new {
              margin: 0 auto;
              vertical-align: middle;
              text-align: center;
              display: block;
              float: none; } }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new .wrapper-partnerimage-disposition-item-2-new {
          vertical-align: middle;
          float: right; }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.wrapper-partnerimage-disposition-new .wrapper-partnerimage-disposition-item-2-new {
              margin: 0 auto;
              vertical-align: middle;
              text-align: center;
              display: block;
              float: none; } }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new {
        height: 170px;
        white-space: nowrap; }
        @media screen and (max-width: 49.99em) {
          .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new {
            height: auto;
            margin: 0 auto; } }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new .promotion-partnerimage-only-item {
          max-height: 170px; }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new .promotion-partnerimage-disposition-item-new {
          max-height: 170px;
          vertical-align: middle;
          margin-right: 25px; }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new .promotion-partnerimage-disposition-item-new {
              margin: 0 auto;
              vertical-align: middle;
              text-align: center;
              display: block; } }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new .promotion-partnerimage-disposition-middle {
          height: 100%;
          display: inline-block;
          vertical-align: middle;
          border-right: 1px solid #000; }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new .promotion-partnerimage-disposition-middle {
              left: 0;
              right: 0;
              width: 3.75rem;
              margin-right: auto;
              margin-left: auto;
              content: '';
              position: absolute;
              display: block;
              height: 1px;
              background: #000; } }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new .promotion-partnerimage-disposition-item-2-new {
          max-height: 170px;
          vertical-align: middle;
          padding-left: 40px; }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition-new .promotion-partnerimage-disposition-item-2-new {
              margin: 0 auto;
              vertical-align: middle;
              text-align: center;
              display: block;
              padding-left: 0px; } }
      .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition {
        display: none;
        margin: 0 auto; }
        @media screen and (max-width: 49.99em) {
          .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition {
            display: none;
            padding: 0px 0px 0px 0px; }
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition > div {
              margin: 0 auto; } }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item-2 {
          margin-left: -90px; }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item-2 {
              margin: 0 auto; } }
          .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item-2 > img {
            max-height: 200px;
            max-width: 170px; }
        .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item {
          vertical-align: middle;
          /*
                        @include media($small) {
                            border-right: 1px solid #999;
                        }*/ }
          @media screen and (min-width: 50em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item {
              padding-top: 5rem;
              border-right: 1px solid #999; } }
          @media screen and (min-width: 55em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item {
              padding-top: 5rem;
              border-right: 1px solid #999; } }
          @media screen and (min-width: 50em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item {
              padding-top: 5rem;
              border-right: 1px solid #999; } }
          @media screen and (max-width: 49.99em) {
            .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item {
              padding-top: 0rem;
              max-width: 16rem;
              position: relative; }
              .partner-promotion-component-wrapper-new.module-hero-new .module-content div.promotion-partnerimage-disposition .promotion-partnerimage-disposition-item:after {
                left: 0;
                right: 0;
                width: 3.75rem;
                margin-right: auto;
                margin-left: auto;
                content: '';
                position: absolute;
                display: block;
                height: 1px;
                background: #000; } }
  .partner-promotion-component-wrapper-new.module-hero-new p {
    /*max-width: rem(640px);
            @include margin(null auto null);
            text-align: center;*/
    max-width: 44rem;
    margin-right: auto;
    margin-left: auto;
    text-align: left; }
    @media screen and (min-width: 40em) {
      .partner-promotion-component-wrapper-new.module-hero-new p {
        text-align: center; } }
    .partner-promotion-component-wrapper-new.module-hero-new p.tiny {
      margin-bottom: 0;
      font-size: 1rem;
      font-family: "bonodiBe-italic", serif; }
      html[lang="ru"] .partner-promotion-component-wrapper-new.module-hero-new p.tiny {
        font-family: "Playfair Display", serif; }
      html[lang="ko"] .partner-promotion-component-wrapper-new.module-hero-new p.tiny {
        font-family: "KoPub Batang", serif; }
      html[lang="zh-cn"] .partner-promotion-component-wrapper-new.module-hero-new p.tiny {
        font-family: "adobe-fangsong-std", serif; }
      html[lang="ar"] .partner-promotion-component-wrapper-new.module-hero-new p.tiny {
        font-family: "Noto Naskh Arabic", serif; }
      .partner-promotion-component-wrapper-new.module-hero-new p.tiny html[lang="ru"],
      html[lang="ko"] .partner-promotion-component-wrapper-new.module-hero-new p.tiny,
      html[lang="zh-cn"] .partner-promotion-component-wrapper-new.module-hero-new p.tiny,
      html[lang="ar"] .partner-promotion-component-wrapper-new.module-hero-new p.tiny {
        font-style: italic; }
    .layout-reversed .partner-promotion-component-wrapper-new.module-hero-new p {
      text-align: right; }
      @media screen and (min-width: 40em) {
        .layout-reversed .partner-promotion-component-wrapper-new.module-hero-new p {
          text-align: center; } }
      .layout-reversed .partner-promotion-component-wrapper-new.module-hero-new p.searchDd-main {
        text-align: right; }
  .partner-promotion-component-wrapper-new.module-hero-new ul {
    text-align: left; }
    @media screen and (min-width: 40em) {
      .partner-promotion-component-wrapper-new.module-hero-new ul {
        text-align: center; } }
  .partner-promotion-component-wrapper-new.module-hero-new .brand-contact {
    max-width: 20rem;
    margin: 2rem auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: inherit; }
    .partner-promotion-component-wrapper-new.module-hero-new .brand-contact li {
      margin-bottom: 0.2rem; }
      .partner-promotion-component-wrapper-new.module-hero-new .brand-contact li:last-child {
        margin-bottom: 0; }
    .partner-promotion-component-wrapper-new.module-hero-new .brand-contact a {
      color: #000; }
  .partner-promotion-component-wrapper-new.module-hero-new .brand-logo {
    max-width: 16rem;
    margin-right: auto;
    margin-left: auto; }
  .partner-promotion-component-wrapper-new.module-hero-new .barcode {
    display: block;
    max-width: 14rem;
    margin: -0.7rem auto; }
  .layout-reversed .partner-promotion-component-wrapper-new.module-hero-new .button {
    text-align: center; }
  .partner-promotion-component-wrapper-new.module-hero-new .terms-conditions-title {
    margin-left: 36%;
    float: left;
    font-size: 15px;
    padding-left: 20px;
    text-transform: uppercase; }
    @media screen and (max-width: 49.99em) {
      .partner-promotion-component-wrapper-new.module-hero-new .terms-conditions-title {
        margin-left: 17%; } }

.ellip {
  display: block;
  height: 100%; }

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%; }

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden; }

@media screen and (max-width: 49.99em) {
  .btn-print {
    display: none; } }

/*@media print {
    .noPrint {
        display: none;
    }

    .module-text-subheader {
        text-align: center !important;
    }

    .yesPrint {
        display: block !important;
    }

    .wrapper-partnerimage-disposition-item-2-new {
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        display: block !important;
        float: none !important;
    }

    .wrapper-partnerimage-disposition-new {
        height: auto !important;
    }

    .wrapper-partnerimage-disposition-item-new {
        margin: 0 auto;
        vertical-align: middle;
        text-align: center;
        display: block !important;
        float: none !important;
    }
}*/
@media print {
  .noPrint {
    display: none; }
  .yesPrint {
    display: block !important; }
    .yesPrint > p {
      margin: 0 auto;
      text-align: center; }
  .promotion-width-TermsConditions {
    display: block; }
  .module-content-wrapper {
    width: 100%; }
    .module-content-wrapper > p {
      margin: 0 auto;
      text-align: center; }
    .module-content-wrapper .module-text-subheader {
      margin: 0 auto;
      text-align: center; }
  .wrapper-partnerimage-disposition-item-2-new {
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    display: block !important;
    float: none !important; }
  .wrapper-partnerimage-disposition-new {
    height: auto !important; }
  .wrapper-partnerimage-disposition-item-new {
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    display: block !important;
    float: none !important; } }

.module-text-subheader {
  color: #333 !important;
  font-family: bonodiStd-roman,serif;
  letter-spacing: .1rem;
  text-transform: none;
  text-align: center;
  margin-bottom: 15px !important; }

.component-wrapper-new.module-hero-new {
  height: auto;
  background: none; }
  .component-wrapper-new.module-hero-new .module-image {
    height: 21.6rem; }
    .component-wrapper-new.module-hero-new .module-image .mobile-tab {
      margin-bottom: 3.3333rem; }
    @media screen and (min-width: 40em) {
      .component-wrapper-new.module-hero-new .module-image {
        height: 42rem; } }
  .component-wrapper-new.module-hero-new .module-content-wrapper {
    position: relative;
    top: auto;
    max-width: 86.6667rem;
    height: auto;
    margin-top: -11.3333rem;
    margin-bottom: 3.3333rem;
    box-shadow: none;
    transform: none; }
    .component-wrapper-new.module-hero-new .module-content-wrapper header {
      margin-bottom: 1.6rem; }
  .component-wrapper-new.module-hero-new .module-content {
    position: static;
    max-width: none;
    transform: none; }
    .component-wrapper-new.module-hero-new .module-content > ul,
    .component-wrapper-new.module-hero-new .module-content > ol {
      font-size: 1.3333rem;
      margin-top: 2rem;
      margin-bottom: 2.4rem; }
    .component-wrapper-new.module-hero-new .module-content .mobile-gap {
      margin-bottom: 4rem; }
  .component-wrapper-new.module-hero-new p {
    max-width: 53.3333rem;
    margin-right: auto;
    margin-left: auto;
    text-align: center; }
    .component-wrapper-new.module-hero-new p.tiny {
      margin-bottom: 0;
      font-size: 1rem;
      font-family: "bonodiBe-italic", serif; }
      html[lang="ru"] .component-wrapper-new.module-hero-new p.tiny {
        font-family: "Playfair Display", serif; }
      html[lang="ko"] .component-wrapper-new.module-hero-new p.tiny {
        font-family: "KoPub Batang", serif; }
      html[lang="zh-cn"] .component-wrapper-new.module-hero-new p.tiny {
        font-family: "adobe-fangsong-std", serif; }
      html[lang="ar"] .component-wrapper-new.module-hero-new p.tiny {
        font-family: "Noto Naskh Arabic", serif; }
      .component-wrapper-new.module-hero-new p.tiny html[lang="ru"],
      html[lang="ko"] .component-wrapper-new.module-hero-new p.tiny,
      html[lang="zh-cn"] .component-wrapper-new.module-hero-new p.tiny,
      html[lang="ar"] .component-wrapper-new.module-hero-new p.tiny {
        font-style: italic; }
    .layout-reversed .component-wrapper-new.module-hero-new p {
      text-align: right; }
      @media screen and (min-width: 40em) {
        .layout-reversed .component-wrapper-new.module-hero-new p {
          text-align: center; } }
      .layout-reversed .component-wrapper-new.module-hero-new p.searchDd-main {
        text-align: right; }
  .component-wrapper-new.module-hero-new ul {
    text-align: left; }
    @media screen and (min-width: 40em) {
      .component-wrapper-new.module-hero-new ul {
        text-align: center; } }
  .component-wrapper-new.module-hero-new .brand-contact {
    max-width: 20rem;
    margin: 2rem auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: inherit; }
    .component-wrapper-new.module-hero-new .brand-contact li {
      margin-bottom: 0.2rem; }
      .component-wrapper-new.module-hero-new .brand-contact li:last-child {
        margin-bottom: 0; }
    .component-wrapper-new.module-hero-new .brand-contact a {
      color: #000; }
  .component-wrapper-new.module-hero-new .brand-logo {
    max-width: 16rem;
    margin-right: auto;
    margin-left: auto; }
  .component-wrapper-new.module-hero-new .barcode {
    display: block;
    max-width: 14rem;
    margin: -0.7rem auto; }
  .layout-reversed .component-wrapper-new.module-hero-new .button {
    text-align: center; }

@media screen and (min-width: 50em) {
  .component-img-content {
    height: 43.3333rem; } }

.component-img-content .link-wrap {
  display: block;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 18.5rem;
  color: inherit;
  cursor: pointer; }
  @media screen and (min-width: 50em) {
    .component-img-content .link-wrap {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%; } }
  .component-img-content .link-wrap .module-content-wrapper {
    position: absolute;
    top: -7.6rem;
    margin-top: 0; }
    @media screen and (min-width: 50em) {
      .component-img-content .link-wrap .module-content-wrapper {
        position: relative;
        top: 50%;
        transform: translateY(-50%); } }

.component-img-content .module-image {
  height: 25rem; }
  @media screen and (min-width: 50em) {
    .component-img-content .module-image {
      height: 100%; } }

.component-img-content .module-content-wrapper {
  width: 100%;
  max-width: 105rem;
  margin-top: -8rem;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 50em) {
    .component-img-content .module-content-wrapper {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-top: 0; } }

.component-img-content .module-content {
  position: relative;
  z-index: 1;
  width: calc(100% - 4rem);
  margin-right: auto;
  margin-left: auto;
  padding: 3.3333rem;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  max-width: 31rem; }
  @media screen and (min-width: 50em) {
    .component-img-content .module-content {
      width: 100%; } }
  @media screen and (min-width: 50em) {
    .component-img-content .module-content.no-link .module-text-copy {
      margin-bottom: 0; } }
  @media screen and (min-width: 50em) {
    .component-img-content .module-content .module-text-copy {
      margin-bottom: 20px;
      max-height: 190px; } }
  @media screen and (min-width: 50em) {
    .component-img-content .module-content {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      margin-right: 2.1667rem;
      margin-left: 2.1667rem;
      max-height: 35rem;
      margin-right: 3.3333rem;
      margin-left: 3.3333rem; } }
  @media screen and (min-width: 64em) {
    .component-img-content .module-content {
      margin-right: 6.6667rem;
      margin-left: 6.6667rem; } }
  @media screen and (min-width: 50em) {
    .component-img-content .module-content.module-content-right {
      left: auto; }
    .component-img-content .module-content.module-content-center {
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto; }
    .component-img-content .module-content.module-content-left {
      right: auto; } }

.component-img-content .module-text-header {
  font-size: 2rem; }
  @media screen and (min-width: 50em) {
    .component-img-content .module-text-header {
      font-size: 2.8333rem; } }

.component-img-content .module-title-deco {
  margin-bottom: 1.4rem;
  padding-bottom: 1.2rem; }
  .component-img-content .module-title-deco:before {
    bottom: 0;
    left: 0.33rem;
    width: 3.75rem; }
  .layout-reversed .component-img-content .module-title-deco:before {
    left: auto;
    right: 0.33rem; }

.promo-editorial {
  margin-bottom: 0; }
  .promo-editorial .module-find-out-more {
    margin: 20px auto 0px;
    display: inline-block;
    border: 1px solid black;
    padding: 1.2rem 1.2rem 1rem;
    height: auto; }
    .promo-editorial .module-find-out-more .arrow-anim {
      display: none; }
  .promo-editorial .module-content-wrapper {
    max-width: 63.6667rem !important; }
  .promo-editorial.component-wrapper-new p {
    max-width: 55rem;
    text-align: left !important; }
    .layout-reversed .promo-editorial.component-wrapper-new p {
      text-align: right; }

.module-text-new {
  height: 35.83rem; }
  @media screen and (max-width: 39.99em) {
    .module-text-new {
      height: auto; }
      .module-text-new .ellipsis-new {
        margin-bottom: 1.4rem; } }
  .module-text-new .module-title-deco {
    margin-bottom: 1.4rem;
    padding-bottom: 1.4rem; }
    .module-text-new .module-title-deco:before {
      bottom: 0;
      left: 0.33rem;
      width: 3.75rem; }
    .layout-reversed .module-text-new .module-title-deco:before {
      left: auto;
      right: 0; }
  .module-text-new .module-text-copy p {
    max-width: 60rem; }
  .text-block-3col-wrapper .module-text-new {
    height: auto; }
  .module-text-new p,
  .module-text-new .module-find-out-more {
    color: #000; }
  .module-text-new.module-text-new-background {
    padding: 2.6rem 3rem; }
  .module-text-new.inverted .module-text-header,
  .module-text-new.inverted p,
  .module-text-new.inverted .module-find-out-more {
    color: #fff; }
  .module-text-new.inverted .module-title-deco:before {
    background: #fff; }
  .module-text-new.center {
    text-align: center; }
    .module-text-new.center .module-title-deco:before {
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto; }

.text-block-3col-wrapper .module-text-new {
  margin-bottom: 3rem; }

.text-block-3col-wrapper .text-block-footer {
  text-align: center; }

@media screen and (min-width: 40em) {
  .text-block-3col-wrapper .widget-slot {
    display: inline; } }

article.module-free-text-new.free-text-background {
  margin-bottom: 40px; }

@media screen and (max-width: 39.99em) {
  .module-free-text-new {
    height: auto; } }

.module-free-text-new.center {
  text-align: center; }
  .module-free-text-new.center .module-free-text-header:before {
    margin: 0 auto; }
  .module-free-text-new.center .module-free-text-header:after {
    margin-right: auto;
    margin-left: auto; }
  .module-free-text-new.center .module-free-text-copy ol,
  .module-free-text-new.center .module-free-text-copy ul {
    text-align: left; }

.module-free-text-new.free-text-background {
  background: #c9c9c9;
  padding: 3rem 10% 1.5rem; }

.module-free-text-new.inverted .module-free-text-header,
.module-free-text-new.inverted .module-free-text-copy p,
.module-free-text-new.inverted .module-free-text-copy ol,
.module-free-text-new.inverted .module-free-text-copy ol li,
.module-free-text-new.inverted .module-free-text-copy ul,
.module-free-text-new.inverted .module-free-text-copy ul li {
  color: #fff; }

.module-free-text-new.inverted .module-free-text-header:after, .module-free-text-new.inverted .module-free-text-header:before {
  background: #fff; }

.module-free-text-new.inverted .module-free-text-copy a,
.module-free-text-new.inverted .module-free-text-copy p a {
  color: #fff;
  opacity: 0.5; }
  .module-free-text-new.inverted .module-free-text-copy a:hover,
  .module-free-text-new.inverted .module-free-text-copy p a:hover {
    opacity: 0.8; }

.module-free-text-new.inverted .module-free-text-copy a {
  font-size: 1.25rem; }

.module-free-text-header {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: "brown-regular", sans-serif;
  letter-spacing: 0.05rem;
  position: relative;
  font-size: 2rem;
  margin-bottom: 1.4rem;
  padding-bottom: 1.2rem;
  color: #008a9d; }
  html[lang="ru"] .module-free-text-header {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .module-free-text-header {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .module-free-text-header {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .module-free-text-header {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
  @media screen and (min-width: 50em) {
    .module-free-text-header {
      font-size: 2.5rem; } }
  @media screen and (min-width: 50em) and (max-width: 63.99em) {
    .module-free-text-header {
      font-size: 2rem; } }
  .module-free-text-header:before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background: #000;
    bottom: 0;
    left: 0;
    right: 0;
    width: 3.75rem; }
  .module-free-text-header sup {
    font-size: 0.5em; }
  .layout-reversed .module-free-text-header {
    letter-spacing: 0; }
  .module-free-text-header.module-free-text-deco {
    position: relative;
    padding-bottom: 1rem; }
    .module-free-text-header.module-free-text-deco:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      margin-right: 0;
      margin-left: 0;
      background: #777;
      width: 3rem;
      height: 1px; }
  .page-title-full .module-free-text-header {
    margin-bottom: 2rem;
    margin-top: 3rem; }
  .module-just-text .module-free-text-header {
    color: white; }
    .module-just-text .module-free-text-header:after {
      background: white; }
  .module-free-text-header.accordion-trigger-text {
    font-size: 1.6rem !important; }
    @media screen and (max-width: 49.99em) {
      .module-free-text-header.accordion-trigger-text {
        margin-bottom: 0;
        padding: 0; } }
    @media screen and (min-width: 50em) {
      .module-free-text-header.accordion-trigger-text {
        font-size: 2rem !important; } }
    .module-free-text-header.accordion-trigger-text:before {
      display: none; }

.page-title-full-seeAll {
  margin-bottom: 5rem;
  text-align: center; }
  @media screen and (max-width: 39.99em) {
    .page-title-full-seeAll .button {
      width: 250px;
      max-width: 100%; } }
  @media screen and (min-width: 40em) {
    .page-title-full-seeAll {
      position: absolute;
      top: 0.8333rem;
      right: 0; }
      .page-title-full-seeAll .button {
        margin: 0;
        padding: 0;
        color: #cdcdcd;
        font-size: 1.3333rem;
        letter-spacing: 0;
        background-color: transparent !important;
        border-color: transparent !important;
        border-radius: none; } }

.row-seeAll {
  position: relative; }

.module-free-text-copy {
  margin-bottom: 3rem; }
  .module-free-text-copy p {
    font-size: 1.1667rem;
    line-height: 1.4;
    text-transform: none; }
    .module-free-text-copy p a {
      color: #000;
      text-decoration: underline; }
  .module-free-text-copy p + .button {
    margin-top: 1.8rem; }
  .module-free-text-copy.rich-text ul,
  .module-free-text-copy.rich-text ol {
    margin-left: 1.5rem; }
  .page-basic-content .module-free-text-copy h2, .page-basic-content .module-free-text-copy h3,
  .page-basic-content-new .module-free-text-copy h2,
  .page-basic-content-new .module-free-text-copy h3 {
    text-transform: uppercase; }

.text-image-component-promo-code .module-free-text-copy {
  height: auto !important; }
  .text-image-component-promo-code .module-free-text-copy ul,
  .text-image-component-promo-code .module-free-text-copy ol {
    color: #fff; }

.alt-img-content-even .grid-item:nth-child(4n+3) {
  float: right; }

.alt-img-content-odd .grid-item:nth-child(4n+1) {
  float: right; }

.module-just-text {
  color: white;
  background: #808080; }
  .module-just-text.module-free-text-deco {
    padding-bottom: 1.2rem; }

#village-google-map {
  margin-bottom: 5rem; }

.google-map-form {
  margin-top: 1.6667rem; }
  .google-map-form .controls,
  .google-map-form .button {
    width: 100%; }
  .google-map-form .controls {
    width: 100%; }
    .google-map-form .controls input[type="text"] {
      width: 100%; }
      @media screen and (min-width: 50em) {
        .google-map-form .controls input[type="text"] {
          min-width: 18.3333rem; } }
      @media only screen and (min-width: 60em) {
        .google-map-form .controls input[type="text"] {
          min-width: 20.8333rem; } }
    @media screen and (min-width: 50em) {
      .google-map-form .controls {
        width: 65%;
        float: left; } }
  .google-map-form .button {
    margin-bottom: 1.6667rem; }
    @media screen and (min-width: 50em) {
      .google-map-form .button {
        width: auto;
        float: right; } }

.slider-partners-wrapper {
  height: auto !important; }
  .slider-partners-wrapper .section-title-wrapper {
    padding-bottom: 0;
    border-bottom: 0;
    color: #008a9d; }
    .slider-partners-wrapper .section-title-wrapper .section-title {
      font-family: "brown-regular", sans-serif;
      font-size: 18px; }
  .slider-partners-wrapper .slider-manual:before, .slider-partners-wrapper .slider-manual:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 20;
    display: block;
    width: 2.0833rem;
    height: 100%;
    background-repeat: repeat-y; }
  .slider-partners-wrapper .slider-manual:before {
    left: 0;
    background-image: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); }
  .slider-partners-wrapper .slider-manual:after {
    right: 0;
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%); }

.partner-image-wrapper {
  padding-right: 1.6667rem;
  padding-bottom: 1rem;
  padding-left: 1.6667rem; }

.partner-brand {
  margin-bottom: 3rem; }
  .slider-partners-wrapper .partner-brand {
    margin-bottom: 0; }
  .partner-brand h5 {
    color: #000;
    font-size: 1.0833rem;
    font-family: "brown-regular", sans-serif;
    letter-spacing: 0.05rem;
    text-transform: uppercase; }

.alert-bar {
  padding: 0 1rem;
  clear: both; }

.alert-bar-single {
  position: relative;
  display: block;
  color: #fff;
  font-size: 1.75rem;
  font-family: "brown-regular", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  background-color: #008a9d;
  padding: 1rem;
  padding-right: 5rem; }
  @media screen and (min-width: 40em) {
    .alert-bar-single {
      text-align: center;
      padding-right: 1rem; } }
  @media screen and (min-width: 50em) {
    .alert-bar-single {
      font-size: 2rem; } }
  .layout-reversed .alert-bar-single {
    padding-right: 1rem; }
    @media screen and (max-width: 39.99em) {
      .layout-reversed .alert-bar-single {
        padding-left: 5rem; } }
  .layout-reversed .alert-bar-single {
    letter-spacing: 0; }
  .alert-bar-single .webfont-arrow-long:before {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    display: block;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    transform: translate(0, -50%); }
  @media screen and (min-width: 40em) {
    .alert-bar-single .webfont-arrow-long {
      white-space: nowrap; }
      .alert-bar-single .webfont-arrow-long:before {
        position: relative;
        top: 0.3333rem;
        right: -0.8333rem;
        display: inline-block;
        margin-top: 0;
        margin-left: 0;
        transform: translate(0, 0); } }
  .layout-reversed .alert-bar-single .webfont-arrow-long {
    padding-right: 0.5rem; }
    .layout-reversed .alert-bar-single .webfont-arrow-long:after {
      content: ""; }
    .layout-reversed .alert-bar-single .webfont-arrow-long:before {
      display: none;
      padding-right: 0.6rem; }
    .layout-reversed .alert-bar-single .webfont-arrow-long:after {
      display: inline-block;
      height: inherit;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      font-size: 1rem;
      font-family: "icons";
      font-variant: normal;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      text-decoration: inherit;
      text-align: center;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      top: 50%;
      left: 1.5rem;
      display: block;
      color: white;
      font-size: 2rem;
      font-weight: bold;
      transform: translate(0, -50%) rotate(180deg); }
    @media screen and (min-width: 40em) {
      .layout-reversed .alert-bar-single .webfont-arrow-long {
        white-space: nowrap; }
        .layout-reversed .alert-bar-single .webfont-arrow-long:after {
          position: relative;
          top: 0.3333rem;
          left: -0.8333rem;
          display: inline-block;
          margin-top: 0;
          margin-left: 0;
          transform: translate(0, 0) rotate(180deg); } }

.module-image {
  height: 35.83rem; }

.main-header {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1000;
  top: 0 !important;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #000;
  -webkit-backface-visibility: hidden; }
  @media screen and (min-width: 64em) {
    .no-touchevents .main-header {
      max-height: 13.8rem;
      padding-top: 2rem;
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid #000;
      -webkit-transition: padding 0.2s ease !important;
      -moz-transition: padding 0.2s ease !important;
      transition: padding 0.2s ease !important; }
      .no-touchevents .main-header.micro-header {
        padding-top: 30px; } }

#navTrigger {
  position: absolute;
  top: 1.5rem;
  right: 1.25rem; }

.open-close {
  font-family: "brown-regular", sans-serif; }
  html[lang="ru"] .open-close {
    font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ko"] .open-close {
    font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="zh-cn"] .open-close {
    font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
  html[lang="ar"] .open-close {
    font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }

@media screen and (min-width: 64em) {
  .no-touchevents .nav-header {
    padding-right: 3.8333rem;
    padding-left: 3.8333rem; }
  .no-touchevents .close-canvas {
    display: none; } }

.page-500 .page-wrapper {
  margin-bottom: 2.0833rem; }

@media print {
  .main-header {
    display: none; }
  .hero {
    display: none; } }

@media only screen and (max-width: 63.99em) {
  .no-touchevents .site-wrapper {
    position: relative;
    width: 100%;
    min-height: 26.6667rem;
    overflow: hidden; }
  .no-touchevents .site-canvas {
    position: relative;
    height: 100%;
    width: 100%; }
  .no-touchevents .site-content {
    position: relative; }
  .no-touchevents .off-canvas-wrapper {
    position: fixed;
    top: 0;
    right: -22rem;
    bottom: 0;
    z-index: 1000;
    width: 22rem;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease; }
    .no-touchevents .off-canvas-wrapper .hidden-medium-down {
      display: none; }
    .no-touchevents .off-canvas-wrapper a {
      display: inline-block !important;
      width: 70%;
      height: 100%;
      line-height: 1.5;
      padding: 16px 0; }
      .layout-reversed .no-touchevents .off-canvas-wrapper a {
        width: 100%; }
    .no-touchevents .off-canvas-wrapper .login-register a {
      width: 100%;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
      .no-touchevents .off-canvas-wrapper .login-register a .webfont-cheveron:before, .no-touchevents .off-canvas-wrapper .login-register a .dk-selected:before, .no-touchevents .off-canvas-wrapper .login-register a
      .searchDd-main:before, .no-touchevents .off-canvas-wrapper .login-register a .searchDd-category span:before, .searchDd-category .no-touchevents .off-canvas-wrapper .login-register a span:before {
        position: absolute;
        top: 1.7rem;
        right: 0.8333rem;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        color: #7b7b7b; }
    .no-touchevents .off-canvas-wrapper .register-menu a:after {
      right: 1rem; } }
    @media only screen and (max-width: 63.99em) and (min-width: 64em) {
      .no-touchevents .no-touchevents .off-canvas-wrapper .register-menu a:after {
        display: none; } }

@media only screen and (max-width: 63.99em) {
    .no-touchevents .off-canvas-wrapper .login-menu a .webfont-cheveron:before, .no-touchevents .off-canvas-wrapper .login-menu a .dk-selected:before, .no-touchevents .off-canvas-wrapper .login-menu a
    .searchDd-main:before, .no-touchevents .off-canvas-wrapper .login-menu a .searchDd-category span:before, .searchDd-category .no-touchevents .off-canvas-wrapper .login-menu a span:before {
      right: -0.25rem; }
    .no-touchevents .off-canvas-wrapper .lang-vill .dropdown-button,
    .no-touchevents .off-canvas-wrapper .topnav-social-media .dropdown-button {
      width: 100%; }
  .no-touchevents .site-canvas.active .off-canvas-wrapper {
    right: 0; }
  .no-touchevents .off-canvas-block,
  .no-touchevents .nav-navItem {
    padding-right: 1.6667rem;
    padding-left: 1.6667rem;
    border-bottom: 1px solid #e5e5e5;
    line-height: 4.1667rem; }
    .no-touchevents .off-canvas-block.last,
    .no-touchevents .nav-navItem.last {
      border-bottom: 0px solid transparent; }
  .no-touchevents .nav-navItem {
    background: white; }
  .no-touchevents .off-canvas-block {
    min-width: 22rem; }
  .no-touchevents .off-canvas-block {
    transform: translateX(270px);
    transition: none; }
    .no-touchevents .off-canvas-block.movein {
      transform: translateX(0);
      transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1); }
  .no-touchevents .moveinSideNav {
    margin-left: 0;
    background: #f5f5f5; }
  .no-touchevents .moveinSearch {
    margin-left: 0;
    background: #f3f3f3; }
  .no-touchevents .close-canvas {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-indent: -9999rem;
    background-color: #000;
    opacity: 0;
    cursor: pointer;
    z-index: 99; }
    .no-touchevents .close-canvas.active {
      display: block;
      opacity: 0.7; }
  .no-touchevents #language .dropdown-button:focus,
  .no-touchevents #village .dropdown-button:focus,
  .no-touchevents .topnav-social-media .dropdown-button:focus {
    opacity: 1; }
  .no-touchevents #language .dropdown-button:before,
  .no-touchevents #village .dropdown-button:before,
  .no-touchevents .topnav-social-media .dropdown-button:before {
    position: absolute;
    top: 2.1rem;
    right: -0.3rem; }
    .layout-reversed .no-touchevents #language .dropdown-button:before, .layout-reversed
    .no-touchevents #village .dropdown-button:before, .layout-reversed
    .no-touchevents .topnav-social-media .dropdown-button:before {
      position: absolute;
      top: 2.1rem;
      right: auto;
      left: -0.3rem; }
  .no-touchevents .nav-container .lang-vill .dropdown,
  .no-touchevents .topnav-social-media {
    background: white; }
  .no-touchevents .topnav-social-media .dropdown-menu {
    position: relative;
    padding-top: 0.5rem;
    padding-bottom: 1.2rem; }
    .no-touchevents .topnav-social-media .dropdown-menu:before {
      content: '';
      width: 30px;
      height: 1px;
      background-color: black;
      position: absolute;
      top: 0;
      left: 0; }
  .no-touchevents .topnav-social-media .social-subnav-trigger {
    color: #7b7b7b; }
    .no-touchevents .topnav-social-media .social-subnav-trigger.active {
      color: #000; }
  .no-touchevents .topnav-social-media .social-linkItem {
    color: #000; }
    .no-touchevents .topnav-social-media .social-linkItem:before,
    .no-touchevents .topnav-social-media .social-linkItem img,
    .no-touchevents .topnav-social-media .social-linkItem span {
      display: inline-block;
      vertical-align: middle; }
    .no-touchevents .topnav-social-media .social-linkItem:before,
    .no-touchevents .topnav-social-media .social-linkItem img {
      margin-right: 1rem;
      margin-left: 0;
      font-size: 1.4rem; }
    .no-touchevents .topnav-social-media .social-linkItem img {
      width: 1.5rem; }
  .no-touchevents .layout-reversed .topnav-social-media .dropdown-button.webfont-cheveron:before, .no-touchevents .layout-reversed .topnav-social-media .dropdown-button.dk-selected:before, .no-touchevents .layout-reversed .topnav-social-media
  .dropdown-button.searchDd-main:before, .no-touchevents .layout-reversed .topnav-social-media .searchDd-category span.dropdown-button:before, .searchDd-category .no-touchevents .layout-reversed .topnav-social-media span.dropdown-button:before {
    right: auto;
    left: -0.3rem; } }

.touchevents .site-wrapper {
  position: relative;
  width: 100%;
  min-height: 26.6667rem;
  overflow: hidden; }

.touchevents .site-canvas {
  position: relative;
  height: 100%;
  width: 100%; }

.touchevents .site-content {
  position: relative; }

.touchevents .off-canvas-wrapper {
  position: fixed;
  top: 0;
  right: -22rem;
  bottom: 0;
  z-index: 1000;
  width: 22rem;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  .touchevents .off-canvas-wrapper .hidden-medium-down {
    display: none; }
  .touchevents .off-canvas-wrapper a {
    display: inline-block !important;
    width: 70%;
    height: 100%;
    line-height: 1.5;
    padding: 16px 0; }
    .layout-reversed .touchevents .off-canvas-wrapper a {
      width: 100%; }
  .touchevents .off-canvas-wrapper .login-register a {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
    .touchevents .off-canvas-wrapper .login-register a .webfont-cheveron:before, .touchevents .off-canvas-wrapper .login-register a .dk-selected:before, .touchevents .off-canvas-wrapper .login-register a
    .searchDd-main:before, .touchevents .off-canvas-wrapper .login-register a .searchDd-category span:before, .searchDd-category .touchevents .off-canvas-wrapper .login-register a span:before {
      position: absolute;
      top: 1.7rem;
      right: 0.8333rem;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      color: #7b7b7b; }
  .touchevents .off-canvas-wrapper .register-menu a:after {
    right: 1rem; }
    @media screen and (min-width: 64em) {
      .no-touchevents .touchevents .off-canvas-wrapper .register-menu a:after {
        display: none; } }
  .touchevents .off-canvas-wrapper .login-menu a .webfont-cheveron:before, .touchevents .off-canvas-wrapper .login-menu a .dk-selected:before, .touchevents .off-canvas-wrapper .login-menu a
  .searchDd-main:before, .touchevents .off-canvas-wrapper .login-menu a .searchDd-category span:before, .searchDd-category .touchevents .off-canvas-wrapper .login-menu a span:before {
    right: -0.25rem; }
  .touchevents .off-canvas-wrapper .lang-vill .dropdown-button,
  .touchevents .off-canvas-wrapper .topnav-social-media .dropdown-button {
    width: 100%; }

.touchevents .site-canvas.active .off-canvas-wrapper {
  right: 0; }

.touchevents .off-canvas-block,
.touchevents .nav-navItem {
  padding-right: 1.6667rem;
  padding-left: 1.6667rem;
  border-bottom: 1px solid #e5e5e5;
  line-height: 4.1667rem; }
  .touchevents .off-canvas-block.last,
  .touchevents .nav-navItem.last {
    border-bottom: 0px solid transparent; }

.touchevents .nav-navItem {
  background: white; }

.touchevents .off-canvas-block {
  min-width: 22rem; }

.touchevents .off-canvas-block {
  transform: translateX(270px);
  transition: none; }
  .touchevents .off-canvas-block.movein {
    transform: translateX(0);
    transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1); }

.touchevents .moveinSideNav {
  margin-left: 0;
  background: #f5f5f5; }

.touchevents .moveinSearch {
  margin-left: 0;
  background: #f3f3f3; }

.touchevents .close-canvas {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-indent: -9999rem;
  background-color: #000;
  opacity: 0;
  cursor: pointer;
  z-index: 99; }
  .touchevents .close-canvas.active {
    display: block;
    opacity: 0.7; }

.touchevents #language .dropdown-button:focus,
.touchevents #village .dropdown-button:focus,
.touchevents .topnav-social-media .dropdown-button:focus {
  opacity: 1; }

.touchevents #language .dropdown-button:before,
.touchevents #village .dropdown-button:before,
.touchevents .topnav-social-media .dropdown-button:before {
  position: absolute;
  top: 2.1rem;
  right: -0.3rem; }
  .layout-reversed .touchevents #language .dropdown-button:before, .layout-reversed
  .touchevents #village .dropdown-button:before, .layout-reversed
  .touchevents .topnav-social-media .dropdown-button:before {
    position: absolute;
    top: 2.1rem;
    right: auto;
    left: -0.3rem; }

.touchevents .nav-container .lang-vill .dropdown,
.touchevents .topnav-social-media {
  background: white; }

.touchevents .topnav-social-media .dropdown-menu {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 1.2rem; }
  .touchevents .topnav-social-media .dropdown-menu:before {
    content: '';
    width: 30px;
    height: 1px;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0; }

.touchevents .topnav-social-media .social-subnav-trigger {
  color: #7b7b7b; }
  .touchevents .topnav-social-media .social-subnav-trigger.active {
    color: #000; }

.touchevents .topnav-social-media .social-linkItem {
  color: #000; }
  .touchevents .topnav-social-media .social-linkItem:before,
  .touchevents .topnav-social-media .social-linkItem img,
  .touchevents .topnav-social-media .social-linkItem span {
    display: inline-block;
    vertical-align: middle; }
  .touchevents .topnav-social-media .social-linkItem:before,
  .touchevents .topnav-social-media .social-linkItem img {
    margin-right: 1rem;
    margin-left: 0;
    font-size: 1.4rem; }
  .touchevents .topnav-social-media .social-linkItem img {
    width: 1.5rem; }

.touchevents .layout-reversed .topnav-social-media .dropdown-button.webfont-cheveron:before, .touchevents .layout-reversed .topnav-social-media .dropdown-button.dk-selected:before, .touchevents .layout-reversed .topnav-social-media
.dropdown-button.searchDd-main:before, .touchevents .layout-reversed .topnav-social-media .searchDd-category span.dropdown-button:before, .searchDd-category .touchevents .layout-reversed .topnav-social-media span.dropdown-button:before {
  right: auto;
  left: -0.3rem; }

.nav-main {
  position: static; }
  .nav-main a {
    display: inline-block; }
  .nav-main .your-village-hideshow {
    display: block; }
    @media screen and (min-width: 64em) {
      .no-touchevents .nav-main .your-village-hideshow {
        display: none; } }
  .nav-main .your-village-prev a:after {
    display: block; }
    @media screen and (min-width: 64em) {
      .no-touchevents .nav-main .your-village-prev a:after {
        display: none; } }
  @media screen and (min-width: 64em) {
    .no-touchevents .nav-main {
      position: relative;
      margin-top: 1rem; }
      .no-touchevents .nav-main > ul {
        float: right; }
      .no-touchevents .nav-main > ul,
      .no-touchevents .nav-main .nav-subnav > ul {
        margin: 0 auto; }
      .no-touchevents .nav-main .nav-subnav > ul {
        margin: 0 auto;
        position: relative; }
      .no-touchevents .nav-main > ul > .nav-navItem:hover .nav-subnav {
        transform: translateY(0); }
      .no-touchevents .nav-main > ul > .nav-navItem:hover .nav-arrow-active {
        bottom: -0.6rem;
        opacity: 1;
        background-color: #b5b5b5;
        cursor: default;
        pointer-events: none; } }
  @media screen and (min-width: 64em) and (-webkit-min-device-pixel-ratio: 0) {
    .no-touchevents .nav-main > ul > .nav-navItem:hover .nav-arrow-active {
      bottom: -0.65rem; } }
  @media screen and (min-width: 64em) {
      @supports (-ms-accelerator: true) {
        .no-touchevents .nav-main > ul > .nav-navItem:hover .nav-arrow-active {
          bottom: -0.6rem; } } }

@media screen and (min-width: 64em) {
  .no-touchevents .nav-parent {
    position: relative;
    transition: none; }
    .no-touchevents .nav-parent:hover {
      color: #000;
      opacity: 1; } }

.nav-subnav {
  display: none;
  width: 22rem;
  margin-left: -1.7rem;
  background-color: #eee; }
  .touchevents .layout-reversed .nav-subnav {
    margin-left: 0;
    margin-right: -1.7rem;
    margin-left: auto; }
  @media screen and (min-width: 64em) {
    .no-touchevents .nav-subnav {
      position: absolute;
      top: 2.6rem;
      right: 0;
      left: 0;
      z-index: 10;
      display: block;
      width: 100%;
      height: 0;
      margin-left: 0;
      padding-top: 0;
      overflow: hidden;
      border-bottom: none;
      -webkit-transition: height 0s ease 0.2s, padding 0s ease 0.2s;
      -moz-transition: height 0s ease 0.2s, padding 0s ease 0.2s;
      transition: height 0s ease 0.2s, padding 0s ease 0.2s; } }
  .nav-subnav .nav-navItem {
    display: block; }
    @media screen and (min-width: 64em) {
      .no-touchevents .nav-subnav .nav-navItem {
        display: inline-block;
        margin: 0.5rem 0 !important;
        padding-top: 0;
        padding-left: 0; } }
    .nav-subnav .nav-navItem:last-child:after {
      display: none; }
    @media screen and (max-width: 63.99em) {
      .no-touchevents .nav-subnav .nav-navItem:after {
        display: none; } }
    .touchevents .nav-subnav .nav-navItem:after {
      display: none; }
    .nav-subnav .nav-navItem a {
      color: #000;
      width: 100%; }
      @media screen and (min-width: 64em) {
        .no-touchevents .nav-subnav .nav-navItem a {
          width: auto;
          margin-left: 1rem;
          margin-right: 0; }
          .layout-reversed .no-touchevents .nav-subnav .nav-navItem a {
            margin-right: 1rem;
            margin-left: 0; } }
    .nav-subnav .nav-navItem.active a {
      font-family: "brown-bold", sans-serif;
      font-family: "brown-regular", sans-serif; }
      html[lang="ru"] .nav-subnav .nav-navItem.active a {
        font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ko"] .nav-subnav .nav-navItem.active a {
        font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="zh-cn"] .nav-subnav .nav-navItem.active a {
        font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ar"] .nav-subnav .nav-navItem.active a {
        font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
      .nav-subnav .nav-navItem.active a html[lang="ru"],
      html[lang="ko"] .nav-subnav .nav-navItem.active a,
      html[lang="zh-cn"] .nav-subnav .nav-navItem.active a,
      html[lang="ar"] .nav-subnav .nav-navItem.active a {
        font-weight: 700; }

@media screen and (min-width: 64em) {
  .no-touchevents .nav-home {
    display: none; } }

.nav-main-links {
  position: relative;
  margin-right: 1rem;
  margin-left: 1rem; }
  @media screen and (max-width: 63.99em) {
    .no-touchevents .nav-main-links {
      margin: 0; } }
  .touchevents .nav-main-links {
    margin: 0; }

.nav-subnav-trigger {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 50;
  display: block;
  transform: translateY(-50%); }
  .nav-subnav-trigger.webfont-cheveron:before, .nav-subnav-trigger.dk-selected:before,
  .nav-subnav-trigger.searchDd-main:before, .searchDd-category span.nav-subnav-trigger:before {
    position: absolute;
    top: 48%;
    right: 0;
    -webkit-transition: -webkit-transform 0.2s linear;
    -moz-transition: -moz-transform 0.2s linear;
    transition: transform 0.2s linear;
    -webkit-transform: rotate(0) translate3d(0, 0, 0);
    -moz-transform: rotate(0) translate3d(0, 0, 0);
    -ms-transform: rotate(0) translate3d(0, 0, 0);
    -o-transform: rotate(0) translate3d(0, 0, 0);
    transform: rotate(0) translate3d(0, 0, 0);
    -webkit-transform-origin: center 0.15rem;
    -moz-transform-origin: center 0.15rem;
    -ms-transform-origin: center 0.15rem;
    -o-transform-origin: center 0.15rem;
    transform-origin: center 0.15rem;
    display: inline-block;
    width: 1.1rem;
    height: 0.6rem;
    font-size: 0.6rem;
    color: #7b7b7b; }
  .nav-subnav-trigger.webfont-cheveron.active:before, .nav-subnav-trigger.active.dk-selected:before,
  .nav-subnav-trigger.active.searchDd-main:before, .searchDd-category span.nav-subnav-trigger.active:before {
    -webkit-transform: rotate(-180deg) translate3d(0, 0, 0);
    -moz-transform: rotate(-180deg) translate3d(0, 0, 0);
    -ms-transform: rotate(-180deg) translate3d(0, 0, 0);
    -o-transform: rotate(-180deg) translate3d(0, 0, 0);
    transform: rotate(-180deg) translate3d(0, 0, 0); }
  @media screen and (min-width: 64em) {
    .no-touchevents .nav-subnav-trigger {
      display: none !important; } }

.layout-reversed .nav-subnav-trigger.webfont-cheveron:before, .layout-reversed .nav-subnav-trigger.dk-selected:before, .layout-reversed
.nav-subnav-trigger.searchDd-main:before, .layout-reversed .searchDd-category span.nav-subnav-trigger:before, .searchDd-category .layout-reversed span.nav-subnav-trigger:before {
  left: auto;
  right: 40%; }

.layout-reversed .off-canvas-wrapper .nav-subnav-trigger {
  right: auto;
  left: 0;
  width: 13%; }
  .layout-reversed .off-canvas-wrapper .nav-subnav-trigger:before {
    right: auto;
    left: -0.3rem; }
  .layout-reversed .off-canvas-wrapper .nav-subnav-trigger:after {
    left: auto;
    right: 0; }
  .layout-reversed .off-canvas-wrapper .nav-subnav-trigger:after {
    left: auto;
    right: 0; }

@media screen and (min-width: 64em) {
  .no-touchevents.ie9 .nav-main > ul > .nav-navItem {
    padding-bottom: 3rem; } }

.main-header {
  height: 50px;
  font-size: 1rem; }
  .main-header .nav-container .logo-container {
    left: 1.5rem;
    position: absolute;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    z-index: 5000; }
    @media screen and (max-width: 63.99em) {
      .no-touchevents .main-header .nav-container .logo-container {
        left: 0;
        right: 0;
        width: calc(100% - 9rem);
        margin-right: auto;
        margin-left: auto;
        text-align: center; }
        .no-touchevents .main-header .nav-container .logo-container .header-logo {
          display: inline-block; } }
    .touchevents .main-header .nav-container .logo-container {
      left: 0;
      right: 0;
      width: calc(100% - 9rem);
      margin-right: auto;
      margin-left: auto;
      text-align: center; }
      .touchevents .main-header .nav-container .logo-container .header-logo {
        display: inline-block; }
    .main-header .nav-container .logo-container .header-logo {
      display: block; }
      .main-header .nav-container .logo-container .header-logo,
      .main-header .nav-container .logo-container .header-logo img {
        position: relative;
        height: 50px;
        z-index: 5000;
        -webkit-backface-visibility: hidden;
        transform: translate3d(0, 0, 0); }
  .main-header .nav-container .opening-hours h3 {
    text-transform: uppercase; }
  .main-header .nav-container .opening-hours .tooltip-wrapper {
    display: none; }
  .main-header .nav-container div.nav-subnav .tooltip-wrapper {
    display: block;
    margin-left: 4rem;
    top: -2.75rem; }
    .main-header .nav-container div.nav-subnav .tooltip-wrapper .tooltip-trigger {
      width: 1.5rem;
      height: 1.5rem; }
    .main-header .nav-container div.nav-subnav .tooltip-wrapper .tooltip-trigger:before {
      left: .15rem;
      font-size: .8rem; }

@media screen and (max-width: 63.99em) {
  .no-touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .nav-main-links .nav-navLink {
    font-size: 1.1rem;
    color: #7b7b7b;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .no-touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .nav-subnav-trigger {
    cursor: pointer;
    width: 100%;
    height: 50px;
    margin-right: 0; }
  .no-touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .opening-hours {
    display: none; }
    .no-touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .opening-hours ul li {
      font-size: 1rem;
      letter-spacing: 0;
      text-transform: none;
      line-height: normal; }
  .no-touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem.active .nav-main-links .nav-navLink {
    color: black; }
  .no-touchevents .main-header .nav-container .nav-subnav {
    position: relative;
    background-color: white;
    margin-bottom: 15px;
    padding-top: 15px; }
    .no-touchevents .main-header .nav-container .nav-subnav:before {
      content: '';
      width: 30px;
      height: 1px;
      background-color: black;
      position: absolute;
      top: 4px;
      left: 20px; }
    .no-touchevents .main-header .nav-container .nav-subnav ul li.nav-navItem {
      font-size: 1.1rem;
      text-transform: none;
      background: white;
      border-bottom: 0;
      line-height: normal; }
      .no-touchevents .main-header .nav-container .nav-subnav ul li.nav-navItem a {
        padding: 10px 0; }
  .no-touchevents .main-header .nav-container .sidenav .sidenav-navItem-trigger h5 {
    color: #767676;
    font-size: 1rem;
    letter-spacing: 1px; }
  .no-touchevents .main-header .nav-container .sidenav .opening-hours ul li {
    line-height: normal; }
  .no-touchevents .main-header .nav-container .login-register {
    background-color: black; }
    .no-touchevents .main-header .nav-container .login-register ul li a {
      color: #ccc;
      font-family: "brown-bold", sans-serif;
      font-family: "brown-regular", sans-serif; }
      html[lang="ru"] .no-touchevents .main-header .nav-container .login-register ul li a {
        font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ko"] .no-touchevents .main-header .nav-container .login-register ul li a {
        font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="zh-cn"] .no-touchevents .main-header .nav-container .login-register ul li a {
        font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
      html[lang="ar"] .no-touchevents .main-header .nav-container .login-register ul li a {
        font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
      .no-touchevents .main-header .nav-container .login-register ul li a html[lang="ru"],
      html[lang="ko"] .no-touchevents .main-header .nav-container .login-register ul li a,
      html[lang="zh-cn"] .no-touchevents .main-header .nav-container .login-register ul li a,
      html[lang="ar"] .no-touchevents .main-header .nav-container .login-register ul li a {
        font-weight: 700; }
  .no-touchevents .main-header .nav-container .lang-vill {
    /*					margin-left: 270px;
					display: block;
					background: red;*/ }
    .no-touchevents .main-header .nav-container .lang-vill .dropdown {
      font-size: 1.1rem; }
    .no-touchevents .main-header .nav-container .lang-vill .dropdown-button {
      letter-spacing: 1px; }
  .no-touchevents .site-canvas.active .logo-container {
    z-index: 0; } }

.touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .nav-main-links .nav-navLink {
  font-size: 1.1rem;
  color: #7b7b7b;
  letter-spacing: 1px;
  text-transform: uppercase; }

.touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .nav-subnav-trigger {
  cursor: pointer;
  width: 100%;
  height: 50px;
  margin-right: 0; }

.touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .opening-hours {
  display: none; }
  .touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem .opening-hours ul li {
    font-size: 1rem;
    letter-spacing: 0;
    text-transform: none;
    line-height: normal; }

.touchevents .main-header .nav-container .nav-main > ul > li.nav-navItem.active .nav-main-links .nav-navLink {
  color: black; }

.touchevents .main-header .nav-container .nav-subnav {
  position: relative;
  background-color: white;
  margin-bottom: 15px;
  padding-top: 15px; }
  .touchevents .main-header .nav-container .nav-subnav:before {
    content: '';
    width: 30px;
    height: 1px;
    background-color: black;
    position: absolute;
    top: 4px;
    left: 20px; }
  .touchevents .main-header .nav-container .nav-subnav ul li.nav-navItem {
    font-size: 1.1rem;
    text-transform: none;
    background: white;
    border-bottom: 0;
    line-height: normal; }
    .touchevents .main-header .nav-container .nav-subnav ul li.nav-navItem a {
      padding: 10px 0; }

.touchevents .main-header .nav-container .sidenav .sidenav-navItem-trigger h5 {
  color: #767676;
  font-size: 1rem;
  letter-spacing: 1px; }

.touchevents .main-header .nav-container .sidenav .opening-hours ul li {
  line-height: normal; }

.touchevents .main-header .nav-container .login-register {
  background-color: black; }
  .touchevents .main-header .nav-container .login-register ul li a {
    color: #ccc;
    font-family: "brown-bold", sans-serif;
    font-family: "brown-regular", sans-serif; }
    html[lang="ru"] .touchevents .main-header .nav-container .login-register ul li a {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] .touchevents .main-header .nav-container .login-register ul li a {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] .touchevents .main-header .nav-container .login-register ul li a {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] .touchevents .main-header .nav-container .login-register ul li a {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
    .touchevents .main-header .nav-container .login-register ul li a html[lang="ru"],
    html[lang="ko"] .touchevents .main-header .nav-container .login-register ul li a,
    html[lang="zh-cn"] .touchevents .main-header .nav-container .login-register ul li a,
    html[lang="ar"] .touchevents .main-header .nav-container .login-register ul li a {
      font-weight: 700; }

.touchevents .main-header .nav-container .lang-vill .dropdown {
  font-size: 1.1rem; }

.touchevents .main-header .nav-container .lang-vill .dropdown-button {
  letter-spacing: 1px; }

.touchevents .site-canvas.active .logo-container {
  z-index: 0; }

@media screen and (min-width: 64em) {
  .no-touchevents .site-canvas {
    padding-top: 80px; }
  .no-touchevents #navTrigger {
    display: none; }
  .no-touchevents .main-header {
    padding-top: 0;
    padding-bottom: 0;
    border-top: solid 30px #008a9d;
    height: 80px; }
    .no-touchevents .main-header .dropdown-button.webfont-cheveron:before, .no-touchevents .main-header .dropdown-button.dk-selected:before, .no-touchevents .main-header
    .dropdown-button.searchDd-main:before, .no-touchevents .main-header .searchDd-category span.dropdown-button:before, .searchDd-category .no-touchevents .main-header span.dropdown-button:before, .no-touchevents .main-header .dropdown-button.dk-selected:before, .no-touchevents .main-header .dropdown-button.searchDd-main:before, .no-touchevents .main-header .searchDd-category span.dropdown-button:before {
      color: #fff; }
    .no-touchevents .main-header .nav-container {
      position: relative;
      height: 50px; }
      .no-touchevents .main-header .nav-container .logo-container {
        position: absolute;
        left: calc((100vw - 960px)/2); }
        .no-touchevents .main-header .nav-container .logo-container .header-logo img {
          line-height: 50px;
          width: 100%;
          max-width: 186px; }
      .no-touchevents .main-header .nav-container .off-canvas-wrapper {
        height: 50px;
        position: static;
        max-width: 960px;
        margin: 0 auto; }
        .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main {
          position: static;
          margin-top: -30px;
          height: 50px; }
          .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main > ul {
            float: right;
            max-width: 960px;
            height: 50px;
            margin: 0 auto;
            padding-right: 30px; }
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main > ul > li.nav-navItem.home-link {
              display: none; }
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main > ul > li.nav-navItem {
              float: left;
              height: 50px;
              padding-top: 0;
              padding-bottom: 0;
              line-height: 54px;
              text-transform: uppercase; }
              .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main > ul > li.nav-navItem .nav-main-links {
                margin: 0; }
                .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main > ul > li.nav-navItem .nav-main-links .nav-navLink {
                  cursor: pointer;
                  display: block;
                  padding-right: 10px;
                  padding-left: 10px;
                  font-size: 1.05rem;
                  letter-spacing: 1px;
                  color: grey; }
                  .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main > ul > li.nav-navItem .nav-main-links .nav-navLink.nav-navLink-noSubnav {
                    cursor: default; }
                    .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main > ul > li.nav-navItem .nav-main-links .nav-navLink.nav-navLink-noSubnav a {
                      cursor: pointer; }
          .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main .nav-navItem {
            /*span.arrow-anim{
								background: red;
							}*/ }
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main .nav-navItem .nav-subnav,
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main .nav-navItem .nav-subnav-tray {
              opacity: 0;
              display: none;
              pointer-events: none; }
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main .nav-navItem.active .nav-subnav,
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main .nav-navItem.active .nav-subnav-tray {
              opacity: 1;
              display: block;
              pointer-events: auto; }
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main .nav-navItem.active .nav-main-links .nav-navLink {
              color: black; }
          .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main.opened .nav-subnav {
            pointer-events: auto; }
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main.opened .nav-subnav .nav-subnav-tray {
              transition: transform 0.2s ease-out;
              transform: translateY(0); }
          .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main.closed .nav-subnav {
            pointer-events: auto; }
            .no-touchevents .main-header .nav-container .off-canvas-wrapper .nav-main.closed .nav-subnav .nav-subnav-tray {
              opacity: 1;
              display: block;
              transition: transform 0.3s ease-out;
              transform: translateY(-350px); }
      .no-touchevents .main-header .nav-container .header-lrs {
        position: relative;
        max-width: 960px;
        margin: 0 auto;
        height: 30px;
        top: -30px;
        left: auto;
        right: auto; }
        .no-touchevents .main-header .nav-container .header-lrs .user-nav {
          position: absolute;
          right: 0;
          line-height: 30px; }
          .no-touchevents .main-header .nav-container .header-lrs .user-nav .login-register {
            z-index: 200;
            margin-right: 0;
            padding-bottom: 0; }
            .no-touchevents .main-header .nav-container .header-lrs .user-nav .login-register .dropdown-menu {
              top: 30px;
              padding: 1rem; }
        .no-touchevents .main-header .nav-container .header-lrs .search, .no-touchevents .main-header .nav-container .header-lrs .bag {
          margin-top: 30px;
          top: 0;
          right: 0; }
        .no-touchevents .main-header .nav-container .header-lrs,
        .no-touchevents .main-header .nav-container .header-lrs a {
          color: #ccc; }
      .no-touchevents .main-header .nav-container .nav-weather {
        position: absolute;
        left: calc((100vw - 960px)/2 + 225px);
        display: block;
        top: -30px;
        background-color: #000; }
        .no-touchevents .main-header .nav-container .nav-weather .weather-thumb {
          height: 30px; }
          .no-touchevents .main-header .nav-container .nav-weather .weather-thumb .weather-thumb-temp {
            line-height: 23px; }
            .no-touchevents .main-header .nav-container .nav-weather .weather-thumb .weather-thumb-temp:before {
              top: 60%; }
          .no-touchevents .main-header .nav-container .nav-weather .weather-thumb p {
            color: white; }
        .no-touchevents .main-header .nav-container .nav-weather .weather-main {
          padding-top: 0;
          transform: none !important; }
          .no-touchevents .main-header .nav-container .nav-weather .weather-main .weather-main-inner {
            padding: 1rem; }
      .no-touchevents .main-header .nav-container .lang-vill {
        position: absolute;
        top: -30px;
        left: calc((100vw - 960px)/2);
        margin-left: 0;
        z-index: 5000;
        -webkit-backface-visibility: hidden;
        transform: translate3d(0, 0, 0); }
        .no-touchevents .main-header .nav-container .lang-vill a {
          color: #fff; }
        .no-touchevents .main-header .nav-container .lang-vill .dropdown {
          padding-left: 0 !important; }
        .no-touchevents .main-header .nav-container .lang-vill ul.dropdown-menu {
          padding: 1rem;
          border: 1px solid #dedede; }
          .no-touchevents .main-header .nav-container .lang-vill ul.dropdown-menu li:last-child {
            margin-bottom: 0; }
          .no-touchevents .main-header .nav-container .lang-vill ul.dropdown-menu li a {
            color: #999; }
  .no-touchevents .nav-subnav {
    top: 50px;
    overflow-y: hidden;
    height: 305px;
    background-color: transparent;
    pointer-events: none; }
    .no-touchevents .nav-subnav .nav-subnav-tray {
      background-color: #ffffff;
      overflow: hidden;
      padding-bottom: 3rem;
      border-bottom: solid 1px black;
      transform: translateY(-305px);
      transition: transform 0.2s ease-out; }
      .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper {
        position: relative;
        overflow: hidden;
        max-width: 960px;
        margin: 25px auto 0;
        z-index: 200; }
        .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper h1 {
          font-size: 1.67rem;
          position: relative;
          padding-bottom: 17px;
          margin-bottom: 15px;
          font-family: "brown-regular", sans-serif;
          color: #008a9d; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper h1:after {
            content: '';
            width: 40px;
            height: 1px;
            background-color: black;
            position: absolute;
            left: 0;
            bottom: 0; }
        .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper ul li.nav-navItem {
          width: 100%;
          display: block;
          float: left;
          line-height: 1.2;
          text-transform: none;
          margin-bottom: 0.8rem; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper ul li.nav-navItem a {
            margin-left: 0;
            font-size: 1.3rem; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper ul li.nav-navItem a.webfont-lock {
              position: relative; }
              .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper ul li.nav-navItem a.webfont-lock:before {
                position: absolute;
                top: -0.1rem;
                right: -1.5rem; }
        .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper h2 {
          font-size: 15px;
          position: relative;
          padding-bottom: 16px;
          margin-bottom: 14px; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper h2:after {
            content: '';
            width: 40px;
            height: 1px;
            background-color: black;
            position: absolute;
            left: 0;
            bottom: 0; }
        .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .opening-hours {
          font-size: 13px; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .opening-hours h3 {
            font-size: 14px;
            margin-bottom: 12px; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .opening-hours ul li {
            line-height: normal;
            text-transform: none; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .opening-hours .day {
            width: 40%; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .opening-hours .opening-time-extra {
            display: none; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .opening-hours .tooltip-content {
            bottom: 5rem;
            top: auto;
            right: auto;
            left: -7.6rem;
            text-transform: none;
            transform: none; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .opening-hours .tooltip-content:after {
              top: auto;
              bottom: -1.7rem;
              left: 50%;
              margin-left: -0.6667rem;
              transform: rotate(90deg); }
        .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper {
          display: flex; }
          .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section {
            flex: 1;
            flex-grow: 2;
            padding-left: 25px; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section::after {
              clear: both;
              content: "";
              display: table; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section:first-child {
              padding-left: 0; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .tray-section-list {
              float: left;
              width: 25%;
              padding-left: 2rem;
              border-right: 1px solid #333; }
              .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .tray-section-list:first-child {
                padding-left: 0; }
              .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .tray-section-list:last-child {
                border: 0; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .ellipsis-new {
              height: 80px !important; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .featured-content-description {
              line-height: 1.5rem;
              text-transform: none;
              font-size: 1.2rem; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section a.nav-featured-link {
              font-size: 13px;
              line-height: 1.5rem;
              color: black;
              letter-spacing: 1px;
              overflow: hidden;
              display: block;
              text-transform: uppercase; }
            .no-touchevents .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section img.nav-featured-image {
              display: block;
              width: 315px;
              height: 175px;
              object-fit: cover; }
  .no-touchevents #language,
  .no-touchevents #village {
    line-height: 30px; } }

.layout-reversed .main-header .nav-container .nav-subnav:before {
  right: 20px;
  left: initial; }
  .no-touchevents .layout-reversed .main-header .nav-container .nav-subnav:before {
    left: 20px;
    right: initial; }

.layout-reversed #language .dropdown-button:before, .layout-reversed #village .dropdown-button:before {
  right: initial;
  left: -0.3rem; }

.layout-reversed .nav-featured-link .arrow-anim {
  left: auto;
  width: 2rem; }

@media screen and (min-width: 64em) {
  .no-touchevents .layout-reversed .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .tray-section-list {
    float: right;
    padding-left: 0;
    padding-right: 25px;
    border-right: none;
    border-left: 1px solid #333; }
    .no-touchevents .layout-reversed .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .tray-section-list:first-child {
      padding-right: 0; }
    .no-touchevents .layout-reversed .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .tray-section-list:last-child {
      border-left: none; }
  .no-touchevents .layout-reversed .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper h1:after {
    left: auto;
    right: 0; }
  .no-touchevents .layout-reversed .nav-featured-link .arrow-anim {
    width: 3.5rem;
    margin-right: 0.8rem;
    margin-left: 0; } }

@media screen and (min-width: 64em) {
  .no-touchevents .lang-vill {
    position: absolute;
    top: 2rem;
    left: 5.1667rem;
    z-index: 600;
    width: auto;
    margin-left: -0.5rem;
    -webkit-transition: opacity 0.2s ease, top 0.1s linear 0.2s;
    -moz-transition: opacity 0.2s ease, top 0.1s linear 0.2s;
    transition: opacity 0.2s ease, top 0.1s linear 0.2s; }
    .no-touchevents .lang-vill::after {
      clear: both;
      content: "";
      display: table; }
    .no-touchevents .lang-vill .dropdown {
      padding-right: 1rem;
      padding-left: 1rem; }
    .layout-reversed .no-touchevents .lang-vill {
      position: absolute;
      top: 2rem;
      right: 5.1667rem;
      left: auto; } }

.lang-vill .dropdown-container {
  position: relative; }

.lang-vill .dropdown-menu {
  margin-bottom: 1rem;
  text-transform: uppercase;
  line-height: 0; }
  @media screen and (min-width: 64em) {
    .no-touchevents .lang-vill .dropdown-menu {
      position: absolute;
      top: 30px;
      left: -0.4167rem;
      margin-bottom: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      background-color: #fff; }
      .layout-reversed .no-touchevents .lang-vill .dropdown-menu {
        position: absolute;
        top: 30px;
        right: -0.4167rem;
        left: auto; } }
  .lang-vill .dropdown-menu li {
    margin-bottom: 0.5rem; }
    .lang-vill .dropdown-menu li.active a {
      border-bottom-color: #7b7b7b; }
  .lang-vill .dropdown-menu a {
    display: block;
    width: 100%;
    line-height: 2.2;
    border-bottom: 1px solid transparent;
    -webkit-transition: border-bottom-color 0.2s linear;
    -moz-transition: border-bottom-color 0.2s linear;
    transition: border-bottom-color 0.2s linear; }
    .lang-vill .dropdown-menu a:hover {
      opacity: 1;
      border-bottom-color: #7b7b7b; }
  @media screen and (max-width: 63.99em) {
    .no-touchevents .lang-vill .dropdown-menu a {
      line-height: 1;
      padding: 10px 0; } }
  .touchevents .lang-vill .dropdown-menu a {
    line-height: 1;
    padding: 10px 0; }

@media screen and (min-width: 64em) {
  .no-touchevents #language,
  .no-touchevents #village {
    float: left;
    min-width: 4rem; }
    .layout-reversed .no-touchevents #language, .layout-reversed
    .no-touchevents #village {
      float: right; } }

#language .dropdown-button,
#village .dropdown-button {
  text-transform: uppercase; }

@media screen and (max-width: 63.99em) {
  .no-touchevents #language .desktop, .no-touchevents
  #village .desktop {
    display: none !important; } }

.touchevents #language .desktop, .touchevents
#village .desktop {
  display: none !important; }

@media screen and (min-width: 64em) {
  .no-touchevents #language .offcanvas, .no-touchevents
  #village .offcanvas {
    display: none !important; }
  .no-touchevents #language a, .no-touchevents
  #village a {
    display: inline; } }

#language a,
#village a {
  height: auto; }

@media screen and (min-width: 64em) {
  .no-touchevents #village .dropdown-menu {
    width: 16rem; } }

#village .dropdown-menu a {
  white-space: nowrap; }

.dd-location {
  display: block;
  text-transform: none;
  font-family: "bonodiBe-italic", serif;
  line-height: 1; }
  html[lang="ru"] .dd-location {
    font-family: "Playfair Display", serif; }
  html[lang="ko"] .dd-location {
    font-family: "KoPub Batang", serif; }
  html[lang="zh-cn"] .dd-location {
    font-family: "adobe-fangsong-std", serif; }
  html[lang="ar"] .dd-location {
    font-family: "Noto Naskh Arabic", serif; }
  .dd-location html[lang="ru"],
  html[lang="ko"] .dd-location,
  html[lang="zh-cn"] .dd-location,
  html[lang="ar"] .dd-location {
    font-style: italic; }
  .layout-reversed .dd-location {
    font-family: "bonodiStd-roman", serif;
    font-style: normal !important; }

.nav-village-link {
  text-transform: uppercase;
  color: #999 !important;
  line-height: 2rem; }

@media screen and (max-width: 64em) {
  .hide-mobile {
    display: none; } }

.search, .bag {
  position: relative;
  height: 4.1667rem;
  background: #f3f3f3; }
  @media screen and (min-width: 64em) {
    .no-touchevents .search, .no-touchevents .bag {
      position: absolute;
      top: -0.2rem;
      right: 0.7rem;
      display: inline-block;
      background: none; } }

.search-form {
  position: relative;
  width: 100%;
  height: 4rem;
  padding-right: 1.4rem;
  z-index: 999999; }
  @media screen and (max-width: 63.99em) {
    .no-touchevents .search-form label {
      color: #7b7b7b;
      font-size: 1.1667rem;
      text-transform: uppercase;
      line-height: 3rem; } }
  .touchevents .search-form label {
    color: #7b7b7b;
    font-size: 1.1667rem;
    text-transform: uppercase;
    line-height: 3rem; }
  @media screen and (min-width: 64em) {
    .no-touchevents .search-form {
      transform: translateX(21.6667rem);
      position: absolute;
      right: 0.3333rem;
      width: 21.6667rem;
      height: 2.5833rem;
      margin-right: 0;
      margin-left: 0;
      opacity: 0;
      -webkit-transition: all 0.5s ease 0.3s;
      -moz-transition: all 0.5s ease 0.3s;
      transition: all 0.5s ease 0.3s;
      background: white;
      top: 10px; }
      .no-touchevents .search-form.active {
        z-index: 50;
        transform: translateX(0); }
      .no-touchevents .search-form input,
      .no-touchevents .search-form label {
        pointer-events: none;
        cursor: default; }
      .no-touchevents .search-form.active {
        opacity: 1;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        .no-touchevents .search-form.active input,
        .no-touchevents .search-form.active label {
          pointer-events: initial; }
        .no-touchevents .search-form.active input[type="text"],
        .no-touchevents .search-form.active label {
          cursor: text; }
        .no-touchevents .search-form.active input[type="submit"] {
          cursor: pointer; } }

#search,
.search-button,
.bag-button,
#searchSubmit {
  background-color: #f3f3f3; }
  @media screen and (min-width: 64em) {
    .no-touchevents #search, .no-touchevents
    .search-button, .no-touchevents
    .bag-button, .no-touchevents
    #searchSubmit {
      background-color: transparent; } }

.search-button,
.bag-button,
#searchSubmit {
  border: 0px solid transparent;
  vertical-align: bottom; }
  .search-button, .search-button:hover, .search-button:focus,
  .bag-button,
  .bag-button:hover,
  .bag-button:focus,
  #searchSubmit,
  #searchSubmit:hover,
  #searchSubmit:focus {
    background-color: transparent; }
  .search-button:hover,
  .bag-button:hover,
  #searchSubmit:hover {
    opacity: 0.6; }

#search,
#searchSubmit {
  display: inline-block;
  vertical-align: middle; }

.search-button,
.bag-button {
  position: absolute;
  z-index: 100;
  display: block; }
  @media screen and (max-width: 63.99em) {
    .no-touchevents .search-button, .no-touchevents
    .bag-button {
      position: absolute;
      top: 0;
      right: 1.2rem; } }
  .touchevents .search-button, .touchevents
  .bag-button {
    position: absolute;
    top: 0;
    right: 1.2rem; }
  .search-button:before,
  .bag-button:before {
    font-size: 1.7rem;
    line-height: 50px;
    color: #b1b1b1; }

.bag-button > span {
  display: none; }

.bag-button > label {
  position: absolute;
  color: red;
  margin-top: -4px;
  top: 50%;
  text-align: center;
  cursor: pointer !important;
  width: 24px; }

.bag-button > label:lang(ru) {
  margin-top: -5px; }

.bag-button > label:lang(zh-cn) {
  margin-top: -6px; }

.bag-button > label:lang(ar) {
  margin-top: -5px;
  left: 0px;
  right: 1.4rem; }

.bag-button > label:lang(ko) {
  margin-top: -5px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .bag-button > label:lang(ru) {
    margin-top: -7px; }
  .bag-button > label:lang(ar) {
    margin-top: -4px; }
  .bag-button > label:lang(ko) {
    margin-top: -6px; } }

@supports (-ms-ime-align: auto) {
  .bag-button > label:lang(zh-cn) {
    margin-top: -5px; }
  .bag-button > label:lang(ru) {
    margin-top: -6px; }
  .bag-button > label:lang(ar) {
    margin-top: -5px;
    left: 0px; } }

@media screen and (max-width: 63.99em) {
  .no-touchevents .bag-button, .touchevents .bag-button {
    position: absolute;
    top: 0;
    left: 1.2rem;
    padding: 0 !important; }
    .no-touchevents .bag-button > span, .touchevents .bag-button > span {
      display: inline;
      font-size: 1.1rem;
      color: #7b7b7b;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
      top: -5px;
      padding-left: 5px; } }

@media screen and (min-width: 64em) {
  .touchevents .bag-button {
    position: absolute;
    top: 0;
    left: 1.2rem;
    padding: 0 !important; }
    .touchevents .bag-button > span {
      display: inline;
      font-size: 1.1rem;
      color: #7b7b7b;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
      top: -5px;
      padding-left: 5px; } }

.touchevents .bag-button {
  position: absolute;
  top: 0;
  right: 0; }

@media screen and (max-width: 63.99em) {
  .no-touchevents .layout-reversed .bag-button > span, .touchevents .layout-reversed .bag-button > span {
    padding-right: 5px; } }

@media screen and (min-width: 64em) {
  .touchevents .layout-reversed .bag-button > span {
    padding-right: 5px; } }

#search {
  padding-left: 0; }
  @media screen and (max-width: 63.99em) {
    .no-touchevents #search {
      border-bottom-width: 0;
      font-size: 1.1667rem; } }
  .touchevents #search {
    border-bottom-width: 0;
    font-size: 1.1667rem; }
  @media screen and (min-width: 64em) {
    .no-touchevents #search {
      margin-left: 0;
      width: 19.0833rem;
      box-shadow: -16px 0 10px #fff; } }

#searchSubmit {
  position: absolute;
  top: 1.15rem;
  left: -0.4rem;
  text-indent: -9999px;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  @media screen and (max-width: 63.99em) {
    .no-touchevents #searchSubmit {
      width: 2rem; } }
  .touchevents #searchSubmit {
    width: 2rem; }
  @media screen and (min-width: 64em) {
    .no-touchevents #searchSubmit {
      top: 0.25rem;
      right: -0.3333rem;
      left: auto; } }

.topnav-social-media .dropdown-container {
  position: relative; }

@media screen and (min-width: 64em) {
  .no-touchevents .topnav-social-media {
    position: absolute;
    top: -1.9167rem;
    right: calc((100vw - 960px)/2);
    margin-left: 0;
    z-index: 5000;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
    .no-touchevents .topnav-social-media .social-subnav-trigger {
      display: none; }
    .no-touchevents .topnav-social-media .dropdown-menu {
      display: block; }
    .no-touchevents .topnav-social-media .social-navItem {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1rem; }
    .no-touchevents .topnav-social-media .social-linkItem {
      color: #fff; }
      .no-touchevents .topnav-social-media .social-linkItem, .no-touchevents .topnav-social-media .social-linkItem:focus {
        opacity: 0.6; }
      .no-touchevents .topnav-social-media .social-linkItem:hover {
        opacity: 1; }
      .no-touchevents .topnav-social-media .social-linkItem span {
        display: none; }
      .no-touchevents .topnav-social-media .social-linkItem:before {
        font-size: 1.4rem;
        margin: 0; }
      .no-touchevents .topnav-social-media .social-linkItem img {
        display: inline-block;
        width: 1.5rem; } }

.topnav-social-media .social-subnav-trigger {
  text-transform: uppercase; }

.login-menu {
  display: inline-block; }

.login-menu {
  border-left: 1px solid #e5e5e5;
  padding-left: 1.6667rem;
  line-height: 1.3; }
  @media screen and (min-width: 64em) {
    .no-touchevents .login-menu {
      border-left-width: 0;
      padding-left: 0; }
      .no-touchevents .login-menu > a {
        display: none; } }

.link-label {
  min-width: 150px; }

#login {
  display: none; }
  #login > .dropdown-container > a {
    color: #fff; }
  @media screen and (min-width: 64em) {
    .no-touchevents #login {
      display: inline-block; }
      .no-touchevents #login .dropdown-menu {
        position: absolute;
        right: -1.5rem;
        left: auto;
        z-index: 200;
        width: 26rem;
        padding: 1.25rem;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        border-top: 0; }
        .no-touchevents #login .dropdown-menu .button {
          border-color: #008a9d;
          background-color: #008a9d;
          color: #fff;
          color: #fff; }
          .no-touchevents #login .dropdown-menu .button:hover, .no-touchevents #login .dropdown-menu .button:focus, .no-touchevents #login .dropdown-menu .button:active {
            color: #000;
            background-color: transparent; }
        .no-touchevents #login .dropdown-menu a:after {
          display: none; }
      .no-touchevents #login .button {
        font-size: 1rem;
        margin-bottom: 0.75rem; } }

.footer-content-wrapper {
  padding-bottom: 0;
  background: #251f25; }
  .footer-content-wrapper.page-wrapper-wide {
    padding-top: 2.5rem !important; }

.footer-content {
  color: #fff; }
  .footer-content h4 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.6667rem;
    font-family: "brown-regular", sans-serif;
    text-transform: uppercase; }
    html[lang="ru"] .footer-content h4 {
      font-family: "Noto Sans", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ko"] .footer-content h4 {
      font-family: "Noto Sans KR", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="zh-cn"] .footer-content h4 {
      font-family: "Noto Sans SC", "brown-regular", "bonodiBe-medium", sans-serif; }
    html[lang="ar"] .footer-content h4 {
      font-family: "Noto Kufi Arabic", "brown-regular", "bonodiBe-medium", sans-serif; }
    @media screen and (min-width: 64em) {
      .footer-content h4 {
        font-size: 1.1667rem; } }
    @media screen and (min-width: 40em) {
      .footer-content h4 {
        margin-top: 0;
        margin-bottom: 1.4rem;
        font-size: 1.3333rem; } }
    .footer-content h4 a {
      color: #8a8a8a;
      font-size: 1.3333rem;
      text-transform: none;
      letter-spacing: normal; }
      @media screen and (min-width: 40em) {
        .footer-content h4 a {
          margin-top: 0;
          margin-bottom: 1.4rem; } }
      @media screen and (min-width: 64em) {
        .footer-content h4 a {
          font-size: 1.1667rem; } }
  .footer-content ul {
    margin-bottom: 1rem; }
    @media screen and (min-width: 64em) {
      .footer-content ul.f-sm-legal-list, .footer-content ul.f-sm-corp-list {
        margin-bottom: 2.1rem; } }
  .footer-content li {
    font-size: 1.3333rem; }
    @media screen and (min-width: 64em) {
      .footer-content li {
        font-size: 1.1667rem; } }
  .footer-content .content-wrapper {
    max-width: 95rem;
    margin-left: auto;
    margin-right: auto; }
    .footer-content .content-wrapper::after {
      clear: both;
      content: "";
      display: table; }
  .microsite .footer-content .opening-hours {
    width: 100%; }
    @media screen and (min-width: 64em) {
      .microsite .footer-content .opening-hours {
        width: 30%; } }
  .layout-reversed .footer-content .opening-hours {
    float: none; }
    @media screen and (min-width: 40em) {
      .layout-reversed .footer-content .opening-hours {
        float: right; } }
  @media screen and (min-width: 64em) {
    .footer-content .opening-hours {
      padding-right: 3.3333rem; } }
  .footer-content .opening-hours ul {
    max-width: 23rem; }
    .layout-reversed .footer-content .opening-hours ul {
      float: none; }
      @media only screen and (min-width: 32.5em) {
        .layout-reversed .footer-content .opening-hours ul {
          float: right; } }
    @media screen and (min-width: 40em) and (max-width: 49.99em) {
      .footer-content .opening-hours ul {
        float: none; } }
    @media screen and (min-width: 40em) {
      .footer-content .opening-hours ul {
        float: left;
        width: 66.667%; } }
    @media screen and (min-width: 50em) and (max-width: 63.99em) {
      .footer-content .opening-hours ul {
        float: left; }
        .layout-reversed .footer-content .opening-hours ul {
          float: right !important; } }
    @media screen and (min-width: 64em) {
      .footer-content .opening-hours ul {
        float: none;
        width: 100%; } }
  .footer-content .opening-hours .hours {
    padding-top: 0.2rem;
    padding-right: 1.8rem;
    text-align: right; }
    .layout-reversed .footer-content .opening-hours .hours {
      text-align: left; }
    .footer-content .opening-hours .hours span {
      width: 3.3333rem;
      display: inline-block;
      word-spacing: normal; }
      @media screen and (min-width: 50em) {
        .footer-content .opening-hours .hours span {
          width: 2.9167rem; } }
    .layout-reversed .footer-content .opening-hours .hours {
      padding-right: 0;
      padding-left: 1.8rem;
      text-align: left; }
    @media screen and (min-width: 64em) {
      .footer-content .opening-hours .hours {
        font-size: 1.1rem; } }
  .footer-content .tooltip-wrapper {
    display: none; }
  .footer-content .opening-time-extra {
    color: #fff;
    width: 100%;
    max-width: 44rem; }
    @media screen and (min-width: 64em) {
      .footer-content .opening-time-extra {
        width: 44rem;
        margin-top: 2rem; } }
  .footer-content > .opening-time-extra {
    display: none; }
    @media screen and (min-width: 64em) {
      .footer-content > .opening-time-extra {
        display: block; } }
  .footer-content > ul {
    display: none; }

.opening-hours::after {
  clear: both;
  content: "";
  display: table; }

.opening-hours ul {
  width: 100%; }
  @media only screen and (min-width: 32.5em) and (max-width: 39.99em) {
    .opening-hours ul {
      float: left;
      display: block;
      margin-right: 0%;
      width: 50%; }
      .opening-hours ul:last-child {
        margin-right: 0; }
      .layout-reversed .opening-hours ul {
        float: none; } }

.opening-hours li {
  display: table;
  width: 100%;
  padding-bottom: 0.37rem; }

.opening-hours .day,
.opening-hours .hours {
  display: table-cell; }
  .layout-reversed .opening-hours .day, .layout-reversed
  .opening-hours .hours {
    float: right;
    width: 50%; }

@media screen and (min-width: 64em) {
  .opening-hours .opening-time-extra {
    display: none; } }

.opening-time-extra {
  clear: both; }
  .opening-time-extra,
  .opening-time-extra + p {
    margin-top: 1.5rem;
    font-size: 0.95rem; }

.f-site-map {
  margin-top: 1.6rem; }
  .f-site-map::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 50em) {
    .f-site-map {
      margin-right: 0; }
      .microsite .f-site-map {
        width: 100%; } }
  @media screen and (min-width: 64em) {
    .f-site-map {
      margin-top: 0; }
      .microsite .f-site-map {
        width: 70%; } }
  .f-site-map li {
    padding-bottom: 0.1rem; }

@media only screen and (min-width: 32.5em) and (max-width: 39.99em) {
  .f-site-map-col {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    .f-site-map-col:last-child {
      margin-right: 0; }
    .f-site-map-col:nth-child(2n) {
      margin-right: 0; }
    .f-site-map-col:nth-child(2n+1) {
      clear: left; } }

@media screen and (min-width: 64em) {
  .f-site-map-col.last {
    margin-right: 0; } }

@media screen and (min-width: 40em) {
  .f-site-map-col.last .f-site-map-group {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .f-site-map-col.last .f-site-map-group, .f-site-map-col.last .f-site-map-group.last {
    width: 100%;
    float: none; } }

.layout-reversed .f-site-map-col, .layout-reversed .f-site-map-col.last {
  float: none;
  width: 100%; }
  @media screen and (min-width: 40em) {
    .layout-reversed .f-site-map-col, .layout-reversed .f-site-map-col.last {
      width: 33.3333%;
      float: right; } }

.f-site-map-col:nth-child(3) {
  margin-bottom: 2rem; }
  @media screen and (min-width: 40em) {
    .f-site-map-col:nth-child(3) {
      margin-right: 0; } }
  @media screen and (min-width: 50em) {
    .f-site-map-col:nth-child(3) {
      margin-bottom: 0; } }
  @media screen and (min-width: 50em) and (max-width: 63.99em) {
    .f-site-map-col:nth-child(3) {
      margin-right: 0; } }

.f-site-map-group {
  width: 100%;
  padding-right: 0.8333rem; }
  .layout-reversed .f-site-map-group {
    padding-right: 0;
    padding-left: 0.8333rem; }
  .f-site-map-group ul {
    margin-bottom: 2.1rem; }

@media screen and (min-width: 64em) {
  .f-sm-register-form p,
  .f-sm-register-form .form-single-item {
    width: 100%;
    float: none; } }

.f-sm-register-form p {
  color: #8a8a8a;
  font-size: 1.3333rem; }
  @media screen and (min-width: 40em) and (max-width: 49.99em) {
    .f-sm-register-form p {
      font-size: 1.1rem; } }
  @media screen and (min-width: 40em) {
    .f-sm-register-form p {
      padding-right: 1rem; } }
  @media screen and (min-width: 64em) {
    .f-sm-register-form p {
      font-size: 1.1667rem; } }

.f-sm-register-form .form-single-item {
  max-width: 21.2rem;
  margin-top: 1.6rem; }
  @media screen and (min-width: 40em) and (max-width: 49.99em) {
    .f-sm-register-form .form-single-item {
      margin-top: -2rem; } }
  @media screen and (min-width: 40em) {
    .f-sm-register-form .form-single-item {
      margin-top: -1.6rem; } }
  @media screen and (min-width: 64em) {
    .f-sm-register-form .form-single-item {
      margin-top: 1.6rem; } }

.link-out > span {
  white-space: nowrap; }

.link-out b {
  color: #7b7b7b;
  margin-left: 0.4167rem; }
  .link-out b:before {
    font-size: 0.8333rem; }
  .layout-reversed .link-out b {
    margin-right: 0;
    margin-left: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }

.footer-extra-wrapper {
  padding-bottom: 2rem;
  background: #251f25;
  line-height: 1rem;
  margin-top: -0.0833rem; }
  @media screen and (min-width: 50em) {
    .footer-extra-wrapper {
      padding-bottom: 4rem; } }
  .footer-extra-wrapper.page-wrapper-wide {
    padding-top: 2.5rem !important;
    padding-bottom: 1.5rem !important; }
  .footer-extra-wrapper .breakWord {
    word-break: break-word; }
  .footer-extra-wrapper .footerTextRight {
    text-align: left; }
    @media screen and (min-width: 40em) {
      .footer-extra-wrapper .footerTextRight {
        text-align: right; } }
  .footer-extra-wrapper .link-out > span {
    white-space: unset; }

@media only screen and (min-width: 32.5em) {
  .footer-legal,
  .chickoutlet-link {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    .footer-legal:last-child,
    .chickoutlet-link:last-child {
      margin-right: 0; } }

.footer-legal {
  padding-top: 1rem;
  color: #fff;
  line-height: 1.5; }
  @media only screen and (min-width: 32.5em) {
    .footer-legal {
      padding-top: 0; }
      .layout-reversed .footer-legal {
        float: right; } }
  .footer-legal p {
    margin-bottom: 1.5rem; }
    @media only screen and (min-width: 32.5em) {
      .footer-legal p {
        margin-bottom: 0; } }
    .microsite .footer-legal p {
      margin-bottom: 0.75rem; }
      @media only screen and (min-width: 32.5em) {
        .microsite .footer-legal p {
          margin-bottom: 0.75rem; } }

.chickoutlet-link {
  font-size: 1.3333rem; }
  @media only screen and (min-width: 32.5em) {
    .chickoutlet-link {
      margin-top: -0.2rem;
      text-align: right; }
      .layout-reversed .chickoutlet-link {
        float: left;
        text-align: left; } }

.icp-number .icp {
  color: #ffffff;
  font-size: 12px;
  display: inline-block;
  margin: 0;
  line-height: 20px;
  vertical-align: middle; }

.icp-number .margin-left {
  margin-left: 2rem;
  color: #ffffff; }

.icp-number a {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  position: relative;
  margin-left: 10px;
  width: auto; }

.icp-number a p {
  float: left;
  margin: 0 0 0 5px;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px; }

.icp-number a img {
  float: left; }

.slider-villages-wrapper {
  margin-bottom: 0;
  background: #f0f0f0; }
  .slider-villages-wrapper .slider-manual:before, .slider-villages-wrapper .slider-manual:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 20;
    display: block;
    width: 1.6667rem;
    height: 100%;
    background-repeat: repeat-y; }
    @media screen and (min-width: 50em) {
      .slider-villages-wrapper .slider-manual:before, .slider-villages-wrapper .slider-manual:after {
        width: 10rem; } }
  .slider-villages-wrapper .slider-manual:before {
    left: 0;
    background-image: -webkit-linear-gradient(left, #f0f0f0 0%, rgba(240, 240, 240, 0.8) 49%, rgba(240, 240, 240, 0.8) 50%, rgba(240, 240, 240, 0) 100%);
    background-image: linear-gradient(to right, #f0f0f0 0%, rgba(240, 240, 240, 0.8) 49%, rgba(240, 240, 240, 0.8) 50%, rgba(240, 240, 240, 0) 100%); }
  .slider-villages-wrapper .slider-manual:after {
    right: 0;
    background-image: -webkit-linear-gradient(left, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.78) 49%, rgba(240, 240, 240, 0.8) 50%, #f0f0f0 100%);
    background-image: linear-gradient(to right, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.78) 49%, rgba(240, 240, 240, 0.8) 50%, #f0f0f0 100%); }
  .slider-villages-wrapper .slider-manual-title {
    text-align: center; }

.slide-village {
  height: 8rem; }
  .slide-village a {
    position: relative;
    display: block;
    height: inherit;
    margin-right: 1rem;
    margin-left: 1rem;
    padding-bottom: 1.8rem; }

.slide-village-image {
  max-height: 6.6rem; }

.slide-village-location {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: #303030;
  font-size: 1.0833rem; }

/**
 * @Contact Accordion Functionality
 */
.contact-accordion > .row .module-free-text-header, .contact-accordion > .accordion-content-2col-row .module-free-text-header {
  margin-top: 3rem;
  margin-bottom: 2rem; }

.contact-accordion .accordion-wrap > .accordion-content {
  display: none;
  max-width: 100%; }

.contact-accordion .first > .accordion-content {
  display: block; }

.page-maintenance_cos .main-header .nav-container {
  position: relative; }
  .page-maintenance_cos .main-header .nav-container .logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    .page-maintenance_cos .main-header .nav-container .logo-container .village-logo {
      height: 50px;
      margin-top: 2px; }

.maintenance .site-canvas {
  padding-top: 0; }

.maintenance .page-maintenance_cos {
  margin-top: 0; }

.maintenance .main-header {
  height: auto;
  border-bottom: 30px solid #008a9d;
  position: relative; }

.maintenance .header-logo {
  padding: 30px 0 0;
  text-align: center;
  max-width: 340px;
  margin: 0 auto; }
  .maintenance .header-logo img {
    margin: 0 auto; }
