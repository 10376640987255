.slider-villages-wrapper {
	margin-bottom: 0;
	background: #f0f0f0;

	.slider-manual {

		&:before,
		&:after {
			content: '';
			@include position(absolute, 0 null);
			z-index: 20;
			display: block;
			width: rem(20);
			height: 100%;
			background-repeat: repeat-y;

			@include media($medium) {
				width: rem(120);
			}
		}

		&:before {
			left: 0;

			@include background-image(linear-gradient(left,  rgba(240,240,240,1) 0%, rgba(240,240,240,0.8) 49%, rgba(240,240,240,0.8) 50%, rgba(240,240,240,0) 100%));
		}
		&:after {
			right: 0;

			@include background-image(linear-gradient(left,  rgba(240,240,240,0) 0%, rgba(240,240,240,0.78) 49%, rgba(240,240,240,0.8) 50%, rgba(240,240,240,1) 100%));
		}
	}

	.slider-manual-title {
		text-align: center;
	}
}

.slide-village {
	height: 8rem;

	a {
		position: relative;
		display: block;
		height: inherit;
		@include margin(null 1rem);
		padding-bottom: 1.8rem;
	}
}

.slide-village-title {
	@extend .hide;
}

.slide-village-image {
	max-height: 6.6rem;
}

.slide-village-location {
	@include position(absolute, null 0 0 0);
	color: #303030;
	font-size: rem(13);
}
