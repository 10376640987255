.carousel-fancy-pagination {

	.slick-dots,
	.flickity-page-dots {
		display: inline-block;
		@include margin(0 auto);

		li,
		.dot {
			display: inline-block;
			@include padding(null rem(2));
		}

		button,
		.dot {
			display: inline-block;
			border: none;
			color: #999;
			@include padding(0);
			@include transition(all 0.4s ease);
			font-size: 1rem;

			@include media($large) {
				@include transition-duration(0.6s);
			}

			&:hover,
			&:focus,
			&:active {
				background: transparent;
				outline: none;
			}

			&:after {
				content: '';
				display: inline-block;
				width: 0;
				vertical-align: middle;
				border-top: 1px solid #999;
				@include transition(all 0.4s ease);

				@include media($large) {
					@include transition-duration(0.6s);
				}
			}
		}

		.slick-active button,
		.dot.is-selected {
			color: $teal;

			&:after {
				width: 6rem;
				@include margin(0 0.5rem 0 0.4rem);
				border-top-color: #000;
			}
		}
	}

	.flickity-page-dots {
		position: static;
		margin-top: 1.6rem;

		.dot {
			width: auto;
			height: auto;
			@include margin(null rem(4));
			background: none;
			opacity: 1;

			&:nth-child(1):before {
				content: '1'
			}
			&:nth-child(2):before {
				content: '2'
			}
			&:nth-child(3):before {
				content: '3'
			}
			&:nth-child(4):before {
				content: '4'
			}
			&:nth-child(5):before {
				content: '5'
			}
			&:nth-child(6):before {
				content: '6'
			}
			&:nth-child(7):before {
				content: '7'
			}
			&:nth-child(8):before {
				content: '8'
			}
			&:nth-child(9):before {
				content: '9'
			}
			&:nth-child(10):before {
				content: '10'
			}
		}
	}
}