.ui-timepicker-wrapper {
    z-index: 10001;
    height: rem(150);
    width: calc(100% - 104px);
    @extend %m-none;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow:0 5px 10px rgba(0,0,0,0.2);
    outline: none;

    @include media($small) {
        max-width: rem(245);
    }

    &.ui-timepicker-with-duration {
        width: 13em;

        &.ui-timepicker-step-30,
        &.ui-timepicker-step-60 {
            width: 11em;
        }
    }
}

.ui-timepicker-list {
    @extend %m-none;
    @extend %p-none;
    list-style: none;
}

.ui-timepicker-duration {
    margin-left: 5px;
    color: #888;
}

.ui-timepicker-list li {
    @extend %m-none;
    @include padding(rem(3) 0 rem(3) rem(5));
    cursor: pointer;
    white-space: nowrap;
    color: #000;
    list-style: none;
}

.ui-timepicker-list:hover .ui-timepicker-selected {
    background: #fff;
    color: #000;
}

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
    background: $block-medium-gray;
    color: #fff;
}

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
    color: #ccc;
}

.ui-timepicker-list:hover .ui-timepicker-duration,
.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
    color: #888;
    cursor: default;
}

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
    background: #f2f2f2;
}
