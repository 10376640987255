.module-new,
.component-wrapper-new,
.content-list-2-col,
.slider.partners,
.module-image {
	position: relative;
	@extend %m-b-xl;
	@include base-font-family;

	.accordion-tab-content & {
		margin-bottom: rem(20);
	}

	&.variable-height {
		height: auto;
	}

	&:not(.hero-products) {
		p {
			color: #000;
			font-size: 1.17rem;
			line-height: 1.4;
		}
	}

	&.module-may-like-new {
		margin-bottom: 0;
	}
}

.grey-wrapper-title {
	position: relative;
	@include base-font-family;
	text-transform: uppercase;
	font-size: 1.33rem;
	letter-spacing: 0.05rem;
	text-align: center;
}

.module-title,
.module-text-header {
	color: $teal;
	font-family: $brown-regular;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	overflow: hidden;
	text-overflow: ellipsis;
}

.module-title-deco {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		display: block;
		height: 1px;
		background: #000;
	}
}

.p-title-border-short {
	text-align: center;

	p {
		position: relative;
		display: inline-block;
		margin-bottom: 1.8rem;
		padding-bottom: 1.4rem;
		color: #000;
		font-size: 1.75rem;
		text-transform: uppercase;
		letter-spacing: 0.2rem;

		.layout-reversed & {
			letter-spacing: 0;
		}

		&:after {
			content: '';
			@include position(absolute, null null 0 50%);
			width: rem(196);
			height: rem(1);
			margin-left: rem(-98);
			background: #b2b2b2;
		}
	}
}

.module-hero-new,
.module-offer-new {
	text-align: center;
}

.module-hero-new,
.module-promo-new {

	.module-content-wrapper {
		max-width: 88rem;
	}
}

.crosslinks-wrapper {

	&.module-widget-carousel-crosslinks {

		.flickity-prev-next-button {
			display: none !important;
		}

		@include media($small-only) {
			.module-content-wrapper {
				height: 19.6rem;
			}
		}
	}
}

.module-img-content,
.component-img-content {
	position: relative;

	.module-image {
		position: relative;
		z-index: 1;
		background-color: $teal;
		@extend %bg-cover;

		.logos-wrapper & {
            background-size: contain;
            background-position: top center;
            background-repeat: no-repeat;
            border: 1px solid #b7b7b7;
		}
	}
}

// old
.module-background {
	@extend %bg-cover;
}
// / old

.module-text-header {
	font-size: 2rem;

	@include media($medium) {
		font-size: 2.83rem;
	}
}

.module-text-subheader {
	margin-bottom: 1rem;
	text-transform: none;

	color: $dark-gray !important;
	@include font-family-serif-roman;
	font-size: 14(rem)!important;

	span {
		text-transform: none;
		color: #000;
		@include font-family-serif-roman;
		letter-spacing: 0;

		&.module-text-subheader-days-right {
			float: right;

			.layout-reversed & {
				float: left;
			}
		}
	}
}

.module-text-subheader,
.module-text-subheader-light {
	color: $dark-gray !important;
	@include font-family-serif-roman;
	letter-spacing: 0.1rem;
	font-size: 14(rem)!important;
}

.module-find-out-more {

	position: relative;
	padding-right: 2.4rem;
	color: #000;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	line-height: 1.2rem;
	font-size: 1.2rem;
	height:1.4rem;

	a.span{
		    bottom: rem(-47);
	}

	&:before {
		@include webfont-styles;
		@include position(absolute, null 0 0 null );
		line-height: 1.4rem;
		font-size: 1.4rem;
		height:1.4rem;
		padding-top: 0;

	}

	.layout-reversed & {
		padding-right: 0;
		padding-left: 2.4rem;

		.iOS & {
			padding-left: 0.8rem;
		}

		&:before {
			right: auto;
			left: 0;
			transform: rotate(180deg);
			line-height: 1.6rem;
		}
	}
}

.widget-carousel-new {

	margin-bottom: -1.6rem;

	&:after {
		content: 'flickity';
		display: none;
	}

	&.flickity-enabled .carousel-cell {
		padding-bottom: 1.6rem;
	}

	.touch & {

		.grid-item {

			@include media($medium-down) {
				width: 42%;
			}
			@include media($xsmall-down) {
				width: 80%;
			}
			@include media($medium-small) {
				width: 33%;

			}

		}
	}

	.no-touchevents .crosslinks-wrapper & {

		.grid-item {
			@include media($small-down) {
				width: 42%;
			}
			@include media($xsmall-down) {
				width: 80%;
			}
		}
	}

	@include media($medium) {

		&.carousel-disable-large {
			@include clearfix;
		}
	}

	@include media($large) {

		&.carousel-disable-xlarge {
			@include clearfix;
		}
	}
}

.component-latest-carousel {

	@include media($medium) {

		&.component-latest.carousel-set.arrows-hidden-large {

			.component-latest-footer .section-title-viewall {
				right: 0;

				.layout-reversed & {
					right: auto;
					left: 0;
				}
			}
		}
	}

	@include media($large) {

		&.component-latest.carousel-set.arrows-hidden-xlarge {

			.component-latest-footer .section-title-viewall {
				right: 0;

				.layout-reversed & {
					right: auto;
					left: 0;
				}
			}
		}
	}
}

.module-widget-carousel-wrapper {

	@include fancy-carousel-arrows-flickity;

	.flickity-prev-next-button {
		top: -4.6rem;
		height: 40px;

		@include media($xsmall-down) {
			display: none !important;
		}

		&:before {
			top: 16px;
		}

		&:after {
			top: 21px;
		}
		&.previous {
			right: 4rem;
			left: auto;

			&:before,
			&:after {
				left: 0.6rem;
			}

			.layout-reversed & {
				right: auto;
				left: 0.6rem;
			}
		}

		&.next {
			right: 0.6rem;
			border-left: 1px solid #000;

			&:before,
			&:after {
				right: 0.6rem;
			}

			.layout-reversed & {
				right: auto;
				left: 4rem;
			}
		}
	}


	&.module-widget-carousel-news,
	&.module-inspiration-new,
	&.module-widget-carousel-may-like {

		.flickity-prev-next-button {
			top: -6.5rem;
		}
	}
}

.widget-carousel-news-feed {

	.flickity-prev-next-button {
		display: none !important;
	}
}

.ellipsis-new {
	overflow: hidden;
}

.section-title-big-wrapper {
	margin-bottom: 3rem;

	@include media($medium) {
		margin-bottom: 5.2rem;
	}
}

.section-title-big,
.section-title-big-wrapper .section-subtitle-big {
	// max-width: 28rem;
	margin-bottom: 0;
	font-size: 3rem;
    @include heading-light-font-family;
    text-transform: uppercase;
	line-height: 1;
	letter-spacing: 0.4rem;

	@include media($small) {
		font-size: rem(44);
	}
}

.component-latest {
	position: relative;

	.section-title-viewall {
		@include margin(null auto);
	}
}

.component-latest-footer {
	@include margin(null null 2.5rem);
	text-align: center;
		margin-bottom: 2.5rem;

	@include media($medium) {
		text-align: right;
		margin: 0;

		.section-title-viewall {
			@include padding(0);
			border: none;
			color: #535353;
			font-size: 1rem;
			@include base-font-family;
			text-align: right;
			text-transform: uppercase;

			@include media($medium) {
				@include position(absolute, 0.8rem 0 null null);

				.layout-reversed & {
					right: auto;
					left: 0;
				}
			}

			&:hover,
			&:focus {
				background: none;
				opacity: 0.6;
			}
		}
	}
}


div.widget.grid-item:not(.grid-2, .grid-3){


	.component-latest-footer {

		margin-bottom: 2.5rem;
		text-align: center;
		margin-right: auto;
		margin-left: auto;

		.section-title-viewall{

			right:0;
			display: inline-block;
			padding: 1.2rem 2.6rem 1rem;
			top: 40px;
			margin-top:0;
			-webkit-appearance: none;
			color: #000;
			@include base-font-family;
			font-size: 1.17rem;
			font-weight: normal;
			line-height: 1;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: 0.1rem;
			vertical-align: middle;
			-webkit-font-smoothing: antialiased;
			background-color: transparent;
			border-radius: 0;
			border: 1px solid #000;
			cursor: pointer;
			position: relative;

			.layout-reversed & {
				right: auto;
				left: 0;
			}

		}
	}
}

@include media($xsmall-down) {
	.component-latest .section-title-wrapper .section-title {
		display: block;
		text-align: center;
	}

    .image-widget {
        margin-bottom: 2rem;
    }
}

@include media($medium) {
	.component-latest-carousel.carousel-set .component-latest-footer .section-title-viewall {
		right: 9rem;

		.layout-reversed & {
			right: auto;
			left: 9rem;
		}

		.component-editorial-new & {
			right: 0;

			.layout-reversed & {
				right: auto;
				left: 0;
			}
		}
	}
}
