
.accordion-tabs {
	position: relative;
	height: auto;
	margin-bottom: rem(60);

	.accordion-tab-trigger,
	.tab-trigger {
		height: 5.2rem;
		padding: rem(15);
		background-color: #fff;
		cursor: pointer;

		&.active {
			background-color: $teal;
			border-color: $teal;
			pointer-events: none;

			h2 {
				color: #fff;

				&:after {
					content: '–';

					@include media($medium) {
						content: '';
					}
				}
			}
		}

		&:focus {
			opacity: 1;
		}

		h2 {
			@include position(relative, 50% null null);
			transform: translateY(-50%);

			color: rgba(0,0,0,0.6);
			font-size: rem(16);
			@include base-font-family;
			text-transform: uppercase;
			letter-spacing: 0.05rem;

			&:after {
				content: '+';
				@include position(absolute, rem(-8) 0 null null);
				font-size: rem(26);

				.active & {
					content: '-';
				}
			}

			@include media($medium) {
				text-align: center;

				&:after {
					content: '';
				}
			}
		}
	}

	.accordion-tab-content {
		.accordion-trigger {
			margin-bottom: rem(10);
			@include padding(rem(16) null);
		}

		.accordion-trigger-text {
			font-size: rem(14);
			@include base-font-family;
		}

		.accordion-content {
			@extend %p-b-md;
			padding-bottom: 1.5rem;
		}

		.module-free-text-header {
			margin-bottom: 1rem;
			@include base-font-family;
			letter-spacing: 0.05rem;
		}

		p + .module-free-text-header {
			margin-top: 2rem;
		}
	}
}

.tab-triggers {
	display: none;
	height: 5.2rem;

	@include media($medium) {
		display: block;
	}
}

.tab-trigger {
	// top: 0;
	// left: 0;
	float: left;
	width: 25%;
	height: 5.2rem;
	padding: rem(15);
	border-style: solid;
	border-color: #ccc transparent transparent #ccc;
	border-width: 1px;
	cursor: pointer;

	&:last-child {
		border-color: #ccc #ccc transparent #ccc;
	}

	.layout-reversed & {
		float: right;
		left: auto;
		right: 0;
		border-color: #ccc #ccc transparent transparent;

		&:last-child {
			border-color: #ccc transparent #ccc #ccc;
		}
	}
}

.accordion-tab-content {
	width: 100%;
	height: auto;
	@include base-font-family;

	> div {
		width: 100%;
		height: 100%;
		padding: rem(10);
		border: rem(1) solid #000;
		border-bottom: none;
		background-color: white;

		@include media($medium) {
			border: rem(1) solid #000;
		}

		@include media($small) {
			padding: rem(25);
		}
	}

	.accordion-tab-wrap:last-child & > div {
		border-bottom: rem(1) solid #000;
	}

	.module-free-text-header {
		margin-bottom: rem(20);
		font-size: rem(16) !important;
		text-transform: uppercase;

		sup {
			font-size: 0.7em;
		}

		&::after {
			display: none;
		}

		@include media($medium) {

			.module-free-text-header {
				font-size: rem(20);
			}
		}
	}

	.overlay-getting-here {
		width: 100%;
		text-align: center;
		margin-bottom: rem(20);

		.button {
			display: inline-block;
		}
	}

	&[data-tab="shopping-express"] {

		.overlay-getting-here {
			margin-top: 3rem;
		}
	}
}

.accordion-tab-trigger {
	border-style: solid;
	border-color: #000;
	border-width: 1px 1px 0;
	cursor: pointer;

	.accordion-tab-wrap:last-child & {
		border-width: 1px;
	}
	@include media($medium) {
		display: none;
	}
}
// links on accordion
a {
	&.accordion-trigger {
		color: #000;
		opacity: 1;
		display: block;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		&:hover {
			opacity: 1;
		}

	}
}