// =============================================================================
//  OFF-CANVAS
// =============================================================================

// Generate @font-face declarations for a list of fonts

// relative path to fonts
$font-path: '../fonts/';
// the name that will be used in font-family property and it's file name
$font-families: (
	'brown-light':'BrownStd-Light',
	'brown-regular': 'BrownStd-Regular',
	'brown-bold': 'BrownStd-Bold',
	'bonodiBe-medium': 'BodoniBE-Medium',
	'bonodiBe-italic': 'BodoniBE-Italic',
	'bonodiStd-roman': 'BodoniStd-Roman'
);

// Let's do the loop!
@each $ff-name, $ff-file in $font-families {

	@include font-face($ff-name, $font-path + $ff-file, $file-formats: woff ttf svg);

}

// NON-EU fonts

// html[lang="ru"] - Russian
@import url(//fonts.googleapis.com/css?family=Noto+Sans); // font-family: 'Noto Sans', sans-serif;
@import url(//fonts.googleapis.com/css?family=Playfair+Display); // font-family: 'Playfair Display', serif;

// html[lang="ko"] - Korean
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css); // font-family: 'Noto Sans KR', sans-serif;
@import url(//fonts.googleapis.com/earlyaccess/kopubbatang.css); // font-family: 'KoPub Batang', serif;

// html[lang="zh-cn"] - Chinese
@import url(//fonts.googleapis.com/earlyaccess/notosanssc.css); // font-family: 'Noto Sans SC', sans-serif;
// font-family: adobe-fangsong-std, sans-serif;

// html[lang="ar"] - Arabic
@import url(//fonts.googleapis.com/earlyaccess/notokufiarabic.css); // font-family: 'Noto Kufi Arabic', sans-serif;
@import url(//fonts.googleapis.com/earlyaccess/notonaskharabic.css); // font-family: 'Noto Naskh Arabic', serif;



// font helpers
@mixin base-font-family {
	font-family: $brown-regular;

	html[lang="ru"] & {
		font-family: $noto-sans;
	}

	html[lang="ko"] & {
		font-family: $noto-sans-kr;
	}

	html[lang="zh-cn"] & {
		font-family: $noto-sans-sc;
	}

	html[lang="ar"] & {
		font-family: $noto-kufi-ar;
	}
}

@mixin base-light-font-family {
	font-family: $brown-light;

	html[lang="ru"] & {
		font-family: $noto-sans;
	}

	html[lang="ko"] & {
		font-family: $noto-sans-kr;
	}

	html[lang="zh-cn"] & {
		font-family: $noto-sans-sc;
	}

	html[lang="ar"] & {
		font-family: $noto-kufi-ar;
	}
}

@mixin heading-font-family {
	@include base-font-family;
}

@mixin heading-light-font-family {
	@include base-light-font-family;
}
/**/

@mixin base-bold-font-family {
	font-family: $brown-bold;

	@include base-font-family;

	html[lang="ru"],
	html[lang="ko"] &,
	html[lang="zh-cn"] &,
	html[lang="ar"] & {
		font-weight: 700;
	}
}


@mixin non-eu-serif {
	html[lang="ru"] & {
		font-family: $playfair-display;
	}

	html[lang="ko"] & {
		font-family: $kopub-batang;
	}

	html[lang="zh-cn"] & {
		font-family: $adobe-fangsong-std;
	}

	html[lang="ar"] & {
		font-family: $noto-naskh-arabic;
	}
}

@mixin font-family-serif {
	font-family: $bodoni-medium;

	@include non-eu-serif;
}

@mixin font-family-serif-roman {
	font-family: $bodoni-roman;

	@include non-eu-serif;
}

@mixin font-family-serif-italic {
	font-family: $bodoni-italic;

	@include non-eu-serif;

	html[lang="ru"],
	html[lang="ko"] &,
	html[lang="zh-cn"] &,
	html[lang="ar"] & {
		font-style: italic;
	}
}

html[lang="ar"] .product-price,
html[lang="ko"] .product-price,
html[lang="zh-cn"] .product-price {
    .was, .now {

        font-family:  $bodoni-medium !important;
    }
}

ul.dropdown-menu li a[href*='/ar/'] {

    font-family:  $noto-kufi-ar !important;
}
