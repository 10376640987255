// ==================================================
// GRID HELPERS
// ==================================================
// Create placeholders for all used grid mixins

$columns: (
	12: (
		'small-down': $small-down,
		'small-only': $small-only,
		'small': $small,
		'medium': $medium,
	),
	11: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	10: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	9: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	8: (
		'small-down': $small-down,
		'small-only': $small-only,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	7: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	6: (
		'small-down': $small-down,
		'small-only': $small-only,
		'small': $small,
		'medium': $medium
	),
	5: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	4: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'medium-only': $medium-only,
		'large': $large,
	),
	3: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	2: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	),
	1: (
		'small-down': $small-down,
		'small': $small,
		'medium': $medium,
		'large': $large,
	)
);

@mixin mq-col($col, $val) {
	@include media($val) {
		@include span-columns($col);
	}
}


@each $column, $map in $columns {
	%col-#{$column} {
		@include span-columns($column);
	}

	@each $name, $value in $map {
		%col-#{$column}-#{$name} {
			@include mq-col($column, $value);
		}
	}
}