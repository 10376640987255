// =============================================================================
//	HELPERS
// =============================================================================

//	Window width helper
// .window-size-helper {
// 	@include position(fixed, 0 null null 0);
// 	z-index: 600;
// 	@include padding(null rem(4));
// 	background: rgba(94,0,0,0.6);
// 	color: #fff;
// }

//	BG Images
%bg-cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

// Anchor nav
[id^="anchor-"] {
	display: block;
    visibility: hidden;

    @include media($large) {
		.no-touchevents & {
			height: 80px;
    		margin-top: -80px;
		}
	}
	@include media($medium-down) {
		.no-touchevents & {
			height: 55px;
    		margin-top: -55px;
		}
	}
	.touchevents & {
		height: 55px;
    	margin-top: -55px;
	}
}

//	Visibility
.hide {
	display: none !important;
}

%hide-text {
	overflow: hidden;
	text-indent: -9999rem;
}

.tel-link {

	&:hover {
		opacity: 1;
		cursor: default;
	}
}

.full-img {
	width: 100%;
}

%margin-center {
	@include margin(0 auto);
}

@mixin fancy-carousel-arrows {
	.slick-arrow {
		position: absolute;
		display: block !important;
		width: 40px;
		// this causes the weird margin bottom bug on chrome on windows (not always)
		// @include transform(translate3d(0,0,0) translateZ(0));
		@include transition(all 0.3s ease);
		-webkit-backface-visibility: hidden;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			border-color: #000;
			border-style: solid;
			@include transform(translateX(0) translate3d(0,0,0) translateZ(0));
			-webkit-backface-visibility: hidden;
			@include transition(all 0.3s ease);
		}

		&:before {
			width: 1rem;
			height: 1rem;
		}

		&:after {
			top: 33px;
			width: 0;
			border-width: 0.2rem 0 0;
		}

		&:not(.slick-disabled) {
			.no-touchevents & {
				&:hover {
					opacity: 1;

					&:after {
						width: 1.8rem;
					}
				}
			}
		}

		&.slick-disabled {

			opacity: 0.5;
			&:hover {
				opacity: 0.5;
			}
		}
	}

	.slick-prev {

		z-index: 3;

		&:before {
			border-width: 2px 0 0 2px;
			@include transform(rotate(-45deg));
		}

		&:not(.slick-disabled) {
			.no-touchevents & {
				&:hover {
					&:before {
						@include transform(translateX(-6px) rotate(-45deg) translate3d(0,0,0) translateZ(0));
					}
					&:after {
						@include transform(translateX(-6px) translate3d(0,0,0) translateZ(0));
					}
				}
			}
		}
	}

	.tabletReset{
		background: red;
	}

	.slick-next {
		z-index: 4;

		&:before {
			border-width: 2px 2px 0 0;
			@include transform(rotate(45deg));
		}

		&:not(.slick-disabled) {
			.no-touchevents & {
				&:hover {

					&:before {
						@include transform(translateX(6px) rotate(45deg) translate3d(0,0,0) translateZ(0));
					}
					&:after {
						@include transform(translateX(6px) translate3d(0,0,0) translateZ(0));
					}
				}
			}
		}
	}
}

@mixin fancy-carousel-arrows_inverted {
	.slick-arrow {
		position: absolute;
		display: block !important;
		width: 40px;
		// this causes the weird margin bottom bug on chrome on windows (not always)
		// @include transform(translate3d(0,0,0) translateZ(0));
		@include transition(all 0.3s ease);
		-webkit-backface-visibility: hidden;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			border-color: #000;
			border-style: solid;
			@include transform(translateX(0) translate3d(0,0,0) translateZ(0));
			-webkit-backface-visibility: hidden;
			@include transition(all 0.3s ease);
		}

		&:before {
			width: 1rem;
			height: 1rem;
		}

		&:after {
			top: 33px;
			width: 0;
			border-width: 0.2rem 0 0;
		}

		&:not(.slick-disabled) {
			.no-touchevents & {
				&:hover {
					opacity: 1;

					&:after {
						width: 1.8rem;
					}
				}
			}
		}

		&.slick-disabled {

			opacity: 0.5;
			&:hover {
				opacity: 0.5;
			}
		}
	}

	.slick-next {

		z-index: 3;

		&:before {
			border-width: 2px 0 0 2px;
			@include transform(rotate(-45deg));
		}

		&:not(.slick-disabled) {
			.no-touchevents & {
				&:hover {
					&:before {
						@include transform(translateX(-6px) rotate(-45deg) translate3d(0,0,0) translateZ(0));
					}
					&:after {
						@include transform(translateX(-6px) translate3d(0,0,0) translateZ(0));
					}
				}
			}
		}
	}

	.tabletReset{
		background: red;
	}

	.slick-prev {
		z-index: 4;

		&:before {
			border-width: 2px 2px 0 0;
			@include transform(rotate(45deg));
		}

		&:not(.slick-disabled) {
			.no-touchevents & {
				&:hover {

					&:before {
						@include transform(translateX(6px) rotate(45deg) translate3d(0,0,0) translateZ(0));
					}
					&:after {
						@include transform(translateX(6px) translate3d(0,0,0) translateZ(0));
					}
				}
			}
		}
	}
}

@mixin fancy-carousel-arrows-flickity {

	.flickity-prev-next-button {
		position: absolute;
		display: block !important;
		width: 40px;
		@include padding(1rem 1.6rem .85rem);
		border-radius: 0;
		background-color: transparent;
		@include transform(translate3d(0,0,0) translateZ(0));
		@include transition(all 0.3s ease);
		-webkit-backface-visibility: hidden;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			border-color: #000;
			border-style: solid;
			@include transform(translateX(0) translate3d(0,0,0) translateZ(0));
			-webkit-backface-visibility: hidden;
			@include transition(all 0.3s ease);
		}

		&:before {
			width: 1rem;
			height: 1rem;
		}

		&:after {
			top: 33px;
			width: 0;
			border-width: 0.2rem 0 0;
		}

		.no-touchevents & {
			&:hover {
				opacity: 1;

				&:after {
					width: 1.8rem;
				}
			}
		}

		svg {
			display: none;
		}

		&.slick-disabled {
			opacity: 0.5;

			&:hover {
				opacity: 0.5;
			}
		}

		&.previous {
			z-index: 3;

			&:before {
				border-width: 2px 0 0 2px;
				@include transform(rotate(-45deg));
			}

			.no-touchevents & {
				&:hover {

					&:before {
						@include transform(translateX(-6px) rotate(-45deg) translate3d(0,0,0) translateZ(0));
					}
					&:after {
						@include transform(translateX(-6px) translate3d(0,0,0) translateZ(0));
					}
				}
			}
			.touchevents & {
				&:active {

					&:before {
						@include transform(translateX(-6px) rotate(-45deg) translate3d(0,0,0) translateZ(0));
					}
					&:after {
						@include transform(translateX(-6px) translate3d(0,0,0) translateZ(0));
					}
				}
			}
		}

		&.next {
			z-index: 4;

			&:before {
				border-width: 2px 2px 0 0;
				@include transform(rotate(45deg));
			}

			.no-touchevents & {
				&:hover {

					&:before {
						@include transform(translateX(6px) rotate(45deg) translate3d(0,0,0) translateZ(0));
					}
					&:after {
						@include transform(translateX(6px) translate3d(0,0,0) translateZ(0));
					}
				}
			}
		}
	}
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}