.component-title-intro {

	.section-title {
		margin-bottom: 2rem;
	}

	.module-img-content {
		height: 36.3rem;
		margin-bottom: 0;
	}

	.module-image {
		height: 60%;
	}

	.module-text-subheader {
		margin-bottom: 1.2rem;
		padding-left: 0.2rem;
	}

	&:not(.module-events-list) .module-title-deco {
		margin-bottom: 0.6rem;
		padding-bottom: 0;
		padding-top: 1.2rem;

		&:before {
			background: auto;
			top: 0;
		}
	}

	.module-text-subheader-light {
		color: #9d9d9d;
	}

	.module-text-copy {

		p {
			line-height: 1.3 !important;
		}
	}

	.widget-carousel-new {
		@include margin(null -1rem);
	}
	.slick-track {
		padding-bottom: 2rem;
	}
	.slick-slide {
		@include padding(null 1rem);
	}

	&.module-events-list {

		.module-new {
			height: 32.5rem;
			@include padding(2rem);
			box-shadow: 0 0 30px rgba(0,0,0,0.12);
			background: #fff;
		}

		.module-text-subheader {
			margin-bottom: 0.6rem;
		}

		.module-text-content {

			.module-text-header {
				margin-bottom: 1.2rem;
				padding-bottom: 1.6rem;
				font-size: 1.25rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&:last-child {

				.module-title-deco {

					&:before {
						display: none;
					}
				}
			}
		}
	}
}