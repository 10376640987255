// =============================================================================
//	ALERT BAR WIDGET
// =============================================================================

.alert-bar {
	@include padding(rem(0) rem(12));
	clear: both;
}

.alert-bar-single {
	position: relative;
	display: block;
	@extend %m-b-lg;
	color: #fff;
	font-size: rem(21);
	font-family: $brown-regular;
	text-transform: uppercase;
	letter-spacing: 0.2rem;
	background-color: $teal;
	padding: rem(12);
	padding-right: rem(60);

	@include media($small) {
		text-align: center;
		padding-right: rem(12);
	}

	@include media($medium) {
		font-size: rem(24);
	}

	.layout-reversed & {
		padding-right: rem(12);

		@include media($xsmall-down) {
			padding-left: rem(60);
		}
	}

	.layout-reversed & {
		letter-spacing: 0;
	}

	.webfont-arrow-long {

		&:before {
			@include position(absolute, 50% 1.5rem null null);
			display: block;
			color: white;
			font-size: rem(24);
			font-weight: bold;
			transform: translate(0,-50%);
		}

		@include media($small) {
			white-space: nowrap;

			&:before {
				@include position(relative, rem(4) rem(-10) null null);
				display: inline-block;
				@include margin(0 null null 0);
				transform: translate(0,0);
			}
		}

		.layout-reversed & {
			@include webfont("arrow-long", "after");
			padding-right: rem(6);

			&:before {
				display: none;
				padding-right: 0.6rem;
			}

			&:after {
				@include webfont-styles;
				@include position(absolute, 50% null null 1.5rem);
				display: block;
				color: white;
				font-size: rem(24);
				font-weight: bold;
				transform: translate(0,-50%) rotate(180deg);
			}

			@include media($small) {
				white-space: nowrap;

				&:after {
					@include position(relative, rem(4) null null rem(-10));
					display: inline-block;
					@include margin(0 null null 0);
					transform: translate(0,0) rotate(180deg);
				}
			}
		}
	}
}
