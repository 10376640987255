.hero-nav-wrapper {

	& + p {
		margin-top: 3.6rem;
	}

	p + & {
		margin-top: 2.4rem;
	}
}

.hero-nav-list {
	display: none;
	text-align: center;
	margin-bottom: -1.8rem;

	@include media($medium-large) {
		display: block;
	}
}

.hero-nav-list-item {
	display: inline-block;
	margin-right: 2%;
	margin-bottom: 1.8rem;

	&:last-child {
		margin-right: 0;
	}

	a {
		@include base-font-family;
		text-transform: uppercase;
		color: #000;
		font-size: rem(14);
	}

	.layout-reversed & {
		margin-right: 0;
		margin-left: 2%;

		&:last-child {
			margin-left: 0;
		}
	}
}

.hero-nav-dropdown {
	max-width: 26rem;
	width: 84%;
    @include margin(null auto 1rem);
    text-align: center;

	@include media($medium-large) {
		display: none;
		margin-bottom: 0;
	}

	select {
		text-align: center;
		text-align-last: center;
	}
	option {
		text-align: center;
	}
}