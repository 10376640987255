.accordion-wrap {
	@extend %p-none;
	@extend %m-b-lg;

	.accordion-trigger {
		position: relative;
		cursor: pointer;
		height: auto;
		padding: 1rem;

		@include media($medium) {
			padding: 0;
			cursor: auto;
		}

		// Fake psuedo element to vertical align text
		&:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;

			@include media($medium) {
				content: none;
			}
		}
	}

	.view-more {
		@include position(absolute, 50% 1rem null null);
		margin-top: rem(-20);
		font-size: 2.1667rem;

		&:after {
			content: '+';
			display: block;
			width: 100%;
			text-align: center;
		}

		&.active {

			&:after {
				content: '–';
				margin-top: -0.1rem;
			}
		}

		@include media($medium) {
			&:after {
				display: none;
			}
		}

		.layout-reversed & {
			right: auto;
			left: 1rem;
		}
	}

	@include media($medium) {

		&.accordion-on-mobile {
			min-height: rem(370);
			margin-bottom: rem(30);
			padding: 2rem;
		}
	}
}

.accordion-trigger-text {
	display: inline-block;
	width: 90%;
	@extend %m-b-none;
	// padding-right: rem(25);
	font-size: rem(20);
	vertical-align: middle;

	@include media($medium) {
		height: auto;
		// margin-bottom: 0.75rem;
		padding-right: 0;
		font-size: rem(26);
		vertical-align: top;
	}

	&:after {
		display: none;

		@include media($medium) {
			display: block;
		}
	}
}

.accordion-content {
	display: none;
	@include padding(0 1rem 1rem);

	@include media($medium) {
		display: block;
		margin-top: 0;
		padding: 0;

		.accordion-on-mobile & {
			display: block!important;
		}
	}

	p, li {
		font-size: rem(14);
	}

	li {
		margin-bottom: 0.8rem;
	}
}

.accordion-wrap.accordion-simple {
	@extend %m-none;
	@extend %p-none;

	@include media($medium) {
		min-height: 1rem;
	}

	&.full {

		.accordion-content {
			max-width: 100%;
		}
	}

	&:first-child {
		display: block;
	}

	.accordion-trigger-text,
	.accordion-content {
		color: #fff;
	}

	.accordion-trigger-text {
		padding-right: 3.1rem;
		text-transform: uppercase;

		@include media($medium) {
			vertical-align: middle;
			font-size: 1.6667rem;
		}

		.layout-reversed & {
			padding-right: 0;
			padding-left: 3.1rem;
		}
	}

	.accordion-content {
		display: none;
		max-width: rem(800);
		@extend %m-t-none;
		@extend %m-b-none;
		padding-left: 1.2rem;

		.layout-reversed & {
			padding-left: 0;
			padding-right: 1.2rem;
		}
	}

	.accordion-trigger {
		height: auto;
		margin-bottom: rem(15);
		background: $teal;
		cursor: pointer;

		@include media($medium) {
			padding: 1rem;
		}
	}

	.view-more {
		display: block;
		margin-top: -1.6rem;
		color: #fff;

		&:after {
			content: '+';
			display: block;
			width: 100%;
			text-align: center;
		}

		&.active {

			&:after {
				content: '–';
				margin-top: -0.1rem;
			}
		}
	}
}

.open-wen-filled {

	.accordion-content{
		display: none;
	}

	.accordion-trigger {
		pointer-events: none;
	}
}

.accordion-content-2col-row {
    @extend .row;
    margin-bottom: 2rem;

    &.bigsmall {

        .accordion-content-2col-col {

            &:first-child {

                @media only screen and (min-width: 34em) {
                    @include span-columns(7);
                }

                @include media($small) {
                    @include span-columns(8);
                }
            }

            &:last-child {

                @media only screen and (min-width: 34em) {
                    @include span-columns(5);
                }

                @include media($small) {
                    @include span-columns(4);
                }
            }
        }
    }

    &.smallbig {

        .accordion-content-2col-col {

            &:first-child {

                @media only screen and (min-width: 34em) {
                    @include span-columns(5);
                }

                @include media($small) {
                    @include span-columns(4);
                }
            }

            &:last-child {

                @media only screen and (min-width: 34em) {
                    @include span-columns(7);
                }

                @include media($small) {
                    @include span-columns(8);
                }
            }
        }
    }

    &.half {

        .accordion-content-2col-col {
            &:first-child {
                @media only screen and (min-width: 34em) {
                    margin-right: 8%;
                }
            }

            @media only screen and (min-width: 34em) {
                // @include span-columns(6);
                float: left;
                display: block;
                width: 46%;
                padding-right: 0;
            }

            .layout-reversed & {
                &:first-child {
                    @media only screen and (min-width: 34em) {
                        margin-right: 0;
                        margin-left: 8%;
                    }
                }

                @media only screen and (min-width: 34em) {
                    // @include span-columns(6);
                    float: right;
                    display: block;
                    width: 46%;
                    padding-left: 0;
                }
            }
        }
    }

    &.submit-right {
        @include clearfix;

        .button {
            float: right;

            .layout-reversed & {
                float: left;
            }
        }
    }

    .button[type="submit"] {
        min-width: 16rem;
    }

    .captcha-container{
        margin: 33px 0;
    }
}

.accordion-content-2col-col {


	&:first-child {
		margin-bottom: 0.6rem;

		@media only screen and (min-width: 34em) {
			margin-bottom: 0;
			padding-right: 1.2rem;
		}
	}
	p {
		margin-bottom: 0;

		&.lead {
			font-family: $brown-bold;
		}
	}

	a {
		display: block;
		font-size: rem(15);

		&.tel {
			color: #000;
		}
	}

	textarea {
		min-height: 8.5rem;
	}

	@media only screen and (min-width: 34em) {
		.layout-reversed & {
			float: right !important;

			&:first-child {
				padding-right: 0;
				padding-left: 1.2rem;
			}
		}
	}
}

// Accordion form
.accordion-form {

	&.first {

		.accordion-content {
			display: block;
		}
	}

	@include media($medium) {
		.accordion-content {
			padding-right: 0.9rem;
		}
	}

	&.shown,
	&.always-visible{
		.accordion-content{
			display: block !important;
		}
	}
}
