.promo-editorial {
	margin-bottom: 0;

	.module-find-out-more {
		margin: 20px auto 0px;
		display: inline-block;
		border: 1px solid black;
		padding: 1.2rem 1.2rem 1rem;
		height: auto;
		.arrow-anim {
			display: none;
		}
	}

	.module-content-wrapper {
		max-width: rem(764) !important;
	}

	&.component-wrapper-new p {
		max-width: 55rem;
		text-align: left !important;

		.layout-reversed & {
			text-align: right;
		}
	}
}
