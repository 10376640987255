.section-title {
	font-size: 1.33rem;
	@include heading-light-font-family;
	text-transform: uppercase;
	letter-spacing: 0.2rem;

	.layout-reversed & {
		letter-spacing: 0;
	}
}

.section-title-wrapper {
	display: table;
	width: 100%;
	padding-bottom: 1rem;
	margin-bottom: 2.16rem;
	border-bottom: 1px solid #b2b2b2;

	.section-title {
		display: inline-block;
		margin-bottom: 0;
		font-size: 1.75rem;
		vertical-align: text-bottom;

		&.centre {
			display: block;
			text-align: center;

			@include media($medium-only) {
				text-align: left;

				.layout-reversed & {
					text-align: right;
				}
			}
		}
	}
}