.arrow-anim-wrapper {
	position: relative;
}

.arrow-anim {
	//bottom: -0.1rem;
	font-size: 1.25rem;
	width: rem(40);
	float: left;
	overflow: hidden;
	position: absolute;
	margin-left: rem(5);
	line-height: 1.5rem;
	height: 1.5rem;

    .long-arrow{
		position: relative;
		display: block;
		left: rem(-20);
		margin-top: -0.2rem;
		// left: rem(-17);
		line-height: 1.5rem;
		height: 1.5rem;

		transform: translateX(0);
		transition: transform 0.3s ease;

		svg{
			transform: translateZ(0);
			@include scale(0.5);
		}

		a:hover & {
			transform: translateX(12px);
        }
    }

    .layout-reversed & {
    	transform: rotate(180deg);
		left: rem(-24);
    }
}

// IE fixes
.lt-ie12 {
	.arrow-anim {
		// margin-left: rem(2);

		.long-arrow {
			svg{
				@include scale(0.58);
			}
		}
	}
}