.full-bleed-img-carousel-wrapper {
	position: relative;
}

.full-bleed-img-carousel {

	.carousel-cell {
		width: 100%;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.full-bleed-img-carousel-img {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.full-bleed-img-carousel-by {
	margin-top: 1.6rem;
	margin-bottom: 0;
	text-align: center;
	font-size: rem(11);

	@include media($small) {
		@include position(absolute, null 0 0 null);
		margin-right: 1.6rem;

		.layout-reversed & {
			right: auto;
			left: 0;
			margin-right: 0;
			margin-left: 1.6rem;
		}
	}

	a {
		color: #000;
		@include font-family-serif;
	}
}