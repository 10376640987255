.topnav-social-media {

	.dropdown-container {
		position: relative;
	}

	// Desktop styles
	@include media($nav-breakpoint) {
		.no-touchevents & {
			position: absolute;
		    top: rem(-23);
		    right: calc((100vw - 960px)/2);
		    margin-left: 0;
		    z-index: 5000;
		    -webkit-backface-visibility: hidden;
		    -webkit-transform: translate3d(0, 0, 0);
		    transform: translate3d(0, 0, 0);

		    .social-subnav-trigger {
				display: none;
		    }

		    .dropdown-menu {
		    	display: block;
		    }

		    .social-navItem {
		    	display: inline-block;
		    	vertical-align: middle;
		    	margin-left: 1rem;
		    }

		    .social-linkItem {
		    	color: #fff;

		    	&,
		    	&:focus {
		    		opacity: 0.6;
		    	}

		    	&:hover {
		    		opacity: 1;
		    	}

		    	span {
		    		display: none;
		    	}

		    	&:before {
		    		font-size: 1.4rem;
		    		margin: 0;
		    	}

		    	img {
		    		display: inline-block;
		    		width: rem(18);
		    	}
		    }
		}
	}

	.social-subnav-trigger {
		text-transform: uppercase;
	}
}