// =============================================================================
//	HEADER
// =============================================================================

.main-header {
	@include position(fixed, null 0);
	z-index: 1000;
	top: 0 !important;
	width: 100%;
	background: #fff;
	border-bottom: 1px solid #000;
	-webkit-backface-visibility: hidden;

	@include media($large) {
		.no-touchevents & {
			max-height: 13.8rem;
			@include padding(2rem 0 null);
			border-bottom: 1px solid #000;
			@include transition(padding 0.2s ease !important);

			&.micro-header {
				padding-top: 30px;
			}
		}
	}
}

//	Off-Canvas triggers
#navTrigger {
	@include position(absolute, 1.5rem rem(15) null null);
}

.open-close {
	@include base-font-family;
}


//	Only Desktop
@include media($large) {

	.no-touchevents {

		.nav-header {
			@include padding(null rem(46));
		}

		.close-canvas {
			display: none;
		}
	}
}

// 500 error page
.page-500 {
	.page-wrapper {
		margin-bottom: rem(25);
	}
}

@media print {
	.main-header {
		display: none;
	}

	.hero {
		display: none;
	}
}