.login-menu {
  display: inline-block;
}

.login-menu {
  border-left: 1px solid #e5e5e5;
  padding-left: rem(20);
  line-height: 1.3;
  // font-size: 1.0833rem;
  @include media($large) {
    .no-touchevents & {
      border-left-width: 0;
      padding-left: 0;

      > a {
        display: none;
      }
    }
  }
}

.link-label {
  min-width: 150px;
}

#login {
  display: none;

  > .dropdown-container {
    > a {
      color: #fff;
    }

    @if $is-opd == true {
      > a {
        padding-right: 1.8333rem;
      }
    }
  }

  @include media($large) {
    .no-touchevents & {
      display: inline-block;

      .dropdown-menu {
        @include position(absolute, null rem(-18) null auto);
        z-index: 200;
        width: rem(312);
        @include padding(1.25rem);
        background-color: #fff;
        border: 1px solid #dbdbdb;
        border-top: 0;
        // margin-top: rem(7);
        .button {
          @include button-inverted;
          color: #fff;
        }

        a {
          &:after {
            display: none;
          }
        }
      }

      .button {
        font-size: 1rem;
        margin-bottom: 0.75rem;
      }
    }
  }
}
