.credits {
	display: inline-block;
	@include padding(1.6rem null);
	border-style: solid;
	border-color: #b2b2b2;
	border-width: 1px 0;

	p {
		padding-bottom: 0;
		margin-bottom: 0;
		font-size: rem(11) !important;

		a {
			color: #000;
			@include font-family-serif;
		}
	}

	&.centre {
		width: 100%;
		max-width: 30rem;
		@include margin(null auto);
		@include padding(null 5%);
		text-align: center;
	}

	.module-hero-new & {
		margin-top: 3rem;
	}
}