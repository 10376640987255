// =============================================================================
//	DROPDOWNS
// =============================================================================

.dropdown-button {
	cursor: pointer;
	@include transition(opacity 0.2s linear);
	@extend %m-b-none;
	color: $action-color;
	font-size: rem(13);
	text-transform: uppercase;

	@include media($medium) {
		text-transform: none;
		font-size: rem(13);
	}

	&:active,
	&:focus,
	&:hover {
		opacity: 0.7;
	}

	&:active {
		outline: none;
	}

	// Lots of cheveron rotation stuff...
	&.webfont-cheveron {
		display: block;

		@include media($large) {
			padding-right: rem(22);
		}

		&:before {
			display: inline-block;
			margin-left: rem(7);
			font-size: 0.6rem;
			color: $action-color;
			@include transition(transform 0.2s linear);
			@include transform( rotate(0deg) );
			@include transform-origin(center 2px);
			@include position(absolute, rem(19) rem(-2) auto auto);

			@include media($large) {
				top: 1rem;
			}
		}

		&.active {
			&:before {
				@include transform(rotate(-180deg));

				// @include media($large) {
				// 	top: 0.4rem;
				// }
			}
		}

		// Reverse layout
		.layout-reversed & {

			@include media($large) {
				padding-right: 0;
				padding-left: rem(22);
			}

			&:before {
				right: auto;
				left: rem(-10);
				margin-right: rem(7);
				margin-left: 0;
				// @include transform(rotate(90deg));

				@include media($large) {
					top: 1rem;
					left: 0;
					// @include transform(rotate(0deg));
				}
			}
			&.active {
				&:before {
					@include transform(rotate(180deg));
				}
			}
		}
	}
}

.dropdown-menu {
	display: none;
}

//	Select dropdowns
.select-wrapper {
	position: relative;
	min-width: rem(188);
	text-align: left;

	&.disabled {
		select {
			opacity: 0.35;
		}
		&:after {
			opacity: 0.35;
		}
	}

	html.touch & {
		select {
			padding-right: rem(30);
		}
		@include webfont('cheveron', $insert: after);
		&:after {
			@include position(absolute, 1.4rem 0.8rem null null);
			z-index: 10;
			display: block;
			@include webfont-styles;
			font-size: rem(8);
		}
	}

	html.touch .layout-reversed & {
		select {
			padding-right: rem(6);
			padding-left: rem(30);
		}
		&:after {
			left: 0.8rem;
			right: auto;
		}
	}

	&.light {
		color: $light-font-color;

		html.touch & {

			&:after {
				color: #000;
			}
		}

		select {
			color: black;
        }
	}

	&.in-form {
		// margin-bottom: 0.75rem;
		&:after {
			// z-index: -1;
		}
	}
}

.dk-select,
.dk-select-multi {
	position: relative;
	z-index: 15;
	width: 100%;

	&.input-block-level {
		z-index: 1;

		&.dk-select-open-down {
			z-index: 1111;
		}

		&.dk-select-open-up {
			z-index: 1111;
		}
	}

	li {
		margin-bottom: 0 !important;
	}
}

.dk-select .dk-selected,
select {

	.light & {
		text-transform: none;

	}
}

.dk-select-options,
.searchDd-list {
	display: none;
}

.dk-select-multi {

	.dk-select-options {
		position: static;
		display: block;
		height: auto;
		background: #fff !important;
		color: #000 !important;
	}

	.in-form & {

		.dk-select-options {
			max-height: 8.5rem;
			margin-bottom: 0.75rem !important;
			border-top-style: solid;
			margin-left: 0;
		}
	}
}

$select-dd-height: 3.3rem;
$select-dd-height-small: 2.75rem;

%select-style-all {
	box-sizing: border-box;
	margin-bottom: 0.3rem;
	padding: $base-spacing / 3;
	width: 100%;
	height: $select-dd-height;
	@include padding(0.6rem 2.5rem 0.6rem 0.9rem);
	font-size: $base-font-size;
	background: #000;
	// border-radius: $base-border-radius;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	transition: border-color;

	background-color: $base-background-color;
	border: 0 solid transparent;
	border-bottom: 1px solid #b7b7b7;
	border-radius: 0;

	&:hover {
		border-color: $form-keyline;
	}

	&:focus {
		border-color: $form-keyline;
		outline: none;
	}

    .layout-reversed &  {
    	text-align: right;
    	@include padding(0.6rem 0.9rem 0.6rem 2.5rem);
    }

	.in-form & {
		// font-size: rem(12);
		@include padding(0.5rem);
		height: 2.9167rem;
		font-size: 1rem;
		line-height: 1.8;
		// margin-bottom: rem(2);
	}

	&:focus {
		outline: none;
	}

	.light & {
		color: #000;
		background-color: #fff;
		border-radius: 0;
		border: 1px solid #b7b7b7;
		transition: all 0.3s ease;

		&:before {
			color: #000;
		}

		&.open {
			color: #fff;
			background: #000;

			&:before {
				color: #000;
			}
		}
	}
}

%select-style-single {
	position: relative;
	z-index: 5;
	@extend %m-b-none;
	color: #fff;
	border: none;
	border-radius: 0;
	text-align: center;
}

@if $is-opd == true {
	.dk-selected,
	select:not(.no-style),
	.searchDd-main {
		@extend %select-style-all;
	}

	select:not(.no-style) {
		@extend %select-style-single;
	}
} @else {
	.dk-selected,
	select,
	.searchDd-main {
		@extend %select-style-all;
	}

	select {
		@extend %select-style-single;
	}
}

.dk-selected,
.searchDd-main {
	cursor: pointer;
	@extend .webfont;
	@extend .webfont-cheveron;

	&:before {
		@include position(absolute, 1.5rem rem(10) auto auto);
		height: rem(10);
		font-size: 0.6rem;
		@include transition(transform 0.2s linear);

		.layout-reversed & {
			@include position(absolute, 1.2rem auto auto rem(10));
		}

		.in-form & {
			top: 1.2rem;
			right: 0.6rem;
		}
	}

	.light & {
		color: #000;
		background-color: #fff;
		border-radius: 0;
		border: 1px solid #b7b7b7;
		transition: all 0.3s ease;
		text-transform: uppercase;

		&:before {
			@include transition(all 0.2s linear);
		}

		&:hover {
			color: #000;
			background-color: #fff;

			&:before {
				color: #000;
			}
		}
	}
}

.dk-select-open-down {

	.dk-selected {

		&:before {
			transform: rotate(-180deg) translateY(3px);
		}
	}
}

.dk-select-options,
.searchDd-list {
	@include position(absolute, $select-dd-height 0 null);
	z-index: 500;
	max-height: rem(300);
	margin-left: 0 !important;
	margin-bottom: 0 !important;
	@include padding(0.5rem null 0.8rem);
	overflow-y: auto;
	font-size: 1.2rem;
	background: #fff;
	border-top: 1px dashed #fff;


	.light & {
		margin-top: -1px;
		color: #000;
		background: #fff;
		border: 1px solid $form-keyline;
		border-top: 1px dashed $form-keyline;
	}

	.in-form & {
		font-size: rem(12);
		max-height: rem(292);
	}
}

.dk-select-open-down,
.dk-select-open-up {

	.dk-select-options {
		display: block;

		.in-form & {
			top: $select-dd-height-small;
		}
	}

	.light & {

		.dk-selected {
			color: black;
			background-color: white;

			&:before {
				color: #000;
			}
		}
	}
}

.dk-option {
	@include padding(0.3rem 0.9rem);
	cursor: pointer;
	@include transition(background 0.2s ease);

	.layout-reversed & {
		text-align: right;
	}

	&.dk-option-selected,
	&:hover {
		background: #777;
	}

	.light & {
		&.dk-option-selected,
		&:hover {
			background: $block-medium-gray;
			color: white;
		}
	}
}



.hero-search-item {

	.dk-option {

		&:first-child {
			display: none;
		}
	}
}

// Special search dropdown
#searchDd {
	position: relative;
}

.searchDd-main {
	margin-bottom: 0;
	cursor: pointer;

	&:before {
		@include transform-origin(center 0.25rem);
	}

	&.open {

		&:before {
			@include transform(rotate(-180deg));
		}
	}
}

.searchDd-list {
	overflow: visible;
	max-height: initial;
	z-index: 480;

	&.open {
		display: block;
	}
}

.searchDd-category {
	position: relative;
	@include padding(0.4rem 1rem);
	cursor: pointer;

	@include media($medium) {

		&:hover {

			& > .searchDd-sublist {
				display: block;
			}

		}
	}

	span {
		position: relative;
		display: block;
		width: 100%;
		@extend .webfont;
		@extend .webfont-cheveron;

		&:before {
			@include position(absolute, 50% 0 null null);
			@include transform(translateY(-50%) rotate(-90deg) translate3d(0,0,0));
			@include transition(transform 0.2s ease);
			font-size: 0.5rem;
		}
	}

	&.searchDd-clear {

		span:before {
			display: none;
		}
	}

	&.open {

		span:before {
			@include transform(translateY(-50%) rotate(0) translate3d(0,0,0));
		}
	}
}

.searchDd-sublist {
	display: none;
	width: 100%;
    max-height: 18.8rem;
	@include padding(0.5rem null 0.8rem);
    overflow-y: auto;
	background: #000;

	@include media($medium) {
		@include position(absolute, 0 null null 99%);
		max-height: 25rem;
	}

	li {
		@include padding(0.2rem 1rem);
		opacity: 1;
		@include transition(opacity 0.2s ease);

		&:hover {
			opacity: 0.7;
		}
	}
}
