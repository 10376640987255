.component-img-content {

	@include media($medium) {
		height: rem(520);
	}

	.link-wrap {
		display: block;
		position: relative;
		z-index: 2;
		width: 100%;
		height: 18.5rem;
		color: inherit;
		cursor: pointer;

		@include media($medium) {
			@include position(absolute, 0 0);
			height: 100%;
		}

		.module-content-wrapper {
			position: absolute;
			top: -7.6rem;
			margin-top: 0;

			@include media($medium) {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.module-image {
		height: 25rem;

		@include media($medium) {
			height: 100%;
		}
	}

	.module-content-wrapper {
		width: 100%;
		max-width: 105rem;
		@include margin(-8rem auto null);

		@include media($medium) {
			height: 100%;
			@include position(absolute, 0 0 0 0);
			margin-top: 0;
		}
	}

	.module-content {
		position: relative;
		z-index: 1;

		width: calc(100% - 4rem);
		@include margin(null auto);
		@include padding(rem(40));
		background: #fff;
		box-shadow: 0 0 30px rgba(0,0,0,0.12);
		max-width: 31rem;
		@include media($medium) {
			width: 100%;
		}
		&.no-link {
			.module-text-copy {
				@include media($medium) {
					margin-bottom: 0;
				}
			}
		}
		.module-text-copy {
			@include media($medium) {
				margin-bottom: 20px;
				max-height: 190px;
			}
		}

		@include media($medium) {
			@include position(absolute, 50% 0 null);
			transform: translateY(-50%);
			@include margin(null 2.1667rem);
			max-height: rem(420);
		// }
		// @include media($medium) {
			@include margin(null 3.3333rem);
		}
		@include media($large) {
			@include margin(null 6.6667rem);
		}

		@include media($medium) {
			&.module-content-right {
				left: auto;
			}
			&.module-content-center {
				left: 0;
				right: 0;
				@include margin(null auto);
			}
			&.module-content-left {
				right: auto;
			}
		}
	}

	.module-text-header {
		font-size: 2rem;

		@include media($medium) {
			font-size: rem(34);
		}
	}

	.module-title-deco {
		margin-bottom: 1.4rem;
		padding-bottom: 1.2rem;

		&:before {
			bottom: 0;
			left: 0.33rem;
			width: 3.75rem;
		}

		.layout-reversed & {

			&:before {
				left: auto;
				right: 0.33rem;
			}
		}
	}
}
