.module-img-content {

	.module-image {
		height: 80%;
	}

	.module-content-wrapper {
		@include position(absolute, null 0 0 0);
		z-index: 2;
		width: calc(100% - 4rem);
		@include padding(2rem 1.2rem);
		@include margin(null auto);
		background: #fff;
		box-shadow: 0 0 30px rgba(0,0,0,0.12);
	}

	.module-title {
		font-size: 2rem;
	}
	h1{
		&.module-title{
			font-size: 2rem;
			@include media($small) {
				font-size: rem(34);
				padding-bottom: 1.4rem;
				padding-top: 1.5rem;
			}
		}
	}

	.module-content {
		max-width: 20rem;
		@include margin(null auto);
	}

	.module-title-deco {
		margin-bottom: 1.4rem;
		padding-bottom: 1.8rem;

		@include media($large) {
			padding-bottom: 1rem;

		}


		&:before {
			bottom: 0;
			left: 0;
			right: 0;
			width: 3.75rem;
			@include margin(null auto);
		}
	}

	&.hero-basic-content {

		.module-title-deco {
			margin-bottom: 2.6rem;
			padding-bottom: 3.2rem;
		}
	}

	&.module-text-new {
		padding: 0;
		background-color: transparent;

		.module-text-header {
			font-size: 2rem;
		}

		.module-title-deco {

			&:before {
				right: auto;
				margin: 0;
			}

			.layout-reversed & {

				&:before {
					left: auto;
					right: 0;
				}
			}
		}
	}

	.module-text-copy {

		p {
			margin-bottom: 0;
		}
	}
}

.page-campaign .module-img-content .module-img {
	background-size: contain;
}