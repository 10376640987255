// =============================================================================
//	VARIABLES
// =============================================================================

// Redesign fonts
$brown-light: 'brown-light', sans-serif;
$brown-regular: 'brown-regular', sans-serif;
$brown-bold: 'brown-bold', sans-serif;
$bodoni-medium: 'bonodiBe-medium', serif;
$bodoni-italic: 'bonodiBe-italic', serif;
$bodoni-roman: 'bonodiStd-roman', serif;
// NON-EU sans-serif
$noto-sans: 'Noto Sans', 'brown-regular', 'bonodiBe-medium', sans-serif; // Russian
$noto-sans-kr: 'Noto Sans KR', 'brown-regular', 'bonodiBe-medium', sans-serif; // Korean
$noto-sans-sc: 'Noto Sans SC', 'brown-regular', 'bonodiBe-medium', sans-serif; // Chinese
$noto-kufi-ar: 'Noto Kufi Arabic', 'brown-regular', 'bonodiBe-medium', sans-serif; // Arabic
// NON-EU serif
$playfair-display: 'Playfair Display', serif;
$kopub-batang: 'KoPub Batang', serif;
$adobe-fangsong-std: "adobe-fangsong-std", serif;
$noto-naskh-arabic: 'Noto Naskh Arabic', serif;

$base-font-family: 'brown-regular', sans-serif;
$base-font-family-light: 'brown-light', sans-serif;

$heading-font-family-light: $base-font-family-light;

// Font Sizes
$em-base: 12px;
$base-font-size: rem(12);

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$large-spacing: $base-line-height * 1.85rem;
$base-spacing: $base-line-height * 1rem;
$small-spacing: $base-spacing / 2;
$x-small-spacing: $base-spacing / 3;
$base-z-index: 0;
$hero-height: rem(350);

// Colors for CMS widgets
$pastel-green: #b0e2d4;
$pastel-blue: #b0d4e2;
$pastel-purple: #c3b0e2;
$pastel-beige: #e2ddb0;

// Colors
$teal: #008a9d;
$blue: #477dca;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$light-coral: #e2b0b0;
$dusty-coral: #b4897e;
$red: #c2092f;

// Form colors
$radio-border: #b7b7b7;
$radio-fill-color: black;
$checkbox-border: #b7b7b7;
$checked-fill-color: black;

$form-keyline: #b7b7b7;

$disabled-color: gray;
$gray-dark: #333;


// Keyline colors
$gray-keyline: #b7b7b7;
$gray-dark-keyline: #777;

// Block colors
$base-background-color: #fff;
$block-medium-gray: #b7b7b7;
$block-mediumlight-gray: #e5e5e5;
$block-light-gray: #f5f5f5;

// Font Colors
$base-font-color: #000;
$very-light-font-color: #ccc;
$light-font-color: #777;
$action-color: #7b7b7b;
$article-title-color: #cdcdcd;

$icon-color: #b1b1b1;

// Social colors
$facebook-color: #3b5998;
$twitter-color: #55acee;
$pinterest-color: #cb2027;
$instagram-color: #fd1d1d;
$google-color: #dd4b39;
$youtube-color: #bb0000;
//$weibo-color: #e89214;
$weibo-color: #e7152e;
$tripadvisor-color: #08a850;
$foursquare-color: #0072b1;
$messenger-color: #0084ff;
$whatsapp-color: #00e676;
$wechat-color: #2bc100;


// Images
$deco: 'src/deco/';

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
$input-text-color: #444;
$input-placeholder-color: #979797;

// Layout
$top-navbar-height: 30px;
$main-navbar-height: 50px;
$navtray-height: 305px;
$container-max-width: 960px; //update this
