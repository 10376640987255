.search-results-form {
	position: relative;
	margin-top: rem(40);
	@include clearfix;

	@include media($medium) {
		margin-top: rem(40);
	}

	label {
		top: 13px !important;
		font-size: 1.2rem;
		animation: fadeIn 0.3s ease 0.3s;

		.layout-reversed & {
			left: auto !important;
			right: rem(58);
		}
	}

	input[type="text"].smart-search {
		float: left;
		width: calc(100% - 49px);
		height: rem(45);
		margin-bottom: 0;
		border-width: 1px 0 1px 1px;
		border-color: black;
		border-style: solid;
		border-radius: 0;
		transition: all 0.3s ease;
		font-size: 14px;
	}

	&.collapsed {
		label {
			display: none;
		}

		input[type="text"].smart-search {
			width: 0;
			padding: 0;
			border: none;
		}
	}

	.smart-search-submit {
		float: left;
		height: rem(45);
	}
}
