.module-text-new {
	height: 35.83rem;

	@include media($xsmall-down) {
		height: auto;

		.ellipsis-new {
			margin-bottom: 1.4rem;
		}
	}

	.module-title-deco {
		margin-bottom: 1.4rem;
		padding-bottom: 1.4rem;

		&:before {
			bottom: 0;
			left: 0.33rem;
			width: 3.75rem;
		}

		.layout-reversed & {

			&:before {
				left: auto;
				right: 0;
			}
		}
	}

	.module-text-copy p {
		max-width: 60rem;
	}

	.text-block-3col-wrapper & {
		height: auto;
	}

	p,
	.module-find-out-more {
		color: #000;
	}

	&.module-text-new-background {
		@include padding(2.6rem 3rem);
	}

	&.inverted {

		.module-text-header,
		p,
		.module-find-out-more {
			color: #fff;
		}

		.module-title-deco {

			&:before {
				background: #fff;
			}
		}
	}

	&.center {
		text-align: center;

		.module-title-deco {

			&:before {
				left: 0;
				right: 0;
				@include margin(null auto);
			}
		}
	}
}

.text-block-3col-wrapper {

	.module-text-new {
		margin-bottom: 3rem;
	}

	.text-block-footer {
		text-align: center;
	}

	.widget-slot {

		@include media($small) {
				display: inline;

			}

	}
}

article.module-free-text-new.free-text-background {
    margin-bottom: 40px;
}