.back-to-top-wrap {
	text-align: center;
	margin: 3rem 0;
}

.back-to-top {
	position: relative;
	width: rem(58);
	height: rem(58);
	background: white;
	cursor: pointer;
	border-radius: 50%;
	border: solid $dark-gray rem(1);

	@extend %hide-text;

	&:before {
		@include position(absolute, 0 rem(-4) auto auto);
		color: $dark-gray;
		font-size: rem(24);
		text-align: center;
		text-indent: 0;
		@include transform(rotate(-90deg));
	}

	&:active {
		background: $dark-gray;
		
		&,
		&:before {
			color: white;
		}
	}
}
