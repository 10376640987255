.footer-content-wrapper {
    padding-bottom: 0;
    background: #251f25;

    &.page-wrapper-wide {
        padding-top: 2.5rem !important;
    }
}

.footer-content {
    color: #fff;

    h4 {
        @include margin(0 null 1rem);
        font-size: rem(20);
        @include base-font-family;
        text-transform: uppercase;

        @include media($large) {
            font-size: rem(14);
        }

        @include media($small) {
            @include margin(0 null 1.4rem);
            font-size: rem(16);
        }

        a {
            color: #8a8a8a;
            font-size: rem(16);
            text-transform: none;
            letter-spacing: normal;

            @include media($small) {
                @include margin(0 null 1.4rem);
            }

            @include media($large) {
                font-size: rem(14);
            }
        }
        // @include media($large) {
        // 	&.f-sm-faqs-title,
        // 	&.f-sm-sitemap-title,
        // 	&.f-sm-register-title {
        // 		margin-top: 2.1rem;
        // 	}
        // }
    }

    ul {
        margin-bottom: 1rem;

        @include media($large) {
            &.f-sm-legal-list,
            &.f-sm-corp-list {
                margin-bottom: 2.1rem;
            }
        }
    }

    li {
        font-size: rem(16);

        @include media($large) {
            font-size: rem(14);
        }
    }

    .content-wrapper {
        @include outer-container;
    }

    .opening-hours {
        @extend %col-4-large;

        .microsite & {
            width: 100%;

            @include media($large) {
                width: 30%;
            }
        }

        .layout-reversed & {
            float: none;

            @include media($small) {
                float: right;
            }
        }

        @include media($large) {
            padding-right: rem(40);
        }

        ul {
            max-width: 23rem;
            @extend %col-8-small-only;

            .layout-reversed & {
                float: none;

                @media only screen and (min-width: 32.5em) {
                    float: right;
                }
            }

            @include media($small-only) {
                float: none;
            }

            @include media($small) {
                float: left;
                width: 66.667%;
            }

            @include media($medium-only) {
                float: left;

                .layout-reversed & {
                    float: right !important;
                }
            }

            @include media($large) {
                float: none;
                width: 100%;
            }
        }

        .hours {
            padding-top: 0.2rem;
            padding-right: 1.8rem;
            text-align: right;

            .layout-reversed & {
                text-align: left;
            }

            span {
                width: rem(40);
                display: inline-block;
                word-spacing: normal;

                @include media($medium) {
                    width: rem(35);
                }
            }

            .layout-reversed & {
                padding-right: 0;
                padding-left: 1.8rem;
                text-align: left;
            }

            @include media($large) {
                font-size: 1.1rem;
            }
        }
    }

    .tooltip-wrapper {
        display: none;
    }

    .opening-time-extra {
        color: #fff;
        width: 100%;
        max-width: 44rem;

        @include media($large) {
            width: 44rem;
            margin-top: 2rem;
        }
    }

    > .opening-time-extra {
        display: none;

        @include media($large) {
            display: block;
        }
    }

    > ul { // Duplicate of opening hours
        display: none;
    }
}

.opening-hours {
    @include clearfix;

    ul {
        width: 100%;

        @media only screen and (min-width: 32.5em) and (max-width: 39.99em) {
            @include span-columns(6);

            .layout-reversed & {
                float: none;
            }
        }
    }

    li {
        display: table;
        width: 100%;
        padding-bottom: 0.37rem;
    }

    .day,
    .hours {
        @extend %col-6-small;
        display: table-cell;

        .layout-reversed & {
            float: right;
            width: 50%;
        }
    }

    @include media($large) {
        .opening-time-extra {
            display: none;
        }
    }
}

.opening-time-extra {
    clear: both;

    &,
    & + p {
        margin-top: rem(18);
        font-size: 0.95rem;
    }
}

.f-site-map {
    @include clearfix;
    margin-top: 1.6rem;
    @extend %col-12-medium;
    @extend %col-8-large;

    @include media($medium) {
        @include omega();

        .microsite & {
            width: 100%;
        }
    }

    @include media($large) {
        margin-top: 0;

        .microsite & {
            width: 70%;
        }
    }

    li {
        padding-bottom: 0.1rem;
    }
}

.f-site-map-col {
    @extend %col-4-small;

    @media only screen and (min-width: 32.5em) and (max-width: 39.99em) {
        @include span-columns(6);
        @include omega(2n);
    }

    &.last {
        @extend %col-12-small;

        @include media($large) {
            @include omega();
        }

        .f-site-map-group {
            @extend %col-4-small;

            @include media($small) {
                width: 100%;
            }

            &.last {
                @extend %col-8-small;
            }

            @include media($large) {
                &,
                &.last {
                    width: 100%;
                    float: none;
                }
            }
        }
    }

    &,
    &.last {
        @extend %col-4-small;

        .layout-reversed & {
            float: none;
            width: 100%;

            @include media($small) {
                width: 33.3333%;
                float: right;
            }
        }
        // .microsite & {
        // 	@include media($small) {
        // 		width: 33.3333%;
        // 	}
        // }
    }

    &:nth-child(3) {
        margin-bottom: 2rem;

        @include media($small) {
            margin-right: 0;
        }

        @include media($medium) {
            margin-bottom: 0;
        }

        @include media($medium-only) {
            @include omega();
        }
    }
}

.f-site-map-group {
    width: 100%;
    padding-right: rem(10);

    .layout-reversed & {
        padding-right: 0;
        padding-left: rem(10);
    }

    ul {
        margin-bottom: 2.1rem;
    }
}

.f-sm-sitemap-title {
    @extend %m-b-none;
}

.f-sm-register-form {

    p,
    .form-single-item {
        @extend %col-6-small;

        @include media($large) {
            width: 100%;
            float: none;
        }
    }

    p {
        color: #8a8a8a;
        font-size: rem(16);

        @include media($small-only) {
            font-size: 1.1rem;
        }

        @include media($small) {
            padding-right: 1rem;
        }

        @include media($large) {
            font-size: rem(14);
        }
    }

    .form-single-item {
        max-width: 21.2rem;
        margin-top: 1.6rem;

        @include media($small-only) {
            margin-top: -2rem;
        }

        @include media($small) {
            margin-top: -1.6rem;
        }

        @include media($large) {
            margin-top: 1.6rem;
        }
    }
}

.link-out {

    > span {
        white-space: nowrap;
    }

    b {
        @extend .webfont;
        @extend .webfont-arrow-link-out;
        color: $action-color;
        margin-left: rem(5);

        &:before {
            font-size: rem(10);
        }

        .layout-reversed & {
            @include margin(null rem(0) null 0);
            @include transform( rotate(90deg) );
        }
    }
}

.footer-extra-wrapper {
    padding-bottom: 2rem;
    background: #251f25;
    line-height: 1rem;
    margin-top: rem(-1);

    @include media($medium) {
        padding-bottom: 4rem;
    }

    &.page-wrapper-wide {
        padding-top: 2.5rem !important;
        padding-bottom: 1.5rem !important;
    }

	.breakWord {
		word-break: break-word;
	}
	
	.footerTextRight {
		text-align: left;
		@include media($small) {
			text-align: right;
		}
	}

	.link-out > span {
		white-space: unset;
	}

}

@media only screen and (min-width: 32.5em) {

    .footer-legal,
    .chickoutlet-link {
        @include span-columns(6);
    }
}

.footer-legal {
    padding-top: 1rem;
    color: #fff;
    line-height: 1.5;

    @media only screen and (min-width: 32.5em) {
        padding-top: 0;

        .layout-reversed & {
            float: right;
        }
    }

    p {
        margin-bottom: 1.5rem;

        @media only screen and (min-width: 32.5em) {
            margin-bottom: 0;
        }

        .microsite & {
            margin-bottom: 0.75rem;

            @media only screen and (min-width: 32.5em) {
                margin-bottom: 0.75rem;
            }
        }
    }
}

.chickoutlet-link {
    font-size: rem(16);

    @media only screen and (min-width: 32.5em) {
        margin-top: -0.2rem;
        text-align: right;

        .layout-reversed & {
            float: left;
            text-align: left;
        }
    }
}

.icp-number .icp {
    color: #ffffff;
    font-size: 12px;
    display: inline-block;
    margin: 0;
    line-height: 20px;
    vertical-align: middle;
}

.icp-number .margin-left {
    margin-left: 2rem;
    color: #ffffff;
}

.icp-number a {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    position: relative;
    margin-left: 10px;
    width: auto;
}

.icp-number a p {
    float: left;
    margin: 0 0 0 5px;
    color: #ffffff;
    font-size: 12px;
    line-height: 20px;
}

.icp-number a img {
    float: left;
}
