@font-face {
	font-family: "icons";
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('eot'),
		url('../fonts/icons.woff') format('woff'),
		url('../fonts/icons.ttf') format('truetype'),
		url('../fonts/icons.svg#icons') format('svg');
}

@mixin webfont-styles {
	display: inline-block;
	height: inherit;
	@include margin(null 0.2rem);
	font-size: 1rem;
	font-family: "icons";
	font-variant: normal;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
	text-align: center;
	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="webfont-"],
[class*=" webfont-"],
.webfont {
	display: inline-block;

	&:before {
		@include webfont-styles;
	}
}

@function webfont-char($filename) {
	$char: "";

	@if $filename == "arrow-link-out" {
		$char: "\E001";
	}
	@if $filename == "arrow-long" {
		$char: "\E002";
	}
	@if $filename == "arrow-longer" {
		$char: "\E003";
	}
	@if $filename == "arrow-short" {
		$char: "\E004";
	}
	@if $filename == "bag-0" {
		$char: "\E005";
	}
	@if $filename == "bag-1" {
		$char: "\E006";
	}
	@if $filename == "bag-2" {
		$char: "\E007";
	}
	@if $filename == "bag-3" {
		$char: "\E008";
	}
	@if $filename == "bag-4" {
		$char: "\E009";
	}
	@if $filename == "bag-5" {
		$char: "\E00A";
	}
	@if $filename == "cheveron-thin" {
		$char: "\E00B";
	}
	@if $filename == "cheveron" {
		$char: "\E00C";
	}
	@if $filename == "close" {
		$char: "\E00D";
	}
	@if $filename == "map-pin" {
		$char: "\E00E";
	}
	@if $filename == "minus" {
		$char: "\E00F";
	}
	@if $filename == "pinch" {
		$char: "\E010";
	}
	@if $filename == "plus" {
		$char: "\E011";
	}
	@if $filename == "search" {
		$char: "\E012";
	}
	@if $filename == "share" {
		$char: "\E013";
	}
	@if $filename == "tooltip" {
		$char: "\E014";
	}
	@if $filename == "user" {
		$char: "\E015";
	}
	@if $filename == "facebook-square" {
		$char: "\E016";
	}
	@if $filename == "facebook" {
		$char: "\E017";
	}
	@if $filename == "foursquare" {
		$char: "\E018";
	}
	@if $filename == "google" {
		$char: "\E019";
	}
	@if $filename == "instagram" {
		$char: "\E01A";
	}
	@if $filename == "messenger" {
		$char: "\E01B";
	}
	@if $filename == "pinterest" {
		$char: "\E01C";
	}
	@if $filename == "tripadvisor" {
		$char: "\E01D";
	}
	@if $filename == "twitter" {
		$char: "\E01E";
	}
	@if $filename == "wechat" {
		$char: "\E01F";
	}
	@if $filename == "weibo" {
		$char: "\E020";
	}
	@if $filename == "whatsapp" {
		$char: "\E021";
	}
	@if $filename == "youtube" {
		$char: "\E022";
	}

	@return $char;
}

@mixin webfont($filename, $insert: before) {
	&:#{$insert} {
		content: webfont-char($filename);
	}
}

.webfont-arrow-link-out {
	@include webfont("arrow-link-out");
}
.webfont-arrow-long {
	@include webfont("arrow-long");
}
.webfont-arrow-longer {
	@include webfont("arrow-longer");
}
.webfont-arrow-short {
	@include webfont("arrow-short");
}
.webfont-bag-0 {
	@include webfont("bag-0");
}
.webfont-bag-1 {
	@include webfont("bag-1");
}
.webfont-bag-2 {
	@include webfont("bag-2");
}
.webfont-bag-3 {
	@include webfont("bag-3");
}
.webfont-bag-4 {
	@include webfont("bag-4");
}
.webfont-bag-5 {
	@include webfont("bag-5");
}
.webfont-cheveron-thin {
	@include webfont("cheveron-thin");
}
.webfont-cheveron {
	@include webfont("cheveron");
}
.webfont-close {
	@include webfont("close");
}
.webfont-map-pin {
	@include webfont("map-pin");
}
.webfont-minus {
	@include webfont("minus");
}
.webfont-pinch {
	@include webfont("pinch");
}
.webfont-plus {
	@include webfont("plus");
}
.webfont-search {
	@include webfont("search");
}
.webfont-share {
	@include webfont("share");
}
.webfont-tooltip {
	@include webfont("tooltip");
}
.webfont-user {
	@include webfont("user");
}
.webfont-facebook-square {
	@include webfont("facebook-square");
}
.webfont-facebook {
	@include webfont("facebook");
}
.webfont-foursquare {
	@include webfont("foursquare");
}
.webfont-google {
	@include webfont("google");
}
.webfont-instagram {
	@include webfont("instagram");
}
.webfont-messenger {
	@include webfont("messenger");
}
.webfont-pinterest {
	@include webfont("pinterest");
}
.webfont-tripadvisor {
	@include webfont("tripadvisor");
}
.webfont-twitter {
	@include webfont("twitter");
}
.webfont-wechat {
	@include webfont("wechat");
}
.webfont-weibo {
	@include webfont("weibo");
}
.webfont-whatsapp {
	@include webfont("whatsapp");
}
.webfont-youtube {
	@include webfont("youtube");
}
