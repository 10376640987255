// =============================================================================
//	SEARCH RESULTS
// =============================================================================


.search-results-form {
	margin-bottom: rem(60);
}

.search-results {
	margin-top: rem(20);

	.search-item {
		margin-bottom: rem(60);

		.search-item-title {
			font-size: rem(17);
			color: $gray-dark;
			margin-bottom: rem(14);
		}
		.search-item-link {
			margin-top: rem(-10);
		}
	}

	.search-results-pagination {
		height: rem(25);
		margin: rem(60) 0 0 rem(-7);
		padding: 0;

		@include media($medium) {
			margin-top: rem(80);
		}

		li {
			width: rem(25);
			height: 100%;
			text-align: center;
			@include base-font-family;
			list-style: none;
			float: left;

			a {
				color: $very-light-font-color;
				width: 100%;
				height: 100%;
			}

			.active, :hover {
				color: $gray-dark;

			}

			.webfont-cheveron {
				display: block;
				position: relative;

				&:hover {
					opacity: 1;

				}
				&.next{
					margin-top: rem(2);
					transform: rotate(-90deg);
				}

				&:before {
					position: absolute;
					width: auto;
					height: auto;
					top: 50%;
					left: 50%;
					margin-top: rem(-7);
					margin-left: rem(-7);
				}
				&.prev::before {
					transform: rotate(90deg);
				}

			}
		}
	}
}
