.module-hero-new {
	height: 35.83rem;

	// Fix added from ticket VRS-536 (might have to be page specific?)
	@media only screen and (min-width: 800px) and (max-width: 930px) {
		height: 43rem;
	}
	@media only screen and (min-width: 931px) and (max-width: 1094px) {
		height: 38rem;
	}

	.module-text-subheader {
		margin-bottom: 0;
	}

	.module-content-wrapper {
		height: 15.17rem;

		// Fix added from ticket VRS-536 (might have to be page specific?)
		@media only screen and (min-width: 800px) and (max-width: 930px) {
			height: 22rem;
		}
		@media only screen and (min-width: 931px) and (max-width: 1094px) {
			height: 18rem;
		}
	}

	.module-content {
		@include position(absolute, 50% 0 null);
		transform: translateY(-50%);
		width: calc(100% - 2rem);
		@include margin(null auto);

		> p {
			font-size: rem(16);
		}

		@include media($small) {
			max-width: calc(100% - 4rem);
		}
	}

	.hero-nav-share {
		display: inline-block;
		margin-top: 1rem;
		color: #000;
		text-transform: uppercase;

		@include media($medium) {
			@include position(absolute, 3.6rem 2.8rem null null);
			width: auto;

			.layout-reversed & {
				right: auto;
				left: 2.8rem;
			}
		}
	}
	.hero-new-links{
		display: inline-block;
		a.button{
    	display: block;
	    margin:1rem;
    }

    @include media($small) {
    	display: flex;
	    justify-content: center;
	    align-items: baseline;
    }

	}

}

.module-hero-new-no-spacing {
	margin-bottom: 0;

	&.component-wrapper-new .module-content-wrapper .hero-content header {
		margin-bottom: 0;
	}
}