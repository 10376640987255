// =============================================================================
//	FREE TEXT WIDGETS
// =============================================================================
.module-free-text-new {
    @include media($xsmall-down) {
        height: auto;
    }
    &.center {
        text-align: center;
        .module-free-text-header {
            &:before {
                margin: 0 auto;
            }
            &:after {
                @include margin(null auto);
            }
        }
        .module-free-text-copy ol,
        .module-free-text-copy ul {
            text-align: left;
        }
    }
    &.free-text-background {
        background: #c9c9c9;
        @include padding(3rem 10% 1.5rem);
    }
    &.inverted {
        .module-free-text-header,
        .module-free-text-copy p,
        .module-free-text-copy ol,
        .module-free-text-copy ol li,
        .module-free-text-copy ul,
        .module-free-text-copy ul li {
            color: #fff;
        }
        .module-free-text-header {
            &:after,
            &:before {
                background: #fff;
            }
        }
        .module-free-text-copy a,
        .module-free-text-copy p a {
            color: #fff;
            opacity: 0.5;
            &:hover {
                opacity: 0.8;
            }
        }
        .module-free-text-copy a {
            font-size: 1.25rem;
        }
    }
}

.module-free-text-header {
    text-transform: uppercase;
    margin-bottom: 1rem;
    @include base-font-family;
    letter-spacing: 0.05rem;
    // new styles
    position: relative;
    font-size: 2rem;
    margin-bottom: 1.4rem;
    padding-bottom: 1.2rem;
    color: $teal;
    @include media($medium) {
        font-size: rem(30);
    }
    @include media($medium-only) {
        font-size: rem(24);
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        background: #000;
        bottom: 0;
        left: 0;
        right: 0;
        width: 3.75rem;
    }
    // /new styles
    sup {
        font-size: 0.5em;
    }
    .layout-reversed & {
        letter-spacing: 0;
    }
    &.module-free-text-deco {
        position: relative;
        padding-bottom: 1rem;
        &:after {
            content: '';
            @include position(absolute, null null 0 0);
            @include margin(null 0);
            background: #777;
            width: 3rem;
            height: 1px;
        }
    }
    .page-title-full & {
        @extend %col-12;
        margin-bottom: 2rem;
        margin-top: 3rem;
    }
    .module-just-text & {
        color: white;

        &:after {
            background: white;
        }
    }
    &.accordion-trigger-text {
        font-size: 1.6rem !important;

        @include media($small-down) {
            margin-bottom: 0;
            padding: 0;
        }
        @include media($medium) {
            font-size: 2rem !important;
        }

        &:before {
            display: none;
        }
    }
}

.page-title-full-seeAll {
    margin-bottom: 5rem;
    text-align: center;

    @include media($xsmall-down) {
        .button {
            width: 250px;
            max-width: 100%;
        }
    }

    @include media($small) {
        @include position(absolute, rem(10) 0 null null);
        .button {
            margin: 0;
            padding: 0;
            color: #cdcdcd;
            font-size: rem(16);
            letter-spacing: 0;
            background-color: transparent !important;
            border-color: transparent !important;
            border-radius: none;
        }
    }
}

.row-seeAll {
    position: relative;
}

.module-free-text-copy {
    margin-bottom: 3rem;
    #train & {
        @extend %m-t-lg;
    }
    p {
        font-size: rem(14);
        line-height: 1.4;
        text-transform: none;
        & a {
            color: #000;
            text-decoration: underline;
        }
    }
    p + .button {
        margin-top: 1.8rem;
    }
    &.rich-text {
        ul,
        ol {
            margin-left: 1.5rem;
        }
    }

    .page-basic-content &,
    .page-basic-content-new & {

        h2, h3 {
            text-transform: uppercase;
        }
    }
}

.text-image-component-promo-code {
    .module-free-text-copy {
        height: auto !important;
        ul,
        ol {
            color: #fff;
        }
    }
}

.alt-img-content-even {
    .grid-item {
        &:nth-child(4n+3) {
            float: right;
        }
    }
}

.alt-img-content-odd {
    .grid-item {
        &:nth-child(4n+1) {
            float: right;
        }
    }
}

.module-just-text {
    color: white;
    background: #808080;
    &.module-free-text-deco {
        padding-bottom: 1.2rem;
    }
}
