// =============================================================================
//  LISTS
// =============================================================================
// Global styles for lists
ul,
ol {
	list-style-type: none;
	@extend %m-none;
	@extend %p-none;

	&%default-ul {
		list-style-type: disc;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
	}

	&%default-ol {
		list-style-type: decimal;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
	}
}

dl {
	margin-bottom: $small-spacing;

	dt {
		@include base-bold-font-family;
		margin-top: $small-spacing;
	}

	dd {
		@extend %m-none;
	}
}
