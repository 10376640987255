// =============================================================================
//  OFF-CANVAS
// =============================================================================

.site-wrapper {
	position: relative;
	width: 100%;
	min-height: rem(320);
	overflow: hidden;
}

.site-canvas {
	position: relative;
	height: 100%;
	width: 100%;
}

.site-content {
	position: relative;
}

.off-canvas-wrapper {
	@include position(fixed, 0 rem(-264) 0 null);
	z-index: 1000;
	width: rem(264);
	height: 100%;
	background: rgba(255,255,255,0);
	@include transition(all 0.5s ease);

	.hidden-medium-down {
		display: none;
	}

	a {
		display: inline-block !important;
		width: 70%;
		height: 100%;
	    line-height: 1.5;
		padding: 16px 0;

		.layout-reversed & {
			width: 100%;
		}
	}

	.login-register {

		a {
			width: 100%;
			@include transform(translate3d(0, 0, 0));

			.webfont-cheveron {

				&:before {
					@include position(absolute, 1.7rem rem(10) null null);
					@include transform( rotate(-90deg) );
					color: $action-color;
				}
			}
		}
	}

	.register-menu {

		a:after {
			right: 1rem;

			@include media($large) {
				.no-touchevents & {
					display: none;
				}
			}
		}
	}

	.login-menu {
		a {
			.webfont-cheveron {
				&:before {
					right: rem(-3);
				}
			}
		}
	}

	.lang-vill,
	.topnav-social-media {

		.dropdown-button {
			width: 100%;
		}
	}
}

.site-canvas.active .off-canvas-wrapper {
	right: 0;
}

.off-canvas-block,
.nav-navItem {
	@include padding(null rem(20));
	border-bottom: 1px solid #e5e5e5;
	line-height: rem(50); // this affects many children

	&.last {
		border-bottom: 0px solid transparent;
	}
}

.nav-navItem {
	//background: rgba(0,0,0,1);
	background: white;
}

.off-canvas-block{
	min-width:22rem;
//	background: rgba(255,255,255,0.2);
}

.off-canvas-block {
	transform: translateX(270px);
	transition: none;

	&.movein {
		transform: translateX(0);
		transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
}

.movein{
//	background: rgba(255,255,255,1);
	// height: 50px; // ?
}

.moveinSideNav{
	margin-left:0;
	background: #f5f5f5;
}

.moveinSearch{
	margin-left:0;
	background: #f3f3f3;

}

.close-canvas {
	@include position(fixed, 0 0 0 0);
	display: none;
	height: 100%;
	width: 100%;
	overflow: hidden;
	text-indent: -9999rem;
	background-color: #000;
	opacity: 0;
	cursor: pointer;
	z-index: 99;

	&.active {
		display: block;
		opacity: 0.7;
	}
}

#language,
#village,
.topnav-social-media {

	.dropdown-button {

		&:focus {
			opacity: 1;
		}

		&:before {
			@include position(absolute, 2.1rem -0.3rem null null);

			.layout-reversed & {
				@include position(absolute, 2.1rem auto null -0.3rem);
			}
		}
	}
}

.nav-container .lang-vill .dropdown,
.topnav-social-media {
	background: white;
}

.topnav-social-media {

	.dropdown-menu {
		position: relative;
		@include padding(0.5rem null 1.2rem);

		&:before {
			content: '';
		    width: 30px;
		    height: 1px;
		    background-color: black;
		    position: absolute;
		    top: 0;
		    left: 0;
		}
	}

	.social-subnav-trigger {
		color: #7b7b7b;

		&.active {
			color: #000;
		}
	}

	.social-linkItem {
		color: #000;

		&:before,
		img,
		span {
			display: inline-block;
			vertical-align: middle;
		}

		&:before,
		img {
			margin-right: 1rem;
			margin-left: 0;
			font-size: 1.4rem;
		}

		img {
			width: rem(18);
		}
	}
}

.layout-reversed {

	.topnav-social-media {

		.dropdown-button.webfont-cheveron {

			&:before {
		    	right: auto;
		    	left: -0.3rem;
		    }
		}
	}
}