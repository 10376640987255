.site-canvas {
	padding-top: $main-navbar-height;

	@include media($nav-breakpoint) {
		.no-touchevents & {
			padding-top: $top-navbar-height + $main-navbar-height;
		}
	}


	&.webview {
		padding: 0;
	}
}

.component-wrapper,
.page-wrapper,
.page-wrapper-wide,
.page-wrapper-narrow,
.hero-wrapper.module {
	@include padding(1rem rem(13) 1.5rem);

	@include media($small) {
		@include padding(1rem rem(20) 1.5rem);
	}

	// @include media($medium-only) {
	// 	@include padding(null rem(40));
	// }

	@include media($medium) {
		padding-top: rem(30);
	}

	@include media($large) {
		@include padding(null rem(40));
	}
}

.page-wrapper-wide,
.page-wrapper-narrow,
.crosslinks-wrapper {
	padding-bottom: 0;

	&.grey-wrapper {
		background-color: #f0f0f0;
		padding-top: 4.6rem !important;

		&.grey-wrapper-last {
			margin-bottom: 0;
		}
	}
}
.page-wrapper-wide,
.page-wrapper-narrow + .page-wrapper-wide,
.page-wrapper-narrow,
.component-wrapper-new + .page-wrapper-wide,
.page-wrapper-narrow {
	padding-top: 0;

	&.grey-wrapper {
		@include margin(2rem null 4rem);
		@include padding(2.2rem null 1rem);
	}
}

.page-wrapper-wide:not(.grey-wrapper) {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.component-wrapper-new {

	&.grey-wrapper {
		background-color: #f0f0f0;
		padding-top: 4.6rem !important;

		&.grey-wrapper-last {
			margin-bottom: 0;
		}
	}

	.hero & {
		margin-bottom: rem(36);
	}
}

.component,
.row {
	@include clearfix;
	@extend %margin-center;
}

.component-wrapper {
	width: 100%;
	padding-top: 2.5rem!important;
}

.component {
	position: relative;

  	.main-footer &,
  	.slider-manual-full & {
  		height: auto;
  	}
}

.page-wrapper,
.page-wrapper-wide,
.page-wrapper-narrow {
	@extend %p-t-none;
}

.page-wrapper-narrow {

	.row {
		max-width: rem(960);
	}
}

.hero,
.module,
.module-new,
.module-editorial,
.module-offer {
	display: block;

	& + .page-wrapper {
		@extend %p-t-none;
	}
}

.row {
	max-width: rem(1040);
}

.component-narrow {
	max-width: 77.5rem;
	@include margin(null auto);
	@include padding(null 1.83rem);
}

// footer link color
.main-footer {
	a {
		color: #8a8a8a;
		&:hover {
			color: #8a8a8a;
		}
	}
}
//skip to content
.skip {
	height: 1px;
	width: 100%;
	position: absolute;
	overflow: hidden;
	top: -10px;

	&:focus {
		opacity: 1;
		height: auto;
		top: 0;
		z-index: 2000;
		background-color: rgba(255,255,255,	1);
		color: #000;
		font-size: 1.5rem;
		text-transform: uppercase;
		text-align: center;
		@include padding(1.5rem 0.8rem);
	}
}

@media print {
	.main-footer {
		display: none;
	}
}
