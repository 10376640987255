// =============================================================================
//  BUTONS
// =============================================================================

@mixin button-inverted {
    border-color: $teal;
    background-color: $teal;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: #000;
        background-color: transparent;
    }
}

// :not(.md-button):not(.md-list-item-button)
button,
.button {

    &,
    &:disabled {
        display: inline-block;
        @include padding(1.2rem 1.2rem 1rem);
        @include appearance(none);
        color: #000;
        @include base-font-family;
        font-size: 1.17rem;
        font-weight: normal;
        line-height: 1.2;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        vertical-align: middle;
        -webkit-font-smoothing: antialiased;
        background-color: transparent;
        border-radius: 0;
        border: 1px solid #000;
        cursor: pointer;
        user-select: none;
        text-align: center;
        @include transition(all 0.2s ease);

        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .layout-reversed & {
        letter-spacing: 0;
    }

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: $teal;
        border-color: $teal;
        opacity: 1;
    }

    &:disabled {

        &,
        &:hover,
        &:focus,
        &:active {
            cursor: not-allowed;
            opacity: 0.5;
            outline: none;
            color: #000;
            background-color: #fff;
            border-color: #fff;
        }
    }

    &.inverted {
        @include button-inverted;

        .button-radio-wrapper & {
            &:hover,
            &:focus,
            &:active {
                color: #fff;
                background-color: $teal;
            }
        }

         &:disabled {

            &,
            &:hover,
            &:focus,
            &:active {
                color: #fff;
                background-color: $teal;
                border-color: $teal;
            }
        }

        &:hover {
            border-color: #000;
        }
    }

    &.extra {


        position: relative;
        border-color: $teal;
        background-color: $teal;
        color: white;
        text-decoration: none;
        @include padding(1.3rem 6rem 1.1rem 1.3rem);


       &:before {
            @include position(absolute, 50% 0.5rem auto auto);
            margin-top: rem(-6);
           // @include base-bold-font-family;
            font-size: rem(14);
            transform: rotate(90deg);
        }
    }

    &.margin-bottom {
        margin-bottom: rem(10);
    }

    &.min-width {
        display: block;

        @media only screen and (min-width: 40em) {
            min-width: rem(250);
        }
    }

    &.webfont-folder {
        font-size: rem(17);

        &:before {
            top: 28%;
            font-size: 2.5rem;
            @include transform(rotate(0deg));
        }
    }
}

.layout-reversed {
    .button.extra {
        @include padding(1.2rem 1.3rem 1.2rem 6rem);

        &:before {
            @include position(absolute, 50% auto auto 0.5rem);
        }
    }
}

// fluid input + btn group (from bootstrap)
.input-group {
    position: relative;
    display: table;
    border-collapse: separate;

    .form-control {
        display: table-cell;
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0;
    }
    .input-group-button {
        display: table-cell;
        border-collapse: separate;
        width: 1%;
        vertical-align: middle;
    }
}