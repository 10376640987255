.cookies-container {
	@include position(fixed, null 0 0);
	z-index: 1100;
	display: none;
	width: 100%;
	@include padding(0 0.7rem 0.4rem);
	background-color: $pastel-blue;
	border-top: 2px solid #000;

	opacity: 0.95;
	@include transition(all 0.2s ease-in-out);
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	.page-wrapper-wide {
		padding-top: rem(25) !important;
		padding-bottom: rem(15) !important;

		p {
			margin-bottom: 0;
		}
	}

	.row {
		position: relative;
		padding-right: rem(50);
	}
}

.cookies-close {
	@include position(absolute, 0 0 null null);
	opacity: 1;
	color: #000;

	&:hover,
	&:visited,
	&:focus {
		color: #000;
		opacity: 1;
	}
}