#village-google-map {
	@extend %m-none;
	@extend %p-none;
	margin-bottom: rem(60);
}

.google-map-form {
	margin-top: rem(20);

	.controls,
	.button {
		width: 100%;
	}

	.controls {
		width: 100%;

		input[type="text"] {
			width: 100%;

			@include media($medium) {
				min-width: rem(220);
			}
			@media only screen and (min-width: 60em) {
				min-width: rem(250);
			}
		}
		@include media($medium) {
			width: 65%;
			float: left;
		}
	}

	.button {
		margin-bottom: rem(20);
		@include media($medium) {
			width: auto;
			float: right;
		}
	}
}