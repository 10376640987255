/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    @extend %m-none;
    @extend %p-none;
    

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    @include position(relative, 0 null null 0);
    display: block;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    display: none;
    height: 100%;
    min-height: 65px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: inline-block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slider:not(.slider-hero-extended, .slider-hero-content) {
    .slick-dots {
        @include position(absolute, null 0 1rem 0);
        text-align: center;

        li {
            display: inline-block;
            @include padding(null rem(5));
        }

        .slick-active {

            button {
                opacity: 1;
            }
        }

        button {
            width: rem(25);
            height: rem(3);
            @extend %p-none;
            background: #fff;
            border: none;
            border-radius: 0;
            opacity: 0.4;
            @extend %hide-text;
            @include transition(opacity 0.3s ease);

            &:focus {
                outline: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .slick-next,
    .slick-prev {
        z-index: 21;
        @extend %p-none;
        // @extend .webfont;
        // @extend .webfont-cheveron-thin;
        @include webfont('cheveron-thin');
        width: 50px;
        height: 50px;
        @include position(absolute, 50% null null null);
        margin-top: -39px;
        @extend %hide-text;
        border: none;

        &:focus,
        &:hover {
            background-color: transparent;
        }

        &:focus {
            outline: none;
        }
        &:hover {

            &:before {
                opacity: 0.7;
            }
        }

        &:before {
            @include webfont-styles;
            display: block;
            text-align: left;
            text-indent: 0;
            color: $icon-color;
            font-size: rem(46);
            line-height: 1.2;
        }
    }

    .slick-prev {
        left: 0;
        @include transform(rotate(-180deg));
    }

    .slick-next {
        right: 0;
    }

    .slider-manual-wrapper {

        .grid-item & {
            height: 30.8333rem;
            @extend %m-b-lg;
        }
    }
}

.slider-manual {
    position: relative;
    @include margin(2.6em auto 0);
    @include padding(null 2.2rem);

    .slick-slide {
        text-align: center;

        > a {
            display: block;
        }
    }
}

.slider-manual-full {
    margin-bottom: rem(10);
}

.slider-manual-title {
    @extend %margin-center;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;

    .layout-reversed & {
        padding-left: 20px;
        padding-right: 0;
    }

    @include media($medium) {
        text-align: left;
    }

    .main-footer & {
        padding-right: 0;
    }
}

.slider {
    @include clearfix;
    overflow: hidden;
    white-space: nowrap;

    > .slide-cta,
    > .product,
    > .slide-village {
        display: inline-block;
    }
}

.slick-list {
    white-space: normal;

    > .slide-cta,
    > .product,
    > .slide-village {
        display: block;
    }
}

.slider-widget-header {
    position: relative;
    margin-right: 90px;
}

.slider-link-full {
    @include position(absolute, 0 0 null null);
    text-transform: uppercase;
    list-style: 0.2rem;
    color: #999;

    .layout-reversed & {
        @include position(absolute, 0 auto null 0);
    }

    .text {
        display: none;
        font-size: rem(13);
    }

    .icon {
        display: block;
        font-size: 2rem;
        line-height: 0.9;
    }
}

.grid-item {

    .slider-link-full {

        // @media only screen and (min-width: 31.875em) and (max-width: 39.99em) {
        //     .text {
        //         display: block;
        //     }
        //     .icon {
        //         display: none;
        //     }
        // }

        @include media($small) {
            .text {
                display: none;
            }
            .icon {
                display: block;
            }
        }
    }

    @include media($small-only) {
        &.grid-odd-last {
            .slider-link-full {
                .text {
                    display: block;
                }
                .icon {
                    display: none;
                }
            }
        }
    }

    &.grid-3 {
        .slider-link-full {
            @include media($small-only) {
                .text {
                    display: none;
                }
                .icon {
                    display: block;
                }
            }
            @include media($medium) {
                .text {
                    display: block;
                }
                .icon {
                    display: none;
                }
            }
        }
        @include media($small) {
            &.grid-odd-last {
                .slider-link-full {
                    .text {

                        display: block;
                    }
                    .icon {
                        display: none;
                    }
                }
            }
        }
    }
}
