// =============================================================================
//  FORMS
// =============================================================================

fieldset {
	@include margin(0 0 $small-spacing);
	@include padding($base-spacing);
	background-color: lighten($base-border-color, 10%);
	border: $base-border;
}

@if $is-opd == true {
	input:not(.no-style):not(.md-input),
	label:not(.no-style):not(.md-radio-label),
	select:not(.no-style) {
		display: block;
		@include base-font-family;
		font-size: $base-font-size;
		color: $input-text-color;
	}

	// Get rid of yellow bg color on Chrome auto complete
	input:not(.no-style):not(.md-input):-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px white inset;
	}

	// Auto fill hack
	@keyframes onAutoFillStart {  from {/**/}  to {/**/}}
	@keyframes onAutoFillCancel {  from {/**/}  to {/**/}}
	select:not(.no-style):-webkit-autofill {
		// Expose a hook for JavaScript when autofill is shown
		// JavaScript can capture 'animationstart' events
		animation-name: onAutoFillStart;
		-webkit-box-shadow: 0 0 0 1000px white inset;
		transition: background-color 1s ease-in-out 0s;
	}
} @else {
	input,
	label,
	select {
		display: block;
		@include base-font-family;
		font-size: $base-font-size;
		color: $input-text-color;
	}

	// Get rid of yellow bg color on Chrome auto complete
	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px white inset;
	}

	// Auto fill hack
	@keyframes onAutoFillStart {  from {/**/}  to {/**/}}
	@keyframes onAutoFillCancel {  from {/**/}  to {/**/}}
	select:-webkit-autofill {
		// Expose a hook for JavaScript when autofill is shown
		// JavaScript can capture 'animationstart' events
		animation-name: onAutoFillStart;
		-webkit-box-shadow: 0 0 0 1000px white inset;
		transition: background-color 1s ease-in-out 0s;
	}
}

select:not(:-webkit-autofill) {
    // Expose a hook for JS onAutoFillCancel
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillCancel;
}

::-webkit-input-placeholder {
	 color: $input-placeholder-color;
}

:-moz-placeholder { // Firefox 18-
	 color: $input-placeholder-color;
}

::-moz-placeholder {  // Firefox 19+
	 color: $input-placeholder-color;
}

:-ms-input-placeholder {
	 color: $input-placeholder-color;
}

%label-style {
	margin-bottom: $small-spacing / 2;
	color: #000;

	&.required::after {
		content: '*';
	}

	abbr {
		display: none;
	}

	&:hover {
		cursor: text;
	}

	a {
		color: $red;
	}
}

@if $is-opd == true {
	label:not(.no-style):not(.md-radio-label) {
		@extend %label-style;
	}
} @else {
	label {
		@extend %label-style;
	}
}

// List of used inputs
$text-inputs-list:
	'input[type="date"]',
	'input[type="email"]',
	'input[type="number"]',
	'input[type="password"]',
	'input[type="search"]',
	'input[type="tel"]',
	'input[type="text"]',
	'input[type="url"]',
	'select[multiple="multiple"]',
	'textarea';

// Let's assign so we can use the list
$all-text-inputs: assign-inputs($text-inputs-list);

%inputs-style {
	box-sizing: border-box;
	width: 100%;
	margin-bottom: $base-spacing / 2;
	padding: $base-spacing / 3;
	@include base-font-family;
	font-size: $base-font-size;
	transition: border-color;
	background-color: $base-background-color;
	border: 0 solid transparent;
	border-bottom: 1px solid $form-keyline;
	border-radius: 0;

	&:hover {
		border-color: $form-keyline;
	}

	&:focus {
		border-color: $form-keyline;
		outline: none;
	}
}

#{$all-text-inputs} {
	@if $is-opd == true {
		&:not(.no-style):not(.md-input) {
			@extend %inputs-style;
		}
	} @else {
		@extend %inputs-style;
	}
}

// .register-form-content,
// .signin-form-content {
.inpage-form {

	#{$all-text-inputs} {
		border: 1px solid $form-keyline;

		&:hover {
			border-color: $form-keyline;
		}

		&:focus {
			border-color: $form-keyline;
			outline: none;
		}
	}

	p, label {

		a {
			color: $red;
		}
	}

	.button-radio-wrapper {

		.button {
			margin-top: 0;
		}
	}
}

input[type="text"],
input[type="date"],
input[type="time"],
input[type="email"],
input[type="password"] {
	@if $is-opd == true {
		&:not(.no-style) {
			height: rem(35);
			-webkit-appearance: none;
			color: black;
		}
	} @else {
		height: rem(35);
		-webkit-appearance: none;
		color: black;
	}
}

input[type="text"] {

	&.smart-search {
		@extend %m-t-none;
		padding-left: 0.9rem;
		border: none;
		border-radius: $base-border-radius;
	}
}

@if $is-opd == true {
	textarea:not(.no-style) {
		resize: vertical;
		color: black;
	}

	input[type="search"]:not(.no-style) {
		@include appearance(none);
	}
	
	input[type="radio"]:not(.no-style) {
		display: inline;
		margin-right: $small-spacing / 2;
	}
	input[type="checkbox"]:not(.no-style) {
		display: inline;
		margin-right: rem(15);
	}
	
	input[type="file"]:not(.no-style) {
		width: 100%;
		padding-bottom: $small-spacing;
	}
	
	select:not(.no-style) {
		max-width: 100%;
		width: auto;
		margin-bottom: $base-spacing;
		@include appearance(none);
	}
} @else {
	textarea {
		resize: vertical;
		color: black;
	}

	input[type="search"] {
		@include appearance(none);
	}
	
	input[type="radio"] {
		display: inline;
		margin-right: $small-spacing / 2;
	}
	input[type="checkbox"] {
		display: inline;
		margin-right: rem(15);
	}
	
	input[type="file"] {
		width: 100%;
		padding-bottom: $small-spacing;
	}
	
	select {
		max-width: 100%;
		width: auto;
		margin-bottom: $base-spacing;
		@include appearance(none);
	}
}

.input-wrapper {
	position: relative;
}

.checkbox-wrap {
	margin: rem(20) 0 rem(12) 0;
}

.checkbox-wrap + .checkbox-wrap {
	// margin: rem(-15) 0 rem(15) 0;
}


// jQuery validate styles...

span.error {
	@include margin(rem(-5) 0 rem(12));
	color: red;
	display: block !important;
	clear: both;
	text-align: left;

	select + & {
		// margin-top: rem(3);
	}
}
span.valid {
	display: none !important;
}

#nlePrivateSale-error {
	@include position(absolute, null null rem(-20) 0);
	@extend %m-none;
}

.dk-selected.select-error,
.dk-select-multi .select-error,
select.error,
textarea.error,
input.error {
    border: red solid 2px !important;
}

.dk-selected.select-error {
	margin-bottom: rem(6);
}

.select-hidden {
	width: 1px;
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
	opacity: 0;

	&.error {
		border: none !important;
	}
}

.dob-hidden {
	width: 1px;
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
	opacity: 0;

	&.error {
		border: none !important;
	}
}

input[type="checkbox"].error + label:before {
	border: solid 2px red;
}

form div.checkbox-error {
	position: relative;
	margin-top: rem(-10);
	margin-bottom: 2rem;
}

.in-form-text {
	margin-top: 1.5rem;

	a {
		color: $red;
	}
}

.update-form {

	form {
		@extend %m-b-lg;
	}

	.button {
		width: auto !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
		text-transform: none !important;
		letter-spacing: normal !important;
	}
	.preferences {
		margin-top: rem(30);
		width: 100%;
		float: left;
	}
}

.with-hidden-form {
	position: relative;
}

.hidden-form-close {
	@include position(absolute, 95px 15px null null);
	display: none;
	color: #767676;
	padding-right: 28px;

	.close-icon {
		position: absolute;
		right: 0;
		top: -2px;
		width: 20px;
		height: 20px;
		// opacity: 0.3;
		background: #767676;
		border-radius: 50%;

		&:before,
		&:after {
			content: ' ';
			position: absolute;
			top: 4px;
			left: 9px;
			height: 12px;
			width: 2px;
			background-color: #fff;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
}

.hidden-form-wrapper {
	margin-top: 1.5rem;
}

.hidden-form {
	display: none;
	padding: 25px 15px 15px;
	border: solid 1px #e5e5e5;

	.button {
		margin-top: 15px !important;
		margin-bottom: 0 !important;
	}
}

.email-form {
	.button.hidden-form-open {
		margin-top: 0 !important;
	}
}

.password-form {
	.hidden-form-close {
		top: 15px;
	}

	.hidden-form-wrapper {
		margin-top: 0;
	}
}

.button-radio {

	.button {
		color: #2e2e2e;
		border-color: #c2c2c2;

		&.inverted {
			color: #fff;

			&:hover {
				color: #fff;
				background: #000;
				cursor: default;
			}
		}
	}

	.button-radio-wrapper & {
		display: inline-block;
		margin-right: rem(8);

		&:last-child {
			margin-right: 0;
		}

		.button {
			margin-top: 0 !important;
		}
	}
}

.mandatory-field {
	float: right;
	font-size: rem(12);
	color: #9C9C9C;
}

.birthday-selects {
	margin-bottom: 0.25rem;
}

// =============================================================================
//  TOOLTIPS
// =============================================================================

.tooltip-wrapper {
	position: relative;
	float: left;
	width: rem(26);
	margin-left: 2rem;
}

.tooltip-trigger {
	position: relative;
	width: rem(26);
	height: rem(26);
	border-radius: 50%;
	background: #000;
	cursor: pointer;
	@include transition(all 0.3s ease);

	&:hover {
		opacity: 0.6;
	}

	&.webfont-tooltip:before {
		@include position(absolute, 0.4rem null null 0.2rem);
		color: #fff;
		font-size: 1.3rem;
	}
}

// default
@mixin tooltip-left {
	@include position(absolute, 50% 167% auto auto);
	transform: translateY(-55%);
	&:after {
		@include position(absolute, 50% rem(-14) auto auto);
		transform: translateY(-50%) rotate(0);
	}
}

@mixin tooltip-top {
	@include position(absolute, auto auto 175% auto);
	transform: translateX(-50%);
	&:after {
		@include position(absolute, auto auto rem(-20) auto);
		transform: translateX(90px) rotate(90deg);
	}
}

@mixin tooltip-right {
	@include position(absolute, 50% auto auto 167%);
	transform: translateY(-55%);
	&:after {
		@include position(absolute, 50% auto auto rem(-14));
		transform: translateY(-50%) rotate(180deg);
	}
}

@mixin tooltip-bottom {
	@include position(absolute, 150% auto auto auto);
	transform: translateX(-50%);
	&:after {
		@include position(absolute, rem(-20) auto auto auto);
		transform: translateX(90px) rotate(270deg);
	}
}

.tooltip-content {
	z-index: 16;
	display: none;
	width: rem(200);
	@include padding(0.6rem 0.8rem);
	line-height: 1.2;
	color: black;
	background: #fff;
	border: 2px solid #b0a680;
	border-radius: 6px;
	box-shadow: 0 0 30px rgba(0,0,0,0.2);

	min-height: 36px;

	p {
		margin-bottom: 0;
	}

	&.active {
		display: block;
	}

	&:after {
		content: '';
		@include position(absolute, 50% rem(-14) null null);
		display: block;
		position: absolute;
		width: rem(14);
		height: rem(27);
		background: url(../img/src/deco/tooltip-arrow.png) center center no-repeat;
	}

	// TOOLTIP CLASSES

	&, &.tooltip-left 		{@include tooltip-left;}
	&.tooltip-top 			{@include tooltip-top;}
	&.tooltip-right			{@include tooltip-right;}
	&.tooltip-bottom 		{@include tooltip-bottom;}

	// TODO: use breakpoints from grid-settings? ex: @include media($medium-large){...}

	// below 479px
	@media only screen and (max-width: 29.99em) {
		&.tooltip-left-xsm   {@include tooltip-left;}
		&.tooltip-top-xsm	{@include tooltip-top;}
		&.tooltip-right-xsm 	{@include tooltip-right;}
		&.tooltip-bottom-xsm {@include tooltip-bottom;}
	}

	// 480px and up
	@media only screen and (min-width: 30em) {
		&.tooltip-left-sm   {@include tooltip-left;}
		&.tooltip-top-sm	{@include tooltip-top;}
		&.tooltip-right-sm 	{@include tooltip-right;}
		&.tooltip-bottom-sm {@include tooltip-bottom;}
	}

	// 640px and up (form 1 to 2 cols breakpoint)
	@media only screen and (min-width: 40em) {
		&.tooltip-left-md   {@include tooltip-left;}
		&.tooltip-top-md	{@include tooltip-top;}
		&.tooltip-right-md 	{@include tooltip-right;}
		&.tooltip-bottom-md {@include tooltip-bottom;}
	}

	// 960px and up
	@media only screen and (min-width: 60em) {
		&.tooltip-left-ml 	{@include tooltip-left;}
		&.tooltip-top-ml 	{@include tooltip-top;}
		&.tooltip-right-ml  {@include tooltip-right;}
		&.tooltip-bottom-ml {@include tooltip-bottom;}
	}

	// 1280px and up
	@media only screen and (min-width: 80em) {
		&.tooltip-left-lg 	{@include tooltip-left;}
		&.tooltip-top-lg 	{@include tooltip-top;}
		&.tooltip-right-lg  {@include tooltip-right;}
		&.tooltip-bottom-lg {@include tooltip-bottom;}
	}

	// 1400px and up
	@media only screen and (min-width: 87.5em) {
		&.tooltip-left-llg 	{@include tooltip-left;}
		&.tooltip-top-llg 	{@include tooltip-top;}
		&.tooltip-right-llg  {@include tooltip-right;}
		&.tooltip-bottom-llg {@include tooltip-bottom;}
	}

	// 1680px and up
	@media only screen and (min-width: 105em) {
		&.tooltip-left-xlg 	{@include tooltip-left;}
		&.tooltip-top-xlg 	{@include tooltip-top;}
		&.tooltip-right-xlg  {@include tooltip-right;}
		&.tooltip-bottom-xlg {@include tooltip-bottom;}
	}
}

.input-and-tooltip {
	@include clearfix;

	input,
	.select-wrapper {
		width: 86%;
		display: inline-block;
		float: left;
	}

	.tooltip-wrapper {
		float: right;
		margin-top: 0.3rem;
		margin-left: 0;

		@media only screen and (min-width: 40em) {
			margin-left: 2.5rem;
		}
	}
}



.hidden-dependant {
	display: none;
}

.accordion-form {

	&.accordion-wrap.accordion-simple .accordion-trigger {
		cursor: default;
	}

	.select-wrapper.in-form {
		margin-bottom: 0.1rem;
	}

	.accordion-content-2col-col .input-and-tooltip {

		.tooltip-wrapper {
			margin-left: 0 !important;
		}
	}

	.inpage-form & {

		.button {
			margin-top: 2rem;
		}
	}

	.dk-select,
	.dk-select-multi {
	    z-index: initial;
	}
}

#MakeABooking {

	.form-row {
		@include clearfix;
	}

	.control-group {
		width: 48%;

		&:first-child {
			float: left;
		}

		&:last-child {
			float: right;
		}

		@media only screen and (min-width: 34em) and (max-width: 40em) {
			float: none !important;
			width: 100%;
		}
	}

	.checkbox-wrap {
		margin-top: 1.4rem;
		margin-bottom: 1.4rem;

		input {
			@include position(absolute, 0 null null 0);
			margin: 0;
		}

		label {
			@include position(static, auto);

			a {
				font-size: 1rem;
				display: inline;
				color: #000;
				cursor: pointer;
			}
		}
	}
}

.g-recaptcha {
	margin-top: 1rem;
}

.grecaptcha-badge {
	z-index: 2;
}

// align second column change password form with first column field
@include media($small) {
	.password-form {
		margin-top: 26px;
		.button.hidden-form-open {
			margin-top: 0 !important;
		}
	}
}

.layout-reversed {
	.dk-selected:before,
	.searchDd-main:before,
	.webfont:before,
	[class*="webfont-"]:before,
	[class^="webfont-"]:before {
		margin-right: 1.5rem;
	}
}