.hero-home-extended {
    margin-top: -10px;
    @extend %m-b-xl;

    .slider-sync-complex {
        overflow: visible;

        &:after {
            display: none;
        }

        .slick-list,
        .slick-track {
            overflow: hidden;
        }
    }
}

.slider-hero-extended {
    height: calc(100vh - 40px);

    @include media($nav-breakpoint) {
        .no-touchevents & {
            height: calc(100vh - 70px);
        }
    }

    .slider,
    .slick-list,
    .slick-track {
        height: 100%;
    }

    article {
        height: 100%;
        @include transition(height 0.5s ease);
    }

    .full-video {
        padding: 0;
        height: 100%;
    }

    .video-cover-content {
        top: 42%;

        .play-button {
            margin: 0;
        }
    }
}

video {
    z-index: 11;
}

.vjs-control-bar {
    z-index: 12;
}

.slider-sync-simple {

    .slick-slide {

        > a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .vjs-controls-enabled {
        .vjs-control-bar {
            display: none !important;
        }
    }
}

.slider-hero-content {
    width: 96%;
    max-width: rem(1040);
    @include margin(-13.2rem auto null);
    padding-bottom: 1.7rem;
    background: #fff;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    @include transition(opacity 0.5s ease, margin 0.3s ease);

    @include media($medium) {
        margin-top: -11.2rem;
        padding-bottom: 2.1rem;
    }

    @media (max-width: 50em) and (orientation: landscape) {
        margin-top: -8.6rem;
    }

    &.hero-home-loaded {
        opacity: 1;
    }

    &.move-down {
        margin-top: 0;
    }

    * {
        &:focus,
        &:active {
            outline: none;
        }
    }

    body.layout-reversed & {
        @include fancy-carousel-arrows_inverted;
    }

    body:not(.layout-reversed) & {
        @include fancy-carousel-arrows;
    }

    .slick-arrow {
        height: 68px;
        background: #fff;

        &:before {
            top: 28px;
        }

        &:after {
            top: 33px;
        }
    }

    body.layout-reversed & {
        @include fancy-carousel-arrows_inverted;

        .slick-next {
            top: 0;
            left: 5px;

            @include media($medium) {
                top: 68px;
            }

            @include media($small) {
                left: 0;
            }

            &:before,
            &:after {
                left: 1.4rem;
            }
        }

        .slick-prev {
            top: 0;
            right: 5px;

            @include media($medium) {
                right: auto;
                left: 0;
                border-bottom: 1px solid #000;
            }

            &:before,
            &:after {
                right: 1.4rem;
            }
        }
    }

    body:not(.layout-reversed) & {
        @include fancy-carousel-arrows;

        .slick-prev {
            top: 0;
            left: 5px;

            @include media($medium) {
                top: 68px;
            }

            @include media($small) {
                left: 0;
            }

            &:before,
            &:after {
                left: 1.4rem;
            }
        }

        .slick-next {
            top: 0;
            right: 5px;

            @include media($medium) {
                right: auto;
                left: 0;
                border-bottom: 1px solid #000;
            }

            &:before,
            &:after {
                right: 1.4rem;
            }
        }
    }


    .slick-dots {
        @include position(absolute, 1.8rem 0 null);
        z-index: 2;
        text-align: center;
        background: #fff;
        box-shadow: 0 0 10px 10px #fff;

        @include media($medium) {
            top: 7.6rem;
            right: 3rem;
            left: auto
        }
    }
}

@include keyframes(moreBellow) {
    0% {
        opacity: 0;
        @include transform(translateY(0));
    }
    // 30% {
    // 	opacity: 1;
    // }
    50% {
        opacity: 1;
        @include transform(translateY(0.8rem));
    }

    80% {
        opacity: 1;
        @include transform(translateY(0.8rem));
    }

    100% {
        opacity: 0;
        @include transform(translateY(0.8rem));
    }
}

.slide-content-header {
    position: relative;
    @include padding(3.8rem 2.2rem null);
    text-align: center;
    @include webfont('cheveron');

    @include media($medium) {
        @include padding(null 4.4rem);
    }

    &:before {
        @include webfont-styles;
        @include position(absolute, null 0 -1.4rem);
        @include margin(0 auto);
        font-size: 0.6rem;
        opacity: 0;
        @include transform(translateY(0));
        @include animation(moreBellow 2.5s ease infinite);
        color: $teal;
    }

    &.hide-arrow {

        &:before {
            display: none;
        }
    }

    @include media($medium) {
        padding-top: 0;
    }

    h2 {
        position: relative;
        padding-top: 1.6rem;
        text-transform: uppercase;
        font-size: 2.2rem;
        color: $teal;
        font-weight: bold;

        @include media($medium) {
            font-size: 2.6rem;
        }

        &:after {
            content: '';
            display: block;
            width: 4rem;
            height: rem(1);
            @include margin(0.8rem auto 0.8rem);
            background: #000;
        }
    }

    p {
        color: black;
        @include font-family-serif-roman;
        letter-spacing: 0.1rem;
        font-size: 16px;

        .layout-reversed & {
            letter-spacing: 0;
        }

        span {
            position: relative;
            display: inline-block;
            margin-left: 0.4rem;
            padding-left: 0.8rem;
            text-transform: none;

            &:before {
                content: '';
                @include position(absolute, 0.5rem null null 0);
                height: 1rem;
                border-right: 1px solid #a3a3a3;
            }
        }
    }
}

.slide-content-row {
    @include clearfix;
    @include padding(2.8rem 2.2rem 2.2rem);
    transition: all 0.3s ease;

    @include media($medium) {
        @include padding(6rem 4rem 2rem);
    }

    .hide-arrow + & {
        padding-top: 0.6rem;

        @include media($medium) {
            padding-top: 4.2rem;
        }
    }
}

.slide-content-full {
    max-width: 60rem;
    @include margin(null auto);
    text-align: center;
}

.slide-content-col {
    @extend %col-6-medium;

    @include media($medium) {
        @include padding(null 3rem);
    }
}

.slide-content-left {

    p {
        font-size: 1.2rem;
    }

    .slide-content-links {

        @include media($medium) {
            text-align: left;
        }
    }
}

.slide-content-right {
    padding-top: 3rem;

    @include media($medium) {
        padding-top: 0;
    }

    .video-cover-content {
        .play-button {
            margin-top: 0;
        }
    }

    .full-video {
        margin-bottom: 0;
    }

    > img {
        width: 100%;
    }
}

.slide-content-links {
    padding-top: 1.6rem;
    text-align: center;
}

.small-product-carousel {
    padding-bottom: 5.4rem;

    @include media($small) {
        padding-bottom: 0;

        .product {
            @include clearfix;
        }

        .product-image-wrapper {
            float: left;
            width: 56%;
        }

        .product-image {
            display: block;
        }

        .product-image-wrapper {
            height: auto;

            img {
                position: static;
            }
        }

        .small-product-carousel-copy {
            float: right;
            width: 40%;
            margin-top: 6.6%;
            text-align: left;
        }

        .product-price {

            .was,
            .now,
            .sale {
                padding-left: 0;
                max-width: 100px;
            }
        }
        // temp fix
        .small-product-carousel {
            height: rem(172);
        }
    }

    .product-brand {
        text-transform: uppercase;
    }

    .product-overlay-open,
    .product-overlay-close {
        top: rem(2);
        right: rem(1);
    }

    .product-overlay-open {
        line-height: 0.6;
    }

    .product-overlay {
        top: rem(2);
        right: rem(1);

        &.active {
            bottom: 0;
            right: rem(1);
            width: 99%;
            height: 99%;

            @include media($medium) {
                width: 98%;
                height: 98%;
            }
        }
    }
}
