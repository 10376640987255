.nav-main {
	position: static;

	a {
		display: inline-block;
	}

	.your-village-hideshow { // to hide show your village for mobile and desktop
		display: block;
		@include media($nav-breakpoint) {
			.no-touchevents & {
				display: none;
			}
		}
	}

	.your-village-prev { // to hide | from last li when your village is present
		a {
			&:after {
				display: block;
				@include media($nav-breakpoint) {
					.no-touchevents & {
						display: none;
					}
				}
			}
		}
	}

	@include media($nav-breakpoint) {
		.no-touchevents & {
			position: relative;
			margin-top: 1rem;

			// first-level-menu
			> ul {
				float: right;
			}

			// both menus
			&,
			.nav-subnav {

				> ul {
					margin: 0 auto;
				}
			}

			// second level menu
			.nav-subnav {
				> ul {
				    margin: 0 auto;
				    position: relative;
				}
			}

			> ul {
				> .nav-navItem {

					&:hover {

						.nav-subnav {
							transform: translateY(0);
						}

						.nav-arrow-active {
							bottom: -0.6rem;
							opacity: 1;
							background-color: #b5b5b5;
							cursor: default;
							pointer-events: none;
						}

						// Hack for Chrome and Safari
						@media screen and (-webkit-min-device-pixel-ratio:0) {
							.nav-arrow-active {
								bottom: -0.65rem;
							}
						}
						// Microsoft Edge Browser 12+ (All) - @supports method
						@supports (-ms-accelerator:true) {
						  	.nav-arrow-active {
								bottom: -0.6rem;
							}
						}
					}
				}
			}
		}
	}
}

@include media($nav-breakpoint) {
	.no-touchevents {
		.nav-parent {
			position: relative;
			transition: none;

			&:hover {
				color: #000;
				opacity: 1;
			}
		}
	}
}


// Subnavigation
.nav-subnav {
	display: none;
	width: rem(264);
	margin-left: -1.7rem;
	background-color: #eee;


	.touchevents .layout-reversed & {
		margin-left: 0;
		margin-right: -1.7rem;
		margin-left: auto;
	}

	@include media($nav-breakpoint) {
		.no-touchevents & {
			@include position(absolute, 2.6rem 0 null 0);
			z-index: 10;
			display: block;
			width: 100%;
			height: 0;
			margin-left: 0;
			padding-top: 0;
			overflow: hidden;
			border-bottom: none;
			@include transition(height 0s ease 0.2s, padding 0s ease 0.2s);
		}
	}

	.nav-navItem {
		display: block;

		@include media($nav-breakpoint) {
			.no-touchevents & {
				display: inline-block;
				margin: 0.5rem 0 !important;
				padding-top: 0;
				padding-left: 0;
			}
		}

		&:last-child {
			&:after {
	    		display: none;
	    	}
	    }

	    @include media($medium-down) {
	    	.no-touchevents & {
		    	&:after {
					display: none;
		    	}
		    }
	    }
	    .touchevents & {
	    	&:after {
				display: none;
	    	}
	    }

		a {
			color: #000;
			width: 100%;

			@include media($nav-breakpoint) {
				.no-touchevents & {
					width: auto;
					margin-left: rem(12);
					margin-right: 0;

					.layout-reversed & {
						margin-right: rem(12);
						margin-left: 0;
					}
				}
			}
		}

		&.active {
			a {
				@include base-bold-font-family;
			}
		}

	}
}


//	Parent links
.nav-home {

	@include media($large) {
		.no-touchevents & {
			display: none;
		}
	}
}

.nav-main-links {
	position: relative;
	margin-right: 1rem;
	margin-left: 1rem;

	@include media($medium-down) {
		.no-touchevents & {
			margin:0;
		}
	}
	.touchevents & {
		margin:0;
	}
}

.nav-subnav-trigger {
	@include position(absolute, 50% 0 null null);
	z-index: 50;
	display: block;
	transform: translateY(-50%);

	// Cheveron...
	&.webfont-cheveron {

		&:before {
			@include position(absolute, 48% 0 null null);
			@include transition(transform 0.2s linear);
			@include transform( rotate(0) translate3d(0,0,0) );
			@include transform-origin(center 0.15rem);
			display: inline-block;
		    width: 1.1rem;
    		height: 0.6rem;
			font-size: 0.6rem;
			color: $action-color;
			@extend %m-none;
		}

		&.active {

			&:before {
				@include transform( rotate(-180deg) translate3d(0,0,0) );
			}
		}
	}

	@include media($nav-breakpoint) {
		.no-touchevents & {
			display: none !important;
		}
	}
}

// Reverse layout
.layout-reversed {

	.nav-subnav-trigger.webfont-cheveron {

		&:before {
			left: auto;
			right: 40%;
		}
	}
}

.layout-reversed .off-canvas-wrapper {

	.nav-subnav-trigger {
		right: auto;
		left: 0;
		width: 13%;

		&:before {
			right: auto;
			left: -0.3rem;
		}

		&:after {
			left: auto;
			right: 0;
		}

		&:after {
			left: auto;
			right: 0;
		}
	}
}

// IE9
@include media($nav-breakpoint) {
.no-touchevents.ie9 {

	.nav-main {

			> ul > .nav-navItem {
				padding-bottom: 3rem;
			}
		}
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// MOBILE FIRST
.main-header {
	height: $main-navbar-height;
	font-size: 1rem;


	.nav-container {
		.logo-container {
			left: 1.5rem;
			position: absolute;
			-webkit-backface-visibility: hidden;
			transform: translate3d(0,0,0);
			z-index: 5000;

			@include media($medium-down) {
				.no-touchevents & {
					left: 0;
					right: 0;
					width: calc(100% - 9rem);
					@include margin(null auto);
					text-align: center;

					.header-logo {
						display: inline-block;
					}
				}
			}
			.touchevents & {
				left: 0;
				right: 0;
				width: calc(100% - 9rem);
				@include margin(null auto);
				text-align: center;

				.header-logo {
					display: inline-block;
				}
			}

			.header-logo {
				display: block;

				&,
				img {
					position: relative;
					height: $main-navbar-height;
					z-index: 5000;
				    -webkit-backface-visibility: hidden;
				    transform: translate3d(0,0,0);
				}
			}
		}

		.opening-hours {
			h3 {
				text-transform: uppercase;
			}
			.tooltip-wrapper {
				display: none;
			}
		}

        div.nav-subnav {
            .tooltip-wrapper {
                display: block;
                margin-left: 4rem;
                top: -2.75rem;


                .tooltip-trigger {
                    width: 1.5rem;
                    height: 1.5rem;
                }

                .tooltip-trigger:before {
                    left: .15rem;
                    font-size: .8rem;
                }
            }
	    }
	}
}



// MOBILE ONLY
@include media($medium-down) {
	.no-touchevents {
		.main-header {
			.nav-container {

				// first level navigation
				.nav-main {
					> ul {
						> li.nav-navItem {



							.nav-main-links .nav-navLink {
								font-size: 1.1rem;
								color: #7b7b7b;
								letter-spacing: 1px;
								text-transform: uppercase;
							}

							.nav-subnav-trigger {
								cursor: pointer;
								width: 100%;
								height: 50px;
								margin-right: 0;
							}




							.opening-hours {
								display: none;

								ul {
									li {
										// cancel styles that should be applied only to direct children but for some weird reason it doesn't work.
										font-size: 1rem;
										letter-spacing: 0;
										text-transform: none;
										line-height: normal;
									}
								}
							}

							//opened subnav
							&.active {
								.nav-main-links .nav-navLink {
									color: black;
								}
							}
						}
					}
				}

				// sub navigation
				.nav-subnav {
					position: relative;
					background-color: white;
					margin-bottom: 15px;

					padding-top: 15px;

					&:before {
						content: '';
						width: 30px;
						height: 1px;
						background-color: black;
						position: absolute;
						top: 4px;
						left: 20px;
					}

					ul {
						li {
							&.nav-navItem {
								font-size: 1.1rem;
								text-transform: none;
								background: white;
								border-bottom: 0;
								line-height: normal;

								a {
									padding: 10px 0;
								}
							}
						}
					}
				}

				.sidenav {
					.sidenav-navItem-trigger h5 {
						color: #767676;
						font-size: 1rem;
						letter-spacing: 1px;
					}

					.opening-hours {
						ul li {
							line-height: normal;
						}
					}
				}
				.login-register {
					background-color: black;

					ul {
						li {
							a {
								color: $very-light-font-color;
								@include base-bold-font-family;
							}
						}
					}
				}


				.lang-vill {

/*					margin-left: 270px;
					display: block;
					background: red;*/

					.dropdown {
						font-size: 1.1rem;
					}
					.dropdown-button {
						letter-spacing: 1px;
					}
				}
			}
		}

		.site-canvas.active .logo-container {
			z-index: 0;
		}
	}
} // end mobile-only media query


.touchevents {
	.main-header {
		.nav-container {

			// first level navigation
			.nav-main {
				> ul {
					> li.nav-navItem {

						.nav-main-links .nav-navLink {
							font-size: 1.1rem;
							color: #7b7b7b;
							letter-spacing: 1px;
							text-transform: uppercase;
						}

						.nav-subnav-trigger {
							cursor: pointer;
							width: 100%;
							height: 50px;
							margin-right: 0;
						}

						.opening-hours {
							display: none;

							ul {
								li {
									// cancel styles that should be applied only to direct children but for some weird reason it doesn't work.
									font-size: 1rem;
									letter-spacing: 0;
									text-transform: none;
									line-height: normal;
								}
							}
						}

						//opened subnav
						&.active {
							.nav-main-links .nav-navLink {
								color: black;
							}
						}
					}
				}
			}

			// sub navigation
			.nav-subnav {
				position: relative;
				background-color: white;
				margin-bottom: 15px;
				padding-top: 15px;

				&:before {
					content: '';
					width: 30px;
					height: 1px;
					background-color: black;
					position: absolute;
					top: 4px;
					left: 20px;
				}

				ul {
					li {
						&.nav-navItem {
							font-size: 1.1rem;
							text-transform: none;
							background: white;
							border-bottom: 0;
							line-height: normal;

							a {
								padding: 10px 0;
							}
						}
					}
				}
			}

			.sidenav {
				.sidenav-navItem-trigger h5 {
					color: #767676;
					font-size: 1rem;
					letter-spacing: 1px;
				}

				.opening-hours {
					ul li {
						line-height: normal;
					}
				}
			}

			.login-register {
				background-color: black;

				ul {
					li {
						a {
							color: $very-light-font-color;
							@include base-bold-font-family;
						}
					}
				}
			}

			.lang-vill {
				.dropdown {
					font-size: 1.1rem;
				}
				.dropdown-button {
					letter-spacing: 1px;
				}
			}
		}
	}

	.site-canvas.active .logo-container {
		z-index: 0;
	}
}

// DESKTOP
@include media($nav-breakpoint) {
	.no-touchevents {

		.site-canvas {
			padding-top: $top-navbar-height + $main-navbar-height;
		}

		#navTrigger {
			display: none;
		}

		.main-header {
			padding-top: 0;
			padding-bottom: 0;

			border-top: solid $top-navbar-height $teal;
			height: $top-navbar-height + $main-navbar-height;

			.dropdown-button.webfont-cheveron:before, .dropdown-button.dk-selected:before, .dropdown-button.searchDd-main:before, .searchDd-category span.dropdown-button:before {
				color: #fff;
			}

			.nav-container {
				position: relative;
				height: $main-navbar-height;

				.logo-container {
					position: absolute;
					left: calc((100vw - #{$container-max-width})/2);

					.header-logo img {
						// display: inline-block;
						line-height: $main-navbar-height;

						width: 100%;
						max-width: 186px;
					}
				}

				.off-canvas-wrapper {
					height: $main-navbar-height;
					position: static;
					max-width: $container-max-width;
					margin: 0 auto;

					.nav-main {
						position: static;
						margin-top: -$top-navbar-height; //yes weird
						height: $main-navbar-height;
						// top: -$top-navbar-height;

						// MAIN NAVIGATION
						> ul {
							float: right;
							max-width: $container-max-width;
							height: $main-navbar-height;
							margin: 0 auto;
							padding-right: 30px; // space for search

                            > li.nav-navItem.home-link {
                                display: none;
                            }

							> li.nav-navItem {
								float: left;
								height: $main-navbar-height;
								padding-top: 0;
								padding-bottom: 0;
								// for some weird reason the following styles affect all children (and not only direct children as it's supposed to)
								line-height: $main-navbar-height + 4px; // vertical alignment
								text-transform: uppercase;


								.nav-main-links {
									margin: 0;

									.nav-navLink {
										cursor: pointer;
										display: block;
										padding-right: 10px;
										padding-left: 10px;
										font-size: rem(12.6);
										letter-spacing: 1px;
										color: grey;

										&.nav-navLink-noSubnav {
											cursor: default;

											a {
												cursor: pointer;
											}
										}
									}
								}

							}

						}

						.nav-navItem {
							.nav-subnav,
							.nav-subnav-tray {
								opacity: 0;
								display: none;
								pointer-events: none;
							}

							//.nav-navItem
							&.active { // desktop-nav.js

								.nav-subnav,
								.nav-subnav-tray {
									opacity: 1;
									display: block;
									pointer-events: auto;
								}

								.nav-main-links .nav-navLink {
									color: black;
								}
							}

							/*span.arrow-anim{
								background: red;
							}*/
						}

						//.nav-main
						&.opened {
							.nav-subnav {
								pointer-events: auto;

								.nav-subnav-tray {
									transition: transform 0.2s ease-out;
									transform: translateY(0);
								}
							}
						}
						&.closed {
							.nav-subnav {
								pointer-events: auto;

								.nav-subnav-tray {
									opacity: 1;
									display: block;
									transition: transform 0.3s ease-out;
									transform: translateY(-350px);
								}
							}
						}
					}
				}

				.header-lrs {
					position: relative;
					max-width: $container-max-width;
					margin: 0 auto;
					height: $top-navbar-height;
					top: -$top-navbar-height;
					left: auto;
					right: auto;

					.user-nav {
						position: absolute;
						right: 0;
						line-height: 30px;

						.login-register {
							z-index: 200;
							margin-right: 0;
							padding-bottom: 0;

							.dropdown-menu {
								top: $top-navbar-height;
								@include padding(rem(12) rem(12));
							}
						}
					}

					.search, .bag {
						margin-top: $top-navbar-height;
						top: 0;
						right: 0;
					}

					&,
					a {
						color: $very-light-font-color;
					}
				}

				.nav-weather {
					position: absolute;
					left: calc((100vw - #{$container-max-width})/2 + 225px); // temp magic number. needs to be positioned with JS because multilingual
					display: block;
					top: -$top-navbar-height;
					background-color: #000;

					.weather-thumb {
						// top: -$top-navbar-height;
						height: $top-navbar-height;

						.weather-thumb-temp {
							line-height: 23px;

							&:before {
								top: 60%;
							}
						}

					p {
						color: white;
					}
				}

				.weather-main {
						padding-top: 0;
						// margin-top: -30px;
						transform: none !important;

						.weather-main-inner {
							@include padding(rem(12) rem(12));
						}
					}
				}

				.lang-vill {
					position: absolute;
					top: -$top-navbar-height;
					left: calc((100vw - #{$container-max-width})/2);
					margin-left: 0;
					z-index: 5000;
					-webkit-backface-visibility: hidden;
					transform: translate3d(0,0,0);

					a {
						color: #fff;
					}

					.dropdown {
						padding-left: 0 !important;
					}

					ul.dropdown-menu {

						@include padding(rem(12) rem(12));
						border: 1px solid #dedede;

						li {
							&:last-child {
								margin-bottom: 0;
							}
							a {
								color: $medium-gray;
							}
						}
			    	}
				}
			}
		}

		.nav-subnav {
			top: $main-navbar-height;
			overflow-y: hidden;
			height: $navtray-height;
			background-color: transparent;

			pointer-events: none;

			.nav-subnav-tray {
				background-color: #ffffff;
				overflow: hidden;
				// height: $navtray-height;
				padding-bottom: 3rem;
				border-bottom: solid 1px black;

				transform: translateY(-$navtray-height);
				// transform: translateY(-50px); // test
				transition: transform 0.2s ease-out;

				.nav-subnav-tray-wrapper {

					position: relative;
					overflow: hidden; // !
					max-width: $container-max-width;
					margin: 25px auto 0;
					z-index: 200;

					h1 {
						font-size: 1.67rem;
						position: relative;
						padding-bottom: 17px;
						margin-bottom: 15px;
						font-family: $brown-regular;
						color: $teal;

						&:after {
							content: '';
							width: 40px;
							height: 1px;
							background-color: black;
							position: absolute;
							left: 0;
							bottom: 0;
						}
					}

					ul {

						li.nav-navItem {
							width: 100%;
							display: block;
							float: left;
							line-height: 1.2;
							text-transform: none;
							margin-bottom: 0.8rem;

							a {
								margin-left: 0;
								font-size: 1.3rem;

								&.webfont-lock {
									position: relative;

									&:before {
										@include position(absolute, -0.1rem -1.5rem null null);
									}
								}
							}
						}
					}

					h2 {
						font-size: 15px;
						position: relative;
						padding-bottom: 16px;
						margin-bottom: 14px;

						&:after {
							content: '';
							width: 40px;
							height: 1px;
							background-color: black;
							position: absolute;
							left: 0;
							bottom: 0;
						}
					}

					.opening-hours {
						font-size: 13px;

						h3 {
							font-size: 14px;
							margin-bottom: 12px;
						}

						ul {
							li {
								line-height: normal;
								text-transform: none;
							}
						}

						.day {
							width: 40%;
						}
						.opening-time-extra {
							display: none;
						}

						.tooltip-content {
							bottom: 5rem;
							top: auto;
							right: auto;
							left: -7.6rem;
							text-transform: none;
							transform: none;

							&:after {
								top: auto;
								bottom: -1.7rem;
								left: 50%;
								margin-left: rem(-8);
								transform: rotate(90deg);
							}
						}
					}

					.tray-section-wrapper {
						display: flex;

						.tray-section {
							flex: 1;
							flex-grow: 2;
							padding-left: 25px;
							@include clearfix;

							&:first-child {
								padding-left: 0;
							}

							.tray-section-list {
								float: left;
								width: 25%;
								padding-left: 2rem;
								border-right: 1px solid $dark-gray;

								&:first-child {
									padding-left: 0;
								}

								&:last-child {
									border: 0;
								}
							}

							.ellipsis-new {
								height: 80px !important;
							}

							.featured-content-description {
								line-height: 1.5rem;
								text-transform: none;
								font-size: 1.2rem;
							}

							a.nav-featured-link {
								font-size: 13px;
								line-height: 1.5rem;
								color: black;
								letter-spacing: 1px;
								overflow: hidden;
								display: block;
								text-transform: uppercase;
							}

							img.nav-featured-image {
								display: block;
								width: 315px;
								height: 175px;
									object-fit: cover;
							}
						}
					}
				}
			}
		}

		#language,
		#village {
			line-height: $top-navbar-height;
		}

		// Reversed layout for Arabic
		@if $is-opd == true {
			.layout-reversed {
				.main-header .nav-container .off-canvas-wrapper .nav-main>ul>li.nav-navItem {
					float: right;
				}
			}
		}
	}
}// end desktop media query


.layout-reversed {
	.main-header .nav-container .nav-subnav:before {
		right: 20px;
		left: initial;
		.no-touchevents & {
			left: 20px;
			right: initial;
		}
	}
	#language .dropdown-button:before, #village .dropdown-button:before {
		right: initial;
		left: -0.3rem;
		// .no-touchevents & {
		// 	right: -0.1667rem;
		// 	left: auto;
		// }
	}
	.nav-featured-link .arrow-anim {
		left: auto;
		width: 2rem;
	}
	@include media($nav-breakpoint) {
		.no-touchevents & .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper .tray-section-wrapper .tray-section .tray-section-list {
			float: right;
			padding-left: 0;
			padding-right: 25px;
			border-right: none;
			border-left: 1px solid #333;

			&:first-child {
				padding-right: 0;
			}

			&:last-child {
				border-left: none;
			}
		}

		.no-touchevents & .nav-subnav .nav-subnav-tray .nav-subnav-tray-wrapper h1:after {
			left: auto;
			right: 0;
		}

		.no-touchevents & .nav-featured-link .arrow-anim {
			width: 3.5rem;
		    margin-right: 0.8rem;
		    margin-left: 0;
		}
	}
}